import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import useQuery from './useQuery';
import Evaluation from './Evaluation';
import useSchoolYearParams from '../../evaluations/useSchoolYearParams';
import { parse } from 'query-string';

export default function Layout(props) {
  const { location } = useRouter();

  const params = useSchoolYearParams();

  const PAGE_SIZE = 12;

  const result = useQuery({
    location,
    search: parse(location.search),
    PAGE_SIZE,
    account: params.account,
    schoolStart: params.schoolStart,
    schoolEnd: params.schoolEnd
  });

  return (
    <ParentLayout
      location={location}
      extra={{
        schoolStart: params.schoolStart,
        schoolEnd: params.schoolEnd,
        variant: 'stories'
      }}
      breadcrumbs={['Evaluations', 'Projects: Educators']}
      title="Complete Evaluations"
      description={(
        <span>
          This is a list of projects with an evaluation that was completed by the educator.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(evaluationProjectEducator) {
        return (
          <Evaluation
            key={evaluationProjectEducator.id}
            evaluation={evaluationProjectEducator}
          />
        );
      }}
    />
  );
}
