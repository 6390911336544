import React  from 'react';

export default function SupportingTextColumn(props) {
  const { title, subtitle, loading = false } = props;

  if (loading) {
    return (
      <td className="px-6 py-4 whitespace-nowrapx">
        <div className="text-sm text-gray-900 line-clamp-1">
          ...
        </div>
        <div className="text-sm text-gray-500 line-clamp-1">
          ...
        </div>
      </td>
    );
  }

  return (
    <td className="px-6 py-4 whitespace-nowrapx">
      <div className="text-sm text-gray-900 line-clamp-1">
        {title}
      </div>
      <div className="text-sm text-gray-500 line-clamp-1">
        {subtitle}
      </div>
    </td>
  );
}
