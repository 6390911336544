import React from 'react';
import moment from 'moment-timezone';
import FeedItem from './FeedItem';
import { useConnect } from '@lore/query-connect';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../dialogs_new/DialogManager';
import FeedHoverText from './FeedHoverText';
import Card from '../../components/Card';

export default function NoteTemplate(props) {
  const { note, type, title, onClick } = props;

  const creator = useConnect('user.byId', {
    id: note.data.creatorId
  });

  const launch = useDialogLauncher();

  function onClickCreator() {
    launch(DialogManager.UserManage.Config(creator.id));
  }

  return (
    <FeedItem timestamp={moment(note.data.createdAt).fromNow()} last={props.last}>
      <FeedHoverText onClick={onClickCreator}>
        {creator.data.firstName} {creator.data.lastName}
      </FeedHoverText> created a note on the {type} <FeedHoverText onClick={onClick}>
        {title}
      </FeedHoverText>
      <Card className="px-3 py-2 mt-2">
        <span className="text-sm text-cs-gray-700">
          {note.data.text}
        </span>
      </Card>
    </FeedItem>
  );
}
