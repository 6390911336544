import React from 'react';
import { useConnect } from '@lore/query-connect';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import FeedHoverText from '../../feed/FeedHoverText';
import Message from './Message';

export default function ProjectMessage(props) {
  const { message } = props;

  const project = useConnect('project.byId', {
    id: message.data.projectId
  });

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  }, {
    enabled: !!project.data.activityId
  });

  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.ProjectManage.Config(project.id));
  }

  return (
    <Message {...props}>
      {' regarding the project'} <FeedHoverText onClick={onClick}>
        {project.data.title || activity.data.name}
      </FeedHoverText>
    </Message>
  );
}
