import React from 'react';
import Educators from './Educators';
import ParentLayout from './ParentLayout';

export default function Layout(props) {
  return (
    <ParentLayout title="Educators">
      {function(params) {
        return (
          <>
            <Educators params={params} />
          </>
        );
      }}
    </ParentLayout>
  );
}
