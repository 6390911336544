import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Form from './Form';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';

Dialog.propTypes = {
  user: PropTypes.object.isRequired
};

export default function Dialog(props) {
  const { user } = props;

  return (
    <DialogTemplate className="w-full max-w-screen-md">
      <CloseDialogButton onClick={props.onClose} />
      <Form
        user={user}
        onClose={props.onClose}
      />
    </DialogTemplate>
  );
};
