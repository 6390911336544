import React from 'react';
import { useConfig } from '@lore/config';
import classNames from 'classnames';
import useAccount from '../hooks/useAccount';
import Tooltip from './Tooltip';

export default function Network(props) {
  const { showName = true, tooltip = false } = props;

  const { tenant } = useConfig();

  const account = useAccount();

  const src = tenant.networkLogo[account.data.subdomain];
  const name = tenant.networkName[account.data.subdomain];

  if (tooltip) {
    return (
      <div className={classNames(
        'flex items-center space-x-3'
      )}>
        <Tooltip tooltip={(
          <>
            {/*<div className="text-base text-cs-gray-500 mb-1">*/}
            <div className="text-base text-cs-gray-500 mb-2">
              Your Network
            </div>
            <div className="text-base text-cs-gray-900">
              {name}
            </div>
          </>
        )} icon={false} place="right">
        <div className={classNames(
          'flex items-center h-14 w-14 overflow-hidden',
          'shadow-cs-flat -m-px border rounded-cs-10',
          'border-cs-gray-200 bg-white'
        )}>
          <img className="h-11 w-11 object-contain m-auto" src={src} alt="logo" />
        </div>
        </Tooltip>
      </div>
    );
  }

  if (src) {
    return (
      <div className={classNames(
        'flex items-center space-x-3'
      )}>
        <div className={classNames(
          'flex items-center h-14 w-14 overflow-hidden',
          'shadow-cs-flat -m-px border rounded-cs-10',
          'border-cs-gray-200 bg-white'
        )}>
          <img className="h-11 w-11 object-contain m-auto" src={src} alt="logo" />
        </div>
        {/*{showName && (*/}
        {/*  <Link to="/my-network" className={classNames(*/}
        {/*    'flex-1 text-lg font-semibold',*/}
        {/*    isSelected ? 'text-cs-orange-500' : 'text-cs-gray-900'*/}
        {/*  )}>*/}
        {/*    {name}*/}
        {/*  </Link>*/}
        {/*)}*/}
        {showName && (
          <div className={classNames(
            'flex-1 text-lg font-semibold',
            'text-cs-gray-900',
            'line-clamp-2'
          )}>
            {name}
          </div>
        )}
      </div>
    );
  }

  return null;
};
