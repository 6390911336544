import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import getYear from '../dashboard/getYear';
import Redirect from '../../components/Redirect';
import PageHeading from '../../@communityshare/ui/PageHeading';
import ParentLayout from '../dashboard/Layout';
import useAccount from '../../hooks/useAccount';
import { Helmet } from 'react-helmet-async';
import Sidebar from '../_components/Sidebar';
import NavigationGroup from '../_navigation/ThickNavigation/NavigationGroup';
import NavigationLabel from '../_navigation/ThickNavigation/NavigationLabel';
import NavigationDropdown from '../dashboard/NavigationDropdown';
import ScrollToTop from '../../components/ScrollToTop';
import Loader from '../../components/Loader';

export default function Layout(props) {
  const year = getYear(moment());
  const account = useAccount();

  return (
    <div className="flex overflow-hidden h-screen">
      <Sidebar
        title="Evaluations"
        header={(
          <Loader />
        )}
      />
      <div className="p-8 space-y-8">
        <Helmet>
          <title>Evaluations: Redirect</title>
        </Helmet>
        <PageHeading
          breadcrumbs={['Evaluations']}
          // title="School Year"
        />
        <Loader />
        <Redirect to={`/c/${account.data.subdomain}/evaluations/${year}/projects/educators/stories`} />
      </div>
    </div>
  );

  // return (
  //   <div className="p-8 space-y-8">
  //     <Helmet>
  //       <title>Evaluations: Redirect</title>
  //     </Helmet>
  //     <PageHeading
  //       breadcrumbs={['Evaluations']}
  //       // title="School Year"
  //     />
  //     <Redirect to={`/c/${account.data.subdomain}/evaluations/${year}/projects/educators/stories`} />
  //   </div>
  // );
}
