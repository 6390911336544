import _ from 'lodash';
import { payload, PayloadStates } from '@lore/utils';
import payloadToModel from '../utils/payloadToModel';

/**
 * Destroy Blueprint
 */

export default function(key, { Model, modelName, queryClient }) {
  return {
    mutationFn: async function(currentPayload) {
      const nextModel = payloadToModel(currentPayload, Model);

      try {
        await nextModel.destroy();

        return payload(
          nextModel,
          PayloadStates.DELETED
        );
      } catch (err) {
        const response = err.response;
        const error = response.data;
        throw payload(nextModel, PayloadStates.ERROR_DELETING, error);
      }
    },
    onMutate: async function(currentPayload) {
      // const previousData = queryClient.getQueryData([`${modelName}.byId`, variables.id]);
      const optimisticModel = payloadToModel(currentPayload, Model);

      // Create optimistic data
      const optimisticPayload = payload(optimisticModel, PayloadStates.DELETING);

      // Add optimistic model to cache
      // queryClient.setQueryData([`${modelName}.byId`, currentPayload.id], optimisticPayload);
      queryClient.setQueryData([modelName, 'byId', currentPayload.id], optimisticPayload);

      // Return context with the optimistic data
      return {
        previousPayload: currentPayload,
        optimisticPayload
      };
    },
    onSuccess: function(result, variables, context) {
      // queryClient.setQueryData([`${modelName}.byId`, context.previousPayload.id], result);
      queryClient.setQueryData([modelName, 'byId', context.previousPayload.id], result);
    },
    onError: function(error, variables, context) {
      // queryClient.setQueryData([`${modelName}.byId`, context.previousPayload.id], context.previousPayload);
      queryClient.setQueryData([modelName, 'byId', context.previousPayload.id], context.previousPayload);
    },
  }
};
