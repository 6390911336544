import React from 'react';
import PropTypes from 'prop-types';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import Tooltip from '../../../components/Tooltip';

export default function Creator(props) {
  const { project } = props;

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  return (
    <div className="flex space-x-1 items-center text-gray-500 text-sm">
      <Tooltip tooltip="Creator" icon={false}>
        <UserCircleIcon className="h-6 w-6" />
      </Tooltip>
      <span>
          {creator.data.firstName} {creator.data.lastName}
        </span>
    </div>
  );
};
