import React from 'react';
import PropTypes from 'prop-types';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import moment from 'moment-timezone';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../@communityshare/ui/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import RadioButtonField from '../../forms/_fields/RadioButtonField';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose,
    claim
  } = props;

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <EnvelopeIcon className="text-cs-orange-500 h-12 w-12" />
        </div>
      )}
      title="Would you like to decline the invitation?"
      subtitle={(
        <span>
          If the offer is active, the partner will be notified that their invitation was declined
          for the reason you select below. If the offer occurred in the past, the invitation will be
          recorded as having been declined, but the partner will not be notified.
        </span>
      )}
      onClose={onClose}
      alert={alert}
      body={(
        <>
          {claim.data.response === 'Decline' ? (
            <div className="text-sm text-gray-700">
              Invitation has already been declined.
            </div>
          ) : (
            <FormField name="responseMessage" data={data} errors={showErrors && errors} onChange={onChange}>
              {(field) => (
                <>
                  <RadioButtonField
                    name="type"
                    // label="Reason*"
                    value={field.value}
                    error={field.error}
                    // placeholder="Select a role..."
                    options={{
                      data: [
                        `Email address for this educator no longer exists.`,
                        `Educator has not responded to invitation after ${moment().diff(claim.data.createdAt, 'days')} days.`,
                        'Educator is not available for your offer at this time.'
                      ].map((option) => {
                        return {
                          id: option,
                          data: {
                            name: option
                          }
                        }
                      })
                    }}
                    optionLabel="name"
                    onChange={field.onChange}
                  />
                </>
              )}
            </FormField>
          )}
        </>
      )}
      footer={claim.data.response === 'Decline' ? (
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Close
          </OutlineButton>
        </FormTemplateButtonFooter>
      ) : (
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Decline invitation
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
