import React  from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import TextField from './TextField';

export default function CreatorEmail(props) {
  const { project } = props;

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  return (
    <TextField label="Email">
      {creator.state === PayloadStates.FETCHING ? '...' : creator.data.email}
    </TextField>
  );
}
