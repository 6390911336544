import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Claim from './Claim';
import moment from 'moment-timezone';

export default function Claims(props) {
  const { offer, responses = [], emptyState } = props;

  const [timestamp] = useState(moment().toISOString());

  const claims = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          response: {
            $or: responses
          },
          createdAt: {
            $lt: timestamp
          }
        }
      }
    },
    pagination: {
      pageSize: 100
    },
    include: responses.length > 0 ? function(model) {
      return (
        model.id &&
        model.data.offerId === offer.id &&
        responses.indexOf(model.data.response) >= 0
      );
    } : undefined,
    exclude: function(model) {
      return responses.indexOf(model.data.response) < 0;
    }
  });

  if (claims.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (claims.data.length === 0) {
    return (
      <div className="space-y-2">
        {emptyState}
        {/*<EmptyState*/}
        {/*  title="[Educator] You have not been invited to participate in this offer"*/}
        {/*  subtitle="But don't worry! We are working on creating a way for you to let the educator know you're interested."*/}
        {/*/>*/}
      </div>
    );
  }

  return _.sortBy(claims.data, function(claim) {
    return {
      Connect: 0,
      Pending: 1,
      Decline: 2
    }[claim.data.response];
  }).map(function(claim, index) {
    return (
      <Claim
        key={claim.id || claim.cid}
        claim={claim}
      />
    );
  });
};
