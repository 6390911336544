import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageHeading from '../../@communityshare/ui/PageHeading';
import Partners from './Partners';
import MobileNavigation from '../partners/MobileNavigation';

export default function Layout(props) {
  const {
    breadcrumbs,
    title,
    description,
    result,
    pageSize,
    row,
    csvQuery
  } = props;

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Partners: {title}</title>
      </Helmet>
      <MobileNavigation />
      <PageHeading
        breadcrumbs={breadcrumbs}
        title={title}
        description={description}
      />
      <Partners
        result={result}
        pageSize={pageSize}
        row={row}
        csvQuery={csvQuery}
      />
    </div>
  );
}
