import getQueryUtils from '../../pages/projects/queryUtils';

export default function useInteractionTypesFilterParam(search = {}) {
  const queryUtils = getQueryUtils(search, [
    'interactionTypes'
  ]);

  const values = queryUtils.params.interactionTypes;

  if (values.length > 0) {
    return {
      'tagNodes.tagId': {
          $in: values
      }
    };
  }

  return {};
}
