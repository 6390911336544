import React from 'react';
import Card from './Card';

export default function DividerCard(props) {
  const { children } = props;

  return (
    <Card className="overflow-hidden divide-y divide-gray-200">
      {children}
    </Card>
  );
};
