import { extractTextFromQuery } from '../../../utils/query';

export default {
  param: extractTextFromQuery,
  query: function (text) {
    const words = text.split(' ');

    if (text) {
      return {
        $or: words.map(function(word){
          return {
            'evaluationProjectEducatorProject.creator.firstName': {
              $ilike: `%${word}%`
            }
          };
        }).concat(words.map(function(word){
          return {
            'evaluationProjectEducatorProject.creator.lastName': {
              $ilike: `%${word}%`
            }
          };
        }))
      }
    }
  }
}
