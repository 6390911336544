import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

DialogTemplate.propTypes = {
  children: PropTypes.node
};

export default function DialogTemplate(props) {
  const {
    children,
    className = 'max-w-md',
    overflow = false
  } = props;

  const marginMap = {
    'max-w-screen-sm': 'mx-2 sm:mx-auto',
    'max-w-screen-md': 'mx-2 sm:mx-7 md:mx-auto',
    'max-w-screen-lg': 'mx-2 sm:mx-7 lg:mx-auto',
    'max-w-screen-xl': 'mx-2 sm:mx-7 lg:mx-auto'
  };

  return (
    <div className={classNames(
      `rounded-xl shadow-cs-flat bg-white`,
      overflow ? '' : 'overflow-hidden',
      // marginMap[className],
      // 'max-w-screen-lg',
      className
    )}>
      <div className="relative h-fullx">
        {children}
      </div>
    </div>
  );
};
