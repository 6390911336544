import getSearchParamsFromQuery from '../user/getSearchParamsFromQuery';
import insertOrRemoveNumberFromArray from './insertOrRemoveNumberFromArray';

export default function searchUtils(search, onChange) {

  function onChangeNextSearch(queryParam, value) {
    const nextSearch = { ...search };
    nextSearch[queryParam] = value;
    onChange(nextSearch);
  }

  return function getSearchParamValues(queryParamName) {
    const searchParams = getSearchParamsFromQuery(search);

    // get the value for a single search params, e.g. [1,2,3]
    const value = searchParams[queryParamName];

    function onChangeArray(value) {
      const newQuery = insertOrRemoveNumberFromArray(search, queryParamName, value);
      onChangeNextSearch(queryParamName, newQuery[queryParamName]);
    }

    function onChangeValue(value) {
      onChangeNextSearch(queryParamName, value);
    }

    return {
      value,
      onChangeArray,
      onChangeValue
    };
  }
};
