import React from 'react';
import { useConnect } from '@lore/query-connect';
import ClampableText from '../../../components/ClampableText';
import ContentCard from '../../../components_profiles/ContentCard';
import TextContentSection from '../../../components_profiles/TextContentSection';
import MissingField from '../ProjectMissingField';

export default function Description(props) {
  const { project } = props;

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  return (
    <ContentCard className="space-y-4">
      {(project.data.title || activity.data.name) ? (
        <div className="text-2xl text-cs-gray-900 font-semibold">
          {project.data.title || activity.data.name}
        </div>
      ) : (
        <MissingField name="Title" />
      )}
      <TextContentSection title="Summary">
        {project.data.interactionDescription ? (
          <ClampableText markup>
            {project.data.interactionDescription || activity.data.description}
          </ClampableText>
        ) : (
          <MissingField name="Summary" />
        )}
      </TextContentSection>
      {project.data.desiredOutcome && (
        <TextContentSection title="Desired outcome">
          <ClampableText markup>
            {project.data.desiredOutcome}
          </ClampableText>
        </TextContentSection>
      )}
      {project.data.demonstration && (
        <TextContentSection title="How learning will be demonstrated">
          <ClampableText markup>
            {project.data.demonstration}
          </ClampableText>
        </TextContentSection>
      )}
    </ContentCard>
  );
};
