import React from 'react';
import TextField from './TextField';

export default function Slots(props) {
  const { event } = props;

  if (!event.data.slots) {
    return (
      <TextField label="Partners Needed">
        Unspecified
      </TextField>
    );
  }

  return (
    <TextField label="Partners Needed">
      {event.data.slots}
    </TextField>
  );
}
