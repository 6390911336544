import React from 'react';
import PropTypes from 'prop-types';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

NotFoundTemplate.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  alert: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func,
  onPrevious: PropTypes.func,
  showClose: PropTypes.bool
};

NotFoundTemplate.defaultProps = {
  showClose: true
};

export default function NotFoundTemplate(props) {
  const {
    header,
    navigation,
    title,
    subtitle,
    alert,
    body,
    footer,
    onClose,
    onPrevious,
    className,
    showClose
  } = props;

  return (
    <div className={className}>
      {header}
      <div className="pt-8 px-10 md:pt-16 md:px-20">
        {navigation}
        <h5 className="text-3xl text-cs-gray-900 font-semibold text-center">
          {title}
        </h5>
        {subtitle ? (
          <h6 className="mt-2 text-cs-gray-500 text-center text-lgx">
            {subtitle}
          </h6>
        ) : null}
        {onPrevious ? (
          <div className="absolute p-4 -m-4 cursor-pointer top-16 left-10" onClick={onPrevious}>
            <ArrowLeftIcon className="w-8 h-8 text-cs-orange-500 stroke-2" />
          </div>
        ) : null}
      </div>
      {alert}
      {body && (
        <div className="pt-10 px-10 pb-0 md:px-20x">
          {body}
        </div>
      )}
      {footer ? (
        <div className="flex flex-col-reverse sm:flex-row items-center justify-end pt-12 px-10 pb-16 md:px-20">
          {footer}
        </div>
      ) : null}
    </div>
  );
}
