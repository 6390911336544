import React, { useState } from 'react';
import moment from 'moment-timezone';
import { PayloadStates } from '@lore/utils';
import PageHeading from '../../../@communityshare/ui/PageHeading';
import ParentLayout from '../../dashboard/Layout';
import Loader from '../../../components/Loader';
import { Helmet } from 'react-helmet-async';

import useQuestionsMapping from '../questions/useMapping';
import useInvitationsMapping from '../invitations/useMapping';
import useClaimsMapping from '../claims/useMapping';

import useQueryMeta from '../../feed/useQueryMeta';
import { OutlineButton } from '../../../@communityshare/ui/Button';
import MobileNavigation from '../../feed/MobileNavigation';

const increment = 10;

export default function Layout(props) {
  const [maxCount, setMaxCount] = useState(increment);
  const { account, params, pagination } = useQueryMeta();

  const questions = useQuestionsMapping({ account, params, pagination });
  const invitations = useInvitationsMapping({ account, params, pagination });
  const claims = useClaimsMapping({ account, params, pagination });

  if (
    questions.models.state === PayloadStates.FETCHING ||
    invitations.models.state === PayloadStates.FETCHING ||
    claims.models.state === PayloadStates.FETCHING
  ) {
    return (
      <ParentLayout>
        <div className="p-8 space-y-8">
          <Helmet>
            <title>Feed: Invitations</title>
          </Helmet>
          <PageHeading
            breadcrumbs={['Feed']}
            title="Invitations"
            description="List of invitations created over the specified time frame."
          />
          <Loader />
        </div>
      </ParentLayout>
    );
  }

  let items = [].concat(
    questions.entries,
    invitations.entries,
    claims.entries
  );

  items = _.sortBy(items, function(item) {
    return -moment(item.timestamp).unix();
  });

  const visibleItems = items.slice(0, maxCount);

  return (
    <ParentLayout>
      <div className="p-8 space-y-8">
        <Helmet>
          <title>Feed: Invitations</title>
        </Helmet>
        <MobileNavigation />
        <PageHeading
          breadcrumbs={['Feed']}
          title="Invitations"
          description="List of invitations created over the specified time frame."
        />
        <div className="max-w-3xl">
          <ul role="list" className="space-y-6">
            {visibleItems.map(function(item, index) {
              const last = items.length === (index + 1);
              return React.cloneElement(item.value, {
                last: last
              });
            })}
            {maxCount < items.length && (
              <OutlineButton onClick={() => setMaxCount(maxCount + increment)}>
                Show more ({items.length - visibleItems.length})
              </OutlineButton>
            )}
          </ul>
        </div>
      </div>
    </ParentLayout>
  );
}
