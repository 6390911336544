import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import colors from 'tailwindcss/colors';
import DonutChart from '../../dashboard/_charts/DonutChart';
import ChartLoader from '../../dashboard/_charts/ChartLoader';
import useProjectEvaluationEducatorResponse, {
  ResponseFields,
  ResponseAnswers,
  ResponseAnswerMap
} from './_hooks/useProjectEvaluationEducatorResponse';

export default function ProjectEvalResponseYear(props) {
  const { account, schoolStart, schoolEnd, subtitle, field = '', title = [] } = props;

  const responses = [
    ResponseAnswers.Significantly,
    ResponseAnswers.Somewhat,
    ResponseAnswers.NotAtAll,
    ResponseAnswers.NotAGoal,
    ResponseAnswers.NoAnswer
  ];

  const requests = responses.map(function(response) {
    const result = useProjectEvaluationEducatorResponse({
      account,
      schoolStart,
      schoolEnd,
      responseField: field,
      responseAnswer: ResponseAnswerMap[response]
    });

    return {
      label: response,
      projects: result
    };
  });

  const loading = _.reduce(requests, function(result, request) {
    return result || request.projects.state === PayloadStates.FETCHING;
  }, false)

  if (loading) {
    return (
      <ChartLoader
        title={title}
        subtitle="For school year"
      />
    );
  }

  const series = requests.map(r => r.projects.meta.totalCount);
  const labels = requests.map(r => r.label);

  return (
    <DonutChart
      title={title}
      subtitle="For school year"
      labels={labels}
      series={series}
      colors={[
        colors.emerald['400'],
        colors.yellow['400'],
        colors.red['400'],
        colors.blue['400'],
        colors.purple['400']
      ]}
    />
  );
}
