import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import classNames from '../../utils/classNames';

export default function Breadcrumb(props) {
  const { title, index, to } = props;

  return (
    <li>
      <div className={index > 0 ? 'flex items-center' : ''}>
        {index > 0 && (
          <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
        )}
        <a href="#" className={classNames(
          index > 0 ? 'ml-4' : '',
          'text-sm font-medium text-gray-500 hover:text-gray-700'
        )}>
          {title}
        </a>
      </div>
    </li>
  );
}
