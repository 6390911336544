import React from 'react';
import moment from 'moment-timezone';

export default function EvaluationCompleteColumn(props) {
  const { evaluation, onClickView } = props;

  return (
    <div className="flex flex-col text-right">
      <div className="text-lg font-semibold text-green-600 space-x-1">
        <span>Complete</span>
      </div>
      <div className="flex text-sm text-gray-500 space-x-1">
        <span>Completed {moment(evaluation.data.updatedAt).fromNow()}</span>
        {onClickView && (
          <>
            <span>·</span>
            <span className="text-setup-tag hover:text-blue-300 cursor-pointer" onClick={onClickView}>
              View
            </span>
          </>
        )}
      </div>
    </div>
  );
};
