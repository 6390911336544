import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

export default function SleepingTag(props) {
  const { user } = props;

  const isSleeping = moment().diff(user.data.inactiveStartingAt) > 0 && moment().diff(user.data.inactiveEndingAt) < 0;

  if (!isSleeping) {
    return null;
  }

  return (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
      SLEEPING
    </span>
  );
}
