import React from 'react';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';
import useCompletedPartnerProjectEvaluations from '../Students/_hooks/useCompletedPartnerProjectEvaluations';
import usePartnerProjectEvaluations from '../Students/_hooks/usePartnerProjectEvaluations';
import moment from 'moment-timezone';
import ComparisonMetric from '../ComparisonMetric';

export default function CompositePartnerProjectEvaluations(props) {
  const { account, schoolStart, schoolEnd, titleOnly } = props;

  const previousSchoolStart = titleOnly ? undefined : moment(schoolStart).subtract(1, 'year').toISOString();

  const totalEvaluations = usePartnerProjectEvaluations(account, undefined, schoolEnd);
  const previousEvaluations = usePartnerProjectEvaluations(account, previousSchoolStart, schoolStart);
  const newEvaluations = usePartnerProjectEvaluations(account, schoolStart, schoolEnd);

  const totalEvaluationsCompleted = useCompletedPartnerProjectEvaluations(account, undefined, schoolEnd);
  const previousEvaluationsCompleted = useCompletedPartnerProjectEvaluations(account, previousSchoolStart, schoolStart);
  const newEvaluationsCompleted = useCompletedPartnerProjectEvaluations(account, schoolStart, schoolEnd);

  if (
    totalEvaluations.state === PayloadStates.FETCHING ||
    previousEvaluations.state === PayloadStates.FETCHING ||
    newEvaluations.state === PayloadStates.FETCHING ||
    totalEvaluationsCompleted.state === PayloadStates.FETCHING ||
    previousEvaluationsCompleted.state === PayloadStates.FETCHING ||
    newEvaluationsCompleted.state === PayloadStates.FETCHING
  ) {
    return (
      <>
        <MetricLoader
          name="Total partner project evaluations sent"
        />
        <MetricLoader
          name="Total partner project evaluations completed"
        />
        {!titleOnly && (
          <>
            <MetricLoader
              name="Partner project evaluations sent"
            />
            <MetricLoader
              name="Partner project evaluations completed"
            />
          </>
        )}
      </>
    );
  }

  return (
    <>
      <DeltaMetric
        name="Total partner project evaluations sent"
        value={totalEvaluations.meta.totalCount}
        delta={newEvaluations.meta.totalCount}
        titleOnly={titleOnly}
      />
      <DeltaMetric
        name="Total partner project evaluations completed"
        value={totalEvaluationsCompleted.meta.totalCount}
        delta={newEvaluationsCompleted.meta.totalCount}
        titleOnly={titleOnly}
      />
      {!titleOnly && (
        <>
          <ComparisonMetric
            name="Partner project evaluations sent"
            value={newEvaluations.meta.totalCount}
            previousValue={previousEvaluations.meta.totalCount}
            titleOnly={titleOnly}
          />
          <ComparisonMetric
            name="Partner project evaluations completed"
            value={newEvaluationsCompleted.meta.totalCount}
            previousValue={previousEvaluationsCompleted.meta.totalCount}
            titleOnly={titleOnly}
          />
        </>
      )}
    </>
  );
}
