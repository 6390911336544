import React, { useState, useEffect } from 'react';
import Auth0 from 'auth0-js';
import { useConfig } from '@lore/config';
import * as Sentry from '@sentry/browser';
import auth from '../utils/auth';
import storage from '../utils/storage';
import RemoveLoadingScreen from './RemoveLoadingScreen';
import useRouter from '../hooks/useRouter';

export default function AuthCallback(props) {
  const { history } = useRouter();

  const [hasError, setHasError] = useState(false);
  const [errorType, setErrorType] = useState('');
  const [error, setError] = useState(null);

  const config = useConfig();

  function reportError(error, errorInfo) {
    if (config.raven.enabled) {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  useEffect(() => {
    const auth0 = new Auth0.WebAuth(config.auth0);

    auth0.parseHash((err, authResult) => {
      try {
        // throw new Error('AuthCallback:throw');

        if (err) {
          if (err.error === 'login_required') {
            auth0.authorize();
            return;
          }

          if (
            err.error === 'unauthorized' &&
            err.errorDescription.indexOf('Please verify your email before logging in') >= 0
          ) {
            // reportError(new Error('AuthCallback:unauthorized'), err);
            // return history.push('/unauthorized');

            setHasError(true);
            setErrorType('unauthorized');

            return history.push('/unauthorized');
            // return;
          }

          // if (
          //   err.error === 'invalid_token' &&
          //   err.errorDescription === '`state` does not match.'
          // ) {
          //   localStorage.clear();
          // }

          reportError(new Error('AuthCallback:err'), {
            href: window.location.href,
            err: err,
            authResult: authResult
          });

          setHasError(true);

          return;
        }

        if (authResult) {
          if (authResult.accessToken && authResult.idToken) {
            auth.saveToken(authResult.idToken);

            if (storage.has('redirectUrl')) {
              const redirectUrl = storage.get('redirectUrl');
              storage.delete('redirectUrl');
              history.push(redirectUrl);
            } else {
              history.push('/');
            }

            return;
          }

          reportError(new Error('AuthCallback:authResult'), {
            href: window.location.href,
            err: err,
            authResult: authResult
          });

          setHasError(true);

          return;
        }

        reportError(new Error('AuthCallback:fallback'), {
          href: window.location.href,
          err: err,
          authResult: authResult
        });

        setHasError(true);
      } catch(error) {
        reportError(new Error('AuthCallback:catch'), {
          href: window.location.href,
          err: err,
          authResult: authResult,
          error: error
        });

        setHasError(true);
      }
    });
  }, []);

  if (hasError) {
    if (errorType === 'unauthorized') {
      return null;
      // return (
      //   <div>
      //     <RemoveLoadingScreen/>
      //     <NotAuthorized/>
      //   </div>
      // );
    }

    return (
      <div>
        <RemoveLoadingScreen />
      </div>
    );
  }

  return null;
};
