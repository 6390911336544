import React from 'react';
import moment from 'moment-timezone';
import FeedItem from '../../feed/FeedItem';
import { useConnect } from '@lore/query-connect';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import FeedHoverText from '../../feed/FeedHoverText';
import Card from '../../../components/Card';

export default function Message(props) {
  const { message, children } = props;

  const sender = useConnect('user.byId', {
    id: message.data.senderId
  });

  const recipient = useConnect('user.byId', {
    id: message.data.recipientId
  });

  const launch = useDialogLauncher();

  function onClickSender() {
    launch(DialogManager.UserManage.Config(sender.id));
  }

  function onClickRecipient() {
    launch(DialogManager.UserManage.Config(recipient.id));
  }

  return (
    <FeedItem timestamp={moment(message.data.createdAt).fromNow()} last={props.last}>
      <FeedHoverText onClick={onClickSender}>
        {sender.data.firstName} {sender.data.lastName}
      </FeedHoverText> sent a message to <FeedHoverText onClick={onClickRecipient}>
        {recipient.data.firstName} {recipient.data.lastName}
      </FeedHoverText>
      {children}
      <Card className="px-3 py-2 mt-2">
        <span className="text-sm text-cs-gray-700">
          {message.data.content}
        </span>
      </Card>
    </FeedItem>
  );
}
