import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function IconSection(props) {
  const { title, subtitle, xs = false, icon, breakAll = false } = props;

  return (
    <div className="flex items-start space-x-2">
      {props.icon && (
        <props.icon className="text-cs-gray-900 h-6 w-6"/>
      )}
      <div className="flex-1 space-y-0.5">
        <div className={classNames(
          'text-cs-gray-900 text-base',
          breakAll ? 'break-all' : 'break-words'
        )}>
          {title}
        </div>
        <div className={xs ? 'text-xs text-cs-gray-500' : 'text-sm text-cs-gray-500'}>
          {subtitle}
        </div>
      </div>
    </div>
  );
}
