import React from 'react';
import PropTypes from 'prop-types';
import Entry from './Entry';
import ContentCard from '../../../components_profiles/ContentCard';
import SectionHeader from '../../../components_profiles/SectionHeader';

TagsToText.propTypes = {
  tags: PropTypes.object.isRequired
};

export default function TagsToText(props) {
  const { title, description, tags } = props;

  if (tags.data.length === 0) {
    return null;
  }

  return (
    <div className="space-y-4">
      <SectionHeader
        title={title}
        subtitle={description}
      />
      <ContentCard className="space-y-3">
        {tags.data.map(function(tag) {
          return (
            <Entry
              key={tag.id}
              title={tag.data.name}
              description={tag.data.description}
            />
          );
        })}
      </ContentCard>
    </div>
  );
};
