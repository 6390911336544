import React from 'react';
import EducatorProjectEvaluationHeaderSection from '../../pages/evaluations/EducatorProjectEvaluation/EducatorProjectEvaluationHeaderSection';
import ShareLinkSection from '../../pages/evaluations-project-educators/EvaluationProjectEducator/ShareLinkSection';
import Notes from '../../pages/evaluations-project-educators/EvaluationProjectEducator/Notes';
import Card from '../../components/Card';

export default function Evaluation(props) {
  const { project, evaluationProjectEducator } = props;

  return (
    <Card className="overflow-hidden divide-y divide-gray-100">
      <EducatorProjectEvaluationHeaderSection
        evaluation={evaluationProjectEducator}
        created={project.data.creatorId === evaluationProjectEducator.data.userId}
        showViewLink={false}
      />
      {!evaluationProjectEducator.data.completed && (
        <ShareLinkSection evaluation={evaluationProjectEducator} />
      )}
      <div className="bg-gray-50 p-4">
        <Notes evaluationProjectEducator={evaluationProjectEducator} />
      </div>
    </Card>
  );
};
