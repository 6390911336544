import { extractArrayFromQuery } from '../../../utils/query';

export default {
  param: extractArrayFromQuery,
  query: function (values) {
    if (values.length > 0) {
      return {
        $or: values.map(function(value) {
          return {
            published: Number(value) === 2
          }
        })
      }
    }
  }
}
