import React from 'react';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../DialogManager';
import Testimonial from '../../../pages/evaluations/Testimonial';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import { ChatBubbleBottomCenterIcon, PhotoIcon } from '@heroicons/react/24/outline';
import EmptyState from '../../../@communityshare/ui/EmptyState';

export default function Testimonials(props) {
  const { project } = props;

  const educatorEvaluations = useConnect('evaluationProjectEducator.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          completed: true
        }
      }
    }
  });

  const partnerEvaluations = useConnect('evaluationProjectPartner.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          completed: true
        }
      }
    }
  });

  const launch = useDialogLauncher();

  function onClickEducator(evaluation) {
    launch(DialogManager.EvaluationProjectEducator.Config(evaluation.id));
  }

  function onClickPartner(evaluation) {
    launch(DialogManager.EvaluationProjectPartner.Config(evaluation.id));
  }

  if (
    educatorEvaluations.data.length === 0 &&
    partnerEvaluations.data.length === 0
  ) {
    return (
      <EmptyState
        icon={ChatBubbleBottomCenterIcon}
        title="No testimonials were provided."
      />
    );
  }

  return (
    <>
      {educatorEvaluations.data.map(function(evaluation, index) {
        return (
          <Testimonial
            key={`educator-${index}`}
            evaluation={evaluation}
            onClick={() => onClickEducator(evaluation)}
          />
        );
      })}
      {partnerEvaluations.data.map(function(evaluation, index) {
        return (
          <Testimonial
            key={`partner-${index}`}
            evaluation={evaluation}
            onClick={() => onClickPartner(evaluation)}
          />
        );
      })}
    </>
  );
}
