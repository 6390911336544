import React  from 'react';
import moment from 'moment-timezone';
import TextField from '../../projects/_fields/TextField';

export default function LastActive(props) {
  const { user } = props;

  return (
    <TextField label="Last active">
      {moment(user.data.lastActiveAt).fromNow()}
    </TextField>
  );

  return (
    <TextField label="Last active">
      {moment(user.data.lastActiveAt).format('MMM D, YYYY')}
    </TextField>
  );
}
