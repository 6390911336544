import * as Sentry from '@sentry/browser';

export default function(config) {
  const { raven: sentry } = config;

  if (sentry.enabled) {
    Sentry.init({
      dsn: sentry.sentryDSN,
      environment: sentry.environment
    });
  }
};
