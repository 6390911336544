import React from 'react';
import build from './builder';
import useRemoveDialogFromSearchParams from '../_hooks/useRemoveDialogFromSearchParams';

export default function Layout(props) {
  const removeDialogFromSearchParams = useRemoveDialogFromSearchParams();
  const dialogs = build(removeDialogFromSearchParams);

  // console.log(`_launcher/Layout:builder - ${dialogs.length}`);
  //
  // if (dialogs.length > 0) {
  //   const searchParams = new URLSearchParams(window.location.search);
  //   const result = JSON.parse(searchParams.get('dialogs'));
  //   console.log(result);
  // }

  return (
    <>
      {dialogs}
    </>
  );
};
