import React from 'react';
import SchoolYearSelectField from './SchoolYearSelectField';
import EvaluationTypeSelectField from './EvaluationTypeSelectField';
import PageSelectField from './PageSelectField';

export default function MobileNavigation(props) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:hidden mb-8" hidden>
      <SchoolYearSelectField />
      <EvaluationTypeSelectField />
      <PageSelectField />
    </div>
  );
}
