import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Project from '../../projects/Project';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Projects']}
      title="Expired but not Closed"
      description={(
        <div className="space-y-2">
          <div>
            These are projects where the date an educator indicated they wanted to complete the
            project by has passed. CommunityShare recommends focusing your efforts on these projects,
            as getting these resolved will help you with your community's impact reporting. Reach
            out with the goal of getting educators to determine the status of their project.
          </div>
          <div>
            1. Does the educator need more time to complete the project? Have them extend the end date
            of their project.
          </div>
          <div>
            2. Did the educator complete the project? Have them select the “Close Project” button and
            select that the project is done, which will unlock evaluations for participants.
          </div>
          <div>
            3. Will the educator not be able to complete the project this year? Have them select
            the “Close Project” button and the project will be marked as cancelled. They can always
            duplicate the project to try again in the future.
          </div>
        </div>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(project) {
        return (
          <Project key={project.id} project={project} />
        );
      }}
    />
  );
}
