import React from 'react';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Template from '../../../components_profiles/Template';
import useEventTags from '../../../hooks/useEventTags';
import TagTypes from '../../../constants/TagTypes';

export default function Subjects(props) {
  const { event } = props;

  const eventSubjects = useEventTags(event, TagTypes.Subject);

  if (eventSubjects.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (eventSubjects.data.length === 0) {
    return null;
  }

  return (
    <Template
      icon={BookOpenIcon}
      title="Subjects"
      subtitle="Event is related to these subject areas."
      tags={eventSubjects}
    />
  );
};
