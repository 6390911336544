import { parse, stringify } from 'query-string';
import { PayloadStates } from '@lore/utils';
import useStewardshipFilter from '../../events/useStewardshipFilter';
import MISSING_FIELDS from '../../events/MISSING_FIELDS';
import { useConnect } from '../../../../hooks/@lore/query-connect';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, extra = {} } = props;

  const stewardshipFilter = useStewardshipFilter();

  return useConnect('event.find', {
    where: {
      eager: {
        $where: {
          // published: false,
          // cancelled: false,
          activityId: {
            $not: null
          },
          $and: {
            $or: MISSING_FIELDS
          },
          $or: stewardshipFilter,
          ...extra
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    },
    exclude: function(model) {
      return model.state === PayloadStates.DELETED;
    }
  }, {
    keepPreviousData: true
  });
}
