import React from 'react';
import classNames from '../../../utils/classNames';
import { useUser } from '@lore/auth';

export default function SecondaryAction(props) {
  const { onClick, children, secret = false, danger = false } = props;

  const currentUser = useUser();

  if (
    secret &&
    !currentUser.data.isSuperuser
  ) {
    return null;
  }

  return (
    <button
      type="button"
      className={classNames(
        'flex-1 w-full inline-flex items-center justify-center px-4 py-2 rounded-md shadow-sm text-sm font-medium',
        'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        secret ? 'border border-accent text-accent bg-orange-50 hover:bg-orange-100' :
          danger ? 'border border-red-500 text-red-700 bg-red-50 hover:bg-red-100' :
            'border border-gray-300 text-gray-700 bg-white hover:bg-gray-50'
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
