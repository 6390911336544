import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../components/Tooltip';
import classNames from '../../utils/classNames';

export default function FormSection(props) {
  const {
    title,
    subtitle,
    tooltip,
    children,
    className,
    white = false
  } = props;

  return (
    <div className={className}>
      {(title || subtitle || tooltip) && (
        <div className="mb-3">
          <div className="flex items-center space-x-2">
            {title && (
              <div className={classNames(
                `text-xl font-semibold flex-1x`,
                white ? 'text-white' : 'text-gray-700'
              )}>
                {title}
              </div>
            )}
            {title && tooltip && (
              <div>
                <Tooltip tooltip={tooltip} white={white} />
              </div>
            )}
          </div>
          {subtitle && (
            <div className="flex items-center space-x-2">
              <div className={classNames(
                'text-base flex-1x',
                white ? 'text-white' : 'text-gray-500'
              )}>
                {subtitle}
              </div>
              {!title && tooltip && (
                <div>
                  <Tooltip tooltip={tooltip} subtitle={true} white={white} />
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  );
}
