import React from 'react';
import PropTypes from 'prop-types';
import Story from '../stories/Story';
import SectionHeader from '../../components_profiles/SectionHeader';
import useStoriesForUser from './useStoriesForUser';

Stories.propTypes = {
  user: PropTypes.object.isRequired
};

export default function Stories(props) {
  const { user, id } = props;

  const stories = useStoriesForUser(user);

  if (stories.data.length === 0) {
    return null;
  }

  return (
    <div id={id} className="space-y-4">
      <SectionHeader
        title="Impact Stories"
        subtitle="Curated stories of past projects I've done and the impact they had on the students and people involved."
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        {stories.data.map(function(story) {
          return (
            <Story
              key={story.id || story.cid}
              story={story}
            />
          );
        })}
      </div>
    </div>
  );
};
