import React from 'react';
import PropTypes from 'prop-types';

export default function CompletedTag(props) {
  const { resource } = props;

  if (!resource.data.completed) {
    return null;
  }

  return (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
      COMPLETE
    </span>
  );
}
