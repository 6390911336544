import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Section from '../../../components_profiles/Section';
import { useConnect } from '@lore/query-connect';
import Resource from '../../../components_profiles/Resource';
import Images from '../../../pages/story/Story/Images';
import getUploadInfo from '../../../utils/getUploadInfo';
import getUrlForUpload from '../../../utils/getUrlForUpload';

export default function Uploads(props) {
  const { offer } = props;

  const uploads = useConnect('upload.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id
        }
      }
    }
  });

  if (uploads.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (uploads.data.length === 0) {
    return null;
  }

  const uploadImages = {
    data: uploads.data.filter(function(upload) {
      return getUploadInfo(upload.data.name).isImage;
    })
  };

  const uploadDocuments = {
    data: uploads.data.filter(function(upload) {
      return !getUploadInfo(upload.data.name).isImage;
    })
  };

  return (
    <Section
      title="Resources"
      subtitle="Documents uploaded by the partner with more information about the offer."
    >
      {uploadDocuments.data.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2x gap-6">
          {uploadDocuments.data.map(function(upload) {
            return (
              <Resource
                key={upload.id}
                title={upload.data.name}
                description={upload.data.description}
                url={upload.data.url}
              />
            );
          })}
        </div>
      )}
      {uploadImages.data.length > 0 && (
        <Images
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4"
          imageHeight="h-64 sm:h-48 lg:h-40"
          images={{
            data: uploadImages.data.map(function(upload) {
              const url = getUrlForUpload(upload);

              return {
                id: upload.id,
                data: {
                  imageUrl: url,
                  // width: 0,
                  // height: 0
                }
              };
            })
          }}
        />
      )}
    </Section>
  );
}
