import React, { useState } from 'react';
import { OutlineButton } from '../../@communityshare/ui/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import Offers from './Offers';
import StringField from '../../forms/_fields_v2/StringField';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../DialogManager';

export default function Form(props) {
  const {
    user,
    onClose
  } = props;

  const [title, setTitle] = useState('');
  const [name, setName] = useState('');

  const launch = useDialogLauncher();

  function onChangeTitle(text) {
    setTitle(text);
  }

  function onChangeName(text) {
    setName(text);
  }

  function onClick(offer) {
    launch(DialogManager.ClaimInvite.Config(offer.id, user.id));
  }

  return (
    <FormTemplate
      title={`Invite ${user.data.firstName || 'Educator'} to an offer?`}
      subtitle={(
        <span>
          Is there an offer that you think would be a good fit for this educator? Search for it below!
        </span>
      )}
      body={(
        <div className="space-y-8">
          <div className="space-y-4">
            <StringField
              label="Title"
              placeholder="Enter title..."
              value={title}
              onChange={onChangeTitle}
            />
            <StringField
              label="Partner name"
              placeholder="Enter name..."
              value={name}
              onChange={onChangeName}
            />
          </div>
          <Offers
            title={title}
            name={name}
            user={user}
            onClick={onClick}
          />
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
