import React from 'react';
import classNames from 'classnames';

export default function DisclosureFilterCount(props) {
  const {
    count = 0,
    className
  } = props;

  if (count > 0) {
    return (
      <div className={classNames(
        'flex items-center justify-center',
        'bg-cs-gray-900 rounded-full w-8 h-5',
        'text-white text-xs',
        className
      )}>
        {count}
      </div>
    );
  }

  return null;
}
