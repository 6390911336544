import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Disclosure } from '@headlessui/react';
import DisclosureTagFilterButton from './DisclosureTagFilterButton';
import DisclosureFilterCount from '../../pages/_common_filters/DisclosureFilterCount';

DisclosureFilter.propTypes = {
  children: PropTypes.node
};

export default function DisclosureFilter(props) {
  const {
    label,
    tooltip,
    value = [],
    children
  } = props;

  const numberSelected = _.remove(value).length;

  return (
    <Disclosure as="div" defaultOpen={numberSelected > 0}>
      {({ open }) => (
        <>
          <DisclosureTagFilterButton
            // label={numberSelected ? `${label} (${numberSelected})` : label}
            label={(
              <span className="flex items-center text-left space-x-2">
                <span>
                  {label}
                </span>
                <DisclosureFilterCount
                  count={numberSelected}
                />
              </span>
            )}
            tooltip={tooltip}
            open={open}
          />
          <Disclosure.Panel className="px-4 py-4 space-y-4">
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
