import { extractArrayFromQuery } from '../../../utils/query';

const options = [
  'Native American / American Indian or Alaska Native',
  'Asian or Asian American',
  'Black or African American',
  'Hispanic or Latino',
  'Native Hawaiian or Other Pacific Islander',
  'White or Caucasian',
  'Multiracial',
  'Other',
  'Prefer Not to Say'
];

export default {
  param: extractArrayFromQuery,
  query: function (values) {
    if (values.length > 0) {
      return {
        ethnicity: {
          $in: values.map(function(value) {
            return options[Number(value) - 1];
          })
        }
      }
    }
  }
}
