import React from 'react';
import { useConnect } from '@lore/query-connect';
import NoteTemplate from '../../feed/NoteTemplate';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function OfferNote(props) {
  const { note } = props;

  const offer = useConnect('offer.byId', {
    id: note.data.offerId
  });

  const activity = useConnect('tag.byId', {
    id: offer.data.activityId
  }, {
    enabled: !!offer.data.activityId
  });

  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.OfferManage.Config(offer.id));
  }

  return (
    <NoteTemplate
      note={note}
      title={`${offer.data.title || activity.data.name}`}
      type="offer"
      last={props.last}
      onClick={onClick}
    />
  );
}
