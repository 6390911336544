import React  from 'react';
import TextField from './TextField';

export default function Budget(props) {
  const { project } = props;

  return (
    <TextField label="Budget">
      {project.data.budget}
    </TextField>
  );
}
