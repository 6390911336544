import React from 'react';
import { useConnect } from '@lore/query-connect';
import NoteTemplate from '../../feed/NoteTemplate';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function ProjectNote(props) {
  const { note } = props;

  const project = useConnect('project.byId', {
    id: note.data.projectId
  });

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  }, {
    enabled: !!project.data.activityId
  });

  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.ProjectManage.Config(project.id));
  }

  return (
    <NoteTemplate
      note={note}
      title={`${project.data.title || activity.data.name}`}
      type="project"
      last={props.last}
      onClick={onClick}
    />
  );
}
