import React from 'react';
import { useConnect } from '@lore/query-connect';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import FeedHoverText from '../../feed/FeedHoverText';
import Message from './Message';

export default function EventMessage(props) {
  const { message } = props;

  const event = useConnect('event.byId', {
    id: message.data.eventId
  });

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  }, {
    enabled: !!event.data.activityId
  });

  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.EventManage.Config(event.id));
  }

  return (
    <Message {...props}>
      {' regarding the event'} <FeedHoverText onClick={onClick}>
        {event.data.title || activity.data.name}
      </FeedHoverText>
    </Message>
  );
}
