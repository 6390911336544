import React from 'react';
import { useUser } from '@lore/auth';
import { CalendarIcon } from '@heroicons/react/24/outline';
import ContentCard from '../../../components_profiles/ContentCard';
import { formatDate, formatTimeRange } from '../../../utils/dateTimeUtils';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';
import TextContentSection from '../../../components_profiles/TextContentSection';
import MissingField from '../EventMissingField';

export default function Commitment(props) {
  const { event } = props;

  const currentUser = useUser();

  return (
    <ContentCard className="space-y-8">
      <SubSectionHeader
        icon={CalendarIcon}
        title="Date & Time"
      />
      {event.data.date ? (
        <TextContentSection title="Date">
          {formatDate(event.data.date)}
        </TextContentSection>
      ) : (
        <MissingField name="Date" />
      )}
      {(
        event.data.startTime &&
        event.data.endTime
      ) ? (
        <TextContentSection title="Time">
          {formatTimeRange(event.data.startTime, event.data.endTime, currentUser.data.timezone)}
        </TextContentSection>
      ) : (
        <MissingField name="Time" />
      )}
    </ContentCard>
  );
}
