import React  from 'react';
import { useConnect } from '@lore/query-connect';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import Tooltip from '../../../components/Tooltip';

export default function Organization(props) {
  const { project } = props;

  let organization = useConnect('organization.byId', {
    id: project.data.organizationId
  }, {
    enabled: !!project.data.organizationId
  });

  if (!project.data.organizationId) {
    organization = {
      data: {
        name: ''
      }
    };
  }

  return (
    <div className="flex items-center space-x-1">
      <Tooltip tooltip="School" icon={false}>
        <BuildingOfficeIcon className="w-5 h-5 text-gray-500" />
      </Tooltip>
      <div className="text-sm text-gray-500">
        {organization.data.name}
      </div>
    </div>
  );
}
