import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Project from '../../projects/Project';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Projects']}
      title="Expiring Projects"
      description={(
        <span>
          These are projects where the date an educator indicated they wanted to complete the
          project by is less than three weeks away. CommunityShare recommends focusing on finding
          partners to match with these expiring projects. You may also wish to work with the
          educator to extend the end date of the project if more time will be necessary to complete
          the project.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(project) {
        return (
          <Project key={project.id} project={project} />
        );
      }}
    />
  );
}
