import getPageNavigationLinks from './pageNavigationLinks';
import useAccount from '../../../hooks/useAccount';
import { useUser } from '@lore/auth';

export default function useNavigationLinks(props) {
  const account = useAccount();
  const currentUser = useUser();

  const pageNavigationLinks = getPageNavigationLinks(account, currentUser);

  return {
    pageLinks: pageNavigationLinks
  };
}
