import React from 'react';
import PropTypes from 'prop-types';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { PayloadStates } from '@lore/utils';
import useMissingFields from './useMissingFields';
import isSleeping from '../../../utils/isSleeping';
import Tooltip from '../../../components/Tooltip';

export default function Visibility(props) {
  const { user } = props;

  const { missingFields } = useMissingFields(user);

  const sleeping = isSleeping(user);

  if (missingFields.state === PayloadStates.FETCHING) {
    return (
      <div className="flex space-x-1 items-center text-gray-500 text-sm">
        <Tooltip tooltip="Visibility" icon={false}>
          <EyeIcon className="h-6 w-6" />
        </Tooltip>
        <span>
          ...
        </span>
      </div>
    );
  }

  if (missingFields.data.length > 0) {
    return (
      <div className="flex space-x-1 items-center text-gray-500 text-sm">
        <Tooltip tooltip="Visibility" icon={false}>
          <EyeSlashIcon className="h-6 w-6" />
        </Tooltip>
        <span>
          Incomplete
        </span>
      </div>
    );
  }

  if (user.data.inactive) {
    return (
      <div className="flex space-x-1 items-center text-gray-500 text-sm">
        <Tooltip tooltip="Visibility" icon={false}>
          <EyeSlashIcon className="h-6 w-6" />
        </Tooltip>
        <span>
          Inactive
        </span>
      </div>
    );
  }

  if (sleeping) {
    return (
      <div className="flex space-x-1 items-center text-gray-500 text-sm">
        <Tooltip tooltip="Visibility" icon={false}>
          <EyeIcon className="h-6 w-6" />
        </Tooltip>
        <span>
          Sleeping
        </span>
      </div>
    );
  }

  return (
    <div className="flex space-x-1 items-center text-gray-500 text-sm">
      <Tooltip tooltip="Visibility" icon={false}>
        <EyeIcon className="h-6 w-6" />
      </Tooltip>
      <span>
        Active
      </span>
    </div>
  );
};
