import React from 'react';
import { BanknotesIcon } from '@heroicons/react/24/outline';
import Tooltip from '../../../components/Tooltip';

export default function Cost(props) {
  const { offer } = props;

  return (
    <div className="flex items-center space-x-1">
      <Tooltip tooltip="Cost" icon={false}>
        <BanknotesIcon className="w-5 h-5 text-gray-500" />
      </Tooltip>
      <div className="text-sm text-gray-500">
        {offer.data.hasCost ? 'Cost' : 'Free'}
      </div>
    </div>
  );
}
