import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import SuccessMessage from '../../forms/_common/SuccessMessage';
import Form from './Form';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Confirmation from './Confirmation';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

Dialog.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Dialog(props) {
  const { project } = props;

  const [data, setData] = useState({
    published: project.data.published
  });
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('project.update', project);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      // props.onClose();
      // props.onSubmit && props.onSubmit(request);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
  }

  return (
    <DialogTemplate>
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          // confirmation: [validators.isValue('PUBLISH')]
        }}>
          {({errors, hasError}) => {
            if (success) {
              return (
                <Confirmation
                  onClose={() => {
                    props.onClose();
                    setTimeout(function() {
                      props.onSubmit && props.onSubmit(request)
                    }, 300);
                  }}
                />
              );
            }

            return (
              <Form
                data={data}
                errors={errors}
                showErrors={showErrors}
                onChange={onChange}
                onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
                onClose={props.onClose}
                alert={error ? (
                  <RequestError request={request}/>
                ) : showErrors ? (
                  <ErrorMessage>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : (false && success) ? (
                  <SuccessMessage>
                    Idea created.
                  </SuccessMessage>
                ) : null}
                project={project}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
