import React from 'react';
import Section from '../../../pages/_components/sidePanel/Section.new';
import TextContentSection from '../../../components_profiles/TextContentSection';
import ContentCard from '../../../components_profiles/ContentCard';
import Category from '../../../pages/user/User/Category';
import Entry from '../../../pages/user/User/Entry';

export default function Students(props) {
  const { evaluation } = props;

  return (
    <ContentCard className="space-y-4">
      <Category>
        Students
      </Category>
      <div className="space-y-3">
        <Entry
          title="How many students were impacted by this activity?"
          description={evaluation.data.students || '-'}
        />
      </div>
    </ContentCard>
  );

  return (
    <Section title="Students">
      <TextContentSection title="How many students were impacted by this activity?">
        {evaluation.data.students || '-'}
      </TextContentSection>
    </Section>
  );
}
