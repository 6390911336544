import React, { useState } from 'react';
import { parse, stringify } from 'query-string';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import TagTypes from '../../constants/TagTypes';
import SearchFilter from '../../forms/_fields_v2/SearchFilter';
import DisclosureTagFilter from '../../forms/_fields_v2/DisclosureTagFilter';
// import SearchTags from '../../components/SearchTags';
import ClearAllFilters from '../../components/ClearAllFilters';
import { getSearchUtils } from '../../utils/query-utils';
import useQueryBase from '../../pages/evaluations-event-educators/useQuery';
import useQueryStories from '../../pages/evaluations-event-educators-filtered/stories/useQuery';
import useQueryTestimonials from '../../pages/evaluations-event-educators-filtered/testimonials/useQuery';
import useQueryPending from '../../pages/evaluations-event-educators-filtered/pending/useQuery';
import useFilterCount from '../../hooks/useFilterCount';
import useStewardshipFilter from './useStewardshipFilter';
// import REQUIRED_FIELDS_PUBLIC from '../../pages/educators/REQUIRED_FIELDS_PUBLIC';
import { REQUIRED_PARTNER_FIELDS } from '../../utils/user/REQUIRED_FIELDS';
import { SolidButton } from '../../@communityshare/ui/Button';
import DisclosureFilter from '../../forms/_fields_v2/DisclosureFilter';
import { attributeKeys } from '../../pages/educators/queryUtils';
import _ from 'lodash';
import DisclosureBaseFilter from '../../forms/_fields_v2/DisclosureBaseFilter';
import PayloadStates from '../../constants/PayloadStates';
import moment from 'moment-timezone';
import useAccountFilter from './useAccountFilter';

export default function PartnerFiltersDialog(props) {
  const { router, schoolStart, schoolEnd, variant = 'base' } = props;

  const useQuery = _.defaultTo({
    'base': useQueryBase,
    'stories': useQueryStories,
    'testimonials': useQueryTestimonials,
    'pending': useQueryPending
  }[variant], useQueryBase);

  const extra = _.defaultTo({
    'stories': {
      completed: true
    },
    'testimonials': {
      completed: true,
      recommend: 'Yes',
      testimonial: {
        $not: ''
      }
    },
    'pending': {
      completed: false
    }
  }[variant], {});

  const { location, history } = router;

  const [search, setSearch] = useState(parse(location.search));

  const accountFilter = useAccountFilter(search);
  // const stewardshipFilter = useStewardshipFilter(search);
  // const interactionTypesFilter = useInteractionTypesFilterParam(search);
  // const distanceFilter = useDistanceFilterParam(search);

  const filterFields = {
    title: 'title',
    educator: 'educator',
    school: 'school',

    testimonials: 'testimonials',
    recommendPlatforms: 'recommendPlatforms',
    statuses: 'statuses',

    engagedRatings: 'engagedRatings',
    skillsRatings: 'skillsRatings',
    awarenessRatings: 'awarenessRatings',
    understandingRatings: 'understandingRatings',
    practicedRatings: 'practicedRatings',

    activities: 'activities',
    ages: 'ages',
    commitments: 'commitments',
    expertiseTypes: 'expertiseTypes',
    groups: 'groups',
    guidances: 'guidances',
    interactionTypes: 'interactionTypes',
    subjects: 'subjects'
  };

  const filterCount = useFilterCount(search, _.keys(filterFields));

  function onSubmit() {
    history.push({
      pathname: location.pathname,
      search: stringify({
        ...search,
        page: 1
      })
    });
    props.onClose();
  }

  function onChange(nextSearch) {
    setSearch(nextSearch);
  }

  const searchUtils = getSearchUtils(search, onChange);

  const result = useQuery({
    search,
    schoolStart,
    schoolEnd
  });

  function getEventTagParams(tag) {
    return {
      where: {
        eager: {
          $where: {
            'tagNodes.tagId': tag.id,
            ...extra,
            ...accountFilter,
            createdAt: {
              $lte: schoolEnd,
              $gte: schoolStart
            }
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    };
  }

  function getEventActivityParams(tag) {
    return {
      where: {
        eager: {
          $where: {
            'event.activityId': tag.id,
            ...extra,
            ...accountFilter,
            createdAt: {
              $lte: schoolEnd,
              $gte: schoolStart
            }
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    };
  }

  function getRatingParams(param) {
    return function(model) {
      return {
        where: {
          eager: {
            $where: {
              [param]: model.data.name === 'Unanswered' ? {
                $exists: false
              } : {
                $equals: model.data.name
              },
              ...extra,
              ...accountFilter,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              }
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      };
    };
  }

  const ratingTags = {
    state: PayloadStates.RESOLVED,
    data: [
      'Significantly',
      'Somewhat',
      'Not at all',
      'Not a goal',
      'Unanswered',
    ].map(function(name, index) {
      return {
        id: index + 1,
        data: {
          name: name
        }
      };
    })
  };

  return (
    <DialogTemplate className="w-screen max-w-screen-sm h-[calc(100dvh-32px)] max-h-[calc(720px)]">
      <CloseDialogButton onClick={props.onClose} />
      <div className="flex flex-col space-y-8x h-full">
        <div className="space-y-1 p-6 border-b border-cs-gray-200">
          <h2 className="text-cs-gray-900 text-2xl font-semibold">
            Filters
          </h2>
          <div className="text-cs-gray-500 text-sm">
            Narrow your search results.
          </div>
          {/*<div className="pt-4">*/}
          {/*  <SearchTags*/}
          {/*    search={search}*/}
          {/*    onChange={setSearch}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div className="px-6 py-8 space-y-8 overflow-y-auto h-full">
          <div className="space-y-4">
            <DisclosureFilter
              label="Search"
              value={[
                searchUtils(filterFields.title).value,
                searchUtils(filterFields.educator).value,
                searchUtils(filterFields.school).value
              ]}
            >
              <SearchFilter
                label="Event title"
                description="Find evaluations for a event title that matches any of the words you enter."
                value={searchUtils(filterFields.title).value}
                onChange={searchUtils(filterFields.title).onChangeValue}
              />
              <SearchFilter
                label="Educator name"
                description="Find evauations for a event created by an educator whose first or last name matches any of the words you enter."
                value={searchUtils(filterFields.educator).value}
                onChange={searchUtils(filterFields.educator).onChangeValue}
              />
              <SearchFilter
                label="School"
                description="Find evauations for a event affiliated with a school whose name contains the phrase you type."
                value={searchUtils(filterFields.school).value}
                onChange={searchUtils(filterFields.school).onChangeValue}
              />
            </DisclosureFilter>
          </div>
          {variant === 'base' && (
            <>
              <DisclosureBaseFilter
                modelName="evaluationEventEducator"
                label="Status"
                getParams={function getTestimonialParams(model) {
                  return {
                    where: {
                      eager: {
                        $where: {
                          completed: model.data.name === 'Complete' ? true : false,
                          ...extra,
                          ...accountFilter,
                          createdAt: {
                            $lte: schoolEnd,
                            $gte: schoolStart
                          }
                        }
                      }
                    },
                    pagination: {
                      pageSize: 1
                    }
                  };
                }}
                value={searchUtils(filterFields.statuses).value}
                onChange={searchUtils(filterFields.statuses).onChangeArray}
                tags={{
                  state: PayloadStates.RESOLVED,
                  data: [
                    {
                      id: 1,
                      data: {
                        name: 'Complete'
                      }
                    },
                    {
                      id: 2,
                      data: {
                        name: 'Pending'
                      }
                    }
                  ]
                }}
              />
              <DisclosureBaseFilter
                modelName="evaluationEventEducator"
                label="Recommends platform"
                getParams={function getTestimonialParams(model) {
                  const valueMap = {
                    1: 'Yes',
                    2: 'No',
                    3: {
                      $exists: false
                    }
                  };

                  return {
                    where: {
                      eager: {
                        $where: {
                          recommend: valueMap[model.id],
                          ...extra,
                          ...accountFilter,
                          createdAt: {
                            $lte: schoolEnd,
                            $gte: schoolStart
                          }
                        }
                      }
                    },
                    pagination: {
                      pageSize: 1
                    }
                  };
                }}
                value={searchUtils(filterFields.recommendPlatforms).value}
                onChange={searchUtils(filterFields.recommendPlatforms).onChangeArray}
                tags={{
                  state: PayloadStates.RESOLVED,
                  data: [
                    {
                      id: 1,
                      data: {
                        name: 'Yes'
                      }
                    },
                    {
                      id: 2,
                      data: {
                        name: 'No'
                      }
                    },
                    {
                      id: 3,
                      data: {
                        name: 'Not answered'
                      }
                    }
                  ]
                }}
              />
              <DisclosureBaseFilter
                modelName="evaluationEventEducator"
                label="Testimonial provided"
                getParams={function getTestimonialParams(model) {
                  return {
                    where: {
                      eager: {
                        $where: {
                          testimonial: model.data.name === 'Yes' ? {
                            $not: ''
                          } : {
                            $equals: ''
                          },
                          ...extra,
                          ...accountFilter,
                          createdAt: {
                            $lte: schoolEnd,
                            $gte: schoolStart
                          }
                        }
                      }
                    },
                    pagination: {
                      pageSize: 1
                    }
                  };
                }}
                value={searchUtils(filterFields.testimonials).value}
                onChange={searchUtils(filterFields.testimonials).onChangeArray}
                tags={{
                  state: PayloadStates.RESOLVED,
                  data: [
                    {
                      id: 1,
                      data: {
                        name: 'Yes'
                      }
                    },
                    {
                      id: 2,
                      data: {
                        name: 'No'
                      }
                    }
                  ]
                }}
              />
            </>
          )}
          <DisclosureBaseFilter
            modelName="evaluationEventEducator"
            label="Learner(s) were more engaged."
            getParams={getRatingParams('engagedRating')}
            value={searchUtils(filterFields.engagedRatings).value}
            onChange={searchUtils(filterFields.engagedRatings).onChangeArray}
            tags={ratingTags}
          />
          <DisclosureBaseFilter
            modelName="evaluationEventEducator"
            label="Learner(s) built social-emotional skills."
            getParams={getRatingParams('skillsRating')}
            value={searchUtils(filterFields.skillsRatings).value}
            onChange={searchUtils(filterFields.skillsRatings).onChangeArray}
            tags={ratingTags}
          />
          <DisclosureBaseFilter
            modelName="evaluationEventEducator"
            label="Learner(s) increased awareness of future academic and career opportunities."
            getParams={getRatingParams('awarenessRating')}
            value={searchUtils(filterFields.awarenessRatings).value}
            onChange={searchUtils(filterFields.awarenessRatings).onChangeArray}
            tags={ratingTags}
          />
          <DisclosureBaseFilter
            modelName="evaluationEventEducator"
            label="Learner(s) increased understanding of the real-world application of topic/content."
            getParams={getRatingParams('understandingRating')}
            value={searchUtils(filterFields.understandingRatings).value}
            onChange={searchUtils(filterFields.understandingRatings).onChangeArray}
            tags={ratingTags}
          />
          <DisclosureBaseFilter
            modelName="evaluationEventEducator"
            label="Learner(s) practiced critical thinking, problem-solving, and/or working as a team."
            getParams={getRatingParams('practicedRating')}
            value={searchUtils(filterFields.practicedRatings).value}
            onChange={searchUtils(filterFields.practicedRatings).onChangeArray}
            tags={ratingTags}
          />
          <DisclosureTagFilter
            modelName="evaluationEventEducator"
            type={TagTypes.Activity}
            label="Activity"
            getParams={getEventActivityParams}
            value={searchUtils(filterFields.activities).value}
            onChange={searchUtils(filterFields.activities).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="evaluationEventEducator"
            type={TagTypes.ExpertiseType}
            label="Skills and experience"
            getParams={getEventTagParams}
            value={searchUtils(filterFields.expertiseTypes).value}
            onChange={searchUtils(filterFields.expertiseTypes).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="evaluationEventEducator"
            type={TagTypes.Subject}
            label="Subject areas"
            getParams={getEventTagParams}
            value={searchUtils(filterFields.subjects).value}
            onChange={searchUtils(filterFields.subjects).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="evaluationEventEducator"
            type={TagTypes.Age}
            label="Student ages"
            getParams={getEventTagParams}
            value={searchUtils(filterFields.ages).value}
            onChange={searchUtils(filterFields.ages).onChangeArray}
          />
          {/*<DisclosureTagFilter*/}
          {/*  modelName="evaluationEventEducator"*/}
          {/*  type={TagTypes.Day}*/}
          {/*  label="Day"*/}
          {/*  getParams={getEventTagParams}*/}
          {/*  value={searchUtils(filterFields.days).value}*/}
          {/*  onChange={searchUtils(filterFields.days).onChangeArray}*/}
          {/*/>*/}
          <DisclosureTagFilter
            modelName="evaluationEventEducator"
            type={TagTypes.InteractionType}
            label="Interaction types"
            getParams={getEventTagParams}
            value={searchUtils(filterFields.interactionTypes).value}
            onChange={searchUtils(filterFields.interactionTypes).onChangeArray}
          />
          {/*<DisclosureTagFilter*/}
          {/*  modelName="evaluationEventEducator"*/}
          {/*  type={TagTypes.Participant}*/}
          {/*  label="Participants"*/}
          {/*  getParams={getEventTagParams}*/}
          {/*  value={searchUtils(filterFields.participants).value}*/}
          {/*  onChange={searchUtils(filterFields.participants).onChangeArray}*/}
          {/*/>*/}
          {/*<DisclosureTagFilter*/}
          {/*  modelName="evaluationEventEducator"*/}
          {/*  type={TagTypes.Time}*/}
          {/*  label="Time"*/}
          {/*  getParams={getEventTagParams}*/}
          {/*  value={searchUtils(filterFields.times).value}*/}
          {/*  onChange={searchUtils(filterFields.times).onChangeArray}*/}
          {/*/>*/}
          <DisclosureTagFilter
            modelName="evaluationEventEducator"
            type={TagTypes.Commitment}
            label="Commitment"
            getParams={getEventTagParams}
            value={searchUtils(filterFields.commitments).value}
            onChange={searchUtils(filterFields.commitments).onChangeArray}
          />
        </div>
        <div className="p-6 flex items-center justify-between border-t border-cs-gray-200">
          <ClearAllFilters
            show={true}
            className="hover:opacity-70"
            onClick={() => setSearch(_.omit(search, _.keys(filterFields)))}
            count={filterCount}
          />
          <SolidButton onClick={onSubmit}>
            Show {result.meta?.totalCount} results
          </SolidButton>
        </div>
      </div>
    </DialogTemplate>
  );
};
