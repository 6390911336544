import React from 'react';
import PropTypes from 'prop-types';
import { PayloadStates } from '@lore/utils';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Loader from '../../components/Loader';
import Offer from './Offer';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';

OfferInviteDialog.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function OfferInviteDialog(props) {
  const { offer } = props;

  if (offer.state === PayloadStates.FETCHING) {
    return (
      <DialogTemplate>
        <Loader />
      </DialogTemplate>
    );
  }

  return (
    <DialogTemplate className="w-full max-w-screen-lg">
      <CloseDialogButton onClick={props.onClose} />
      <Offer
        offer={offer}
        onClose={props.onClose}
      />
    </DialogTemplate>
  );
};
