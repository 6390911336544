import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useConfig } from '@lore/config';
import classNames from 'classnames';
import ScrollAnchorContext from '../context/ScrollAnchorContext';

export default function DialogTemplate(props) {
  const { childDialog, children, dialog, options = {} } = props;

  let [isOpen, setIsOpen] = useState(false);
  const config = useConfig();
  const modalRef = useRef(null);
  const scrollAnchorRef = useRef(null);

  useEffect(() => {
    openModal();
  }, []);

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false);
    // children.props.onClose && children.props.onClose();
  }

  const { showTransitions } = config.dialogs;

  return (
    <ScrollAnchorContext.Provider value={scrollAnchorRef}>
      <Transition
        show={isOpen}
        as={Fragment}
        afterLeave={props.afterLeave}
        afterEnter={props.afterEnter}
      >
        <Dialog as="div" className="relative z-50" onClose={closeModal} initialFocus={modalRef}>
          <div ref={modalRef} />
          {showTransitions ? (
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>
          ) : (
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          )}
          <div ref={scrollAnchorRef} className="fixed inset-0 overflow-y-auto">
            <div className={classNames(
              'flex items-center justify-center p-4',
              options.topAlign ? '' : 'min-h-full'
            )}>
              {showTransitions ? (
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel>
                    {children ? React.cloneElement(children, {
                      closeModal: closeModal,
                      dismiss: closeModal,
                      onCancel: closeModal,
                      onClose: closeModal
                    }) : null}
                    {dialog ? dialog({ onClose: closeModal }) : null}
                  </Dialog.Panel>
                </Transition.Child>
              ) : (
                <Dialog.Panel>
                  {children ? React.cloneElement(children, {
                    closeModal: closeModal,
                    dismiss: closeModal,
                    onCancel: closeModal,
                    onClose: closeModal
                  }) : null}
                  {dialog ? dialog({ onClose: closeModal }) : null}
                </Dialog.Panel>
              )}
            </div>
          </div>
          {childDialog}
        </Dialog>
      </Transition>
    </ScrollAnchorContext.Provider>
  );
}
