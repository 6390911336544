import React from 'react';
import PropTypes from 'prop-types';
import { PayloadStates } from '@lore/utils';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Loader from '../../components/Loader';
import Event from './EventTemplate';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';

EventManageDialog.propTypes = {
  event: PropTypes.object.isRequired
};

export default function EventManageDialog(props) {
  const { event } = props;

  if (event.state === PayloadStates.FETCHING) {
    return (
      <DialogTemplate>
        <Loader />
      </DialogTemplate>
    );
  }

  return (
    <DialogTemplate className="max-w-screen-lg">
      <CloseDialogButton onClick={props.onClose} />
      <div className="px-6 pt-16 pb-6">
        <Event event={event} />
      </div>
    </DialogTemplate>
  );
};
