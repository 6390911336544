/**
 * This is a utility file you can use to store and retrieve data
 * from localStorage (if available)
 */

import storageAvailable from './storageAvailable';

// If the user's browser doesn't support localStorage, we can use this variable
// to store key/value pairs, though it will be deleted if the page is refreshed.

const temp = {};

export default {

  set: function(key, value) {
    if (storageAvailable('localStorage')) {
      localStorage.setItem(key, value);
    } else {
      temp[key] = value;
    }
  },

  has: function(key) {
    if (storageAvailable('localStorage')) {
      return !!localStorage.getItem(key);
    }

    return !!temp[key];
  },

  get: function(key) {
    if (storageAvailable('localStorage')) {
      return localStorage.getItem(key);
    }

    return temp[key];
  },

  delete: function(key) {
    if (storageAvailable('localStorage')) {
      localStorage.removeItem(key);
    } else {
      delete temp[key];
    }
  }

};
