import React  from 'react';
import { ChatBubbleLeftIcon, ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import Tooltip from '../../../components/Tooltip';

export default function Testimonials(props) {
  const { evaluation } = props;

  return (
    <div className="flex items-center space-x-1">
      <Tooltip tooltip="Testimonial" icon={false}>
        <ChatBubbleLeftIcon className="w-5 h-5 text-gray-500" />
      </Tooltip>
      <div className="text-sm text-gray-500">
        {evaluation.data.testimonial ? 1 : 0}
      </div>
    </div>
  );
}
