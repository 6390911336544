import React from 'react';
import PageHeading from '../../@communityshare/ui/PageHeading';
import ParentLayout from '../settings/Layout';
import Form from './Form';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useAccount from '../../hooks/useAccount';
import { Helmet } from 'react-helmet-async';
import { useQueryClient } from '@tanstack/react-query';
import MobileNavigation from '../settings/MobileNavigation';

export default function Layout(props) {
  const account = useAccount();
  const queryClient = useQueryClient();

  return (
    <ParentLayout>
      <div className="p-8 space-y-8">
        <Helmet>
          <title>Settings: Grants</title>
        </Helmet>
        <MobileNavigation />
        <PageHeading
          breadcrumbs={['Settings']}
          title="Grants"
          description={(
            <>
              <span className="block">
                Would you like to allow Educators to apply for grant consideration when creating
                a project?
              </span>
            </>
          )}
        />
        {account.state === PayloadStates.FETCHING ? (
          <Loader />
        ) : (
          <Form
            account={account}
            onSubmit={function(request) {
              queryClient.invalidateQueries({ queryKey: ['account'] });
            }}
          />
        )}
      </div>
    </ParentLayout>
  );
}
