import React from 'react';
import PropTypes from 'prop-types';
import { CalendarIcon } from '@heroicons/react/24/outline';
import moment from 'moment-timezone';
import Tooltip from '../../../components/Tooltip';

export default function DateRange(props) {
  const { offer } = props;

  return (
    <div className="flex space-x-1 items-center text-gray-500 text-sm">
      <Tooltip tooltip="Date Range" icon={false}>
        <CalendarIcon className="h-6 w-6" />
      </Tooltip>
      {offer.data.specificDate === 'Yes' ? (
        <span>
          {moment(offer.data.date).format('MMM Do, YYYY')}
        </span>
      ) : (
        <span>
          {moment(offer.data.startDate).format('MMM Do, YYYY')} - {moment(offer.data.endDate).format('MMM Do, YYYY')}
        </span>
      )}
    </div>
  );
};
