import React from 'react';
import SelectField from '../../../forms/_fields_v2/SelectField';
import useRouter from '../../../hooks/useRouter';
import useNavigationLinks from '../Sidebar/useNavigationLinks';
import SelectFieldLinkOption from '../../../forms/SelectFieldLinkOption';
import { getEvaluationTypeNavigationLinks } from '../Sidebar/pageNavigationLinks';
import _ from 'lodash';

export default function EvaluationTypeSelectField(props) {
  const { location, history } = useRouter();

  // const pageLinks = getEvaluationTypeNavigationLinks();

  const { evaluationTypeLinks } = useNavigationLinks();

  const activePageLink = _.find(evaluationTypeLinks, function(pageLink) {
    return location.pathname.indexOf(pageLink.href) >= 0;
  });

  return (
    <SelectField
      label="Evaluation type"
      value={activePageLink?.href}
      // placeholder="Select page..."
      options={{
        data: evaluationTypeLinks.map(function(link, index) {
          return {
            id: link.href,
            data: {
              name: link.name
            }
          };
        })
      }}
      optionLabel="name"
      // optionLabel={function(datum) {
      //   return (
      //     <SelectFieldLinkOption
      //       name={datum.data.name}
      //       count={datum.data.count}
      //       highlight={datum.data.highlight}
      //     />
      //   );
      // }}
      onChange={function(id) {
        history.push(id);
      }}
    />
  );
}
