import React from 'react';
import DateWindow from './DateWindow';
import Partners from './Partners';
import Offer from './Offer';
import Bookmark from '../../_components/Bookmark';
import Educator from './Educator';
import Organization from './Organization';
import Cost from './Cost';
import Notes from './Notes';
import Header from '../../../dialogs_new/offer_manage/Header';
import DateRange from '../../../dialogs_new/offer_manage/Header/DateRange';
import Creator from '../../../dialogs_new/offer_manage/Header/Creator';
import Visibility from '../../../dialogs_new/offer_manage/Header/Visibility';
import ShareLink from '../../../dialogs_new/offer_manage/Header/ShareLink';
import Title from './Title';
import StatusTags from './StatusTags';
import Description from './Description';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function Row(props) {
  const { offer, children } = props;

  const launch = useDialogLauncher();

  function onClick(offer) {
    launch(DialogManager.OfferManage.Config(offer.id));
  }

  return (
    <div className="bg-white border border-gray-200 shadow rounded-lg overflow-hidden divide-y divide-gray-200 relative">
      <div className="absolute z-10 top-4 right-5 pt-0.5 pr-0.5">
        <Bookmark
          type="offer"
          resource={offer}
        />
      </div>
      <div className="flex flex-col p-4 space-y-2">
        <div className="space-y-2">
          <StatusTags offer={offer} />
          <div className="cursor-pointer hover:text-gray-500 space-y-1 pr-10" onClick={() => onClick(offer)}>
            <Title offer={offer} />
            <Description offer={offer} />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            <DateRange offer={offer} />
            <Creator offer={offer} />
            <Organization offer={offer} />
            <Cost offer={offer} />
          </div>
          <Partners offer={offer} />
        </div>
      </div>
      {children && (
        <div className="flex p-4">
          {children}
        </div>
      )}
      <div className="bg-gray-50 p-4">
        <Notes offer={offer} />
      </div>
    </div>
  );
}
