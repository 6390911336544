import React from 'react';
import DeltaMetric from './DeltaMetric';

export default function ComparisonMetric(props) {
  const {
    name,
    value,
    previousValue,
    // delta,
    currency = false,
    titleOnly
  } = props;

  const delta = value - previousValue;

  return (
    <DeltaMetric
      name={name}
      value={value}
      delta={delta}
      currency={currency}
      titleOnly={titleOnly}
    />
  );
}
