import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from '../../../components_profiles/SectionHeader';
import Project from '../../projects/Project';
import Event from '../../events/Event';
import usePastQuestionsForUser from '../usePastQuestionsForUser';
import usePastInvitationsForUser from '../usePastInvitationsForUser';
import ProjectImages from './ProjectImages';
import EventImages from './EventImages';
import CardChildrenWrapper from '../../_common_results/CardChildrenWrapper';

PastInvitations.propTypes = {
  user: PropTypes.object.isRequired
};

export default function PastInvitations(props) {
  const { user } = props;

  const pastProjects = usePastQuestionsForUser(user);
  const pastEvents = usePastInvitationsForUser(user);

  if (
    pastProjects.data.length > 0 ||
    pastEvents.data.length > 0
  ) {
    return (
      <div className="space-y-4">
        <SectionHeader
          title="Past Experiences"
          subtitle="Projects and events that I partnered on in the past."
        />
        <div className="grid grid-cols-1 gap-4">
          {pastProjects.data.map(function(project) {
            return (
              <Project
                key={project.id || project.cid}
                project={project}
              >
                <ProjectImages project={project} />
              </Project>
            );
          })}
          {pastEvents.data.map(function(event) {
            return (
              <Event
                key={event.id || event.cid}
                event={event}
              >
                <EventImages event={event} />
              </Event>
            );
          })}
        </div>
      </div>
    );
  }

  return null;
}
