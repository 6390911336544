import { useConnect } from '@lore/query-connect';

export default function UserRole(props) {
  const { role } = props;

  const organization = useConnect('organization.byId', {
    id: role.data.organizationId
  });

  return [
    role.data.title || '...',
    ' at ',
    organization.data.name || '...'
  ].join('');
}
