import React from 'react';
import PropTypes from 'prop-types';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export default function MissingField(props) {
  const {
    name,
    description = (
      <>
        Field must be provided before educator can connect with partners, or
        before the event will be shown to others.
      </>
    )
  } = props;

  return (
    <div className="flex bg-orange-50 shadow-md rounded-lg py-4 px-6 space-x-4">
      <div>
        <ExclamationTriangleIcon className="h-12 w-12 text-accent" />
      </div>
      <div className="flex-1 flex flex-col">
        <div className="text-base font-semibold text-gray-700">
          Missing Field: {name}
        </div>
        <div className="text-base text-gray-500">
          {description}
        </div>
      </div>
    </div>
  );
}
