import React, { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import moment from 'moment-timezone';
import NoteForm from '../../../forms/note';
import Note from '../../../components/Note';
import { UseQueryConnect } from '../../../../hooks/@lore/query-connect';

export default function Notes(props) {
  const { evaluationEventPartner } = props;

  const [expanded, setExpanded] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const _notes = useConnect('note.find', {
    where: {
      eager: {
        $where: {
          evaluationEventPartnerId: evaluationEventPartner.id
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt asc'
    }
  });

  const notes = {
    ..._notes,
    data: _.sortBy(_notes.data, note => -moment(note.data.createdAt).unix())
  }

  const MIN_COUNT = 1;

  const _data = expanded ? notes.data : notes.data.slice(0, MIN_COUNT);

  return (
    <div className="px-6x">
      <div className="flex justify-between text-sm font-semibold">
        <div className="flex space-x-2">
          <div className="text-gray-700">
            Evaluation Notes ({notes.data.length})
          </div>
          {notes.data.length > MIN_COUNT && (
            <div className="text-blue-500 hover:text-blue-400 cursor-pointer" onClick={() => setExpanded(!expanded)}>
              {expanded ? 'Show less' : 'Show more'}
            </div>
          )}
        </div>
        <div className="text-accent hover:text-primary cursor-pointer" onClick={() => setShowForm(!showForm)}>
          {showForm ? 'Hide form' : 'Add note'}
        </div>
      </div>
      {notes.data.length > 0 && (
        <div className="flow-root mt-2">
          <ul className="-mb-4">
            {_data.map(function(note, index) {
              return (
                <UseQueryConnect key={note.id || note.cid} _key="user.byId" params={{
                  id: note.data.creatorId
                }}>
                  {function(creator) {
                    return (
                      <li key={note.id || note.cid}>
                        <div className="relative pb-4">
                          {index !== notes.data.length - 1 ? (
                            <span className="absolute top-5 left-3 -ml-px h-full max-h-6 w-px bg-gray-200"
                                  aria-hidden="true"/>
                          ) : null}
                          <Note
                            model={note}
                            title={`${creator.data.firstName} ${creator.data.lastName}`}
                            imageSrc={creator.data.avatarUrl}
                          />
                        </div>
                      </li>
                    );
                  }}
                </UseQueryConnect>
              );
            })}
          </ul>
        </div>
      )}
      {showForm && (
        <div className="-mx-6 -mb-4 py-1">
          <NoteForm
            extraParams={{
              evaluationEventPartnerId: evaluationEventPartner.id
            }}
            onSubmit={function(note) {
              _notes._add(note);
              setShowForm(false);
            }}
          />
        </div>
      )}
    </div>
  );
}
