/*
 * Install the Google Maps Javascript libraries.
 *
 * This might be an easier (and official) way of interacting with the Maps API:
 * https://github.com/googlemaps/js-api-loader
 */

export default function(config) {
  const { gmaps } = config;

  // <script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyC5i3i-QZ53KpZsxi_qpOA89fP3evwmNx4"></script>
  var load_gmaps = function () {
    var script = document.createElement('script');

    script.type = 'text/javascript';
    // script.async = true;
    script.src = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyC5i3i-QZ53KpZsxi_qpOA89fP3evwmNx4';

    var x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(script, x);
  };

  // <script src="https://cdnjs.cloudflare.com/ajax/libs/gmaps.js/0.4.25/gmaps.min.js" integrity="sha256-7vjlAeb8OaTrCXZkCNun9djzuB2owUsaO72kXaFDBJs=" crossorigin="anonymous"></script>
  var load_maps = function () {
    var script = document.createElement('script');

    script.type = 'text/javascript';
    // script.async = true;
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/gmaps.js/0.4.25/gmaps.min.js';
    script.integrity = 'sha256-7vjlAeb8OaTrCXZkCNun9djzuB2owUsaO72kXaFDBJs=';
    script.crossOrigin = 'anonymous';

    var x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(script, x);
  };

  if (true) {
    if (document.readyState === 'complete') {
      load_gmaps();
      load_maps();
    } else if (window.attachEvent) {
      window.attachEvent('onload', load_gmaps);
      window.attachEvent('onload', load_maps);
    } else {
      window.addEventListener('load', load_gmaps, false);
      window.addEventListener('load', load_maps, false);
    }
  }
};
