import React from 'react';
import PropTypes from 'prop-types';
import { SolidButton } from '../../@communityshare/ui/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import { CheckIcon } from '@heroicons/react/24/outline';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const { onClose, data } = props;

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <CheckIcon className="text-cs-green-500 h-12 w-12" />
        </div>
      )}
      title={data.inactive ? 'Profile is active' : 'Profile is inactive'}
      // subtitle={(
      //   <span>
      //     This will send an email to {educator.data.firstName} nudging them to complete their user.
      //   </span>
      // )}
      success={true}
      onClose={onClose}
      // alert={alert}
      footer={(
        <FormTemplateButtonFooter>
          <SolidButton onClick={onClose}>
            Close
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
