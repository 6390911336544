import _ from 'lodash';
import toJsonKey from '../utils/toJsonKey';
import { payload, PayloadStates } from '@lore/utils';

/**
 * ById Connection Blueprint
 */

export default function(key, params = {}, { Model, Collection, modelName }) {
  const jsonKey = toJsonKey(params);

  if (!params.id) {
    // throw new Error('Missing id');
    // to do: turn this back on...maybe
    // console.log(`Missing id: ${key}`);
  }

  const model = new Model({
    id: params.id
  });

  return {
    queryKey: [modelName, 'byId', params.id],
    queryFn: async function() {
      if (!params.id) {
        throw new Error('Missing id');
      }

      try {
        await model.fetch({
          data: params.query
        });

        return payload(
          model,
          PayloadStates.RESOLVED
        );
      } catch (err) {
        const response = err.response;
        const error = response.data;

        if (response.status === 404) {
          return _.merge(payload(model), {
            state: PayloadStates.NOT_FOUND,
            error: error
          });
        } else {
          return payload(model, PayloadStates.ERROR_FETCHING, error);
        }
      }
    },
    placeholderData: payload(
      model,
      PayloadStates.FETCHING
    )
  }
};
