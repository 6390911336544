import moment from 'moment-timezone';

/*
 * Custom moment locales to replace "a year ago" with "1 year ago"
 * https://github.com/moment/moment/issues/3764
 */
export default function(config) {
  moment.updateLocale('en', {
    relativeTime: {
      m:  '1 minute',
      h:  '1 hour',
      d:  '1 day',
      M:  '1 month',
      y:  '1 year'
    }
  });
};
