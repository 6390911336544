import React  from 'react';
import classNames from 'classnames';

export default function TextField(props) {
  const { label, children, loading = false, align = 'left' } = props;

  const aligned = {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right',
  }[align];

  return (
    <div className="flex flex-col text-sm space-y-1">
      <div className={classNames(
        'text-cs-gray-700 font-semibold',
        aligned
      )}>
        {label}
      </div>
      <div className={classNames(
        'text-cs-gray-500',
        aligned
      )}>
        {loading ? '...' : children}
      </div>
    </div>
  );
}
