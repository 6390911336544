import React from 'react';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import Template from './Template';

export default function Website(props) {
  const { offer } = props;

  if (!offer.data.website) {
    return null;
  }

  return (
    <Template
      icon={GlobeAltIcon}
      title="Website"
      subtitle="More information about this offer can be found at the URL below."
    >
      <a className="block text-cs-orange-500 hover:text-cs-orange-400" href={offer.data.website} target="_blank">
        {offer.data.website}
      </a>
    </Template>
  );
};
