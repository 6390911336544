import React from 'react';
import moment from 'moment-timezone';
import { useUser } from '@lore/auth';
import { marked } from 'marked';
import avatar from '../../../../assets/images/avatar.svg';

export default function Note(props) {
  const { model, title, imageSrc, tag } = props;

  const currentUser = useUser();

  // const timestamp = moment(note.data.createdAt).tz(currentUser.data.timezone).format('h:mm A, MMM D YYYY');
  const timestamp = moment(model.data.createdAt).tz(currentUser.data.timezone);
  const day = timestamp.format('MMM D, YYYY');
  const time = timestamp.format('h:mma');

  return (
    <div className="relative flex items-start space-x-3">
      <div className="relative">
        <img
          className="h-6 w-6 rounded-full bg-gray-400 flex items-center justify-center object-cover"
          src={imageSrc || avatar}
          alt="Avatar"
        />
      </div>
      <div className="min-w-0 flex-1">
        <div className="flex space-x-2">
          <div className="text-sm font-semibold text-gray-700">
            {title}
          </div>
          <div className="text-sm text-gray-500">
            {/*Commented {moment().diff(creator.data.createdAt, 'days')}d ago*/}
            {day} at {time} {tag}
          </div>
        </div>
        <div className="text-sm text-gray-500 mt-0.5">
          <div
            className="space-y-2"
            dangerouslySetInnerHTML={{__html: marked(model.data.text)}}
          />
        </div>
      </div>
    </div>
  )
}
