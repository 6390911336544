import React from 'react';
import PropTypes from 'prop-types';
import Activity from './Activity';
import Description from './Description';
import Commitment from './Commitment';
import Section from '../../_common/Section';
import Participation from './Participation';
import Uploads from './Uploads';
import Experience from './Experience';
import Subjects from './Subjects';
import Website from './Website';
import Cost from './Cost';
import Supplies from './Supplies';
import Participants from './Participants';

export default function Offer(props) {
  const { offer, onClose } = props;

  return (
    <div className="space-y-8">
      <div className="flex flex-col md:flex-row space-x-8">
        <div className="w-full md:w-2/3 space-y-8">
          <Section title="Offer">
            <Activity offer={offer} />
            <div className="w-full space-y-4 block md:hidden">
              <Commitment offer={offer} />
              <Cost offer={offer} />
              <Supplies offer={offer} />
              <Participation offer={offer} />
            </div>
          </Section>
          <Section title="Details">
            <Description offer={offer} />
            <Website offer={offer} />
          </Section>
          <Section
            title="Audience"
            subtitle="Learners the activity is designed for."
          >
            <Participants offer={offer} />
          </Section>
          <Section
            title="Subjects and skills addressed"
            // subtitle="Do you have experience with some or all of these topics?"
            hideIfNoChildren={true}
          >
            <Subjects offer={offer} />
            <Experience offer={offer} />
          </Section>
          <Uploads offer={offer} />
        </div>
        <div className="w-full md:w-1/3 space-y-8 mt-12 hidden md:block">
          <Commitment offer={offer} />
          <Cost offer={offer} />
          <Supplies offer={offer} />
          <Participation offer={offer} />
        </div>
      </div>
    </div>
  );
};
