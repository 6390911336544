import React from 'react';
import { useConnect } from '@lore/query-connect';
import BaseActivity from '../../../components_profiles/BaseActivity';
import MissingField from '../EventMissingField';

export default function Activity(props) {
  const { event } = props;

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  }, {
    enabled: !!event.data.activityId
  });

  if (!event.data.activityId) {
    return (
      <MissingField name="Activity" />
    );
  }

  return (
    <BaseActivity activity={activity} />
  );
};
