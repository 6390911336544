import React from 'react';
import PropTypes from 'prop-types';
import useActiveProjectsForUser from '../useActiveProjectsForUser';
import usePastProjectsForUser from '../usePastProjectsForUser';
import useActiveEventsForUser from '../useActiveEventsForUser';
import usePastEventsForUser from '../usePastEventsForUser';
import useActiveOffersForUser from '../useActiveOffersForUser';
import usePastOffersForUser from '../usePastOffersForUser';
import SectionHeader from '../../../components_profiles/SectionHeader';
import Project from '../../projects/Project';
import Event from '../../events/Event';
import ActiveProjectBanner from './ActiveProjectBanner';
import ActiveEventBanner from './ActiveEventBanner';
import CardChildrenWrapper from '../../_common_results/CardChildrenWrapper';

ActiveExperiences.propTypes = {
  user: PropTypes.object.isRequired
};

export default function ActiveExperiences(props) {
  const { user } = props;

  const activeProjects = useActiveProjectsForUser(user);
  const pastProjects = usePastProjectsForUser(user);
  const activeEvents = useActiveEventsForUser(user);
  const pastEvents = usePastEventsForUser(user);
  const activeOffers = useActiveOffersForUser(user);
  const pastOffers = usePastOffersForUser(user);

  if (
    activeProjects.data.length > 0 ||
    // pastProjects.data.length > 0 ||
    activeEvents.data.length > 0
    // pastEvents.data.length > 0
    // activeOffers.data.length > 0 ||
    // pastOffers.data.length > 0 ||
  ) {
    return (
      <div className="space-y-4">
        <SectionHeader
          title="Active Experiences"
          subtitle="Current projects and events that I'm looking for partners to help with."
        />
        <div className="grid grid-cols-1 gap-4">
          {activeProjects.data.map(function(project) {
            return (
              <Project
                key={project.id || project.cid}
                project={project}
              >
                <CardChildrenWrapper>
                  <ActiveProjectBanner project={project} />
                </CardChildrenWrapper>
              </Project>
            );
          })}
          {activeEvents.data.map(function(event) {
            return (
              <Event
                key={event.id || event.cid}
                event={event}
              >
                <CardChildrenWrapper>
                  <ActiveEventBanner event={event} />
                </CardChildrenWrapper>
              </Event>
            );
          })}
        </div>
      </div>
    );
  }

  return null;
}
