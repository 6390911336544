import TagTypes from '../../constants/TagTypes';

export const MISSING_EDUCATOR_FIELDS = {
  $or: [
    {
      firstName: {
        $or: [
          {
            $equals: ''
          },
          {
            $equals: null
          }
        ]
      },
    },
    {
      lastName: {
        $or: [
          {
            $equals: ''
          },
          {
            $equals: null
          }
        ]
      },
    },
    {
      zipCode: {
        $or: [
          {
            $equals: ''
          },
          {
            $equals: null
          }
        ]
      },
    }
  ]
}

export const MISSING_PARTNER_FIELDS = {
  $or: [
    {
      firstName: {
        $or: [
          {
            $equals: ''
          },
          {
            $equals: null
          }
        ]
      },
    },
    {
      lastName: {
        $or: [
          {
            $equals: ''
          },
          {
            $equals: null
          }
        ]
      },
    },
    {
      zipCode: {
        $or: [
          {
            $equals: ''
          },
          {
            $equals: null
          }
        ]
      },
    },
    {
      tags: {
        $tag_type_count_lte: {
          type: TagTypes.Expertise,
          value: 0
        }
      }
    }
  ]
}
