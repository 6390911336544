import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { parse, stringify } from 'query-string';
import PaginationLinks from './PaginationLinks';
import useRouter from '../../../hooks/useRouter';

export default function Pagination(props) {
  const {
    totalCount,
    pageSize,
    currentPage,
    count,
    onNavigate = () => {}
  } = props;

  const start = currentPage * pageSize - (pageSize - 1);
  // const end = currentPage * pageSize;
  const end = start + count - 1;

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <a
          href="#"
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </a>
        <a
          href="#"
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </a>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{end > 0 ? start : end}</span> to <span className="font-medium">{end}</span> of{' '}
            <span className="font-medium">{totalCount}</span> results
          </p>
        </div>
        <div>
          <PaginationLinks
            totalCount={totalCount}
            pageSize={pageSize}
            onNavigate={onNavigate}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  )
}
