import React from 'react';
import Section from '../../../pages/_components/sidePanel/Section.new';
import TextContentSection from '../../../components_profiles/TextContentSection';
import ContentCard from '../../../components_profiles/ContentCard';
import Category from '../../../pages/user/User/Category';
import Entry from '../../../pages/user/User/Entry';

export default function Reflections(props) {
  const { evaluation } = props;

  return (
    <ContentCard className="space-y-4">
      <Category>
        Reflections
      </Category>
      <div className="space-y-3">
        <Entry
          title="In a few sentences explain why you decided to do this event and what you actually did with your students and partner(s)."
          description={evaluation.data.description || '-'}
        />
        <Entry
          title="How were you, your students and/or your partners changed by this event?"
          description={evaluation.data.changes || '-'}
        />
      </div>
    </ContentCard>
  );

  return (
    <Section title="Reflections">
      <TextContentSection title="In a few sentences explain why you decided to do this event and what you actually did with your students and partner(s).">
        {evaluation.data.description || '-'}
      </TextContentSection>
      <TextContentSection title="How were you, your students and/or your partners changed by this event?">
        {evaluation.data.changes || '-'}
      </TextContentSection>
    </Section>
  );
}
