import React from 'react';
import PropTypes from 'prop-types';
import Invitations from './Invitations';
import EmptyState from '../../_common/EmptyState';
import SectionTitle from '../../_common/SectionTitle';
import { useDialogRouter } from '../../../utils/routeUtils';
import { useSearchParams } from 'react-router-dom';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../DialogManager';

export default function Layout(props) {
  const { event } = props;

  const launch = useDialogLauncher();

  function onInvitePartner() {
    launch(DialogManager.EventInvite.Config(event.id));
  }

  return (
    <div className="page space-y-12">
      <div className="flex space-x-8">
        <div className="flex-1 space-y-12">
          {/*<SectionTitle*/}
          {/*  title="Invitations"*/}
          {/*  subtitle="Partners who expressed positive interest in the event."*/}
          {/*>*/}
            <div className="hover:shadow hover:opacity-80 cursor-pointer" onClick={onInvitePartner}>
              <EmptyState
                title="Find partners to invite"
                // subtitle="The more people that are invited, the better the chances of finding a match!"
                color="blue"
               />
            </div>
          {/*</SectionTitle>*/}
          <SectionTitle
            title="Attending"
            subtitle={(
              <>
                Partners who will be attending the event.
              </>
            )}
          >
            <div className="grid grid-cols-1 gap-4">
              <Invitations
                event={event}
                responses={[
                  'Yes'
                ]}
                emptyState={(
                  <EmptyState
                    title="No partners are attending yet."
                    subtitle="The more people that are invited, the better the chances of finding a match!"
                  >
                    {/*<div className="mt-6 flex flex-col space-y-2">*/}
                    {/*  <div>*/}
                    {/*    <Link className="hover:text-orange-300 hover:underline" to={`/event/${event.id}/invite-populate`}>*/}
                    {/*      Find Partners*/}
                    {/*    </Link>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </EmptyState>
                )}
              />
            </div>
          </SectionTitle>
          <SectionTitle
            title="Maybe"
            subtitle={(
              <>
                Partners who have expressed positive interest in the event, but are unsure if they
                will be able to attend.
              </>
            )}
          >
            <div className="grid grid-cols-1 gap-4">
              <Invitations
                event={event}
                responses={[
                  'Maybe'
                ]}
                emptyState={(
                  <EmptyState
                    title="Invitations without a response will appear here."
                  />
                )}
              />
            </div>
          </SectionTitle>
          <SectionTitle
            title="Invited"
            subtitle={(
              <>
                Partners who were invited to attend but have not yet responded. Consider
                following up with recent invitees, declining older invitations, and inviting
                new partners.
              </>
            )}
          >
            <div className="grid grid-cols-1 gap-4">
              <Invitations
                event={event}
                responses={[
                  'Pending'
                ]}
                emptyState={(
                  <EmptyState
                    title="Invitations without a response will appear here."
                    // subtitle="Extra text..."
                  >
                    {/*<div className="mt-6 flex flex-col space-y-2">*/}
                    {/*  <div>*/}
                    {/*    <Link className="hover:text-orange-300 hover:underline" to={`/event/${event.id}/invite-populate`}>*/}
                    {/*      Find Partners*/}
                    {/*    </Link>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </EmptyState>
                )}
              />
            </div>
          </SectionTitle>
          <SectionTitle
            title="Declined"
            subtitle="Partners who are either uninterested or unable to participate."
          >
            <div className="grid grid-cols-1 gap-4">
              <Invitations
                event={event}
                responses={[
                  'No'
                ]}
                emptyState={(
                  <EmptyState
                    title="Partners who turn down the invitation will appear here."
                    // subtitle="Partners who are unable to participate will show up here."
                  />
                )}
              />
            </div>
          </SectionTitle>
        </div>
      </div>
    </div>
  );
};
