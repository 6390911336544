import React from 'react';
import PropTypes from 'prop-types';
import { PayloadStates } from '@lore/utils';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Loader from '../../components/Loader';
import Project from './Project';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';

ProjectInviteDialog.propTypes = {
  project: PropTypes.object.isRequired
};

export default function ProjectInviteDialog(props) {
  const { project } = props;

  if (project.state === PayloadStates.FETCHING) {
    return (
      <DialogTemplate>
        <Loader />
      </DialogTemplate>
    );
  }

  return (
    <DialogTemplate className="w-full max-w-screen-lg">
      <CloseDialogButton onClick={props.onClose} />
      <Project
        project={project}
        onClose={props.onClose}
      />
    </DialogTemplate>
  );
};
