import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import TagTypes from '../../../constants/TagTypes';
import useEventTags from '../../../hooks/useEventTags';
import BaseParticipation from '../../../components_profiles/BaseParticipation';
import ContentCard from '../../../components_profiles/ContentCard';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';
import { ShareIcon } from '@heroicons/react/24/outline';
import MissingField from '../../event_manage/EventMissingField';

Participation.propTypes = {
  event: PropTypes.object.isRequired
};

export default function Participation(props) {
  const { event } = props;

  const creator = useConnect('user.byId', {
    id: event.data.creatorId
  });

  const eventInteractionTypes = useEventTags(event, TagTypes.InteractionType);

  if (eventInteractionTypes.data.length === 0) {
    return (
      <ContentCard className="space-y-8">
        <SubSectionHeader
          icon={ShareIcon}
          title="Participation Options"
        />
        <MissingField name="Participation Options" />
      </ContentCard>
    );
  }

  return (
    <BaseParticipation
      creator={creator}
      interactionTypes={eventInteractionTypes}
    />
  );
}
