import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Project from '../../projects/Project';
import Funding from '../../projects/_fields/Funding';
import Budget from '../../projects/_fields/Budget';
import useQuery from './useQuery';
import CardChildrenWrapper from '../../_common_results/CardChildrenWrapper';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Projects']}
      title="Requested Funding"
      description={(
        <span>
          These are projects where an educator indicated they'd like to be considered for project
          funding. CommunityShare recommends sending the educator any additional information they
          need to be eligible for your community's project grants (additional questions, grant
          agreements, etc.) You can make notes visible only to your team about the status or
          any outreach you have done.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(project) {
        return (
          <Project key={project.id} project={project}>
            <CardChildrenWrapper>
              <div className="grid grid-cols-2 gap-4 w-full">
                <Funding project={project} />
                <Budget project={project} />
              </div>
            </CardChildrenWrapper>
          </Project>
        );
      }}
    />
  );
}
