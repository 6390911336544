import React from 'react';
import classNames from 'classnames';
import Tooltip from '../../../components/Tooltip';

export default function ThinNavigationLink(props) {
  const { name, icon, onClick, isActive = false } = props;

  return (
    <div className={classNames(
      // isActive && '-mx-4'
      '-mx-1x'
    )}>
      <Tooltip tooltip={name} icon={false} place="right">
        <div
          onClick={onClick}
          className={classNames(
            isActive ?
              // 'bg-cs-orange-500 bg-opacity-10 text-cs-orange-500' :
              'bg-cs-orange-100 text-cs-orange-500' :
              'bg-white text-cs-gray-500 hover:bg-cs-gray-50 hover:text-cs-gray-500 font-normal',
            'group flex flex-col items-center px-4 py-2.5 rounded-cs-10 text-base',
            'space-y-1',
            'cursor-pointer'
          )}
        >
          <props.icon
            className={classNames(
              isActive ? 'text-cs-gray-500x text-cs-orange-500' : 'text-cs-gray-500 group-hover:text-cs-gray-500',
              'h-6 w-6'
            )}
            aria-hidden="true"
          />
          {/*<div className="text-xs text-center">*/}
          {/*  {name}*/}
          {/*</div>*/}
        </div>
      </Tooltip>
    </div>
  );
}
