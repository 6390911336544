import React from 'react';
import classNames from 'classnames';

export default function NavigationLink(props) {
  const {
    name,
    icon,
    count,
    highlight,
    onClick,
    isActive = false
  } = props;

  return (
    <div className={classNames(
      // isActive && '-mx-4'
      '-mx-4'
    )}>
      <div
        onClick={onClick}
        className={classNames(
          isActive ?
            // 'bg-cs-orange-500 bg-opacity-10 text-cs-orange-500' :
            'bg-cs-orange-100 text-cs-orange-500' :
            'bg-white text-cs-gray-500 hover:bg-cs-gray-50 hover:text-cs-gray-500 font-normal',
          'group flex items-center px-4 py-2.5 rounded-cs-10 text-base',
          'cursor-pointer'
        )}
      >
        {icon && (
          <props.icon
            className={classNames(
              isActive ? 'text-cs-orange-500' : 'text-cs-gray-500 group-hover:text-cs-gray-500',
              'mr-4 h-6 w-6'
            )}
            aria-hidden="true"
          />
        )}
        {name}
        {(count && !highlight) ? (
          <span
            className={classNames(
              // isActive ? 'bg-gray-50' : 'bg-gray-200 text-gray-600',
              isActive ? 'bg-white' : 'text-cs-gray-500',
              'ml-auto inline-block py-0.5 px-2 text-sm rounded-full'
            )}
          >
            {count}
          </span>
        ) : null}
        {(count && highlight) ? (
          <span
            className={classNames(
              isActive ? 'bg-white' : 'bg-cs-orange-500 text-white',
              'ml-auto inline-block py-0.5 px-2 text-sm rounded-full'
            )}
          >
            {count}
          </span>
        ) : null}
      </div>
    </div>
  )
}
