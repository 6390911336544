import React from 'react';
import PropTypes from 'prop-types';
import { PayloadStates } from '@lore/utils';
import classNames from 'classnames';
import { useUser } from '@lore/auth';
import Avatar from '../../../components/Avatar';
import Loader from '../../../components/Loader';
import UserPhone from '../../project_manage/project-manage-evaluations/Question/UserPhone';
import UserEmail from '../../project_manage/project-manage-evaluations/Question/UserEmail';
import useMissingFields from '../../project_manage/useMissingFields';
import { DialogManager } from '../../DialogManager';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';

export default function Creator(props) {
  const { user, children } = props;

  const currentUser = useUser();

  const { missingFields } = useMissingFields(user);

  const launch = useDialogLauncher();

  function onSendReminder() {
    launch(DialogManager.ReminderProfileComplete.Config(user.id));
  }

  if (
    user.state === PayloadStates.FETCHING ||
    missingFields.state === PayloadStates.FETCHING
  ) {
    return (
      <div className="flex bg-white shadow-lg rounded-xl p-2.5 w-full">
        <Loader />
      </div>
    );
  }

  return (
    <div className="bg-white border border-gray-200 shadow-md rounded-xl overflow-hidden divide-y divide-gray-200">
      <div className="flex justify-between p-4">
        <div className="flex flex-1 items-center space-x-4">
          <Avatar size={12} src={user.data.avatarUrl}/>
          <div className="flex flex-col">
            <div className="text-lg font-semibold">
              {user.data.firstName} {user.data.lastName}
            </div>
            <div className="flex text-sm text-gray-500 space-x-2">
              <UserPhone user={user} />
              <UserEmail user={user} />
            </div>
          </div>
        </div>
        <div className="flex flex-col text-right">
          <div
            className={classNames(
              'text-lg font-semibold text-gray-700 space-x-1',
              currentUser.data.isSuperuser && 'hover:text-gray-500 cursor-pointer'
            )}
            onClick={currentUser.data.isSuperuser ? onSendReminder : undefined}
          >
            <span>
              {missingFields.data.length > 0 ?
                'Missing required fields' :
                'Profile is fully described'
              }
            </span>
          </div>
          <div className="flex text-sm text-gray-500 space-x-1 text-right">
            <span className="w-full">
              {missingFields.data.length > 0 ?
                'Profile description is incomplete.' :
                ''
              }
            </span>
          </div>
        </div>
      </div>
      {children && (
        <div className="flex p-4">
          {children}
        </div>
      )}
    </div>
  );
};
