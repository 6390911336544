import React from 'react';
import { PayloadStates } from '@lore/utils';
import { UsersIcon } from '@heroicons/react/24/outline';
import Loader from '../../../components/Loader';
import ContentCard from '../../../components_profiles/ContentCard';
import useProjectTags from '../../../hooks/useProjectTags';
import TagTypes from '../../../constants/TagTypes';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';
import TextContentSection from '../../../components_profiles/TextContentSection';

export default function Students(props) {
  const { project } = props;

  const projectGroups = useProjectTags(project, TagTypes.Group);
  const projectParticipants = useProjectTags(project, TagTypes.Participant);
  const projectAges = useProjectTags(project, TagTypes.Age);

  if (
    projectGroups.state === PayloadStates.FETCHING ||
    projectParticipants.state === PayloadStates.FETCHING ||
    projectAges.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  const workingWithStudents = _.find(projectParticipants.data, model => model.data.name === 'Students');

  if (!workingWithStudents) {
    return null;
  }

  return (
    <ContentCard className="space-y-4">
      <SubSectionHeader
        icon={UsersIcon}
        title="Students"
      />
      {projectAges.data.length > 0 && (
        <TextContentSection title="Age groups">
          <ul className="list-disc ml-6">
            {projectAges.data.map(function(projectAge) {
              return (
                <li key={projectAge.id || projectAge.cid}>
                  {projectAge.data.name}
                </li>
              );
            })}
          </ul>
        </TextContentSection>
      )}
      <TextContentSection title="Student group size">
        <ul className="list-disc ml-6">
          <li>
            {project.data.groupSize || '(Not provided)'}
          </li>
        </ul>
      </TextContentSection>
      {projectGroups.data.length > 0 && (
        <TextContentSection title="Students identify as">
          <ul className="list-disc ml-6">
            {projectGroups.data.map(function(projectGroup) {
              return (
                <li key={projectGroup.id || projectGroup.cid}>
                  {projectGroup.data.name}
                </li>
              );
            })}
          </ul>
        </TextContentSection>
      )}
    </ContentCard>
  );
}
