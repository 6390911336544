import React from 'react';
import { DialogManager } from '../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import User from './User';
import { useConnect } from '@lore/query-connect';
import _ from 'lodash';

export default function EventUser(props) {
  const {
    event,
    user,
    children
  } = props;

  const launch = useDialogLauncher();

  const invitations = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  });

  const invitation = _.find(invitations.data, function(invitation) {
    return invitation.data.userId === user.id;
  });

  const selected = !!invitation;

  function onClick() {
    launch(DialogManager.UserView.Config(user.id, {
      modelName: 'event',
      modelId: event.id
    }));
  }

  return (
    <User
      user={user}
      selected={selected}
      footer={event ? event.data.response : undefined}
      children={children}
      onClick={onClick}
    />
  );
}
