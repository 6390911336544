import _ from 'lodash';
import toJsonKey from '../utils/toJsonKey';
import { payload, PayloadStates } from '@lore/utils';

/**
 * ById Connection Blueprint
 */

export default function(key, params = {}, { Model, Collection, modelName }) {
  const jsonKey = toJsonKey(params);

  const model = new Model();

  return {
    queryKey: [modelName, 'singleton'],
    queryFn: async function() {
      try {
        await model.fetch({
          data: params.query
        });

        return payload(
          model,
          PayloadStates.RESOLVED
        );
      } catch (err) {
        const response = err.response;
        const error = response.data;

        if (response.status === 404) {
          return _.merge(payload(model), {
            state: PayloadStates.NOT_FOUND,
            error: error
          });
        } else {
          return payload(model, PayloadStates.ERROR_FETCHING, error);
        }
      }
    },
    placeholderData: payload(
      model,
      PayloadStates.FETCHING
    )
  }
};
