import { extractArrayFromQuery } from '../../../utils/query';

export default function(param) {
  return {
    param: extractArrayFromQuery,
    query: function (values) {
      if (values.length > 0) {
        return {
          [`evaluationProjectEducator.${param}`]: {
            $or: values.map(function(value) {
              return {
                1: 'Significantly',
                2: 'Somewhat',
                3: 'Not at all',
                4: 'Not a goal',
                5: {
                  $exists: false
                }
              }[value];
            })
          }
        }
      }
    }
  }
}
