import React from 'react';
import { PayloadStates } from '@lore/utils';
import { UsersIcon } from '@heroicons/react/24/outline';
import Loader from '../../../components/Loader';
import ContentCard from '../../../components_profiles/ContentCard';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import useEventTags from '../../../hooks/useEventTags';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';
import TextContentSection from '../../../components_profiles/TextContentSection';

export default function Students(props) {
  const { event } = props;

  const eventParticipants = useEventTags(event, TagTypes.Participant);
  const ages = useTags(TagTypes.Age);
  const eventAges = useEventTags(event, TagTypes.Age);

  if (
    eventParticipants.state === PayloadStates.FETCHING ||
    ages.state === PayloadStates.FETCHING ||
    eventAges.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  const workingWithStudents = _.find(eventParticipants.data, model => model.data.name === 'Students');

  if (!workingWithStudents) {
    return null;
  }

  return (
    <ContentCard className="space-y-4">
      <SubSectionHeader
        icon={UsersIcon}
        title="Students"
      />
      {eventAges.data.length > 0 && (
        <TextContentSection title="Age groups">
          <ul className="list-disc ml-6">
            {eventAges.data.map(function(eventAge) {
              return (
                <li key={eventAge.id || eventAge.cid}>
                  {eventAge.data.name}
                </li>
              );
            })}
          </ul>
        </TextContentSection>
      )}
      <TextContentSection title="Student group size">
        <ul className="list-disc ml-6">
          <li>
            {event.data.groupSize || '(Not provided)'}
          </li>
        </ul>
      </TextContentSection>
    </ContentCard>
  );
}
