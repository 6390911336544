import React from 'react';
import { ArrowSmallDownIcon, ArrowSmallUpIcon } from '@heroicons/react/24/solid';
import classNames from '../../utils/classNames';
import Card from '../../components/Card';

export default function Metric(props) {
  const {
    name,
    title,
    subtitle,
    children
  } = props;

  return (
    <Card className="py-6 px-4 text-center">
      <div
        className={classNames(
          'text-sm text-cs-gray-700',
          // 'text-xs text-cs-gray-700 font-semibold uppercase',
          `${title}` && 'mb-3'
        )}
      >
        {name}
      </div>
      <div className={classNames(
        'text-2xl font-semibold text-gray-900',
        subtitle && 'mb-1'
      )}>
        {title}
      </div>
      {subtitle && (
        <div className="text-sm font-semiboldx text-cs-gray-500">
          {subtitle}
        </div>
      )}
      {children}
    </Card>
  );
}
