import React from 'react';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import { CalendarIcon, ClockIcon, FolderIcon, NewspaperIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import DropdownFilter from '../../components/DropdownFilter';

export default function SortBy(props) {
  const { location, history } = useRouter();

  const search = parse(location.search) || {};

  function onChange(order) {
    const search = parse(location.search) || {};

    search.order = order;
    delete search.page;

    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, search))
    });
  }

  const orderMap = {
    Newest: {
      name: 'Newest',
      param: 'createdAt desc'
    },
    Active: {
      name: 'Active',
      param: 'lastActiveAt desc'
    },
    ProjectsCreated: {
      name: 'Projects created',
      param: 'numberOfProjectsCreated desc'
    },
    ProjectsCompleted: {
      name: 'Projects completed',
      param: 'numberOfProjectsCompleted desc'
    },
    EventsCreated: {
      name: 'Events created',
      param: 'numberOfEventsCreated desc'
    },
    EventsCompleted: {
      name: 'Events completed',
      param: 'numberOfEventsCompleted desc'
    },
    ClaimsConnect: {
      name: 'Offer invitations (connected)',
      param: 'numberOfClaimsConnect desc'
    },
    ClaimsPending: {
      name: 'Offer invitations (pending)',
      param: 'numberOfClaimsPending desc'
    },
    ClaimsDeclined: {
      name: 'Offer invitations (declined)',
      param: 'numberOfClaimsDecline desc'
    }
  };

  const reverseOrderMap = _.mapKeys(orderMap, function(value, key) {
    return value.param;
  });

  return (
    <DropdownFilter subtitle="Sort by:" title={reverseOrderMap[search.order]?.name || 'Newest'}>
      <DropdownFilter.MenuItem
        icon={ClockIcon}
        title={orderMap.Newest.name}
        subtitle="Display recently created educators first."
        selected={!search.order || search.order === orderMap.Newest.param}
        onClick={() => onChange(orderMap.Newest.param)}
      />
      <DropdownFilter.MenuItem
        icon={ClockIcon}
        title={orderMap.Active.name}
        subtitle="Display educators by date they last logged in."
        selected={search.order === orderMap.Active.param}
        onClick={() => onChange(orderMap.Active.param)}
      />
      <DropdownFilter.MenuItem
        icon={FolderIcon}
        title={orderMap.ProjectsCreated.name}
        subtitle="Display educators by the number of projects they created."
        selected={search.order === orderMap.ProjectsCreated.param}
        onClick={() => onChange(orderMap.ProjectsCreated.param)}
      />
      <DropdownFilter.MenuItem
        icon={FolderIcon}
        title={orderMap.ProjectsCompleted.name}
        subtitle="Display educators by the number of projects they completed."
        selected={search.order === orderMap.ProjectsCompleted.param}
        onClick={() => onChange(orderMap.ProjectsCompleted.param)}
      />
      <DropdownFilter.MenuItem
        icon={CalendarIcon}
        title={orderMap.EventsCreated.name}
        subtitle="Display educators by the number of events they created."
        selected={search.order === orderMap.EventsCreated.param}
        onClick={() => onChange(orderMap.EventsCreated.param)}
      />
      <DropdownFilter.MenuItem
        icon={CalendarIcon}
        title={orderMap.EventsCompleted.name}
        subtitle="Display educators by the number of events they completed."
        selected={search.order === orderMap.EventsCompleted.param}
        onClick={() => onChange(orderMap.EventsCompleted.param)}
      />
      <DropdownFilter.MenuItem
        icon={NewspaperIcon}
        title={orderMap.ClaimsConnect.name}
        subtitle="Display educators by the number of offer invitations they have connected over."
        selected={search.order === orderMap.ClaimsConnect.param}
        onClick={() => onChange(orderMap.ClaimsConnect.param)}
      />
      <DropdownFilter.MenuItem
        icon={NewspaperIcon}
        title={orderMap.ClaimsPending.name}
        subtitle="Display educators by the number of offer invitations waiting for a response."
        selected={search.order === orderMap.ClaimsPending.param}
        onClick={() => onChange(orderMap.ClaimsPending.param)}
      />
      <DropdownFilter.MenuItem
        icon={NewspaperIcon}
        title={orderMap.ClaimsDeclined.name}
        subtitle="Display educators by the number of offer invitations they have declined."
        selected={search.order === orderMap.ClaimsDeclined.param}
        onClick={() => onChange(orderMap.ClaimsDeclined.param)}
      />
    </DropdownFilter>
  );
}
