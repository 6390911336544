import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import { useUser } from '@lore/auth';
import { CalendarIcon } from '@heroicons/react/24/outline';
import ContentCard from '../../../components_profiles/ContentCard';
import useOfferTags from '../../../hooks/useOfferTags';
import TagTypes from '../../../constants/TagTypes';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';
import TextContentSection from '../../../components_profiles/TextContentSection';
import { formatDate, formatDateRange, formatTimeRange } from '../../../utils/dateTimeUtils';
import MissingField from '../OfferMissingField';

export default function Commitment(props) {
  const { offer } = props;

  const currentUser = useUser();
  const offerTimes = useOfferTags(offer, TagTypes.Time);

  if (offerTimes.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <ContentCard className="space-y-8">
      <SubSectionHeader
        icon={CalendarIcon}
        title="Date & Time"
      />
      {offer.data.specificDate === 'Yes' ? (
        <>
          {offer.data.date ? (
            <TextContentSection title="Date">
              {formatDate(offer.data.date)}
            </TextContentSection>
          ) : (
            <MissingField name="Date" />
          )}
        </>
      ) : (
        <>
          {(
            offer.data.startDate &&
            offer.data.endDate
          ) ? (
            <>
              <TextContentSection title="Date Window">
                {formatDateRange(offer.data.startDate, offer.data.endDate)}
              </TextContentSection>
            </>
          ) : (
            <MissingField name="Date Window" />
          )}
        </>
      )}
      {(
        offer.data.specificTime === 'Yes' &&
        offer.data.startTime &&
        offer.data.endTime
      ) ? (
        <TextContentSection title="Time">
          {formatTimeRange(offer.data.startTime, offer.data.endTime, currentUser.data.timezone)}
        </TextContentSection>
      ) : (
        <>
          {offerTimes.data.length > 0 && (
            <TextContentSection title="Possible Times of Day">
              <ul className="list-disc ml-6">
                {offerTimes.data.map(function(offerTime) {
                  return (
                    <li key={offerTime.id || offerTime.cid}>
                      {offerTime.data.name}
                    </li>
                  );
                })}
              </ul>
            </TextContentSection>
          )}
        </>
      )}
    </ContentCard>
  );
}
