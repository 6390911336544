/**
 * This component is intended to reflect the high level structure of your application,
 * and render any components that are common across all views, such as the header or
 * top-level navigation. All other components should be rendered by route handlers.
 */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as FullStory from '@fullstory/browser';
import PrimaryNavigation from './_components/PrimaryNavigation';
import ScrollToTop from '../components/ScrollToTop';
import moment from 'moment';
import { useUser } from '@lore/auth';
import { useConfig } from '@lore/config';
import { Outlet } from 'react-router-dom';
import useRouter from '../hooks/useRouter';
import ThinNavigation from './_navigation/ThinNavigation';
import MobileNavigationHeader from './_navigation/MobileNavigationHeader';
import ScrollAnchorContext from '../context/ScrollAnchorContext';
import ErrorBoundary from './ErrorBoundary';

export default function Layout(props) {
  const { location, history } = useRouter();
  const currentUser = useUser();
  const config = useConfig();
  const scrollAnchorRef = useRef(null);

  useEffect(() => {
    const { enabled, appId, companyId, companyName } = config.intercom;
    const createdAt = moment(currentUser.data.createdAt).unix();

    if (enabled) {
      window.Intercom("boot", {
        app_id: appId,
        name: `${currentUser.data.firstName} ${currentUser.data.lastName}`,
        email: currentUser.data.email,
        created_at: createdAt,
        company: {
          id: companyId,
          name: companyName
        }
      });
    }
  }, []);

  useEffect(() => {
    const { enabled } = config.intercom;

    if (enabled) {
      window.Intercom('update');
    }
  }, [location]);

  useEffect(() => {
    const { enabled } = config.fullstory;

    if (enabled) {
      FullStory.setUserVars({
        uid: currentUser.id,
        displayName: `${currentUser.data.firstName} ${currentUser.data.lastName}`,
        email: currentUser.data.email,
      });
    }
  }, []);

  return (
    <ScrollAnchorContext.Provider value={scrollAnchorRef}>
      <div className="h-screen flex flex-col lg:flex-row overflow-hiddenx bg-whitex">
        <div className="w-24 hidden lg:block z-20 border-r border-gray-100">
          <nav className="flex flex-col -mr-0.5 border-r-2 border-cs-gray-100 py-8 bg-white overflow-y-auto h-screen">
            <ThinNavigation/>
          </nav>
        </div>
        <div className="h-12x block lg:hidden z-20 border-b border-gray-100 bg-gray-50">
          <MobileNavigationHeader />
        </div>
        {/*<div className="w-72 hidden lg:block z-10">*/}
        {/*  <nav className="flex flex-col -mr-0.5 border-r-2 border-cs-gray-100 py-8 bg-white overflow-y-auto h-screen">*/}
        {/*    <ThickNavigation/>*/}
        {/*  </nav>*/}
        {/*</div>*/}
        <div ref={scrollAnchorRef} className="flex-1 overflow-y-auto">
          {/*<ScrollToTop key={`scroll-to-top-${location.pathname}`} />*/}
          {/*<PrimaryNavigation sidebarOpen={sidebarOpen} />*/}
          <ErrorBoundary config={config}>
            <Outlet />
          </ErrorBoundary>
        </div>
      </div>
    </ScrollAnchorContext.Provider>
  );
}
