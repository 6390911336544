import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../utils/classNames';

FormTemplate.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  alert: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func,
  onPrevious: PropTypes.func,
  showClose: PropTypes.bool
};

FormTemplate.defaultProps = {
  showClose: true
};

export default function FormTemplate(props) {
  const {
    icon,
    title,
    subtitle,
    alert,
    body,
    footer,
    onClose,
    onPrevious,
    className,
    showClose,
    gray = false
  } = props;

  return (
    <div className={classNames(
      gray && 'bg-cs-gray-old',
      className
    )}>
      <div className="pt-16 px-10 md:px-20 text-center">
        {(showClose || onPrevious) && (
          <div className="mb-8 md:mb-4" />
        )}
        {icon}
        <h5 className="text-3xl font-semibold">
          {title}
        </h5>
        {subtitle ? (
          <h6 className="text-base mt-2 text-gray-500">
            {subtitle}
          </h6>
        ) : null}
        {showClose && (
          <button type="button" className="close absolute p-4 -m-4 text-3xl text-accent opacity-100 hover:text-accent hover:opacity-75" onClick={onClose}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        )}
        {onPrevious ? (
          <div className="absolute p-4 -m-4 text-accent cursor-pointer dialog-previous-button sm:dialog-previous-button-sm lg:dialog-previous-button-lg" onClick={onPrevious}>
            <i className="material-icons text-4xl font-bold">arrow_back</i>
          </div>
        ) : null}
      </div>
      {alert}
      {body && (
        <div className="pt-8 px-8">
          {body}
        </div>
      )}
      {footer ? (
        // <div className="flex items-center justify-end pt-6 pr-10 pb-6 pl-10 md:pt-12 md:pr-20 md:pb-12 md:pl-20">
        <div className="flex items-center justify-end px-8 py-8">
          {footer}
        </div>
      ) : null}
    </div>
  );
}
