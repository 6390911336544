import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import useOfferTags from '../../hooks/useOfferTags';
import TagTypes from '../../constants/TagTypes';

function isMissing(name, missingFields) {
  return _.find(missingFields, function(missingField) {
    return missingField.key === name;
  });
}

export default function useMissingFields(offer) {
  const missingFields = [];

  const offerParticipants = useOfferTags(offer, TagTypes.Participant);
  const offerInteractionTypes = useOfferTags(offer, TagTypes.InteractionType);

  if (
    offerParticipants.state === PayloadStates.FETCHING ||
    offerInteractionTypes.state === PayloadStates.FETCHING
  ) {
    return {
      missingFields: {
        state: PayloadStates.FETCHING,
        data: missingFields
      },
      isMissing: isMissing
    };
  }

  if (!offer.data.activityId) {
    missingFields.push({
      key: 'activityId',
      name: 'Activity',
      to: `/post-offer/${offer.id}/activity`
    });
  }

  // if (!offer.data.title) {
  //   missingFields.push({
  //     key: 'title',
  //     name: 'Title',
  //     to: `/post-offer/${offer.id}/title`
  //   });
  // }

  if (!offer.data.description) {
    missingFields.push({
      key: 'description',
      name: 'Summary',
      to: `/post-offer/${offer.id}/summary`
    });
  }

  if (offerParticipants.data.length === 0) {
    missingFields.push({
      key: 'offerParticipants',
      name: 'Participants',
      to: `/post-offer/${offer.id}/participants`
    });
  }

  if (
    offer.data.specificDate === 'Yes' &&
    !offer.data.date
  ) {
    missingFields.push({
      key: 'date',
      name: 'Date',
      to: `/post-offer/${offer.id}/date`
    });
  }

  if (
    offer.data.specificDate === 'No' && (
      !offer.data.startDate ||
      !offer.data.endDate
    )
  ) {
    missingFields.push({
      key: 'date',
      name: 'Date Range',
      to: `/post-offer/${offer.id}/date`
    });
  }

  if (offerInteractionTypes.data.length === 0) {
    missingFields.push({
      key: 'offerInteractionTypes',
      name: 'Location',
      to: `/post-offer/${offer.id}/location`
    });
  }

  return {
    missingFields: {
      state: PayloadStates.RESOLVED,
      data: missingFields
    },
    isMissing: function(name) {
      return isMissing(name, missingFields)
    }
  };
};
