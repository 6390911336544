import React  from 'react';
import PropTypes from 'prop-types';
import { useUser } from '@lore/auth';
import { useDialog } from '@lore/dialogs';
import useUserProfileMissingFields from '../../../../hooks/useUserProfileMissingFields';
// import ProfileMissingDialog from '../../../../dialogs/profile.missing';
// import MessageBlockerDialog from '../../../../dialogs/MessageBlocker';
import Loader from '../../../../components/Loader';
import classNames from '../../../../utils/classNames';

export default function ConnectButton(props) {
  const { children, onClick, disabled = false } = props;

  const user = useUser();
  // const missingFields = useUserProfileMissingFields();

  const show = useDialog();

  // if (!missingFields) {
  //   return (
  //     <Loader />
  //   );
  // }

  // const fieldCount = missingFields.profile.length + missingFields.interests.length;

  // function onCompleteProfile() {
  //   show(
  //     <ProfileMissingDialog user={user} />
  //   );
  // }

  // function onClick() {
  //   show(
  //     <MessageBlockerDialog
  //       onSubmit={onCompleteProfile}
  //     />
  //   );
  // }

  const className = classNames(
    'bg-white text-setup-tag font-bold uppercase rounded-lg text-center py-3 px-3 w-56',
    disabled && 'opacity-80',
    !disabled && 'cursor-pointer hover:bg-blue-50 shadow-lg'
  )

  if (disabled) {
    return (
      <div className={className}>
        {children}
      </div>
    )
  }

  // if (fieldCount > 0) {
  //   return (
  //     <div className={className} onClick={onCompleteProfile}>
  //       {children}
  //     </div>
  //   );
  // }

  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
};
