import { DateTime } from 'luxon';

/*
 * Add a missing TIME
 * https://github.com/moment/moment/issues/3764
 */

const n = "numeric";
const s = "short";
const l = "long";

export default function(config) {
  DateTime.TIME_SIMPLE_WITH_SHORT_OFFSET = {
    hour: n,
    minute: n,
    timeZoneName: s
  };

  DateTime.SHORT_OFFSET = {
    timeZoneName: s
  };
};
