import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Project from '../../projects/Project';
import DateCreated from '../../projects/_fields/DateCreated';
import MissingFields from '../../projects/_fields/MissingFields';
import useQuery from './useQuery.last4weeks';
import CardChildrenWrapper from '../../_common_results/CardChildrenWrapper';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Projects']}
      title="Incomplete Projects"
      description={(
        <span>
          These are projects that an educator started but did not complete the minimum information
          to be shown publicly on the platform. CommunityShare recommends a quick call or email to
          remind them that you are here to help them talk through their ideas and needs. Choosing
          the project from their home page will highlight exactly which fields are missing.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function (project) {
        return (
          <Project key={project.id} project={project}>
            <CardChildrenWrapper>
              <div className="grid grid-cols-2 gap-4 w-full">
                <DateCreated project={project}/>
                <MissingFields project={project}/>
              </div>
            </CardChildrenWrapper>
          </Project>
        );
      }}
    />
  );
}
