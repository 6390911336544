import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../../forms/_common/FormField';
import { OutlineButton } from '../../../@communityshare/ui/Button';
import { SolidButton } from '../../../@communityshare/ui/Button';
import StringField from '../../../forms/_fields_v2/StringField';
import FormTemplate from './Template';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit
  } = props;

  return (
    <FormTemplate
      // title="Leave a note"
      // subtitle="This note will be visible to everyone on your team."
      alert={alert}
      body={(
        <>
          <FormField name="screeningUrl" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="Enter the URL where screening information can be found (optional):"
                placeholder="Enter screening URL..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
        </>
      )}
      footer={(
        <div className="flex justify-end">
          <SolidButton onClick={onSubmit}>
            Save
          </SolidButton>
        </div>
      )}
    />
  );
}
