import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from '../project_manage/Header';
import ProjectInvitePopulateLayout from './project-invite-populate/Layout';
import ProjectInviteLayout from './project-invite/Layout';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import useDialogRouter from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogRouter';
import { Helmet } from 'react-helmet-async';

Project.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Project(props) {
  const { project } = props;

  const { id, route } = useDialogConfig();
  const router = useDialogRouter(id);

  useEffect(() => {
    if (route === '/') {
      // router.navigate('/invite-populate');
      router.navigate('/invite');
    }
  }, []);

  const pageMap = {
    '/invite': 'Invite',
    '/invite-populate': 'Invite'
  };

  const pathname = route.split('?')[0];
  const page = pageMap[pathname] || pathname;

  return (
    <>
      <Helmet>
        <title>
          {project.data.title ? `${project.data.title}: ${page}` : `Project #${project.id}: ${page}`}
        </title>
      </Helmet>
      <div className="px-6 pt-4 border-b border-cs-gray-100 pb-6">
        <Header project={project} />
      </div>
      <div className="flex-1 relative z-0 overflow-y-auto bg-cs-gray-50 px-6 py-6">
        {pathname === `/invite-populate` && (
          <ProjectInvitePopulateLayout
            project={project}
            onClose={props.onClose}
          />
        )}
        {pathname === `/invite` && (
          <ProjectInviteLayout
            project={project}
            router={router}
            onClose={props.onClose}
          />
        )}
      </div>
    </>
  );
};
