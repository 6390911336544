import _ from 'lodash';
import toJsonKey from '../utils/toJsonKey';
import { payloadCollection, PayloadStates } from '@lore/utils';

/**
 * Find Connection Blueprint
 */

export default function(key, params, { Model, Collection, modelName, queryClient }) {
  const jsonKey = toJsonKey(params);

  const collection = new Collection();

  const query = params.where;
  const pagination = params.pagination;

  const combinedQuery = {
    where: query,
    pagination: pagination
  };

  return {
    queryKey: [modelName, 'find', jsonKey],
    queryFn: async function() {
      try {
        const queryParameters = _.extend({}, query, pagination);

        // serialize the data to mimic behavior of axios v0 -- changed for v1 (they serialize differently)
        const serializedData = _.mapValues(queryParameters, function(value) {
          if(_.isObject(value)) {
            return JSON.stringify(value);
          }

          return value;
        });

        await collection.fetch({
          // data: queryParameters,
          data: serializedData
        });

        const _payload = payloadCollection(
          collection,
          PayloadStates.RESOLVED,
          null,
          combinedQuery
        );

        _payload.data.map(function(datum) {
          // queryClient.setQueryData([`${modelName}.byId`, datum.id], datum);
          queryClient.setQueryData([modelName, 'byId', datum.id], datum);
        });

        return _payload;
      } catch (err) {
        const response = err.response;
        const error = response.data;
        return payloadCollection(
          collection,
          PayloadStates.ERROR_FETCHING,
          error,
          combinedQuery
        );
      }
    },
    placeholderData: payloadCollection(
      collection,
      PayloadStates.FETCHING,
      null,
      combinedQuery
    )
  }
};
