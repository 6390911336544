import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func
};

Checkbox.defaultProps = {
  checked: false
};

export default function Checkbox(props) {
  const { checked } = props;

  // position: relative;
  // display: block;
  // min-height: 1.5rem;
  // padding-left: 1.5rem;

  function onChange() {
    props.onChange({
      target: {
        checked: !checked
      }
    });
  }

  return (
    <div className="relative flex">
      <input
        className="absolute -z-1 opacity-0"
        type="checkbox"
        checked={checked}
        onChange={props.onChange}
      />
      <div
        className={classNames(
          'h-6 w-6 -mx-px border rounded-md relative cursor-pointer',
          checked ? 'border-cs-orange-500 bg-cs-orange-500' : 'border-cs-gray-300 bg-white'
        )}
        onClick={onChange}
      >
        <div className={classNames(
          'flex w-full items-center justify-center h-full',
          checked ? '' : 'hidden'
        )}>
          <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3105 1.18942C13.5917 1.47072 13.7497 1.85218 13.7497 2.24992C13.7497 2.64767 13.5917 3.02913 13.3105 3.31042L5.8105 10.8104C5.52921 11.0916 5.14775 11.2496 4.75 11.2496C4.35225 11.2496 3.97079 11.0916 3.6895 10.8104L0.6895 7.81042C0.416263 7.52752 0.265072 7.14862 0.268489 6.75532C0.271907 6.36203 0.42966 5.98581 0.707773 5.7077C0.985885 5.42958 1.3621 5.27183 1.7554 5.26841C2.14869 5.26499 2.5276 5.41619 2.8105 5.68942L4.75 7.62892L11.1895 1.18942C11.4708 0.908217 11.8523 0.750244 12.25 0.750244C12.6477 0.750244 13.0292 0.908217 13.3105 1.18942Z" fill="white"/>
          </svg>
        </div>
      </div>
    </div>
  );
}
