import React from 'react';
import { useConnect } from '@lore/query-connect';

export default function useSchools(account, schoolStart, schoolEnd, extra = {}, eager = {}) {
  return useConnect('organization.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfEducators',
            relation: 'users',
            $where: {
              isEducator: true,
              'accounts.id': account.id
            }
          }
        ],
        $where: {
          numberOfEducators: {
            $gt: 0
          },
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          },
          ...extra
        },
        ...eager
      },
    },
    pagination: {
      pageSize: 1
    }
  });
}
