/*
 * This file is the entry point for the application and is responsible for building
 * and mounting the application.
 */

/*
 * Import the styles for the loading screen. We're doing that here to make
 * sure they get loaded regardless of the entry point for the application.
 */

import '@fontsource-variable/figtree';
import './assets/sass/loading-screen.scss';
import './assets/sass/main.scss';


/*
 * CSS Element Queries
 *
 * Attaches to DOMLoadContent and does anything for you
 */

// import ElementQueries from 'css-element-queries/src/ElementQueries';

// attaches to DOMLoadContent and does anything for you
// ElementQueries.listen();

// or if you want to trigger it yourself:
// 'init' parses all available CSS and attach ResizeSensor to those elements which
// have rules attached (make sure this is called after 'load' event, because
// CSS files are not ready when domReady is fired.
// ElementQueries.init();


/*
 * Environment
 *
 * Get the environment we should be using. This is controlled via the LORE_ENV environment
 * variable, and defaults to 'development' if not defined.
 */

import { getEnvironment } from './.lore/environment';

const environment = getEnvironment();


/*
 * Modules
 *
 * Import files from the project directories and convert them into objects where
 * the key is the name of the file. These objects will be used to set up the actions,
 * reducers, Redux store, and other parts of the application.
 *
 * The approach used below (require.context + regex) prevents us from needing to
 * explicitly import every file, which helps to reduce configuration errors related to
 * simple forgetfulness. Simply add a file to one of the imported directories, and it
 * will automatically be imported.
 *
 * The downside to this approach is that it's not easy to understand. So if you'd
 * prefer to use a more explicit (or selective) approach, feel free to build the objects
 * yourself and manually import/require all files as needed.
 */

import { getModuleFromContext, buildObjectFromContext } from '@lore/utils';

const modules = {
  config: {
    // Import all the *.js files in the root of /config, excluding local.js
    baseConfig: buildObjectFromContext(require.context(`./config`, false, /^(?!.*(?:local.js$)).*\.js$/)),

    // Import the environment config override from /config/env
    envConfig: require(`./config/env/${environment || 'development'}`).default,

    // Import the local.js file in the root of /config, but ONLY in development
    localConfig: environment === 'development' ?
      getModuleFromContext(require.context(`./config`, false, /local.js$/)) :
      undefined
  },

  // Import all the *.js files in the root of /collections
  collections: buildObjectFromContext(require.context('./src/collections', false, /\.js$/)),

  // Import all the *.js files in the root of  /models
  models: buildObjectFromContext(require.context('./src/models', false, /\.js$/)),

  // Import all the *.js files in the root of /initializers
  initializers: buildObjectFromContext(require.context('./initializers', false, /\.js$/))
};


/*
 * Config
 *
 * Construct the final project config by combining the default/base config,
 * the environment specific overrides, and any local overrides defined in
 * config/local.js.
 */

import { getConfig } from './.lore/config';

const config = getConfig(modules.config);


/*
 * Models
 *
 * An AJAX abstraction that reduces the boilerplate associated with creating,
 * retrieving, updating, and deleting a single resource in a REST API.
 *
 * These are instances of Model from @lore/backbone.
 */

import { getModels } from './.lore/models';

const models = getModels(config, {
  models: modules.models
});


/*
 * Collections
 *
 * An AJAX abstraction that reduces the boilerplate associated with searching,
 * filtering, and paginating resources in a REST API.
 *
 * These are instances of Collection from @lore/backbone.
 */

import { getCollections } from './.lore/collections';

const collections = getCollections(config, { models }, {
  models: modules.models,
  collections: modules.collections
});


/*
 * Run the initializers
 *
 * These are small functions that run before the application is mounted and
 * are primarily used to initialize libraries for analytics, error reporting,
 * support, etc.
 */

import { runInitializers } from './.lore/initializers';

runInitializers(config, {
  initializers: modules.initializers
});


/*
 * React
 *
 * Establish the root component and render it to the DOM
 */

import { createRoot } from 'react-dom/client';
import App from './App';

const domElementId = 'root';
const container = document.getElementById(domElementId);
const root = createRoot(container);

root.render((
  <App {...{
    config,
    models,
    collections
  }}/>
));


/*
 * Globals (optional)
 *
 * Attach key values to the window, so you can access them from the console
 * in the browser. Use within the application is discouraged, but they can
 * sometimes improve the developer experience by allowing you to manually
 * invoke action creators, check the store state, or just play around with
 * project libraries.
 *
 * Example commands:
 *
 * lore.actions.xyz()          => invoke the xyz action
 * lore.store.getState().xyz   => check the state of xyz reducer
 * new lore.models.xyz()       => create instance of xyz model
 * new lore.collections.xyz()  => create instance of xyz collection
 */

window.lore = {
  environment: environment,
  config: config,
  models: models,
  collections: collections
};
