import React from 'react';
import PropTypes from 'prop-types';
import Questions from './Questions';
import EmptyState from '../../_common/EmptyState';
import EducatorEvaluation from './Question/index.educator';
import SectionTitle from '../../_common/SectionTitle';
import CloseProjectAlert from './CloseProjectAlert';

export default function Layout(props) {
  const { project } = props;

  return (
    <div className="page space-y-12">
      <div className="flex space-x-8">
        <div className="flex-1 space-y-12">
          <CloseProjectAlert project={project} />
          <SectionTitle
            title="Educator"
            subtitle="Creator of the project. There is only one."
          >
            <div className="grid grid-cols-1 gap-4">
              <EducatorEvaluation
                project={project}
              />
            </div>
          </SectionTitle>
          <SectionTitle
            title="Partners"
            subtitle={(
              <>
                This is a list of partners who connected over this project. Once their involvement is
                classified by the educator, an evaluation (appropriate for their level of contribution)
                will be created and sent to that partner.
              </>
            )}
          >
            <div className="grid grid-cols-1 gap-4">
              <Questions
                project={project}
                responses={[
                  'Connect'
                ]}
                results={[
                  'Participated',
                  'Contributed',
                  {
                    $exists: false
                  }
                ]}
                emptyState={(
                  <EmptyState
                    title="No partners to display"
                    // subtitle="Extra text..."
                  />
                )}
              />
            </div>
          </SectionTitle>
          <SectionTitle
            title="Not involved"
            subtitle={(
              <>
                These partners connected with the educator, but the educator said they did not
                participate or contribute any supplies.
              </>
            )}
          >
            <div className="grid grid-cols-1 gap-4">
              <Questions
                project={project}
                responses={[
                  'Connect'
                ]}
                results={[
                  'Nothing'
                ]}
                emptyState={(
                  <EmptyState
                    title="No partners to display"
                    // subtitle="Partners who are unable to participate will show up here."
                  />
                )}
              />
            </div>
          </SectionTitle>
        </div>
      </div>
    </div>
  );
};
