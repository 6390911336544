import { parse, stringify } from 'query-string';
import _ from 'lodash';

export default function changePage(history, location, pageNumber) {
  history.push({
    pathname: location.pathname,
    search: stringify(_.extend({}, parse(location.search), {
      page: pageNumber
    }))
  });
}
