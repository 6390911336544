import React from 'react';
import Card from '../../../components/Card';
import EducatorProjectEvaluationHeaderSection from '../../evaluations/EducatorProjectEvaluation/EducatorProjectEvaluationHeaderSection';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import ShareLinkSection from '../../evaluations-project-educators/EvaluationProjectEducator/ShareLinkSection';
import Notes from '../../evaluations-project-educators/EvaluationProjectEducator/Notes';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function Evaluation(props) {
  const { evaluation } = props;

  const launch = useDialogLauncher();

  const project = useConnect('project.byId', {
    id: evaluation.data.projectId
  });

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  }, {
    enabled: !!project.data.activityId
  });

  if (project.state === PayloadStates.FETCHING) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  function onClickProject() {
    launch(DialogManager.ProjectManage.Config(project.id));
  }

  const link = (
    <span
      className="text-amber-800 hover:text-amber-500 cursor-pointer underline"
      onClick={onClickProject}>
      {project.data.title || activity.data.name}
    </span>
  );

  return (
    <Card className="overflow-hidden">
      <div className="divide-y divide-gray-200">
        {/*<ProjectHeader*/}
        {/*  project={project}*/}
        {/*  tags={false}*/}
        {/*/>*/}
        <EducatorProjectEvaluationHeaderSection
          evaluation={evaluation}
          created={project.data.creatorId === evaluation.data.userId}
        />
        <ShareLinkSection
          evaluation={evaluation}
          description={(
            <>
              The link below will take the educator directly to the beginning of the evaluation
              for the project {link}. This link will only work for them.
            </>
          )}
        />
      </div>
      <div className="bg-gray-50 p-4">
        <Notes evaluationProjectEducator={evaluation} />
      </div>
    </Card>
  );
}
