import React, { useRef, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import PageHeading from '../../@communityshare/ui/PageHeading';
import PaginationNew from '../../components/pagination-new/Pagination';
import PaginationInformation from '../../components/pagination-new/PaginationInformation';
import useScrollToTop from '../../hooks/useScrollToTop';
import Results from './Results';
import MobileNavigation from '../feed/MobileNavigation';

export default function Layout(props) {
  const {
    breadcrumbs,
    title,
    description,
    result,
    pageSize,
    row
  } = props;

  const node = useRef();
  const scrollToTop = useScrollToTop();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Feed: {title}</title>
      </Helmet>
      <MobileNavigation />
      <PageHeading
        breadcrumbs={breadcrumbs}
        title={title}
        description={description}
      />
      <>
        <div ref={node} className="flex items-center justify-between">
          <PaginationInformation
            pageSize={pageSize}
            totalCount={result.meta?.totalCount}
          />
        </div>
        <div>
          <Results
            result={result}
            row={row}
          />
        </div>
        <div className="flex items-center justify-between">
          <PaginationInformation
            pageSize={pageSize}
            totalCount={result.meta?.totalCount}
          />
          <PaginationNew
            pageSize={pageSize}
            totalCount={result.meta?.totalCount}
            onNavigate={() => scrollToTop(node)}
          />
        </div>
      </>
    </div>
  );
}
