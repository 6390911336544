import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import Dialog from '../reminder_invitation_respond';

export default function Launcher(props) {
  const config = useDialogConfig();

  const invitation = useConnect('invitation.byId', {
    id: config.params.invitationId
  });

  if (invitation.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Dialog
      invitation={invitation}
      onClose={props.onClose}
    />
  );
};
