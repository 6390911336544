import React from 'react';
import {
  CalendarIcon,
  ClipboardDocumentListIcon,
  Cog6ToothIcon,
  FolderIcon,
  ListBulletIcon,
  NewspaperIcon,
  PresentationChartLineIcon,
  UsersIcon,
  AcademicCapIcon
} from '@heroicons/react/24/outline';
import { useUser } from '@lore/auth';
import _ from 'lodash';
import useAccount from '../../../hooks/useAccount';

export default function useMainNavigationLinks() {
  const account = useAccount();
  const currentUser = useUser();

  const root = `/c/${account.data.subdomain}`;

  const urls = {
    dashboard: `${root}/dashboard`,
    feed: `${root}/feed`,
    projects: `${root}/explore/projects`,
    events: `${root}/explore/events`,
    offers: `${root}/explore/offers`,
    partners: `${root}/explore/partners`,
    educators: `${root}/explore/educators`,
    evaluations: `${root}/evaluations`,
    settings: `${root}/settings`,
    // team lives under settings
    team: `${root}/team`
  };

  return _.remove([
    { name: 'Dashboard', icon: PresentationChartLineIcon, to: urls.dashboard },
    currentUser.data.isSuperuser ?
      { name: '[Feed]', icon: ListBulletIcon, to: urls.feed } :
      null,
    { name: 'Projects', icon: FolderIcon, to: urls.projects },
    { name: 'Events', icon: CalendarIcon, to: urls.events },
    { name: 'Offers', icon: NewspaperIcon, to: urls.offers },
    { name: 'Partners', icon: UsersIcon, to: urls.partners },
    { name: 'Educators', icon: AcademicCapIcon, to: urls.educators },
    { name: 'Evaluations', icon: ClipboardDocumentListIcon, to: urls.evaluations },
    { name: 'Settings', icon: Cog6ToothIcon, to: urls.settings, startsWithOneOf: [urls.settings, urls.team] }
  ]);
}
