import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import moment from 'moment-timezone';
import Avatar from '../../../components/Avatar';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function EvaluationUserColumn(props) {
  const { evaluation, created = false } = props;

  const user = useConnect('user.byId', {
    id: evaluation.data.userId
  });

  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.UserManage.Config(user.id));
  }

  return (
    <div className="flex flex-1 items-center space-x-4">
      <Avatar size={12} src={user.data.avatarUrl}/>
      <div className="flex flex-col">
        <div className="text-lg font-semibold hover:text-cs-gray-500 cursor-pointer" onClick={onClick}>
          {user.data.firstName} {user.data.lastName}
        </div>
        <div className="text-sm text-gray-500 space-x-1">
          <span>
            {created ? 'Creator' : 'Participated'}
          </span>
          <span>·</span>
          <span>Evaluation created {moment(evaluation.data.createdAt).fromNow()}</span>
        </div>
      </div>
    </div>
  );
};
