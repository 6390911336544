import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Invitation from './Invitation';
import moment from 'moment-timezone';

export default function Invitations(props) {
  const { event, responses = [], results = [], emptyState } = props;

  const [timestamp] = useState(moment().toISOString());

  const invitations = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          response: {
            $or: responses
          },
          result: {
            $or: results
          },
          createdAt: {
            $lt: timestamp
          }
        }
      }
    },
    pagination: {
      pageSize: 100
    }
  });

  if (invitations.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (invitations.data.length === 0) {
    return (
      <div className="space-y-2">
        {emptyState}
        {/*<EmptyState*/}
        {/*  title="[Partner] You have not been invited to participate in this event"*/}
        {/*  subtitle="But don't worry! We are working on creating a way for you to let the educator know you're interested."*/}
        {/*/>*/}
      </div>
    );
  }

  return _.sortBy(invitations.data, function(invitation) {
    return {
      Yes: 0,
      Maybe: 1,
      Pending: 2,
      No: 3
    }[invitation.data.response];
  }).map(function(invitation, index) {
    return (
      <Invitation
        key={invitation.id || invitation.cid}
        invitation={invitation}
      />
    );
  });
};
