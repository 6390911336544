import _ from 'lodash';
import getSearchParamsFromQuery from '../utils/user/getSearchParamsFromQuery';

export default function useFilterCount(search = {}, attributeKeys = []) {
  const searchParams = getSearchParamsFromQuery(search);

  const filterMap = _.pick(searchParams, attributeKeys);

  const count = _.reduce(filterMap, function(result, value, key) {
    if (_.isArray(value)) {
      return result + value.length;
    }

    if (_.isObject(value) && _.keys(value).length > 0) {
      return result + 1;
    }

    if (_.isString(value) && value) {
      return result + 1;
    }

    return result;
  }, 0);

  return count;
}
