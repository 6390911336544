import { extractArrayFromQuery } from '../../../utils/query';
import REQUIRED_FIELDS_PUBLIC from '../REQUIRED_FIELDS_PUBLIC';

export default {
  param: extractArrayFromQuery,
  query: function (values) {
    if (values.length > 0) {
      return {
        $or: values.map(function(value) {
          const whereMap = {
            1: {
              $and: REQUIRED_FIELDS_PUBLIC.$and,
              completed: false,
              cancelled: false
            },
            2: {
              completed: true
            },
            3: {
              cancelled: true
            }
          };

          return whereMap[value] || {};
        })
      }
    }
  }
}
