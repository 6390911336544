import React from 'react';
import PropTypes from 'prop-types';

export default function ContentHeading(props) {
  const { title, subtitle, icon } = props;

  return (
    <div>
      <div className="flex text-gray-700">
        <props.icon className="h-8 w-8 mr-2"/>
        <h3 className="text-2xl font-semibold">
          {title}
        </h3>
      </div>
      <div>
        {subtitle}
      </div>
    </div>
  );
}
