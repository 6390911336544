import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Event from '../../events/Event';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Events']}
      title="All Events this School Year"
      description={(
        <span>
          These are all events that were shared publicly on your community's platform. They include
          active events as well as those that expired, were completed or were cancelled during this
          academic year (7/1-6/30). CommunityShare recommends finding ways to publicize successful
          events through media releases, social media, and presentations. Congratulating educators (and
          their supervisors) or partners (and their employers) after a successful project builds
          goodwill and momentum for community-engaged learning in your community. Observe patterns
          for future outreach and resource planning.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(event) {
        return (
          <Event key={event.id} event={event} />
        );
      }}
    />
  );
}
