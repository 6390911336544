import React from 'react';
import classNames from 'classnames';
import ButtonLabel from '../../components/DropdownFilter/ButtonLabel';
import FilterButtonCount from './FilterButtonCount';
import { AdjustmentsHorizontalIcon, AdjustmentsVerticalIcon, FunnelIcon } from '@heroicons/react/24/outline';

export default function FilterButton(props) {
  const {
    count = 0,
    onClick
  } = props;

  const x = [
    FunnelIcon,
    AdjustmentsVerticalIcon,
    AdjustmentsHorizontalIcon
  ];

  return (
    <div className={classNames(
      'relative flex items-center rounded-2xl bg-cs-gray-100 px-3 py-2',
      'shadow-cs-flat -m-px border',
      count > 0 ? 'border-cs-gray-900' : 'border-cs-gray-200',
      'hover:bg-cs-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
      'h-10',
      onClick && 'cursor-pointer'
    )} onClick={onClick}>
      <div className="mx-1.5x mr-2">
        {/*<FunnelIcon className="h-3 w-3 text-cs-gray-900" />*/}
        {/*<AdjustmentsVerticalIcon className="h-5 w-5 text-cs-gray-900" />*/}
        {/*<AdjustmentsHorizontalIcon className="h-5 w-5 text-cs-gray-500" />*/}
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M6.60962 8C6.60962 7.44772 7.05734 7 7.60962 7H17.6096C18.1619 7 18.6096 7.44772 18.6096 8C18.6096 8.55228 18.1619 9 17.6096 9H7.60962C7.05734 9 6.60962 8.55228 6.60962 8ZM8.60962 12C8.60962 11.4477 9.05734 11 9.60962 11H15.6096C16.1619 11 16.6096 11.4477 16.6096 12C16.6096 12.5523 16.1619 13 15.6096 13H9.60962C9.05734 13 8.60962 12.5523 8.60962 12ZM10.6096 16C10.6096 15.4477 11.0573 15 11.6096 15H13.6096C14.1619 15 14.6096 15.4477 14.6096 16C14.6096 16.5523 14.1619 17 13.6096 17H11.6096C11.0573 17 10.6096 16.5523 10.6096 16Z" fill="#8F92A1"/>
        </svg>
      </div>
      <FilterButtonCount count={count} />
      <ButtonLabel bold={true}>
        Filters
      </ButtonLabel>
    </div>
  );
}
