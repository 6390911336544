import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Category from './Category';
import Entry from './Entry';
import { PayloadStates } from '@lore/utils';
import useTags from '../../../hooks/useTags';
import ContentCard from '../../../components_profiles/ContentCard';
import SectionHeader from '../../../components_profiles/SectionHeader';

TagNodesToTree.propTypes = {
  nodes: PropTypes.object.isRequired
};

export default function TagNodesToTree(props) {
  const { title, description, nodes } = props;

  const tags = useTags();

  if (nodes.state === PayloadStates.FETCHING) {
    return null;
  }

  if (nodes.data.length === 0) {
    return null;
  }

  const groups = _.groupBy(nodes.data, function(node) {
    return node._parentTag.id;
  });

  return (
    <div className="space-y-4">
      <SectionHeader
        title={title}
        subtitle={description}
      />
      <div className="space-y-3">
        {_.map(groups, function(value, key) {
          const parentTag = _.find(tags.data, function(tag) {
            return tag.id == key;
          })

          return (
            <ContentCard key={key} className="space-y-4">
              <Category>
                {parentTag.data.name}
              </Category>
              <div className="space-y-3">
                {value.map(function(node) {
                  const { _tag, _tagNode } = node;

                  return (
                    <div key={_tagNode.id}>
                      <Entry
                        title={_tag.data.name}
                        description={_tagNode.data.description}
                      />
                    </div>
                  );
                })}
              </div>
            </ContentCard>
          );
        })}
      </div>
    </div>
  );
}
