import React from 'react';
import Id from '../_components/table/columns/user/Id';
import Name from '../_components/table/columns/user/Name';
import Organization from '../_components/table/columns/user/Organization';
import Joined from '../_components/table/columns/user/Joined';
import Profile from '../_components/table/columns/user/Profile';

export default [
  { id: 'id',            name: 'ID',           row: Id,           selected: false },
  { id: 'name',          name: 'Name',         row: Name,         selected: true },
  { id: 'profile_type',  name: 'Profile type', row: Profile,      selected: true },
  { id: 'organization',  name: 'Organization', row: Organization, selected: true },
  { id: 'date_joined',   name: 'Date joined',  row: Joined,       selected: true }
];
