import React from 'react';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import useStewardshipFilter from '../../../educators/useStewardshipFilter';

export default function useUsersWhoConnectedAboutOffer(account, timestamp) {
  const stewardshipFilter = useStewardshipFilter();

  return useConnect('user.find', {
    where: {
      eager: {
        $aggregations: _.remove([
          {
            type: 'count',
            alias: 'numberOfClaims',
            relation: 'claims',
            $where: {
              createdAt: {
                $gte: timestamp.start,
                $lte: timestamp.end
              },
              response: 'Connect'
            }
          }
        ]),
        $where: {
          numberOfClaims: {
            $gte: 1
          },
          isEducator: true,
          $or: stewardshipFilter
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });
}
