import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Offer from '../../offers/Offer';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Offers']}
      title="New Offers"
      description={(
        <span>
          These are offers created by a partner within the last week. CommunityShare recommends
          reviewing the offer to see if any clarification would make the offer more relevant to
          educators, and if so, contacting the partner with your suggestions. Partners appreciate
          being thanked for creating an offer. We also suggest reviewing to see if any new offers
          meet educator needs and then inviting the educator to the offer.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(offer) {
        return (
          <Offer key={offer.id} offer={offer} />
        );
      }}
    />
  );
}
