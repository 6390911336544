import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Project from './Project';
import useQuery from './useQuery';
import useQueryMeta from '../../feed/useQueryMeta';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const { account, params, pagination } = useQueryMeta({ pageSize: PAGE_SIZE });

  const result = useQuery({
    account,
    pagination,
    params,
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Feed']}
      title="Projects"
      description="List of projects created over the specified time frame."
      pageSize={PAGE_SIZE}
      result={result}
      row={function(project, index) {
        return (
          <Project
            key={project.id}
            project={project}
            last={result.data.length === index + 1}
          />
        );
      }}
    />
  );
}
