import React  from 'react';
import moment from 'moment-timezone';
import TextField from './TextField';

export default function DateCreated(props) {
  const { event } = props;

  return (
    <TextField label="Date Created">
      {moment(event.data.createdAt).format('MMM D, YYYY')} · {`${moment().diff(event.data.createdAt, 'days')} days ago`}
    </TextField>
  );
}
