import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import User from '../../_common_user/ManageUser';
import useQuery from './useQuery';
import MissingFields from '../../_common_user/User/MissingFields';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';

export default function Layout(props) {
  const { location } = useRouter();
  const launch = useDialogLauncher();

  const PAGE_SIZE = 12;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  const csvQuery = useQuery({
    location,
    PAGE_SIZE,
    returnRawQuery: true
  });

  function onDownload(query) {
    launch(DialogManager.CSVDownload.Config(query));
  }

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Educators']}
      title="Incomplete Profiles"
      description={(
        <span>
          These are educators who started a profile but never finished. CommunityShare recommends
          a quick call or email to remind them that you are here to help them find the community
          partners their students need. Ask them to log in to the platform and a banner across the
          top will guide them to exactly what is missing in their profile.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      csvQuery={function () {
        onDownload(csvQuery);
      }}
      row={function(user) {
        return (
          <User key={user.id} user={user}>
            <div className="bg-cs-gray-50 p-4 border-t border-cs-gray-100">
              <MissingFields user={user} />
            </div>
          </User>
        );
      }}
    />
  );
}
