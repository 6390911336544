import React from 'react';
import Section from '../../../pages/_components/sidePanel/Section.new';
import TextContentSection from '../../../components_profiles/TextContentSection';
import ContentCard from '../../../components_profiles/ContentCard';
import Category from '../../../pages/user/User/Category';
import Entry from '../../../pages/user/User/Entry';

export default function Recommend(props) {
  const { evaluation } = props;

  return (
    <ContentCard className="space-y-4">
      <Category>
        Recommend
      </Category>
      <div className="space-y-3">
        <Entry
          title="Would you recommend CommunityShare to other community members?"
          description={evaluation.data.recommend || '-'}
        />
        {evaluation.data.recommend === 'Yes' && (
          <Entry
            title="Please give us a sentence or two about the interaction that we can use to inspire others to volunteer."
            description={evaluation.data.testimonial || '-'}
          />
        )}
        {evaluation.data.recommend === 'No' && (
          <Entry
            title="Tell us how we can improve."
            description={evaluation.data.improve || '-'}
          />
        )}
      </div>
    </ContentCard>
  );

  return (
    <Section title="Recommend">
      <TextContentSection title="Would you recommend CommunityShare to other community members?">
        {evaluation.data.recommend || '-'}
      </TextContentSection>
      {evaluation.data.recommend === 'Yes' && (
        <TextContentSection title="Please give us a sentence or two about the interaction that we can use to inspire others to volunteer.">
          {evaluation.data.testimonial || '-'}
        </TextContentSection>
      )}
      {evaluation.data.recommend === 'No' && (
        <TextContentSection title="Tell us how we can improve.">
          {evaluation.data.improve || '-'}
        </TextContentSection>
      )}
    </Section>
  );
}
