import React from 'react';
import { useConfig } from '@lore/config';
import EvaluationLinkSection from '../_common/EvaluationLinkSection';

export default function EducatorProjectEvaluationLinkSection(props) {
  const { evaluation } = props;

  const config = useConfig();
  const platformUrl = config.custom.platformUrl();

  return (
    <EvaluationLinkSection
      profileType="educator"
      href={`${platformUrl}/evaluation-project-educator/${evaluation.id}`}
    />
  );
};
