import React from 'react';
import moment from 'moment-timezone';
import Card from '../../../components/Card';
import EducatorProjectEvaluationLinkSection from './EducatorProjectEvaluationLinkSection';
import EducatorProjectEvaluationSummarySection from './EducatorProjectEvaluationSummarySection';
import EducatorProjectEvaluationHeaderSection from './EducatorProjectEvaluationHeaderSection';

export default function EducatorProjectEvaluation(props) {
  const { evaluation, project, children, alignChildren = 'bottom' } = props;

  const diff = moment(evaluation.data.updatedAt).unix() - moment(evaluation.data.createdAt).unix();

  return (
    <Card className="overflow-hidden divide-y divide-gray-200">
      {alignChildren === 'top' && children}
      <EducatorProjectEvaluationHeaderSection
        evaluation={evaluation}
        created={project.data.creatorId === evaluation.data.userId}
      />
      {!evaluation.data.completed && (
        <EducatorProjectEvaluationLinkSection evaluation={evaluation} />
      )}
      {(evaluation.data.completed || diff > 100) && (
        <EducatorProjectEvaluationSummarySection evaluation={evaluation} />
      )}
      {alignChildren === 'bottom' && children && (
        <div className="flex p-4">
          {children}
        </div>
      )}
    </Card>
  );
};
