import _ from 'lodash';

function exactOneOf(route, matches = []) {
  let isActive = false;

  matches.forEach(function(match) {
    if (isActive) return;

    if (route === match) {
      isActive = true;
    }
  });

  return isActive;
}

function startsWithOneOf(route, matches = []) {
  let isActive = false;

  matches.forEach(function(match) {
    if (isActive) return;

    if (_.startsWith(route, match)) {
      isActive = true;
    }
  });

  return isActive;
}

function exact(route, matches = '') {
  return exactOneOf(route, [matches]);
}

function startsWith(route, matches = '') {
  return startsWithOneOf(route, [matches]);
}

export default {
  exact,
  exactOneOf,
  startsWith,
  startsWithOneOf
}
