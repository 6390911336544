import React from 'react';
import PropTypes from 'prop-types';
import { BanknotesIcon } from '@heroicons/react/24/outline';
import ContentCard from '../../../components_profiles/ContentCard';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';
import TextContentSection from '../../../components_profiles/TextContentSection';

export default function Grant(props) {
  const { project } = props;

  return (
    <ContentCard className="space-y-8">
      <SubSectionHeader
        icon={BanknotesIcon}
        title="Grant Application"
      />
      <TextContentSection title="Grant Requested">
        {project.data.grant}
      </TextContentSection>
      {project.data.grant === 'Yes' && (
        <>
          <TextContentSection title="Amount">
            ${project.data.request}
          </TextContentSection>
          <TextContentSection title="Budget">
            {project.data.budget}
          </TextContentSection>
        </>
      )}
    </ContentCard>
  );
}
