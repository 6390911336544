import { createBrowserHistory } from '@remix-run/router';

export const searchParamKey = 'dialogs';

export function getSearchParams() {
  const search = window.location.search;
  return new URLSearchParams(search);
}

export function setSearchParams(searchParams, navigate) {
  const to = {
    pathname: window.location.pathname,
    search: searchParams.toString()
  };

  if (navigate) {
    navigate(to);
  } else {
    const history = createBrowserHistory();
    history.push(to);
    // window.location.search = searchParams.toString();
  }
}

export function getSearchParam(key) {
  const searchParams = getSearchParams();
  return searchParams.get(key);
}

export function setSearchParam(key, value, navigate) {
  const searchParams = getSearchParams();
  if (value) {
    searchParams.set(key, value);
  } else {
    searchParams.delete(key);
  }
  setSearchParams(searchParams, navigate);
}

export function getDialogArray() {
  const searchParam = getSearchParam(searchParamKey);
  return JSON.parse(searchParam) || [];
}

export function setDialogArray(dialogArray, navigate) {
  if (dialogArray.length > 0) {
    setSearchParam(searchParamKey, JSON.stringify(dialogArray), navigate);
  } else {
    setSearchParam(searchParamKey, undefined, navigate);
  }
}

export function getRouteForDialog(dialogId) {
  const dialogArray = getDialogArray();
  const dialogConfig = _.find(dialogArray, dialogConfig => dialogConfig.id === dialogId);
  return dialogConfig?.route || '/';
}

export function setRouteForDialog(dialogId, route, navigate) {
  const dialogArray = getDialogArray();
  const dialogConfig = _.find(dialogArray, dialogConfig => dialogConfig.id === dialogId);
  dialogConfig.route = route;
  setDialogArray(dialogArray, navigate);
}

export default function launch(dialog, navigate) {
  const dialogArray = getDialogArray();
  dialogArray.push(dialog);
  setDialogArray(dialogArray, navigate);
};
