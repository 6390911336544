import React, { useRef, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import PageHeading from '../../@communityshare/ui/PageHeading';
import PaginationNew from '../../components/pagination-new/Pagination';
import PaginationInformation from '../../components/pagination-new/PaginationInformation';
import useScrollToTop from '../../hooks/useScrollToTop';
import Results from './Results';
import SortBy from '../evaluations-project-educators/SortBy';
import FilterButton from '../_common_filters/FilterButton';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import { DialogManager } from '../../dialogs_new/DialogManager';
import { parse } from 'query-string';
import useFilterCount from '../../hooks/useFilterCount';
import _ from 'lodash';
import { attributeKeys } from '../evaluations-project-educators/queryUtils';
import MobileNavigation from '../evaluations/MobileNavigation';

export default function Layout(props) {
  const {
    location,
    extra,
    breadcrumbs,
    title,
    description,
    result,
    pageSize,
    row,
    render,
    filterDialog
  } = props;

  const node = useRef();
  const scrollToTop = useScrollToTop();

  const search = parse(location?.search);
  const filterCount = useFilterCount(search, _.filter(attributeKeys, key => [
    'accounts'
  ].indexOf(key) < 0));

  const launch = useDialogLauncher();
  const config = useDialogConfig();

  function showFilters() {
    const dialog = filterDialog || DialogManager.FiltersEvaluationProjectEducator;
    launch(dialog.Config(config?.id, extra));
  }

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Educator Project Evaluations: {title}</title>
      </Helmet>
      <MobileNavigation />
      <PageHeading
        breadcrumbs={breadcrumbs}
        title={title}
        description={description}
      />
      <>
        <div ref={node} className="flex flex-wrap gap-4 justify-between items-end">
          <PaginationInformation
            pageSize={pageSize}
            totalCount={result.meta?.totalCount}
          />
          {/*<PaginationNew*/}
          {/*  pageSize={pageSize}*/}
          {/*  totalCount={result.meta?.totalCount}*/}
          {/*  onNavigate={() => scrollToTop(node)}*/}
          {/*/>*/}
          {location && (
            <div className="flex flex-wrap gap-4">
              <SortBy/>
              <FilterButton
                count={filterCount}
                onClick={showFilters}
              />
            </div>
          )}
        </div>
        {/*<div ref={node} className="flex justify-between">*/}
        {/*  <div className="flex space-x-4">*/}
        {/*    <NetworkFilter/>*/}
        {/*    <TypeFilter/>*/}
        {/*    <DistanceFilter/>*/}
        {/*  </div>*/}
        {/*  <div className="flex space-x-4">*/}
        {/*    <SortBy/>*/}
        {/*    <FilterButton*/}
        {/*      count={filterCount}*/}
        {/*      onClick={showFilters}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        {render ? render : (
          <Results
            result={result}
            row={row}
          />
        )}
        <div className="flex flex-wrap gap-4 items-center justify-between">
          <PaginationInformation
            pageSize={pageSize}
            totalCount={result.meta?.totalCount}
          />
          <PaginationNew
            pageSize={pageSize}
            totalCount={result.meta?.totalCount}
            onNavigate={() => scrollToTop(node)}
          />
        </div>
      </>
    </div>
  );
}
