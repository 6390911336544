import React from 'react';

export default function LineChartLegend(props) {
  const { series = [], colors = [] } = props;

  return (
    <div className="flex flex-wrap gap-x-12 gap-y-3 justify-center">
      {series.map(function(series, index) {
        const label = series.name;
        const color = colors[index];

        return (
          <div key={index} className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <div className="h-3 w-3 rounded-full" style={{ backgroundColor: color }}/>
              <div className="text-sm text-cs-gray-500 text-nowrap">
                {label}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
