import React from 'react';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';

export default function ContributionYear(props) {
  const { account, schoolStart, schoolEnd, titleOnly } = props;

  const totalEvaluations = useConnect('evaluationProjectPartner.find', {
    where: {
      eager: {
        $where: {
          'project.accountId': account.id,
          contributionValue: {
            $gte: 0
          },
          createdAt: {
            $lte: schoolEnd
          }
        }
      },
    },
    pagination: {
      pageSize: 100
    }
  });

  const newEvaluations = useConnect('evaluationProjectPartner.find', {
    where: {
      eager: {
        $where: {
          'project.accountId': account.id,
          contributionValue: {
            $gte: 0
          },
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          }
        }
      },
    },
    pagination: {
      pageSize: 100
    }
  });

  if (
    totalEvaluations.state === PayloadStates.FETCHING ||
    newEvaluations.state === PayloadStates.FETCHING
  ) {
    return (
      <MetricLoader
        name="Total in-kind contributions"
      />
    );
  }

  const totalCount = _.reduce(totalEvaluations.data, function(result, evaluation) {
    return result + evaluation.data.contributionValue;
  }, 0);

  const newCount = _.reduce(newEvaluations.data, function(result, evaluation) {
    return result + evaluation.data.contributionValue;
  }, 0);

  return (
    <DeltaMetric
      name="Total in-kind contributions"
      value={totalCount}
      delta={newCount}
      currency={true}
      titleOnly={titleOnly}
    />
  );
}
