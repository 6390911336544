import React from 'react';
import { parse, stringify } from 'query-string';
import { MISSING_PARTNER_FIELDS } from '../../../utils/user/MISSING_FIELDS';
import useStewardshipFilter from '../../partners/useStewardshipFilter';
import { useConnect } from '../../../../hooks/@lore/query-connect';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 12, returnRawQuery = false } = props;

  const stewardshipFilter = useStewardshipFilter();

  const query = {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfQuestionsConnect',
            relation: 'questions',
            $where: {
              response: 'Connect'
            }
          },
          {
            type: 'count',
            alias: 'numberOfInvitationsYes',
            relation: 'invitations',
            $where: {
              response: 'Yes'
            }
          }
        ],
        $where: {
          isEducator: false,
          numberOfQuestionsConnect: {
            $equals: 0
          },
          numberOfInvitationsYes: {
            $equals: 0
          },
          $or: stewardshipFilter
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  };

  const result = useConnect('user.find', query, {
    enabled: !returnRawQuery,
    keepPreviousData: true
  });

  if (returnRawQuery) {
    return query;
  }

  return result;
}
