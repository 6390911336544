import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import Dialog from '../project_assign';

export default function Launcher(props) {
  const config = useDialogConfig();

  const project = useConnect('project.byId', {
    id: config.params.projectId
  });

  if (project.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Dialog
      project={project}
      onClose={props.onClose}
    />
  );
};
