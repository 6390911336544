import _ from 'lodash';
import { getDialogArray, setDialogArray } from './launch';

export default function removeDialogFromSearchParams(id, navigate) {
  const dialogArray = getDialogArray();
  _.remove(dialogArray, function(dialogConfig) {
    return dialogConfig.id === id;
  });
  setDialogArray(dialogArray, navigate);
}
