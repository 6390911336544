import React from 'react';
import PropTypes from 'prop-types';
import Event from '../../../pages/events/Row';

export default function EventWrapper(props) {
  const { event } = props;

  return (
    <Event
      key={event.id}
      event={event}
    />
  );
};
