import React from 'react';
import LazyMetrics from '../../dashboard-year/LazyMetrics';
import ProjectActivityYear from '../../dashboard-year/Projects/ProjectActivityYear';
import ProjectResponseYear from '../../dashboard-year/Projects/ProjectResponseYear';
import ProjectStatusYear from '../../dashboard-year/Projects/ProjectStatusYear';
import TotalYear from '../../dashboard-year/Projects/TotalYear';
import StudentsYear from '../../dashboard-year/Projects/StudentsYear';
import QuestionsByMonth from '../../dashboard-year/Projects/QuestionsByMonth';
import QuestionCreatorYear from '../../dashboard-year/Projects/QuestionCreatorYear';
import QuestionDeclinerYear from '../../dashboard-year/Projects/QuestionDeclinerYear';
import QuestionDeclineReasonYear from '../../dashboard-year/Projects/QuestionDeclineReasonYear';

export default function Projects(props) {
  const { params } = props;

  return (
    <>
      <LazyMetrics title="Projects" columns={1}>
        <ProjectActivityYear {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          <ProjectResponseYear {...params} />
          <ProjectStatusYear {...params} />
        </div>
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <TotalYear {...params} titleOnly={true} />
          <StudentsYear {...params} titleOnly={true} />
        </div>
      </LazyMetrics>
      <LazyMetrics
        title="Invitations - Projects"
        columns={1}
      >
        <QuestionsByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          <QuestionCreatorYear {...params} />
          <QuestionDeclinerYear {...params} />
        </div>
        <QuestionDeclineReasonYear {...params} />
      </LazyMetrics>
    </>
  );
}
