import React, { useState } from 'react';
import PageHeading from '../../@communityshare/ui/PageHeading';
import useAccount from '../../hooks/useAccount';
import useTags from '../../hooks/useTags';
import TagTypes from '../../constants/TagTypes';
import { Helmet } from 'react-helmet-async';
import getTimestampsForAccount from '../dashboard/getTimestampsForAccount';
import PayloadStates from '../../constants/PayloadStates';
import moment from 'moment-timezone';
import MobileNavigation from '../dashboard/MobileNavigation';

export default function ParentLayout(props) {
  const { children, title } = props;

  const [currentTimestamp] = useState(moment().toISOString());

  const account = useAccount();
  const activities = useTags(TagTypes.Activity);

  function empty() {
    return {
      state: PayloadStates.RESOLVED,
      data: [],
      meta: {
        totalCount: 0
      }
    }
  }

  const params = {
    account,
    activities,
    timestamps: getTimestampsForAccount(account),
    empty,
    currentTimestamp
  };

  return (
    <div key={`${account.id}:${title}`} className="p-8 space-y-8">
      <Helmet>
        <title>Dashboard: All School Years: {title}</title>
      </Helmet>
      <MobileNavigation />
      <PageHeading
        breadcrumbs={['Dashboard', 'All School Years']}
        title={title}
      />
      <div className="space-y-12">
        {children(params)}
      </div>
    </div>
  );
}
