import _ from 'lodash';

export function removeEmpty(array) {
  return _.filter(array, function(item) {
    return !!item;
  });
}

export function extractArrayFromQuery(query, param) {
  if (query[param]) {
    return query[param].split(',').map(id => Number(id));
  }

  return [];
}

export function extractTextFromQuery(query, param) {
  return query[param] || '';
}

export function extractObjectFromQuery(fallback = {}) {
  return function(query, param) {
    if(query[param]) {
      return JSON.parse(query[param]);
    }

    return fallback;
  }
}

export function extractStringArrayFromQuery(query, param) {
  if (query[param]) {
    return query[param].split(',').map(id => id);
  }

  return [];
}

export function extractFilterFromQuery(query, param) {
  if (query[param] && query[param] === 'all') {
    return 'all';
  }

  return 'any';
}

export function addOrRemoveNumberFromArray(array, value) {

  const isSelected = !!_.find(array, function(id) {
    return id === value;
  });

  if (isSelected) {
    array = array.filter(function(id) {
      return id !== value;
    });
  } else {
    array.push(value);
  }

  return array;
}

export function insertOrRemoveNumberFromArray(query, param, value) {
  const extractedArray = extractArrayFromQuery(query, param);
  const updatedArray = addOrRemoveNumberFromArray(extractedArray, value);
  return _.extend({}, query, {
    [param]: updatedArray.join(',')
  });
}

export function changeFilter(query, param, value) {
  return _.extend({}, query, {
    [`${param}Filter`]: value
  });
}

export function mapAttributes(attributes) {
  return function(query, selectedParams = [], timestamp) {
    const selectedAttributes = _.pick(attributes, selectedParams);

    const params = _.mapValues(selectedAttributes, function(attribute, name) {
      return attribute.param(query, name);
    });

    const queries = _.mapValues(selectedAttributes, function(attribute, name) {
      const param = params[name];
      return attribute.query(param, timestamp);
    });

    if (selectedParams.length > 0) {
      return {
        params: params,
        queries: queries,
        where: _.reduce(queries, function(result, query) {
          if (query) {
            result.push(query);
          }

          return result;
        }, [])
      };
    }

    return {
      params: params,
      queries: queries,
      where: []
      // where: _.reduce(queries, function(result, query) {
      //   if (query) {
      //     result.push(query);
      //   }
      //
      //   return result;
      // }, [])
    };

  }
}

export default {
  removeEmpty,
  extractArrayFromQuery,
  extractTextFromQuery,
  extractStringArrayFromQuery,
  extractFilterFromQuery,
  addOrRemoveNumberFromArray,
  insertOrRemoveNumberFromArray,
  changeFilter,
  mapAttributes
}
