import React from 'react';
import PageHeading from '../../@communityshare/ui/PageHeading';
import ParentLayout from '../settings/Layout';
import Screening from './Form';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useAccount from '../../hooks/useAccount';
import { Helmet } from 'react-helmet-async';
import { useQueryClient } from '@tanstack/react-query';
import MobileNavigation from '../settings/MobileNavigation';

export default function Layout(props) {
  const account = useAccount();
  const queryClient = useQueryClient();

  return (
    <ParentLayout>
      <div className="p-8 space-y-8">
        <Helmet>
          <title>Settings: Screening</title>
        </Helmet>
        <MobileNavigation />
        <PageHeading
          breadcrumbs={['Settings']}
          title="Screening"
          description={(
            <>
              <span className="block mb-2">
                Some districts have specific background checks, paperwork, agreements, and
                information that all volunteer community partners need to complete and return
                to Human Resources.
              </span>
              <span className="block mb-2">
                In the box below, enter the URL where this screening information is located. CommunityShare
                will automatically send an email with this link when a partner signs up inviting them to
                start the screening process with your district and again when they accept an interaction
                request as a reminder that they need to complete your required screening.
              </span>
              <span className="block text-left">
                As we grow, we'll add the ability to track screening progress of individual community
                partners in this Admin system.
              </span>
            </>
          )}
        />
        {account.state === PayloadStates.FETCHING ? (
          <Loader />
        ) : (
          <Screening
            account={account}
            onSubmit={function(request) {
              queryClient.invalidateQueries({ queryKey: ['account'] });
            }}
          />
        )}
      </div>
    </ParentLayout>
  );
}
