import React  from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import TextField from '../../projects/_fields/TextField';

export default function HoursServed(props) {
  const { user } = props;

  const projects = useConnect('project.find', {
    where: {
      eager: {
        $where: {
          creatorId: user.id,
          completed: true
        }
      }
    }
  });

  const events = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          creatorId: user.id,
          completed: true
        }
      }
    }
  });

  if (
    projects.state === PayloadStates.FETCHING ||
    events.state === PayloadStates.FETCHING
  ) {
    return (
      <TextField label="Projects/events completed">
        ...
      </TextField>
    );
  }

  return (
    <TextField label="Projects/events completed">
      {projects.meta.totalCount + events.meta.totalCount}
    </TextField>
  );
}
