import React, { useState } from 'react';
import NoteForm from '../../forms/note';
import Note from '../../pages/events/Row/Note';
import { UseQueryConnect } from '../../../hooks/@lore/query-connect';

export default function Notes(props) {
  const {
    notes,
    label,
    extraParams
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const MIN_COUNT = 1;

  const _data = expanded ? notes.data : notes.data.slice(0, MIN_COUNT);

  return (
    <div className="bg-white border-t border-cs-gray-100 shadow-mdx rounded-lgx px-8 py-4">
      <div className="flex justify-between text-base font-semibold">
        <div className="flex space-x-2">
          <div className="text-gray-700">
            {label} ({notes.data.length})
          </div>
          {notes.data.length > MIN_COUNT && (
            <div className="text-blue-500 hover:text-blue-400 cursor-pointer" onClick={() => setExpanded(!expanded)}>
              {expanded ? 'Show less' : 'Show more'}
            </div>
          )}
        </div>
        <div className="text-accent hover:text-primary cursor-pointer" onClick={() => setShowForm(!showForm)}>
          {showForm ? 'Hide form' : 'Add note'}
        </div>
      </div>
      {notes.data.length > 0 && (
        <div className="flow-root mt-2">
          <ul className="-mb-4">
            {_data.map(function(note, index) {
              return (
                <UseQueryConnect key={index} _key="user.byId" params={{
                  id: note.data.creatorId
                }}>
                  {function(creator) {
                    return (
                      <li key={note.id || note.cid}>
                        <div className="relative pb-4">
                          {index !== notes.data.length - 1 ? (
                            <span
                              className="absolute top-5 left-3 -ml-px h-full max-h-6 w-px bg-gray-200"
                              aria-hidden="true"
                            />
                          ) : null}
                          <Note
                            model={note}
                            title={`${creator.data.firstName} ${creator.data.lastName}`}
                            imageSrc={creator.data.avatarUrl}
                          />
                        </div>
                      </li>
                    );
                  }}
                </UseQueryConnect>
              );
            })}
          </ul>
        </div>
      )}
      {showForm && (
        <div className="-mx-4 -mb-5 py-1">
          <NoteForm
            extraParams={extraParams}
            onSubmit={function(note) {
              notes._add(note);
              setShowForm(false);
            }}
          />
        </div>
      )}
    </div>
  );
}
