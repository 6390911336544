import React  from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import TextField from '../../projects/_fields/TextField';

export default function HoursServed(props) {
  const { user } = props;

  const evaluations = useConnect('evaluationProjectPartner.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  })

  if (evaluations.state === PayloadStates.FETCHING) {
    return (
      <TextField label="Hours served">
        ...
      </TextField>
    );
  }

  const hours = _.reduce(evaluations.data, function(result, evaluation) {
    return result + Number(evaluation.data.hours || 0);
  }, 0)

  return (
    <TextField label="Hours served">
      {hours}
    </TextField>
  );
}
