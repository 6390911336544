import React from 'react';
import { Outlet } from 'react-router-dom';
import { useUser } from '@lore/auth';
import useAccount from '../../hooks/useAccount';
import LayoutExploreEvaluations from './LayoutExploreEvaluations';
import LayoutFeed from '../feed/Sidebar/LayoutFeedWrapper';
import LayoutSidebarDashboard from './LayoutSidebarDashboard';
import LayoutSidebar from './LayoutSidebar';
import getNavigationLinks_projects from '../projects/Sidebar/pageNavigationLinks';
import getNavigationLinks_events from '../events/Sidebar/pageNavigationLinks';
import getNavigationLinks_offers from '../offers/Sidebar/pageNavigationLinks';
import getNavigationLinks_partners from '../partners/Sidebar/pageNavigationLinks';
import getNavigationLinks_educators from '../educators/Sidebar/pageNavigationLinks';
import getNavigationLinks_settings from '../settings/Sidebar/pageNavigationLinks';


export default function LayoutSidebarNavigation(props) {
  const { type } = props;

  const account = useAccount();
  const currentUser = useUser();

  if (type === 'dashboard') {
    return (
      <LayoutSidebarDashboard key={type}>
        <Outlet/>
      </LayoutSidebarDashboard>
    );
  }

  if (type === 'feed') {
    return (
      <LayoutFeed key={type}>
        <Outlet/>
      </LayoutFeed>
    );
  }

  if (type === 'projects') {
    return (
      <LayoutSidebar
        key={type}
        title="Projects"
        getNavigationLinks={() => getNavigationLinks_projects()}
      >
        <Outlet/>
      </LayoutSidebar>
    );
  }

  if (type === 'events') {
    return (
      <LayoutSidebar
        key={type}
        title="Events"
        getNavigationLinks={() => getNavigationLinks_events()}
      >
        <Outlet/>
      </LayoutSidebar>
    );
  }

  if (type === 'offers') {
    return (
      <LayoutSidebar
        key={type}
        title="Offers"
        getNavigationLinks={() => getNavigationLinks_offers()}
      >
        <Outlet/>
      </LayoutSidebar>
    );
  }

  if (type === 'partners') {
    return (
      <LayoutSidebar
        key={type}
        title="Partners"
        getNavigationLinks={() => getNavigationLinks_partners()}
      >
        <Outlet/>
      </LayoutSidebar>
    );
  }

  if (type === 'educators') {
    return (
      <LayoutSidebar
        key={type}
        title="Educators"
        getNavigationLinks={() => getNavigationLinks_educators()}
      >
        <Outlet/>
      </LayoutSidebar>
    );
  }

  if (type === 'evaluations') {
    return (
      <LayoutExploreEvaluations key={type}>
        <Outlet/>
      </LayoutExploreEvaluations>
    );
  }

  if (type === 'settings') {
    return (
      <LayoutSidebar
        key={type}
        title="Settings"
        getNavigationLinks={() => getNavigationLinks_settings(account, currentUser)}
      >
        <Outlet/>
      </LayoutSidebar>
    );
  }

  return (
    <Outlet/>
  );
}
