import React from 'react';
import classNames from 'classnames';

export default function Metrics(props) {
  const { title, children, columns = 3 } = props;

  if (!title) {
    <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      {children}
    </div>
  }

  return (
    <div className="space-y-5">
      <h3 className="text-2xl leading-6 font-semibold text-gray-900">
        {title}
      </h3>
      <div className={classNames(
        'mt-5 grid grid-cols-1 gap-5',
        columns > 1 && 'sm:grid-cols-2',
        columns > 2 && 'lg:grid-cols-3'
      )}>
        {children}
      </div>
    </div>
  );
}
