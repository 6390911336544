import React from 'react';
import PropTypes from 'prop-types';
import { ClockIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import StringField from '../../forms/_fields_v2/StringField';
import { OutlineButton } from '../../@communityshare/ui/Button';
import { SolidButton } from '../../@communityshare/ui/Button';
import TextAreaField from '../../forms/_fields_v2/TextAreaField';
import FormTemplate from '../_common_old/FormTemplate.vertical';
import FormField from '../../forms/_common/FormField';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose,
    offer
  } = props;

  const educator = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  let activity = useConnect('tag.byId', {
    id: offer.data.activityId
  }, {
    enabled: !!offer.data.activityId
  });

  if (!offer.data.activityId) {
    activity = {
      state: PayloadStates.RESOLVED,
      data: {
        name: 'No Title'
      }
    };
  }

  return (
    <FormTemplate
      title="Delete offer"
      subtitle={(
        <>
          <span className="block mb-4">
            You are about to delete the
            offer <strong>{offer.data.title || activity.data.name || '...'}</strong> created
            by <strong>{educator.data.firstName} {educator.data.lastName}</strong>.
          </span>
          <span className="block mb-4">
            If you are sure you want to do this, type <strong>DELETE</strong> into the field below.
          </span>
        </>
      )}
      icon={ClockIcon}
      onClose={onClose}
      alert={alert}
      body={(
        <>
          <FormField name="confirmation" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="Confirmation"
                placeholder="Type DELETE..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
        </>
      )}
      footer={(
        <>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Delete offer
          </SolidButton>
        </>
      )}
    />
  );
}
