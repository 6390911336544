import React from 'react';
import { DialogManager } from '../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import User from './User';

export default function ManageUser(props) {
  const {
    user,
    selected = false,
    footer,
    children
  } = props;

  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.UserManage.Config(user.id));
  }

  return (
    <User
      user={user}
      selected={selected}
      footer={footer}
      children={children}
      onClick={onClick}
    />
  );
}
