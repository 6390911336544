import React from 'react';
import { useConnect } from '@lore/query-connect';
import moment from 'moment-timezone';
import Notes from '../_common_manage/Notes';

export default function ProjectNotes(props) {
  const { project } = props;

  const _notes = useConnect('note.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt desc'
    }
  });

  const notes = {
    ..._notes,
    data: _.sortBy(_notes.data, note => -moment(note.data.createdAt).unix())
  }

  return (
    <Notes
      notes={notes}
      label="Project notes"
      extraParams={{
        projectId: project.id
      }}
    />
  );
}
