import React  from 'react';
import moment from 'moment-timezone';
import TextField from '../../projects/_fields/TextField';

export default function InactiveStarting(props) {
  const { user } = props;

  return (
    <TextField label="Inactive since">
      {moment(user.data.inactiveStartingAt).fromNow()}
    </TextField>
  );

  return (
    <TextField label="Inactive since">
      {moment(user.data.inactiveStartingAt).format('MMM D, YYYY')}
    </TextField>
  );
}
