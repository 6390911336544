import React from 'react';
import moment from 'moment-timezone';
import FeedItem from '../../feed/FeedItem';
import { useConnect } from '@lore/query-connect';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import FeedHoverText from '../../feed/FeedHoverText';

export default function Claim(props) {
  const { claim } = props;

  const offer = useConnect('offer.byId', {
    id: claim.data.offerId
  });

  const educator = useConnect('user.byId', {
    id: claim.data.userId
  });

  const creator = useConnect('user.byId', {
    id: claim.data.creatorId
  });

  const activity = useConnect('tag.byId', {
    id: offer.data.activityId
  }, {
    enabled: !!offer.data.activityId
  });

  const launch = useDialogLauncher();

  function onClickCreator() {
    launch(DialogManager.UserManage.Config(creator.id));
  }

  function onClickEducator() {
    launch(DialogManager.UserManage.Config(educator.id));
  }

  function onClickOffer() {
    launch(DialogManager.OfferManage.Config(offer.id));
  }

  const steward = (
    offer.data.creatorId === creator.id ||
    creator.id === educator.id
  ) ? '' : '(steward)';

  return (
    <FeedItem timestamp={moment(claim.data.createdAt).fromNow()} last={props.last}>
      <FeedHoverText onClick={onClickCreator}>
        {creator.data.firstName} {creator.data.lastName} {steward}
      </FeedHoverText> invited <FeedHoverText onClick={onClickEducator}>
        {educator.data.firstName} {educator.data.lastName}
      </FeedHoverText> to the offer <FeedHoverText onClick={onClickOffer}>
        {offer.data.title || activity.data.name}
      </FeedHoverText>
    </FeedItem>
  );

  return (
    <FeedItem timestamp={moment(claim.data.createdAt).fromNow()} last={props.last}>
      <span className="font-medium text-gray-900">
        {educator.data.firstName} {educator.data.lastName}
      </span> was invited to the offer titled <span className="font-medium text-gray-900">
        {offer.data.title || '[missing title]'}
      </span> by <span className="font-medium text-gray-900">
        {creator.data.firstName} {creator.data.lastName}
      </span> {offer.data.creatorId === creator.id ? '' : '(Steward)'}
    </FeedItem>
  );
}
