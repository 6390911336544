import React from 'react';
import Organizations from './Organizations';
import ParentLayout from './ParentLayout';

export default function Layout(props) {
  return (
    <ParentLayout title="Organizations">
      {function(params) {
        return (
          <>
            <Organizations params={params} />
          </>
        );
      }}
    </ParentLayout>
  );
}
