import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ScrollToTop from '../../components/ScrollToTop';
import Sidebar from '../_components/Sidebar';
import getNavigationLinks from '../evaluations/Sidebar/pageNavigationLinks';
import usePageRouter from '../../../hooks/@lore/dialogs-routable/_hooks/usePageRouter';
import useAccount from '../../hooks/useAccount';
import ScrollAnchorContext from '../../context/ScrollAnchorContext';
import getSchoolYearNavigationLinks from '../evaluations/Sidebar/schoolYearNavigationLinks';
import NavigationGroup from '../_navigation/ThickNavigation/NavigationGroup';
import NavigationLabel from '../_navigation/ThickNavigation/NavigationLabel';
import NavigationDropdown from '../dashboard/NavigationDropdown';
import { useUser } from '@lore/auth';

LayoutExploreEvaluations.propTypes = {
  children: PropTypes.node
};

export default function LayoutExploreEvaluations(props) {
  const { children } = props;
  const { location, history } = usePageRouter();
  const scrollAnchorRef = useRef(null);

  const account = useAccount();
  const currentUser = useUser();

  const schoolYearNavigationLinks = getSchoolYearNavigationLinks(account, currentUser);
  const currentSchoolYearNavigationLink = _.find(schoolYearNavigationLinks, function(schoolYear) {
    return _.startsWith(location.pathname, schoolYear.href);
  });

  const navigationLinks = currentSchoolYearNavigationLink ?
    getNavigationLinks(account, currentUser, currentSchoolYearNavigationLink) :
    [];

  return (
    <ScrollAnchorContext.Provider value={scrollAnchorRef}>
      <div className="flex overflow-hidden h-screen">
        <Sidebar
          title="Evaluations"
          navigation={navigationLinks}
          grouped={true}
          header={(
            <NavigationGroup>
              <NavigationLabel>
                School Year
              </NavigationLabel>
              <div className="-mx-3">
                <NavigationDropdown
                  header="Switch school year to"
                  navigationLinks={schoolYearNavigationLinks}
                />
              </div>
            </NavigationGroup>
          )}
        />
        <div key={location.pathname} className="flex flex-col w-0 flex-1 overflow-hidden">
          {/*<SearchHeader*/}
          {/*  sidebarOpen={sidebarOpen}*/}
          {/*  setSidebarOpen={setSidebarOpen}*/}
          {/*/>*/}
          <div ref={scrollAnchorRef} className="flex-1 overflow-y-auto">
            <ScrollToTop key={`scroll-to-top-${location.pathname}`}/>
            {children}
          </div>
        </div>
      </div>
    </ScrollAnchorContext.Provider>
  );
}
