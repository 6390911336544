import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

export default function ExpiredTag(props) {
  const { resource } = props;

  const diff = moment().diff(resource.data.endDate, 'days');

  if (
    resource.data.completed ||
    resource.data.cancelled ||
    diff < 0
  ) {
    return null;
  }

  return (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
      EXPIRED
    </span>
  );
}
