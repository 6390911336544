import React from 'react';
import useCurrentPage from '../../hooks/useCurrentPage';

function Highlight(props) {
  return (
    <span className="text-cs-gray-900 text-base font-semibold">
      {props.children}
    </span>
  );
}
export default function PaginationInformation(props) {
  const { pageSize, totalCount } = props;

  const currentPage = useCurrentPage();

  if (totalCount === 0) {
    return (
      <span className="text-cs-gray-500 text-sm font-normal">
        Showing <Highlight>0</Highlight> results
      </span>
    );
  }

  const min = pageSize * (currentPage - 1) + 1;
  let max = currentPage * pageSize;

  if (max > totalCount) {
    max = min + (totalCount - min);
  }

  return (
    <span className="text-cs-gray-500 text-sm font-normal">
      Showing <Highlight>{min}</Highlight> to <Highlight>{max}</Highlight> of <Highlight>{totalCount}</Highlight> results
    </span>
  );
}
