import classNames from 'classnames';

export default function ButtonLabel(props) {
  const { children, bold = false } = props;

  return (
    <span className={classNames(
      bold ?
        'text-cs-gray-900 text-sm leading-6 font-semibold mr-3' :
        'text-cs-gray-500 text-sm leading-6 font-normal mr-2'
    )}>
      {children}
    </span>
  );
}
