import React from 'react';
import classNames from 'classnames';

export default function SelectFieldLinkOption(props) {
  const { name, count, highlight } = props;
  const isActive = false;

  if (!count) {
    return (
      <>
        {name}
      </>
    );
  }

  console.log(name, count, highlight);

  return (
    <div className="flex justify-between">
      <span>
        {name}
      </span>
      {(count && !highlight) ? (
        <span
          className={classNames(
            // isActive ? 'bg-gray-50' : 'bg-gray-200 text-gray-600',
            isActive ? 'bg-white' : 'text-cs-gray-500',
            'ml-auto inline-block py-0.5 px-2 text-sm rounded-full'
          )}
        >
          {count}
        </span>
      ) : null}
      {(count && highlight) ? (
        <span
          className={classNames(
            isActive ? 'bg-white' : 'bg-cs-orange-500 text-white',
            'ml-auto inline-block py-0.5 px-2 text-sm rounded-full'
          )}
        >
          {count}
        </span>
      ) : null}
    </div>
  );
}
