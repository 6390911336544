import React from 'react';
import Id from './_columns/Id';
import Email from './_columns/Email';
import Sent from './_columns/Sent';
import Accepted from './_columns/Accepted';
import Revoke from './_columns/Revoke';

export default [
  { id: 'id',        name: 'ID',       row: Id,       selected: false },
  { id: 'email',     name: 'Email',    row: Email,    selected: true },
  { id: 'date_sent', name: 'Sent',     row: Sent,     selected: true },
  { id: 'accepted',  name: 'Accepted', row: Accepted, selected: true },
  { id: 'actions',  name: 'Actions',   row: Revoke,   selected: true }
];
