import React  from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import TextField from '../../projects/_fields/TextField';

export default function DonationsProvided(props) {
  const { user } = props;

  const evaluations = useConnect('evaluationProjectPartner.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  });

  if (evaluations.state === PayloadStates.FETCHING) {
    return (
      <TextField label="In-kind donations">
        ...
      </TextField>
    );
  }

  const donation = _.reduce(evaluations.data, function(result, evaluation) {
    return result + Number(evaluation.data.contributionValue || 0);
  }, 0)

  return (
    <TextField label="In-kind donations">
      ${donation.toLocaleString()}
    </TextField>
  );
}
