import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet-async';
import SectionTitle from '../_common/SectionTitle';
import Students from './sections/Students';
import Outcome from './sections/Outcome';
import Reflections from './sections/Reflections';
import Recommend from './sections/Recommend';
import Photographs from './sections/Photographs';
import Card from '../../components/Card';
import ProjectHeader from '../../pages/projects/Row/Header';

Template.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Template(props) {
  const { project, evaluationProjectEducator } = props;

  if (project.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const page = `Educator Project Evaluation #${evaluationProjectEducator.id}`;

  return (
    <div className="space-y-6 -mt-12">
      <Helmet>
        <title>
          {project.data.title ? `${project.data.title}: ${page}` : page}
        </title>
      </Helmet>
      <Header
        project={project}
        evaluationProjectEducator={evaluationProjectEducator}
      />
      <div className="space-y-12">
        <SectionTitle
          title="Project"
          subtitle="The experience the evaluation is for."
        >
          <Card>
            <ProjectHeader
              project={project}
              tags={false}
            />
          </Card>
        </SectionTitle>
      </div>
      <div className="space-y-12">
        <SectionTitle
          title="Response"
          subtitle="What the educator said about their experience."
        >
          <div className="grid grid-cols-1 gap-4">
            <Students evaluation={evaluationProjectEducator} />
            <Outcome evaluation={evaluationProjectEducator} />
            <Reflections evaluation={evaluationProjectEducator} />
            <Recommend evaluation={evaluationProjectEducator} />
            <Photographs evaluation={evaluationProjectEducator} />
          </div>
        </SectionTitle>
      </div>
    </div>
  );
};
