import React  from 'react';

export default function Description(props) {
  const { offer } = props;

  if (!_.trim(offer.data.description)) {
    return null;
  }

  return (
    <div className="text-base text-gray-500 line-clamp-2">
      {offer.data.description}
    </div>
  );
}
