import React from 'react';
import PropTypes from 'prop-types';
import Option from './Option';
import { CheckIcon } from '@heroicons/react/24/outline';

export default function Enabled(props) {
  const { selected, onClick } = props;

  return (
    <Option
      color="green"
      title="Enabled"
      icon={CheckIcon}
      subtitle={(
        <span>
          Educators who create a project will have the option to specify if they are
          seeking additional funding and provide a budget outline, beyond just expressing
          resources they need that partners can also see. The platform explicitly clarifies
          that requesting funding is not a guarantee an educator will receive funding. You
          might use this to simplify an existing mini-grant program (no need for educators
          to complete basic information twice), share funding needs with community members,
          or bundle projects together and apply for corporate funding.
        </span>
      )}
      selectable={true}
      selected={selected}
      onClick={() => onClick('Yes')}
    />
  );
}
