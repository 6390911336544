import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Offer from '../../offers/Offer';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Offers']}
      title="Starred Offers"
      description={(
        <span>
          These are offers that you starred. CommunityShare recommends using this page to help
          track offers you want to pay special attention to.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(offer) {
        return (
          <Offer key={offer.id} offer={offer} />
        );
      }}
    />
  );
}
