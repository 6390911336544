import React from 'react';
import ContentCard from './ContentCard';
import { getActivityIconMap } from '../utils/activityIconMap';
import SubSectionHeader from './SubSectionHeader';

export default function BaseActivity(props) {
  const { activity } = props;

  const iconMap = getActivityIconMap(activity);

  return (
    <ContentCard>
      <SubSectionHeader
        icon={iconMap.icon}
        iconSize="large"
        iconColor="yellow"
        title={activity.data.name}
        subtitle={activity.data.description}
      />
    </ContentCard>
  );
};
