import { useContext } from 'react';
import ScrollAnchorContext from '../context/ScrollAnchorContext';

export function useScrollToTop() {
  const scrollAnchor = useContext(ScrollAnchorContext);

  return function scrollToTop(node) {
    const anchor = scrollAnchor.current || window;
    let top = 0;

    if (node) {
      top = node.current.offsetTop;
    }

    anchor.scrollTo({
      top: top,
      behavior: 'smooth'
    });
  }
}

export default useScrollToTop;
