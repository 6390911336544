import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '../../../hooks/@lore/query-connect';
import ReminderDialogTemplate from '../_common_reminders/ReminderDialogTemplate';

Dialog.propTypes = {
  evaluationProjectPartner: PropTypes.object.isRequired
};

export default function Dialog(props) {
  const { evaluationProjectPartner } = props;

  const reminders = useConnect('reminder.find', {
    where: {
      eager: {
        $where: {
          evaluationProjectPartnerId: evaluationProjectPartner.id
        }
      }
    }
  });

  const user = useConnect('user.byId', {
    id: evaluationProjectPartner.data.userId
  });

  let _data = {
    evaluationProjectPartnerId: evaluationProjectPartner.id,
    recipientId: user.id,
    type: 'Complete'
  };

  return (
    <ReminderDialogTemplate
      title="Send reminder to complete evaluation"
      subtitle={(
        <span>
          This will send an email to {user.data.firstName} nudging them to complete the evaluation.
        </span>
      )}
      reminders={reminders}
      _data={_data}
      MAX_COUNT={2}
      onClose={props.onClose}
      blockerText={evaluationProjectPartner.data.completed && (
        <div className="text-base text-cs-gray-700">
          Evaluation has been completed. Sending a reminder is not allowed.
        </div>
      )}
    />
  );
};
