import React from 'react';
import useRouter from '../hooks/useRouter';
import classNames from 'classnames';

export default function ClearAllFilters(props) {
  const { className, show = false, onClick, count } = props;

  const { location, history } = useRouter();

  if (!show && !location.search) {
    return null;
  }

  return (
    <div
      className={classNames(
        'mt-1 text-cs-orange-500 underline cursor-pointer',
        className
      )}
      onClick={() => onClick ? onClick() : history.push(location.pathname)}
    >
      Clear all filters {count ? `(${count})`: ''}
    </div>
  );
}
