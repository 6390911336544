import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Project from '../../projects/Project';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Projects']}
      title="All Projects this School Year"
      description={(
        <span>
          These are all projects that were shared publicly on your community's platform. They
          include active projects as well as those that expired, were completed or were cancelled
          during this academic year (7/1-6/30). CommunityShare recommends finding ways to publicize
          successful projects through media releases, social media, and presentations. Congratulating
          educators (and their supervisors) or partners (and their employers) after a successful
          project builds goodwill and momentum for community-engaged learning in your community.
          Observe patterns for future outreach and resource planning.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function (project) {
        return (
          <Project key={project.id} project={project} />
        );
      }}
    />
  );
}
