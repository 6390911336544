import React from 'react';

export default function ChartHeader(props) {
  const { title, subtitle } = props;

  return (
    <div className="space-y-1 mb-8">
      <div className="text-lg font-semibold text-cs-gray-900">
        {title}
      </div>
      <div className="text-sm text-cs-gray-500">
        {subtitle}
      </div>
    </div>
  );
}
