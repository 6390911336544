import _ from 'lodash';
import { payload, PayloadStates } from '@lore/utils';

/**
 * Create Blueprint
 */

export default function(key, { Model, modelName, queryClient }) {
  const model = new Model();
  // console.log(model.cid);

  return {
    mutationFn: async function(params) {
      // console.log('mutationFn');
      // console.log(arguments);

      // const model = new Model(params);
      model.set(params);
      // console.log(model.cid);

      // return model.save();
      try {
        await model.save();

        return payload(
          model,
          PayloadStates.RESOLVED
        );
      } catch (err) {
        const response = err.response;
        const error = response.data;
        throw payload(model, PayloadStates.ERROR_CREATING, error);
      }
    },
    onMutate: async function(variables) {
      // console.log('onMutate');
      // console.log(arguments);
      // // Cancel current queries for the todos list
      // await queryClient.cancelQueries({ queryKey: ['todos'] })

      // Create optimistic data
      const optimisticData = payload(model, PayloadStates.CREATING);

      // Add optimistic model to cache
      // queryClient.setQueryData([`${modelName}.byCid`, model.cid], optimisticData);
      queryClient.setQueryData([modelName, 'byCid', model.cid], optimisticData);

      // Return context with the optimistic data
      return optimisticData;
    },
    onSuccess: function(result, variables, context) {
      // console.log('onSuccess');
      // console.log(arguments);
      // queryClient.setQueryData([`${modelName}.byId`, model.id], result);
      // queryClient.setQueryData([`${modelName}.byCid`, model.cid], result);
      queryClient.setQueryData([modelName, 'byId', model.id], result);
      queryClient.setQueryData([modelName, 'byCid', model.cid], result);
      // return {
      //   onSuccess: 'onSuccess'
      // };
    },
    onError: function(error, variables, context) {
      // console.log('onError');
      // console.log(arguments);
      // queryClient.setQueryData([`${modelName}.byCid`, model.cid], error);
      queryClient.setQueryData([modelName, 'byCid', model.cid], error);
      // return {
      //   onError: 'onError'
      // };
    },
  }
};
