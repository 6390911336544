import React from 'react';
import { PayloadStates } from '@lore/utils';
import classNames from 'classnames';
import Loader from '../../components/Loader';
import EmptyState from '../../@communityshare/ui/EmptyState';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';

export default function Results(props) {
  const { result, row } = props;

  if (result.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (result.data.length === 0) {
    return (
      <EmptyState
        icon={ClipboardDocumentListIcon}
        title="There are currently no matching evaluations."
        subtitle="But check back later and there might be."
      />
    );
  }

  return (
    <div className={classNames(
      result._isPreviousData && 'opacity-50',
      // 'w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'
      'w-full grid grid-cols-1 gap-4'
    )}>
      {result.data.map(row)}
    </div>
  );
}
