import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Question from '../../project_manage/project-manage-invitations/Question';
import ProjectHeader from '../../../pages/projects/Row/Header';

export default function QuestionWrapper(props) {
  const { question } = props;

  const project = useConnect('project.byId', {
    id: question.data.projectId
  });

  return (
    <Question
      question={question}
      alignChildren="top"
    >
      {project.state === PayloadStates.FETCHING ? (
        <Loader />
      ) : (
        <ProjectHeader project={project} />
      )}
    </Question>
  );
};
