let community = '';

export function getCommunity() {
  const tokens = window.location.pathname.split('/');

  if (community) {
    return community;
  }

  if (tokens[1] === 'c') {
    return tokens[2];
  }
}

export function setCommunity(value) {
  community = value;
}

function isCommunity() {
  const tokens = window.location.pathname.split('/');
  return tokens[1] === 'c' && !!tokens[2];
}

export default {
  isCommunity,
  getCommunity,
  setCommunity
}
