import React from 'react';
import PropTypes from 'prop-types';
import { useUser } from '@lore/auth';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { getDistanceFromLatLonInMiles } from '../../../components/User/utils';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import IconSection from '../../../@communityshare/ui/card/IconSection';

Distance.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Distance(props) {
  const { project } = props;

  const currentUser = useUser();

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  const organization = useConnect('organization.byId', {
    id: project.data.organizationId
  }, {
    enabled: !!project.data.organizationId
  });

  const school = useConnect('school.byId', {
    id: organization.data.schoolId
  }, {
    enabled: !!organization.data.schoolId
  });

  if (
    creator.state === PayloadStates.FETCHING ||
    (organization && organization.state === PayloadStates.FETCHING) ||
    (school && school.state === PayloadStates.FETCHING)
  ) {
    return (
      <IconSection
        icon={MapPinIcon}
        title="..."
      />
    );
  }

  const distance = getDistanceFromLatLonInMiles(
    organization.data.schoolId ? school.data : creator.data,
    currentUser.data
  );

  return (
    <IconSection
      icon={MapPinIcon}
      title={distance <= 1 ? '1 mile away' : `${distance} miles away`}
    />
  );
}
