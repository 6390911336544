import React from 'react';
import PropTypes from 'prop-types';
import { ClockIcon } from '@heroicons/react/24/outline';
import moment from 'moment-timezone';
import { useUser } from '@lore/auth';
import { OutlineButton, SolidButton } from '../../@communityshare/ui/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';
import MessageDeliveryStatus from '../../components/MessageDeliveryStatus';
import { formatTime, formatDate } from '../../utils/dateTimeUtils';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    title,
    subtitle,
    reminders,
    _data,
    MAX_COUNT,

    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose,
    question
  } = props;

  const currentUser = useUser();

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <ClockIcon className="text-cs-orange-500 h-12 w-12" />
        </div>
      )}
      title={title}
      subtitle={subtitle}
      onClose={onClose}
      alert={alert}
      body={(
        <div className="mt-4">
          {reminders.data.length > 0 && (
            <div className="text-base text-cs-gray-700 mb-8">
              <h3 className="mb-2">
                Reminder history:
              </h3>
              <ul className="list-disc list-insidex ml-8 space-y-2">
                {reminders.data.map(function (reminder) {
                  return (
                    <li key={reminder.id || reminder.cid}>
                      <>
                        <div>
                          {formatDate(reminder.data.createdAt)}, {formatTime(reminder.data.createdAt, currentUser.data.timezone, true)}
                        </div>
                        <div className="text-sm uppercase font-semibold text-cs-orange-500">
                          <MessageDeliveryStatus message={reminder} />
                        </div>
                      </>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {reminders.data.length >= MAX_COUNT ? (
            <div>
              The maximum allowed number of reminders have been sent.
            </div>
          ) : (
            <div>
              Would you like to send reminder #{reminders.data.length + 1}?
            </div>
          )}
        </div>
      )}
      footer={reminders.data.length >= MAX_COUNT ? (
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Close
          </OutlineButton>
        </FormTemplateButtonFooter>
      ) : (
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={() => onSubmit(_data)}>
            Send reminder
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
