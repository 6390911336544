import React, { useState } from 'react';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';

export default function useFeedRouteParams(props) {
  const routeParams = useParams();

  const weeks = routeParams.weeks || 4;

  const [timestamp] = useState(moment().toISOString());

  return {
    weeks,
    timestamp
  };
}
