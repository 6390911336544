import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FormTemplate from './SetupTemplate';
import Community from './Community';
import useRouter from '../../hooks/useRouter';

export default function Communities(props) {
  const { accounts } = props;

  const { history } = useRouter();

  return (
    <div className="my-auto">
      <FormTemplate
        className="w-full"
        title="Which community are you here to steward?"
        body={(
          <>
            <div className="flex flex-col space-y-2">
              {_.sortBy(accounts.data, a => a.data.name).map(function(account) {
                return (
                  <Community
                    key={account.id || account.cid}
                    account={account}
                    onClick={account => history.push(`/c/${account.data.subdomain}`)}
                  />
                );
              })}
            </div>
            <div className="mt-16">
              <h5 className="text-3xl font-semibold text-left m-auto mb-4">
                Don't see your community?
              </h5>
              <div className="text-base text-cs-gray-500">
                You may need to contact an existing steward to gain access.
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
};
