import React from 'react';
import PropTypes from 'prop-types';
import { LinkIcon } from '@heroicons/react/24/outline';
import { useConfig } from '@lore/config';
import Tooltip from '../../../components/Tooltip';

export default function ShareLink(props) {
  const { offer } = props;

  const config = useConfig();

  return (
    <div className="flex space-x-1 items-center text-gray-500 text-sm">
      <Tooltip tooltip="Platform Link" icon={false}>
        <LinkIcon className="h-6 w-6" />
      </Tooltip>
      <a href={`${config.custom.platformUrl()}/offers/${offer.id}`} target="_blank">
        .../offers/{offer.id}
      </a>
    </div>
  );
};
