import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Question from './Question';
import moment from 'moment-timezone';

export default function Questions(props) {
  const { project, responses = [], emptyState } = props;

  const [timestamp] = useState(moment().toISOString());

  const questions = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          response: {
            $or: responses
          },
          createdAt: {
            $lt: timestamp
          }
        }
      }
    },
    pagination: {
      pageSize: 100
    },
    include: responses.length > 0 ? function(model) {
      return (
        model.id &&
        model.data.projectId === project.id &&
        responses.indexOf(model.data.response) >= 0
      );
    } : undefined,
    exclude: function(model) {
      return responses.indexOf(model.data.response) < 0;
    }
  });

  if (questions.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (questions.data.length === 0) {
    return (
      <div className="space-y-2">
        {emptyState}
        {/*<EmptyState*/}
        {/*  title="[Partner] You have not been invited to participate in this project"*/}
        {/*  subtitle="But don't worry! We are working on creating a way for you to let the educator know you're interested."*/}
        {/*/>*/}
      </div>
    );
  }

  return _.sortBy(questions.data, function(question) {
    return {
      Connect: 0,
      Pending: 1,
      Decline: 2
    }[question.data.response];
  }).map(function(question, index) {
    return (
      <Question
        key={question.id || question.cid}
        question={question}
      />
    );
  });
};
