import React from 'react';
import PropTypes from 'prop-types';
import Students from './Students';
import Creator from './Creator';
import useOfferTags from '../../../hooks/useOfferTags';
import TagTypes from '../../../constants/TagTypes';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import MissingField from '../OfferMissingField';

export default function Participants(props) {
  const { offer } = props;

  const offerParticipants = useOfferTags(offer, TagTypes.Participant);

  if (offerParticipants.state === PayloadStates.FETCHING) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (offerParticipants.data.length === 0) {
    return (
      <MissingField name="Participants" />
    );
  }

  return (
    <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
      <div className="w-full">
        <Creator offer={offer} />
      </div>
      <div className="w-full">
        <Students offer={offer} />
      </div>
    </div>
  );
};
