import React from 'react';
import usePartners from './usePartners';

export default function useSleepingPartners(account, schoolStart, schoolEnd, currentTimestamp) {
  return usePartners(account, schoolStart, schoolEnd, {
    sleepingStartingAt: {
      $lte: currentTimestamp
    },
    sleepingEndingAt: {
      $gte: currentTimestamp
    },
    inactive: false
  });
}
