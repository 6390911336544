import { Menu } from '@headlessui/react';
import classNames from 'classnames';

export default function MenuItem(props) {
  const { title, subtitle, selected = false, onClick } = props;

  return (
    <div className="px-2 py-2 ">
      <Menu.Item>
        {({ active }) => (
          <button
            className={classNames(
                selected ? 'bg-cs-orange-500 text-white' :
                  active ? 'bg-cs-orange-50' :
                  'text-cs-gray-900',
                'group flex w-full rounded-md px-2 py-2 text-sm'
            )}
            onClick={onClick}
          >
            {props.icon && (
              <div className="mr-2">
                <props.icon
                  className={classNames(
                    'h-6 w-6',
                      selected ? 'text-white' :
                        active ? 'text-cs-orange-500' :
                        'text-cs-orange-500'
                  )}
                  aria-hidden="true"
                />
              </div>
            )}
            <div className="flex flex-col text-left">
              <div className={classNames(
                'text-base',
                  selected ? 'text-white' :
                    active ? 'text-cs-gray-900' :
                    'text-cs-gray-900'
              )}>
                {title}
              </div>
              {subtitle && (
                <div className={classNames(
                  'text-sm',
                    selected ? 'text-cs-orange-100' :
                      active ? 'text-cs-gray-500' :
                      'text-cs-gray-500'
                )}>
                  {subtitle}
                </div>
              )}
            </div>
          </button>
        )}
      </Menu.Item>
    </div>
  );
}
