import React from 'react';
import PropTypes from 'prop-types';
import ClampableText from '../../../components/ClampableText';
import ContentCard from '../../../components_profiles/ContentCard';
import SectionHeader from '../../../components_profiles/SectionHeader';

About.propTypes = {
  user: PropTypes.object.isRequired
};

export default function About(props) {
  const { user } = props;

  if (!user.data.biography) {
    return null;
  }

  return (
    <div className="space-y-2">
      <SectionHeader title="About Me"/>
      <ContentCard>
        <ClampableText className="text-cs-gray-900" markup={true}>
          {user.data.biography}
        </ClampableText>
      </ContentCard>
    </div>
  );
}
