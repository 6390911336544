import React, { useState } from 'react';
import PageHeading from '../../@communityshare/ui/PageHeading';
import Table from './Table';
import { useDialog } from '@lore/dialogs';
import { parse, stringify } from 'query-string';
import ParentLayout from '../settings/Layout';
import useRouter from '../../hooks/useRouter';
import initialColumns from './columns';
import moment from 'moment-timezone';
import useAccount from '../../hooks/useAccount';
import { Helmet } from 'react-helmet-async';
import MobileNavigation from '../settings/MobileNavigation';

export default function Layout(props) {
  const { location, history } = useRouter();

  const account = useAccount();

  const show = useDialog();
  const [timestamp] = useState(moment().toISOString());

  const PAGE_SIZE = 20;

  const [columns, setColumns] = useState(initialColumns);

  const selectedColumns = columns.filter(column => column.selected)

  return (
    <ParentLayout>
      <div className="p-4 space-y-8">
        <Helmet>
          <title>Team: Staff</title>
        </Helmet>
        <MobileNavigation />
        <PageHeading
          breadcrumbs={['Team']}
          title="Staff"
        />
      </div>
      <Table
        pageSize={PAGE_SIZE}
        columns={selectedColumns}
        select={function (getState) {
          return getState('user.find', {
            where: {
              eager: {
                $where: {
                  'members.isStaff': true,
                  'members.isHidden': false,
                  'members.account.subdomain': account.data.subdomain,
                  createdAt: {
                    $lte: timestamp
                  }
                }
              }
            },
            pagination: {
              page: parse(location.search).page || '1',
              pageSize: PAGE_SIZE,
              order: 'createdAt desc'
            }
          });
        }}
        row={function (user) {
          return (
            <tr key={user.id}>
              {selectedColumns.map(function (column) {
                if (!column.row) {
                  return null;
                }

                return (
                  <column.row
                    key={column.id}
                    user={user}
                  />
                );
              })}
            </tr>
          );
        }}
      />
    </ParentLayout>
  );
}
