import React  from 'react';
import { PayloadStates } from '@lore/utils';
import TextField from './TextField';
import useMissingFields from '../useMissingFields';

export default function MissingFields(props) {
  const { event } = props;

  const { missingFields } = useMissingFields(event);

  if (missingFields.state === PayloadStates.FETCHING) {
    return (
      <TextField label="Missing Fields">
        ...
      </TextField>
    );
  }

  return (
    <TextField label="Missing Fields">
      {missingFields.data.map(function(field) {
        return field.label;
      }).join(', ')}
    </TextField>
  );
}
