import React  from 'react';
import TextField from '../../projects/_fields/TextField';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';

export default function EvaluationsCompleted(props) {
  const { user, align } = props;

  const projectEvaluations = useConnect('evaluationProjectPartner.find', {
    where: {
      eager: {
        $where: {
          userId: user.id,
          completed: true
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const eventEvaluations = useConnect('evaluationEventPartner.find', {
    where: {
      eager: {
        $where: {
          userId: user.id,
          completed: true
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  if (
    projectEvaluations.state === PayloadStates.FETCHING ||
    eventEvaluations.state === PayloadStates.FETCHING
  ) {
    return (
      <TextField label="Evaluations completed" align={align}>
        ...
      </TextField>
    );
  }

  return (
    <TextField label="Evaluations completed" align={align}>
      {projectEvaluations.meta.totalCount + eventEvaluations.meta.totalCount}
    </TextField>
  );
}
