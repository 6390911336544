import {
  BeakerIcon,
  BriefcaseIcon,
  BuildingOffice2Icon,
  CalculatorIcon,
  ChartBarIcon,
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftRightIcon,
  LightBulbIcon,
  MapIcon,
  MicrophoneIcon,
  MusicalNoteIcon,
  PresentationChartLineIcon,
  SparklesIcon,
  TrophyIcon,
  WrenchScrewdriverIcon
} from '@heroicons/react/24/outline';

export const activityIconMap = {
  'Advisor': ChatBubbleLeftEllipsisIcon,
  'Challenge': ChartBarIcon,
  'Competition': TrophyIcon,
  'Demonstration': BeakerIcon,
  'Guest Instructor': PresentationChartLineIcon,
  'Internship Host': BriefcaseIcon,
  'Interview': ChatBubbleLeftRightIcon,
  'Job Shadow': BuildingOffice2Icon,
  'Mentor': LightBulbIcon,
  'Performance': MusicalNoteIcon,
  'Speaker': MicrophoneIcon,
  'Tour': MapIcon,
  'Tutor': CalculatorIcon,
  'Workshop': WrenchScrewdriverIcon
};

export function getActivityIconMap(activity) {
  return {
    icon: activityIconMap[activity.data.name] || SparklesIcon
  };
}
