import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';

export default function useTagTrees(props = {}, options = {}) {
  const enabled = options.enabled || true;

  return useConnect('tagTree.find', {
    pagination: {
      pageSize: 1000
    }
  }, {
    enabled: enabled
  });
}
