import { extractTextFromQuery } from '../../../utils/query';

export default {
  param: extractTextFromQuery,
  query: function (text) {
    // const words = text.split(' ');

    if (text) {
      return {
        $or: [
          {
            'activity.description': {
              $ilike: `%${text}%`
            }
          },
          {
            description: {
              $ilike: `%${text}%`
            }
          },
          {
            audience: {
              $ilike: `%${text}%`
            }
          },
          {
            objective: {
              $ilike: `%${text}%`
            }
          }
        ]
      }
    }
  }
}
