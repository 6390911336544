import React from 'react';
import ContentCard from '../../../components_profiles/ContentCard';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';

export default function Template(props) {
  const {
    title,
    subtitle,
    icon,
    tags,
    children
  } = props;

  return (
    <ContentCard className="space-y-8">
      <SubSectionHeader
        icon={icon}
        title={title}
        subtitle={subtitle}
      />
      {children}
      {tags && (
        <ul className="list-disc ml-6 text-cs-gray-500">
          {tags.data.map(function(tag) {
            return (
              <li key={tag.id || tag.cid}>
                {tag.data.name}
              </li>
            );
          })}
        </ul>
      )}
    </ContentCard>
  );
}
