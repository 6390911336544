import { extractArrayFromQuery } from '../../../utils/query';

export default {
  param: extractArrayFromQuery,
  query: function (values) {
    if (values.length > 0) {
      return {
        hasCost: values[0] === 1 ? 'Yes' : 'No'
        // "id": {
        //   [`$includes_any`]: {
        //     "offers.hasCost": costs
        //   }
        // }
      }
    }
  }
}
