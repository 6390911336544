import validator from 'validator';
import PayloadStates from '../constants/PayloadStates';

const isRequired = function(value) {
  if (value === null || value === undefined || validator.isEmpty(value)) {
    return 'This field is required'
  }
};

const isRequiredAndNumber = function(value) {
  if (value === null || value === undefined || !validator.isNumeric(String(value))) {
    return 'This field is required'
  }
};

const isOptionalAndNumber = function(value) {
  if (value && !validator.isNumeric(String(value))) {
    return 'Must be a number'
  }
};

const isEmail = function(value) {
  if (!validator.isEmail(value)) {
    return 'Must be an email address'
  }
};

const isAscii = function(value) {
  if (value === null || value === undefined || !validator.isAscii(value)) {
    return 'This field is not allowed to have any special characters, symbols or accented letters (such as an umlaut, tilde or cedilla)';
  }
};

const isUsername = function(value) {
  if (value === null || value === undefined) {
    return;
  }

  // remove underscores
  const cleanedUsername = String(value).replace(/_/g, "");

  if (String(value).length < 5) {
    return 'Usernames must be at least 5 characters long';
  }

  if (
    !validator.isAlphanumeric(cleanedUsername) ||
    !validator.isLowercase(cleanedUsername)
  ) {
    return 'Usernames must be all lowercase and can only contain letters and numbers such a-z, 1-9, and _';
  }
};

const isPassword = function(value) {
  if (value === null || value === undefined) {
    return;
  }

  if (String(value).length < 6) {
    return 'Passwords must be at least 6 characters long';
  }

  if (String(value).indexOf(' ') >= 0) {
    return 'Passwords may not contain spaces';
  }

  if (!validator.isAscii(value)) {
    return 'Passwords may not contain special characters such as symbols or accented letters';
  }
};

const isPasswordMatch = function(password, value) {
  if (value !== password) {
    return 'Passwords must match'
  }
};

const isResolved = function(model, value) {
  if (!model) return;

  if (model.state !== PayloadStates.RESOLVED) {
    return 'Checking availability...'
  }
};

const isUrl = function(value) {
  if (value === null || value === undefined || value === '') {
    return;
  }

  if (!validator.isURL(value)) {
    return 'Must be a valid url'
  }
};

const maxLength = function(length) {
  return function(value) {
    if (value === null || value === undefined || value === '') {
      return;
    }

    if (value.length > length) {
      return 'Must be less than ' + length + ' characters';
    }
  }
};

const isValue = function(text) {
  return function(value) {
    if (value !== text) {
      return `Must enter '${text}'`;
    }
  }
};

export default {
  isRequired: isRequired,
  isEmail: isEmail,
  isPasswordMatch: isPasswordMatch,
  isUsername: isUsername,
  isPassword: isPassword,
  isAscii: isAscii,
  maxLength: maxLength,
  isUrl: isUrl,
  isValue: isValue,
  isNumber: isOptionalAndNumber,
  number: {
    isRequired: isRequiredAndNumber
  },
  model: {
    isResolved: isResolved
  }
};
