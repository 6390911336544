import React from 'react';
import Partners from './Partners';
import Organization from './Organization';
import Cost from './Cost';
import DateRange from '../../../dialogs_new/offer_manage/Header/DateRange';
import Creator from '../../../dialogs_new/offer_manage/Header/Creator';
import Title from './Title';
import StatusTags from './StatusTags';
import Description from './Description';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function Header(props) {
  const { offer, children } = props;

  const launch = useDialogLauncher();

  function onClick(offer) {
    launch(DialogManager.OfferManage.Config(offer.id));
  }

  return (
    <div className="flex flex-col p-4 space-y-2">
      <div className="space-y-2">
        <StatusTags offer={offer} />
        <div className="cursor-pointer hover:text-gray-500 space-y-1 pr-10" onClick={() => onClick(offer)}>
          <Title offer={offer} />
          <Description offer={offer} />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          <DateRange offer={offer} />
          <Creator offer={offer} />
          <Organization offer={offer} />
          <Cost offer={offer} />
        </div>
        <Partners offer={offer} />
      </div>
    </div>
  );
}
