import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import useRouter from '../../hooks/useRouter';
import classNames from 'classnames';

PaginationLink.propTypes = {
  page: PropTypes.number,
  text: PropTypes.string,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isDivider: PropTypes.bool,
  onNavigate: PropTypes.func
};

export default function PaginationLink(props) {
  const {
    onNavigate,
    page,
    isDisabled,
    isDivider,
    isActive,
    icon
  } = props;

  const { location } = useRouter();

  const text = props.text || page;

  const borderClassNames = [
    'flex w-10 h-10 items-center justify-center',
    'bg-white shadow-cs-flat rounded-lg',
    isActive ? '-m-0.5 border-2 border-cs-orange-500' : '-m-px border border-cs-gray-200',
    'text-cs-gray-500'
  ].join(' ');

  const content = icon ? (
    <div className={classNames(
      borderClassNames,
      'p-2.5'
    )}>
      {icon}
    </div>
  ) : (
    <span>{text}</span>
  );

  function onClick(e) {
    if (onNavigate) {
      e.preventDefault();
      onNavigate(page);
    }
  }

  if(isDivider) {
    if (icon) {
      return (
        <div className={classNames(
          // borderClassNames,
          'flex items-center text-center justify-center h-10',
          'py-2.5'
        )}>
          {icon}
        </div>
      );
    }
    return (
      <div className={classNames(
        // borderClassNames,
        'flex items-center text-center justify-center h-10'
      )}>
        {content}
      </div>
    );
  }

  if(isDisabled) {
    return (
      <div className={classNames(
        borderClassNames,
        'opacity-50 cursor-not-allowed bg-white',
        'text-cs-gray-500'
      )}>
        {content}
      </div>
    );
  }

  if(isActive) {
    return(
      <div className={classNames(
        borderClassNames,
        'bg-white',
        'text-cs-gray-500'
      )}>
        {content}
      </div>
    );
  }

  const query = parse(location.search);
  query.page = page;

  return (
    <Link className={classNames(
      borderClassNames,
      'bg-white hover:bg-cs-gray-50',
      'text-cs-gray-500'
    )} to={{ pathname: location.pathname, search: stringify(query) }} onClick={onClick}>
      {content}
    </Link>
  );
};
