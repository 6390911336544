import React  from 'react';
import PropTypes from 'prop-types';
import classNames from '../../utils/classNames';

export default function ContentCard(props) {
  const { children, className } = props;

  return (
    <div className={classNames(
      'bg-white rounded-xl shadow-lg py-6 px-6 space-y-8',
      className
    )}>
      {children}
    </div>
  );
}
