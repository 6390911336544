import React from 'react';
import useAccount from '../../../hooks/useAccount';
import useGetCount from '../../explore/useGetCount';

// Offers
import starredOffers from '../../offers-filtered/starred/useQuery';
import newOffers from '../../offers-filtered/new/useQuery';
import activeOffers from '../../offers-filtered/active/useQuery';
import schoolYearOffers from '../../offers-filtered/school-year/useQuery';

export default function getNavigationLinks() {
  const account = useAccount();

  const root = `/c/${account.data.subdomain}`;

  return [
    { name: 'All', href: `${root}/explore/offers` },
    // { name: 'All (Table)', href: `${root}/explore/offers` },
    { name: 'Starred', href: `${root}/explore/offers/starred`, count: useGetCount(starredOffers) },
    { name: 'New', href: `${root}/explore/offers/new`, count: useGetCount(newOffers), highlight: true },
    { name: 'Active Offers', href: `${root}/explore/offers/active`, count: useGetCount(activeOffers) },
    { name: 'All Offers this School Year', href: `${root}/explore/offers/school-year`, count: useGetCount(schoolYearOffers) }
  ];
}
