import React from 'react';
import PropTypes from 'prop-types';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import StringField from '../../forms/_fields_v2/StringField';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../@communityshare/ui/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose,
    project
  } = props;

  const educator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  let activity = useConnect('tag.byId', {
    id: project.data.activityId
  }, {
    enabled: !!project.data.activityId
  });

  if (!project.data.activityId) {
    activity = {
      state: PayloadStates.RESOLVED,
      data: {
        name: 'No Title'
      }
    };
  }

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <TrashIcon className="text-cs-orange-500 h-12 w-12" />
        </div>
      )}
      title="Delete project"
      subtitle={(
        <div className="space-y-2">
          <div>
            You are about to delete the
            project <span className="font-semibold text-cs-gray-700">{project.data.title || activity.data.name || '...'}</span> created
            by <span className="font-semibold text-cs-gray-700">{educator.data.firstName} {educator.data.lastName}</span>.
          </div>
          <div>
            If you are sure you want to do this, type <span className="font-semibold text-cs-gray-700">DELETE</span> into the field below.
          </div>
        </div>
      )}
      onClose={onClose}
      body={(
        <>
          <FormField name="confirmation" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="Confirmation"
                placeholder="Type DELETE..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
        </>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Delete project
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
