import { extractArrayFromQuery } from '../../../utils/query';

export default {
  param: extractArrayFromQuery,
  query: function (values) {
    if (values.length > 0) {
      return {
        contributionValue: {
          $or: values.map(function(value) {
            const hours = {
              1: 0,
              2: 1
            }[value] || Number(value);

            return Number(value) === 1 ? {
              $equals: 0
            } : {
              $gte: hours
            }
          })
        }
      }
    }
  }
}
