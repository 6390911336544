import * as FullStory from '@fullstory/browser';

export default function(config) {
  const { fullstory } = config;

  if (
    fullstory.enabled &&
    fullstory.orgId
  ) {
    FullStory.init({
      orgId: fullstory.orgId
    });
  }
};
