import React from 'react';
import { PayloadStates } from '@lore/utils';
import classNames from 'classnames';
import Loader from '../../../components/Loader';
import EmptyState from '../../../@communityshare/ui/EmptyState';
import { PhotoIcon } from '@heroicons/react/24/outline';
import getUrlForUpload from '../../../utils/getUrlForUpload';
import ImageChildBuilder from '../../evaluations/_common/ImageChildBuilder';
import Images from '../../story/Story/Images';

export default function Results(props) {
  const { result } = props;

  if (result.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (result.data.length === 0) {
    return (
      <EmptyState
        icon={PhotoIcon}
        title="There are currently no matching photos."
        subtitle="But check back later and there might be."
      />
    );
  }

  return (
    <Images
      className={classNames(
        'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4',
        result._isPreviousData && 'opacity-50'
      )}
      imageHeight="h-64 sm:h-64 lg:h-64"
      images={{
        data: result.data.map(function(upload) {
          const url = getUrlForUpload(upload);

          return {
            id: upload.id,
            data: {
              name: upload.data.name,
              imageUrl: url,
              // width: 0,
              // height: 0
            },
            _upload: upload
          };
        })
      }}
      imageChild={function(image) {
        return (
          <ImageChildBuilder upload={image._upload} />
        );
      }}
    />
  );
}
