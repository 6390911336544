import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

export default function Tooltip(props) {
  const {
    tooltip,
    subtitle = false,
    label = false,
    icon = true,
    white = false,
    children,
    place
  } = props;

  const [uuid] = useState(uuidv4());

  /*
   * Weird hack required to get ReactTooltip to work inside a modal.
   * https://github.com/wwayne/react-tooltip#1-using-tooltip-within-the-modal-eg-react-modal
   */
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  if (!tooltip) {
    return children || null;
  }

  if (!icon) {
    return (
      <>
        <div data-tip data-for={uuid}>
          {children}
        </div>
        <ReactTooltip
          id={uuid}
          className={classNames(
            'tooltip-custom-theme',
            _.isString(tooltip) && 'max-w-xs'
          )}
          type="light"
          effect="solid"
          place={place}
        >
          {_.isString(tooltip) ? (
            <div className={classNames(
              'text-base',
              white ? 'text-white' : 'text-cs-gray-500'
            )}>
              {tooltip}
            </div>
          ) : tooltip}
        </ReactTooltip>
      </>
    );
  }

  if (label) {
    return (
      <>
        <QuestionMarkCircleIcon
          data-tip
          data-for={uuid}
          className={classNames(
            'h-5 w-5 cursor-pointer',
            white ? 'text-white' : 'text-gray-500'
          )}
        />
        <ReactTooltip
          id={uuid}
          className={classNames(
            'tooltip-custom-theme',
            _.isString(tooltip) && 'max-w-xs'
          )}
          type="light"
          effect="solid"
          place={place}
        >
          {_.isString(tooltip) ? (
            <div className={classNames(
              'text-base',
              white ? 'text-white' : 'text-gray-500'
            )}>
              {tooltip}
            </div>
          ) : tooltip}
        </ReactTooltip>
      </>
    );
  }

  if (subtitle) {
    return (
      <>
        <QuestionMarkCircleIcon
          data-tip
          data-for={uuid}
          className="h-6 w-6 text-gray-500 cursor-pointer"
        />
        <ReactTooltip
          id={uuid}
          className={classNames(
            'tooltip-custom-theme',
            _.isString(tooltip) && 'max-w-xs'
          )}
          type="light"
          effect="solid"
          place={place}
        >
          {_.isString(tooltip) ? (
            <div className={classNames(
              'text-base',
              white ? 'text-white' : 'text-gray-500'
            )}>
              {tooltip}
            </div>
          ) : tooltip}
        </ReactTooltip>
      </>
    );
  }

  return (
    <>
      <QuestionMarkCircleIcon
        data-tip
        data-for={uuid}
        className={classNames(
          'h-6 w-6 cursor-pointer',
          white ? 'text-white' : 'text-gray-700'
        )}
      />
      <ReactTooltip
        id={uuid}
        className={classNames(
          'tooltip-custom-theme',
          _.isString(tooltip) && 'max-w-xs'
        )}
        type="light"
        effect="solid"
        place={place}
        // delayHide={500}
        // delayShow={100}
        // delayUpdate={500}
        // getContent={(dataTip) => tooltip}
      >
        {_.isString(tooltip) ? (
          <div className={classNames(
            'text-base',
            white ? 'text-white' : 'text-gray-500'
          )}>
            {tooltip}
          </div>
        ) : tooltip}
      </ReactTooltip>
    </>
  );
}
