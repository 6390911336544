import React from 'react';
import Claim from './Claim';
import useQuery from './useQuery';

export default function useMapping(props) {
  const models = useQuery(props);

  const type = 'claim';

  const entries = models.data.map((value, index) => ({
    type: type,
    value: (
      <Claim
        key={`${type}-${index}`}
        claim={value}
      />
    ),
    data: value,
    timestamp: value.data.createdAt
  }));

  return {
    models,
    entries
  };
}
