import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import useAccount from '../../hooks/useAccount';
import useRouter from '../../hooks/useRouter';
import { REQUIRED_PARTNER_FIELDS } from '../../utils/user/REQUIRED_FIELDS';

export default function useStewardshipFilter(allowPublic = false) {
  const { location } = useRouter();
  const query = parse(location.search);

  const account = useAccount();

  if (
    allowPublic &&
    query.accounts === '0'
  ) {
    return [
      { accountId: account.id },
      REQUIRED_PARTNER_FIELDS
    ];
  }

  return [
    { accountId: account.id }
  ];
};
