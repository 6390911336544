import React from 'react';
import useEducators from './useEducators';

export default function useSleepingPartners(account, schoolStart, schoolEnd, currentTimestamp) {
  return useEducators(account, schoolStart, schoolEnd, {
    sleepingStartingAt: {
      $lte: currentTimestamp
    },
    sleepingEndingAt: {
      $gte: currentTimestamp
    },
    inactive: false
  });
}
