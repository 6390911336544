import React from 'react';
import Partners from './Partners';
import Organization from './Organization';
import DateRange from '../../../dialogs_new/project_manage/Header/DateRange';
import Creator from '../../../dialogs_new/project_manage/Header/Creator';
import Title from './Title';
import StatusTags from './StatusTags';
import Description from './Description';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function Header(props) {
  const { project, tags = true } = props;

  const launch = useDialogLauncher();

  function onClick(project) {
    launch(DialogManager.ProjectManage.Config(project.id));
  }

  return (
    <div className="flex flex-col p-4 space-y-2">
      <div className="space-y-2">
        {tags && (
          <StatusTags project={project} />
        )}
        <div className="cursor-pointer hover:text-gray-500 space-y-1 pr-10" onClick={() => onClick(project)}>
          <Title project={project} />
          <Description project={project} />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          <DateRange project={project} />
          <Creator project={project} />
          <Organization project={project} />
        </div>
        <Partners project={project} />
      </div>
    </div>
  );
}
