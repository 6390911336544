import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/images/logo.png';
import classNames from 'classnames';
import { useConfig } from '@lore/config';

export default function NavigationLogo() {
  const config = useConfig();

  return (
    <Link to="/" className="flex justify-center">
      <img className={classNames(
        'h-12',
        config.flags.grayscale && 'grayscale'
      )} src={logo} alt="logo" />
    </Link>
  );
}
