import { useNavigate } from 'react-router-dom';
import launch from './launch';

export default function useDialogLauncher() {
  const navigate = useNavigate();

  return function(dialogConfig) {
    launch(dialogConfig, navigate);
  }
};
