import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Project from '../../projects/Project';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Projects']}
      title="No Partners Invited"
      description={(
        <span>
          These are projects where an educator did not invite any partners or where all the partners
          they invited had to decline. CommunityShare recommends contacting the educator to determine
          why there are no partners invited. Did they not have enough time? Did they not find the right
          partner match? Do they need your help reaching out into the community to recruit the right
          partner?
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function (project) {
        return (
          <Project key={project.id} project={project} />
        );
      }}
    />
  );
}
