import React from 'react';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import Testimonial from '../../evaluations/Testimonial';

export default function Evaluation(props) {
  const { evaluation } = props;

  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.EvaluationProjectPartner.Config(evaluation.id));
  }

  return (
    <Testimonial
      evaluation={evaluation}
      onClick={onClick}
    />
  );
}
