import React from 'react';
import PropTypes from 'prop-types';
import { PhoneIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';

export default function UserPhone(props) {
  const { user } = props;

  // const creator = useConnect('user.byId', {
  //   id: event.data.creatorId
  // });

  return (
    <div className="flex space-x-1 items-center text-gray-500 text-sm">
      <PhoneIcon className="h-5 w-5" />
      <span>
        {user.data.phoneNumber}
      </span>
    </div>
  );
};
