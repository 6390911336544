import { mapAttributes, extractArrayFromQuery } from '../../../utils/query';

import accounts from './accounts';
import contributions from './contributions';
import createdDates from './createdDates';
import hours from './hours';
import locationCondition from './locationCondition';
import name from './name';
import organization from './organization';
import partnerInteractions from './partnerInteractions';
import searchPartner from './searchPartner';
import students from './students';
import genders from './genders';
import ethnicities from './ethnicities';

function tag() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagNodes.tagId': {
            $in_subquery: values
          }
        }
      }
    }
  };
}

function tagTree() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagTrees.parentId': {
            $in: values
          }
        }
      }
    }
  };
}

const attributes = {
  // accounts,
  activities: tag(),
  activityTypes: tag(),
  ages: tag(),
  contributions,
  createdDates,
  ethnicities,
  experiences: tag(),
  expertiseTypes: tagTree(),
  genders,
  groups: tag(),
  guidances: tag(),
  hours,
  locationCondition,
  name,
  organization,
  partnerInteractions,
  searchPartner,
  students
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
