import React from 'react';
import PropTypes from 'prop-types';
import { CalendarIcon } from '@heroicons/react/24/outline';
import moment from 'moment-timezone';
import Tooltip from '../../../components/Tooltip';

export default function Date(props) {
  const { event } = props;

  return (
    <div className="flex space-x-1 items-center text-gray-500 text-sm">
      <Tooltip tooltip="Date" icon={false}>
        <CalendarIcon className="h-6 w-6" />
      </Tooltip>
      <span>
        {moment(event.data.date).format('MMM Do, YYYY')}
      </span>
    </div>
  );
};
