import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Claim from '../../offer_manage/offer-manage-invitations/Claim';
import OfferHeader from '../../../pages/offers/Row/Header';

export default function ClaimWrapper(props) {
  const { claim } = props;

  const offer = useConnect('offer.byId', {
    id: claim.data.offerId
  });

  return (
    <Claim
      claim={claim}
      alignChildren="top"
    >
      {offer.state === PayloadStates.FETCHING ? (
        <Loader />
      ) : (
        <OfferHeader offer={offer} />
      )}
    </Claim>
  );
};
