import React from 'react';
import { Helmet } from 'react-helmet-async';
import Offers from './Offers';
import useRouter from '../../hooks/useRouter';
import PageHeading from '../../@communityshare/ui/PageHeading';
import Results from '../_common_results/Results';
import Offer from './Offer';
import Filters from '../../forms/filters_offers';
import { NewspaperIcon } from '@heroicons/react/24/outline';
import MobileNavigation from './MobileNavigation';

export default function Layout(props) {
  const router = useRouter();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Explore Offers</title>
      </Helmet>
      <MobileNavigation />
      <PageHeading
        breadcrumbs={['Explore', 'Offers']}
        title="All Offers"
        description={(
          <>
            This page shows all offers in your network. Use the filters to narrow the
            list. Find a filter you want to see again in the future? Bookmark the page
            or save a link to the URL.
          </>
        )}
      />
      <Filters router={router} />
      <Offers router={router}>
        {function(result) {
          return (
            <Results result={result} columns={1} resource="offers" emptyIcon={NewspaperIcon}>
              {function(offer, index) {
                return (
                  <Offer
                    key={index}
                    offer={offer}
                  />
                );
              }}
            </Results>
          );
        }}
      </Offers>
    </div>
  );
}
