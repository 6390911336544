/**
 * Production-next environment settings
 *
 * This file is where you define overrides for any of the config settings that
 * should only be applied in the production environment.
 *
 * The production environment is defined as 'process.env.LORE_ENV=production' and
 * is automatically set when webpack is invoked using the --env.lore=production argument.
 */

import { getCommunity } from '../../src/utils/community';

export default {

  /**
   * To override a config setting, specify the name of the config file you
   * want to override, followed by the settings you want to change.
   */

  auth0: {
    clientID: '97v6t0JM9UWXQxkSuhep0exSMikrzuAO',
    redirectUri: `https://steward-next.communityshare.org/auth/callback`,
  },

  connections: {
    default: {
      // apiRoot: `https://${community}-admin.communityshare.cloud`
      apiRoot: function() {
        const community = getCommunity();

        if (community) {
          return `https://${community}-admin.communityshare.cloud`;
        }

        return `https://admin.communityshare.cloud`;
      }
    }
  },

  intercom: {
    companyId: 'production-admin',
    companyName: 'Production (Admin)'
  },

  raven: {
    environment: 'production-admin',
    enabled: true
  },

  fullstory: {
    enabled: true
  },

  dialogs: {
    showTransitions: true
  },

  flags: {
    grayscale: false
  }

}
