import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Event from '../../events/Event';
import Slots from '../../events/_fields/Slots';
import Filled from '../../events/_fields/Filled';
import useQuery from './useQuery';
import CardChildrenWrapper from '../../_common_results/CardChildrenWrapper';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Events']}
      title="Happening Soon"
      description={(
        <span>
          These are events planned for the next two weeks. CommunityShare recommends checking the
          “percent filled” column and contacting the educator to see if the event requires 100%
          attendance to happen. If so, invite more partners to try and reach 100%.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(event) {
        return (
          <Event key={event.id} event={event}>
            <CardChildrenWrapper>
              <div className="grid grid-cols-2 gap-4 w-full">
                <Slots event={event}/>
                <Filled event={event}/>
              </div>
            </CardChildrenWrapper>
          </Event>
        );
      }}
    />
  );
}
