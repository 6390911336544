import React  from 'react';
import TextField from './TextField';

export default function Funding(props) {
  const { project } = props;

  return (
    <TextField label="Funding Requested">
      ${project.data.request}
    </TextField>
  );
}
