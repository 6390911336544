import React from 'react';
import { useUser } from '@lore/auth';
import useAccount from '../../../hooks/useAccount';
import useGetCount from '../../explore/useGetCount';

// Educators
import starredEducators from '../../educators-filtered/starred/useQuery';
import incompleteEducators from '../../educators-filtered/incomplete/useQuery';
import newEducators from '../../educators-filtered/new/useQuery';
import sleepingEducators from '../../educators-filtered/sleeping/useQuery';
import inactiveEducators from '../../educators-filtered/inactive/useQuery';
import noneCreatedEducators from '../../educators-filtered/none-created/useQuery';
import multipleCompletedEducators from '../../educators-filtered/multiple-completed/useQuery';

export default function getNavigationLinks() {
  const account = useAccount();
  const currentUser = useUser();

  const root = `/c/${account.data.subdomain}`;

  return _.remove([
    { name: 'All', href: `${root}/explore/educators` },
    currentUser.data.isSuperuser ?
      { name: '[Engagement]', href: `${root}/explore/educators/engagement` } :
      null,
    { name: 'Starred', href: `${root}/explore/educators/starred`, count: useGetCount(starredEducators) },
    { name: 'New', href: `${root}/explore/educators/new`, count: useGetCount(newEducators), highlight: true },
    { name: 'Sleeping', href: `${root}/explore/educators/sleeping`, count: useGetCount(sleepingEducators) },
    { name: 'Inactive', href: `${root}/explore/educators/inactive`, count: useGetCount(inactiveEducators) },
    { name: 'Incomplete Profile', href: `${root}/explore/educators/incomplete`, count: useGetCount(incompleteEducators) },
    { name: 'No Requests', href: `${root}/explore/educators/none-created`, count: useGetCount(noneCreatedEducators) },
    { name: '3+ Completed', href: `${root}/explore/educators/multiple-completed`, count: useGetCount(multipleCompletedEducators) }
  ]);
}
