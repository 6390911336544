import React  from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import { PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import { getDistanceFromLatLonInMiles } from '../../../components/User/utils';
import IconSection from '../../../components/IconSection';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';

ContactInformation.propTypes = {
  user: PropTypes.object.isRequired
};

export default function ContactInformation(props) {
  const { user } = props;

  return (
    <div className="space-y-3">
      <SubSectionHeader title="Contact Information" />
      <div className="space-y-2">
        <IconSection
          icon={EnvelopeIcon}
          title="Email"
          subtitle={user.data.email}
          breakAll={true}
        />
        <IconSection
          icon={PhoneIcon}
          title="Phone Number"
          subtitle={user.data.phoneNumber}
        />
      </div>
    </div>
  );
}
