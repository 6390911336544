import React from 'react';
import PropTypes from 'prop-types';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import Tooltip from '../../../components/Tooltip';

export default function CreatorEmail(props) {
  const { offer } = props;

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  return (
    <div className="flex space-x-1 items-center text-gray-500 text-sm">
      <Tooltip tooltip="Creator Email" icon={false}>
        <EnvelopeIcon className="h-6 w-6" />
      </Tooltip>
      <span>
        {creator.data.email || '[None]'}
      </span>
    </div>
  );
};
