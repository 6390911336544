import { extractArrayFromQuery } from '../../../utils/query';

export default {
  param: extractArrayFromQuery,
  query: function (values) {
    if (values.length > 0) {
      return {
        [`evaluationEventEducator.testimonial`]: {
          $or: _.flatten(values.map(function(value) {
            return {
              1: {
                $not: ''
              },
              2: {
                $equals: ''
              }
            }[value];
          }))
        }
      }
    }
  }
}
