import { extractArrayFromQuery } from '../../../utils/query';

export default {
  param: extractArrayFromQuery,
  query: function (values) {
    if (values.length > 0) {
      return {
        // numberOfProjects: {
        //   $in: values.map(function(value) {
        //     return Number(value) - 1;
        //   })
        // }
        $or: values.map(function(value) {
          return {
            projects: {
              $count_eq: Number(value) - 1
            }
          }
        })
      }
    }
  }
}
