import React from 'react';
import PropTypes from 'prop-types';
import Activity from './Activity';
import Description from './Description';
import Commitment from './Commitment';
import Section from '../../_common/Section';
import Participants from './Participants';
import Participation from './Participation';
import Uploads from './Uploads';
import Experience from './Experience';
import Skills from './Skills';
import Subjects from './Subjects';
import Support from './Support';
import Grant from './Grant';

export default function Project(props) {
  const { project } = props;

  return (
    <div className="space-y-8">
      <div className="flex flex-col md:flex-row space-x-8">
        <div className="w-full md:w-2/3 space-y-8">
          <Section title="Project">
            <Activity project={project} />
            <div className="w-full space-y-4 block md:hidden">
              <Commitment project={project} />
              <Participation project={project} />
              <Support project={project} />
              <Grant project={project} />
            </div>
          </Section>
          <Section title="Details">
            <Description project={project} />
            <Subjects project={project} />
          </Section>
          <Section
            title="Audience"
            subtitle="Learners the activity is designed for."
          >
            <Participants project={project} />
          </Section>
          <Section
            title="Ideal Partner"
            subtitle="Do you have experience with some or all of these topics?"
            hideIfNoChildren={true}
          >
            <Skills project={project} />
            <Experience project={project} />
          </Section>
          <Uploads project={project} />
        </div>
        <div className="w-full md:w-1/3 space-y-8 mt-12 hidden md:block">
          <Commitment project={project} />
          <Participation project={project} />
          <Support project={project} />
          <Grant project={project} />
        </div>
      </div>
    </div>
  );
};
