/**
 * Development environment settings
 *
 * This file is where you define overrides for any of the config settings when operating under the
 * development environment. Development environment is defined as `LORE_ENV=development` or the absence
 * of a `LORE_ENV` environment variable.
 */

export default {

  /**
   * To override a config setting, specify the name of the config file you
   * want to override, followed by the settings you want to change.
   */

}
