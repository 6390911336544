import React from 'react';
import Network from '../../../components/Network';
import AccountThin from '../../AccountThin';
import NavigationLogo from './NavigationLogo';
import ThinNavigationLink from './ThinNavigationLink';
import NavigationGroup from './NavigationGroup';
import usePageRouter from '../../../../hooks/@lore/dialogs-routable/_hooks/usePageRouter';
import isActiveChecker from './isActiveChecker';
import useMainNavigationLinks from './useMainNavigationLinks';

export default function ThinNavigation() {
  const { location, history } = usePageRouter();

  const navigationLinks = useMainNavigationLinks();

  const currentRoute = location.pathname;

  return (
    <div className="space-y-12 bg-white">
      <NavigationGroup>
        <NavigationLogo />
      </NavigationGroup>
      <NavigationGroup>
        <Network
          showName={false}
          tooltip={true}
        />
      </NavigationGroup>
      <div className="space-y-8">
        <NavigationGroup className="flex-grow flex flex-col">
          <div className="space-y-1">
            {navigationLinks.map((item) => (
              <ThinNavigationLink
                key={item.name}
                name={item.name}
                icon={item.icon}
                onClick={() => history.push(item.to)}
                isActive={item.startsWithOneOf ?
                  isActiveChecker.startsWithOneOf(currentRoute, item.startsWithOneOf) :
                  isActiveChecker.startsWith(currentRoute, item.to)
                }
              />
            ))}
          </div>
        </NavigationGroup>
      </div>
      {/*<NavigationGroup>*/}
      {/*  <div className="-mx-3">*/}
      {/*    <Account />*/}
      {/*  </div>*/}
      {/*</NavigationGroup>*/}
      <NavigationGroup>
        <AccountThin />
      </NavigationGroup>
    </div>
  )
}
