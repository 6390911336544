import React from 'react';
import Section from '../../../pages/_components/sidePanel/Section.new';
import TextContentSection from '../../../components_profiles/TextContentSection';
import ContentCard from '../../../components_profiles/ContentCard';
import Category from '../../../pages/user/User/Category';
import Entry from '../../../pages/user/User/Entry';

export default function Outcome(props) {
  const { evaluation } = props;

  return (
    <ContentCard className="space-y-4">
      <Category>
        Outcome
        <div className="text-base font-normal text-cs-gray-500">
          As a community partner, please rate the following statements as they relate to your experience.
        </div>
      </Category>
      <div className="space-y-3">
        <Entry
          title="It helped me realize that I have something valuable to offer to students and teachers."
          description={evaluation.data.offerStatement || '-'}
        />
        <Entry
          title="It helped me realize that I have things to learn from others who may be different than me."
          description={evaluation.data.learnStatement || '-'}
        />
        <Entry
          title="It increased my understanding of the realities of a teacher's job."
          description={evaluation.data.understandingStatement || '-'}
        />
        <Entry
          title="I am likely to volunteer again."
          description={evaluation.data.volunteerStatement || '-'}
        />
      </div>
    </ContentCard>
  );

  return (
    <Section
      title="Outcome"
      subtitle="As a community partner, please rate the following statements as they relate to your experience."
    >
      <TextContentSection title="It helped me realize that I have something valuable to offer to students and teachers.">
        {evaluation.data.offerStatement || '-'}
      </TextContentSection>
      <TextContentSection title="It helped me realize that I have things to learn from others who may be different than me.">
        {evaluation.data.learnStatement || '-'}
      </TextContentSection>
      <TextContentSection title="It increased my understanding of the realities of a teacher's job.">
        {evaluation.data.understandingStatement || '-'}
      </TextContentSection>
      <TextContentSection title="I am likely to volunteer again.">
        {evaluation.data.volunteerStatement || '-'}
      </TextContentSection>
    </Section>
  );
}
