import React from 'react';
import { useConnect } from '@lore/query-connect';
import MissingField from '../OfferMissingField';
import BaseActivity from '../../../components_profiles/BaseActivity';

export default function Activity(props) {
  const { offer } = props;

  const activity = useConnect('tag.byId', {
    id: offer.data.activityId
  }, {
    enabled: !!offer.data.activityId
  });

  if (!offer.data.activityId) {
    return (
      <MissingField name="Activity" />
    );
  }

  return (
    <BaseActivity activity={activity} />
  );
};
