import React from 'react';
import Schools from './Schools';
import ParentLayout from './ParentLayout';

export default function Layout(props) {
  return (
    <ParentLayout title="Schools">
      {function(params) {
        return (
          <>
            <Schools params={params} />
          </>
        );
      }}
    </ParentLayout>
  );
}
