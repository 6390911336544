/**
 * Configuration file for feature flags
 *
 * This file is where you define overrides for the default feature flags
 */

export default {

  /**
   * Display logo in grayscale to signal that we are in a development
   * or staging environment.
   */

  grayscale: true

};
