import React from 'react';
import PropTypes from 'prop-types';
import FeedContext from '../../../context/FeedContext';
import LayoutFeed from './LayoutFeed';
import useFeedRouteParams from '../useFeedRouteParams';

LayoutFeedWrapper.propTypes = {
  children: PropTypes.node
};

export default function LayoutFeedWrapper(props) {
  const { weeks, timestamp } = useFeedRouteParams();

  return (
    <FeedContext.Provider value={{ timestamp, weeks }}>
      <LayoutFeed {...props} />
    </FeedContext.Provider>
  );
}
