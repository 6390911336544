import React, { useState, useCallback, useEffect } from 'react';
import { parse, stringify } from 'query-string';
import SearchFilter from '../_fields_v2/SearchFilter';
import { getSearchUtils } from '../../utils/query-utils';
import _ from 'lodash';

export default function PartnerFiltersDialog(props) {
  const { router } = props;

  const { location, history } = router;

  const [search, setSearch] = useState(parse(location.search));

  const debounceOnSubmit = useCallback(_.debounce(onSubmit, 250), []);

  useEffect(() => {
    setSearch(parse(location.search));
  }, [location.search]);

  const filterFields = {
    name: 'name',
    organization: 'organization',
    searchEducator: 'searchEducator',
  };

  function onSubmit(nextSearch) {
    history.push({
      pathname: location.pathname,
      search: stringify({
        ...nextSearch,
        page: 1
      })
    });
  }

  function onChange(nextSearch) {
    setSearch(nextSearch);
    debounceOnSubmit(nextSearch);
  }

  const searchUtils = getSearchUtils(search, onChange);

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        <SearchFilter
          label="Name"
          tooltip="Find educators whose first or last name matches any of the words you enter."
          value={searchUtils(filterFields.name).value}
          onChange={searchUtils(filterFields.name).onChangeValue}
        />
        {/*<SearchFilter*/}
        {/*  label="School"*/}
        {/*  description="Find educators affiliated with a school whose name contains the phrase you type."*/}
        {/*  value={searchUtils(filterFields.organization).value}*/}
        {/*  onChange={searchUtils(filterFields.organization).onChangeValue}*/}
        {/*/>*/}
        <SearchFilter
          label="Profile"
          tooltip="Find educators that have a specific phrase in their biography, or in the description of the subjects they teach."
          value={searchUtils(filterFields.searchEducator).value}
          onChange={searchUtils(filterFields.searchEducator).onChangeValue}
        />
      </div>
    </>
  );
};
