import React from 'react';

export default function View(props) {
  const { children, onClick } = props;

  return (
    <button
      type="button"
      className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      onClick={onClick}
    >
      {props.icon && (
        <props.icon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
      )}
      <span>
        {children}
      </span>
    </button>
  )
}
