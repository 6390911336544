import React  from 'react';
import { useConnect } from '@lore/query-connect';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import useOrganization from '../../../hooks/useOrganization';
import Tooltip from '../../../components/Tooltip';

export default function Organization(props) {
  const { offer } = props;

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  const organization = useOrganization(creator);

  return (
    <div className="flex items-center space-x-1">
      <Tooltip tooltip="Organization" icon={false}>
        <BuildingOfficeIcon className="w-5 h-5 text-gray-500" />
      </Tooltip>
      <div className="text-sm text-gray-500">
        {organization.data.name || '[None]'}
      </div>
    </div>
  );
}
