import React from 'react';
import moment from 'moment-timezone';
import EvaluationUserColumn from '../_common/EvaluationUserColumn';
import EvaluationCompleteColumn from '../_common/EvaluationCompleteColumn';
import EvaluationIncompleteColumn from '../_common/EvaluationIncompleteColumn';
import EvaluationNotStartedColumn from '../_common/EvaluationNotStartedColumn';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function PartnerEventEvaluationHeaderSection(props) {
  const { evaluation, created, showViewLink = true } = props;

  const launch = useDialogLauncher();

  function onSendReminder() {
    launch(DialogManager.ReminderEvaluationEventPartnerComplete.Config(evaluation.id));
  }

  function onClickView() {
    launch(DialogManager.EvaluationEventPartner.Config(evaluation.id));
  }

  const diff = moment(evaluation.data.updatedAt).unix() - moment(evaluation.data.createdAt).unix();

  return (
    <div className="flex justify-between p-4">
      <EvaluationUserColumn
        evaluation={evaluation}
        created={created}
      />
      {evaluation.data.completed ? (
        <EvaluationCompleteColumn
          evaluation={evaluation}
          onClickView={showViewLink && onClickView}
        />
      ) : diff > 100 ? (
        <EvaluationIncompleteColumn
          evaluation={evaluation}
          onClickView={showViewLink && onClickView}
          onSendReminder={onSendReminder}
        />
      ) : (
        <EvaluationNotStartedColumn
          evaluation={evaluation}
          onSendReminder={onSendReminder}
        />
      )}
    </div>
  );
};
