/**
 * Configuration file for Intercom
 */

export default {

  /**
   * Enabled
   */

  enabled: true,

  /**
   * ID reflecting the application environment to load
   *
   * Intercom includes two ids; one for the production environment
   * and a second for the staging/test environment.
   */

  appId: 'pay1vqbt',

  /**
   * Unique identifier for the company
   *
   * Should be consistent across all environments
   */

  companyId: 'localhost',

  /**
   * Common name for the company
   *
   * This should be different for each environment
   */

  companyName: 'Localhost'

};
