import React from 'react';

export default function DialogTemplate(props) {
  const { children } = props;

  return (
    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hiddenx shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
      {children}
    </div>
  );
}
