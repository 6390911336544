import React from 'react';
import MissingField from '../../@communityshare/ui/alerts/MissingField';

export default function ProjectMissingField(props) {
  const { name } = props;

  return (
    <MissingField
      name={name}
      description={(
        <>
          Field must be provided before educator can connect with partners, or
          before the project will be shown to others.
        </>
      )}
    />
  );
}
