import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { useUser } from '@lore/auth';
import { LinkIcon } from '@heroicons/react/24/outline';
import { useConfig } from '@lore/config';
import Avatar from '../../../../components/Avatar';
import Loader from '../../../../components/Loader';
import UserPhone from './UserPhone';
import UserEmail from './UserEmail';
import Recommend from '../EducatorEvaluation/Recommend';
import Photographs from '../EducatorEvaluation/Photographs';
import { DialogManager } from '../../../DialogManager';
import useDialogLauncher from '../../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import Card from '../../../../components/Card';

export default function EducatorEvaluation(props) {
  const { event, children, alignChildren = 'bottom' } = props;

  const currentUser = useUser();
  const config = useConfig();
  const platformUrl = config.custom.platformUrl();

  const creator = useConnect('user.byId', {
    id: event.data.creatorId
  });

  const evaluation = useConnect('evaluationEventEducator.first', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          userId: creator.id
        }
      }
    }
  });

  const launch = useDialogLauncher();

  function onSendReminder() {
    launch(DialogManager.ReminderEvaluationEventEducatorComplete.Config(evaluation.id));
  }

  function onClick() {
    launch(DialogManager.EvaluationEventEducator.Config(evaluation.id));
  }

  if (
    creator.state === PayloadStates.FETCHING ||
    evaluation.state === PayloadStates.FETCHING
  ) {
    return (
      <div className="flex bg-white shadow-lg rounded-xl p-2.5 w-full">
        <Loader />
      </div>
    );
  }

  /*
   * If evaluation exists, display it.
   */
  if (evaluation.state === PayloadStates.RESOLVED) {
    const diff = moment(evaluation.data.updatedAt).unix() - moment(evaluation.data.createdAt).unix();

    return (
      <Card className="overflow-hidden divide-y divide-gray-200">
        {alignChildren === 'top' && children}
        <div className="flex justify-between p-4">
          <div className="flex flex-1 items-center space-x-4">
            <Avatar size={12} src={creator.data.avatarUrl}/>
            <div className="flex flex-col">
              <div className="text-lg font-semibold">
                {creator.data.firstName} {creator.data.lastName}
              </div>
              <div className="text-sm text-gray-500 space-x-1">
                <span>Creator</span>
                <span>·</span>
                <span>Evaluation created {moment(evaluation.data.createdAt).fromNow()}</span>
              </div>
            </div>
          </div>
          {evaluation.data.completed ? (
            <div className="flex flex-col text-right">
              <div className="text-lg font-semibold text-green-600 space-x-1">
                <span>Complete</span>
              </div>
              <div className="flex text-sm text-gray-500 space-x-1">
                <span>Completed {moment(evaluation.data.updatedAt).fromNow()}</span>
                <span>·</span>
                <span className="text-setup-tag hover:text-blue-300 cursor-pointer" onClick={onClick}>
                  View
                </span>
              </div>
            </div>
          ) : diff > 100 ? (
            <div className="flex flex-col text-right">
              <div
                className={classNames(
                  'text-lg font-semibold text-yellow-700 space-x-1',
                  currentUser.data.isSuperuser && 'hover:text-yellow-500 cursor-pointer'
                )}
                onClick={currentUser.data.isSuperuser ? onSendReminder : undefined}
              >
                <span>Started</span>
                <span>·</span>
                <span>Incomplete</span>
              </div>
              <div className="flex text-sm text-gray-500 space-x-1">
                <span>Abandoned {moment(evaluation.data.updatedAt).fromNow()}</span>
                <span>·</span>
                <span className="text-setup-tag hover:text-blue-300 cursor-pointer" onClick={onClick}>
                  View
                </span>
              </div>
            </div>
          ) : (
            <div className="flex flex-col text-right">
              <div
                className={classNames(
                  'text-lg font-semibold text-yellow-700 space-x-1',
                  currentUser.data.isSuperuser && 'hover:text-yellow-500 cursor-pointer'
                )}
                onClick={currentUser.data.isSuperuser ? onSendReminder : undefined}
              >
                <span>Not Started</span>
              </div>
              <div className="flex text-sm text-gray-500 space-x-6">
                <UserPhone user={creator} />
                <UserEmail user={creator} />
              </div>
            </div>
          )}
        </div>
        {!evaluation.data.completed && (
          <div className="flex flex-col p-4 space-y-5 bg-amber-100">
            <div>
              <div className="text-lg font-semibold text-amber-800">
                Need to share a link to the evaluation?
              </div>
              <div className="text-sm text-amber-800">
                The link below will take the educator directly to the beginning of the evaluation. This link will only work for them.
              </div>
            </div>
            <Card className="flex space-x-1 text-gray-500 items-center p-4">
              <LinkIcon className="h-5 w-5"/>
              <div>
                {platformUrl}/evaluation-event-educator/{evaluation.id}
              </div>
            </Card>
          </div>
        )}
        {(evaluation.data.completed || diff > 100) && (
          <>
            <Recommend evaluation={evaluation} />
            <Photographs evaluation={evaluation} />
          </>
        )}
        {alignChildren === 'bottom' && children && (
          <div className="flex p-4">
            {children}
          </div>
        )}
      </Card>
    );
  }

  /*
   * If event was cancelled there will not be any evaluations sent.
   */
  if (event.data.cancelled) {
    return (
      <Card className="overflow-hidden divide-y divide-gray-200">
        {alignChildren === 'top' && children}
        <div className="flex justify-between p-4">
          <div className="flex flex-1 items-center space-x-4">
            <Avatar size={12} src={creator.data.avatarUrl}/>
            <div className="flex flex-col">
              <div className="text-lg font-semibold">
                {creator.data.firstName} {creator.data.lastName}
              </div>
              <div className="text-sm text-gray-500 space-x-1">
                <span>Creator</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col text-right">
            <div className="text-lg font-semibold text-red-500 space-x-1">
              Cancelled
            </div>
            <div className="flex text-sm text-gray-500 space-x-1">
              Event was cancelled. No evaluations will be sent.
            </div>
          </div>
        </div>
        {alignChildren === 'bottom' && children && (
          <div className="flex p-4">
            {children}
          </div>
        )}
      </Card>
    );
  }

  /*
   * If we reached this point, it means the event is complete, but no evaluation was
   * created. Steward should contact support to have them investigate and create the
   * missing evaluation.
   */
  if (
    event.data.completed &&
    evaluation.state === PayloadStates.NOT_FOUND
  ) {
    return (
      <Card className="overflow-hidden divide-y divide-gray-200">
        {alignChildren === 'top' && children}
        <div className="flex justify-between p-4">
          <div className="flex flex-1 items-center space-x-4">
            <Avatar size={12} src={creator.data.avatarUrl}/>
            <div className="flex flex-col">
              <div className="text-lg font-semibold">
                {creator.data.firstName} {creator.data.lastName}
              </div>
              <div className="text-sm text-gray-500 space-x-1">
                <span>Creator</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col text-right">
            <div className="text-lg font-semibold text-red-500 space-x-1">
              <span>Evaluation missing</span>
            </div>
            <div className="flex text-sm text-gray-500 space-x-1">
              This should not happen. Contact support and ask them to investigate.
            </div>
          </div>
        </div>
        {alignChildren === 'bottom' && children && (
          <div className="flex p-4">
            {children}
          </div>
        )}
      </Card>
    );
  }

  return (
    <Card className="overflow-hidden divide-y divide-gray-200">
      {alignChildren === 'top' && children}
      <div className="flex justify-between p-4">
        <div className="flex flex-1 items-center space-x-4">
          <Avatar size={12} src={creator.data.avatarUrl}/>
          <div className="flex flex-col">
            <div className="text-lg font-semibold">
              {creator.data.firstName} {creator.data.lastName}
            </div>
            <div className="text-sm text-gray-500 space-x-1">
              <span>Creator</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col text-right">
          <div className="text-lg font-semibold text-gray-700 space-x-1">
            <span>Event not complete</span>
          </div>
          <div className="flex text-sm text-gray-500 space-x-1">
            Evaluation will be created once the event is marked complete.
          </div>
        </div>
      </div>
      {alignChildren === 'bottom' && children && (
        <div className="flex p-4">
          {children}
        </div>
      )}
    </Card>
  );
};
