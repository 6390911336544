import React  from 'react';
import { useConnect } from '@lore/query-connect';

export default function Partners(props) {
  const { event } = props;

  const pending = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          response: 'Pending'
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt asc'
    },
    include: function(model) {
      return (
        model.data.eventId === event.id &&
        model.data.response === 'Pending'
      )
    },
    exclude: function(model) {
      return (
        model.data.eventId !== event.id ||
        model.data.response !== 'Pending'
      );
    }
  });

  const declined = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          response: 'No'
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt asc'
    },
    include: function(model) {
      return (
        model.data.eventId === event.id &&
        model.data.response === 'No'
      )
    },
    exclude: function(model) {
      return (
        model.data.eventId !== event.id ||
        model.data.response !== 'No'
      );
    }
  });

  const maybe = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          response: 'Maybe'
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt asc'
    },
    include: function(model) {
      return (
        model.data.eventId === event.id &&
        model.data.response === 'Maybe'
      )
    },
    exclude: function(model) {
      return (
        model.data.eventId !== event.id ||
        model.data.response !== 'Maybe'
      );
    }
  });

  const attending = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          response: 'Yes'
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt asc'
    },
    include: function(model) {
      return (
        model.data.eventId === event.id &&
        model.data.response === 'Yes'
      )
    },
    exclude: function(model) {
      return (
        model.data.eventId !== event.id ||
        model.data.response !== 'Yes'
      );
    }
  });

  return (
    <div className="whitespace-nowrap space-x-2">
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
        {declined.data.length}
      </span>
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
        {pending.data.length}
      </span>
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
        {maybe.data.length}
      </span>
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
        {attending.data.length}
      </span>
    </div>
  );
}
