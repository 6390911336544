import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from './ErrorMessage';
import _ from 'lodash';

RequestError.propTypes = {
  request: PropTypes.object
};

export default function RequestError(props) {
  const { request } = props;
  const _error = request.error || {};
  let text = '';

  const error = _.omit(_error, ['stack']);

  if (_.isPlainObject(error)) {
    text = JSON.stringify(error);
  } else if (_.isArray(error)) {
    text = JSON.stringify(error);
  } else {
    text = error;
  }

  return (
    <ErrorMessage>
      <strong>Error!</strong>
      <p className="break-all">
        {text}
      </p>
    </ErrorMessage>
  );
}
