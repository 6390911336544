import React, { useEffect }  from 'react';
import PropTypes from 'prop-types';
import useRouter from '../hooks/useRouter';

export default function Redirect(props) {
  const { to } = props;
  const { history } = useRouter();

  useEffect(() => {
    history.push(to);
  }, []);

  return null;
};
