import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../utils/classNames';

Overlay.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool,
  label: PropTypes.string
};

Overlay.defaultProps = {
  isVisible: false
};

export default function Overlay(props) {
  const {
    children,
    label,
    isVisible
  } = props;

  // return (
  //   <div className={isVisible ? 'm-overlay--visible' : 'm-overlay'}>
  //     {label ? (
  //       <div className="m-overlay__label">
  //         <div>Loading...</div>
  //       </div>
  //     ) : null}
  //     <div className="m-overlay__content">
  //       {React.cloneElement(children)}
  //     </div>
  //   </div>
  // );

  return (
    <div className={classNames(
      isVisible ? 'pointer-events-none' : '',
      'relative'
    )}>
      <div className={classNames(
        isVisible ? 'opacity-50' : 'opacity-100'
      )}>
        {React.cloneElement(children)}
      </div>
    </div>
  );
}
