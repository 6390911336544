import React from 'react';
import moment from 'moment-timezone';
import FeedItem from '../../feed/FeedItem';
import { useConnect } from '@lore/query-connect';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import FeedHoverText from '../../feed/FeedHoverText';

export default function Offer(props) {
  const { offer } = props;

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  const activity = useConnect('tag.byId', {
    id: offer.data.activityId
  });

  const launch = useDialogLauncher();

  function onClickCreator() {
    launch(DialogManager.UserManage.Config(creator.id));
  }

  function onClickOffer() {
    launch(DialogManager.OfferManage.Config(offer.id));
  }

  return (
    <FeedItem timestamp={moment(offer.data.createdAt).fromNow()} last={props.last}>
      <FeedHoverText onClick={onClickCreator}>
        {creator.data.firstName} {creator.data.lastName}
      </FeedHoverText> created an offer titled <FeedHoverText onClick={onClickOffer}>
        {offer.data.title || activity.data.name}
      </FeedHoverText>
    </FeedItem>
  );
}
