import { useUser } from '@lore/auth';
import usePageRouter from '../../../../hooks/@lore/dialogs-routable/_hooks/usePageRouter';
import useAccount from '../../../hooks/useAccount';
import getSchoolYearNavigationLinks from './schoolYearNavigationLinks';
import getPageNavigationLinks, { getEvaluationTypeNavigationLinks, getEvaluationTypePageNavigationLinks } from './pageNavigationLinks';

export default function useNavigationLinks(props) {
  const { location } = usePageRouter();

  const account = useAccount();
  const currentUser = useUser();

  const schoolYearNavigationLinks = getSchoolYearNavigationLinks(account, currentUser);

  const currentSchoolYearNavigationLink = _.find(schoolYearNavigationLinks, function(schoolYear) {
    // return _.startsWith(location.pathname, schoolYear.href);
    const tokens = location.pathname.split(schoolYear.href);
    const remainder = tokens[1];
    return remainder ? remainder[0] === '/' : false;
  });

  const evaluationTypeLinks = currentSchoolYearNavigationLink ?
    getEvaluationTypeNavigationLinks(currentSchoolYearNavigationLink) :
    [];

  const pageNavigationLinks = currentSchoolYearNavigationLink ?
    getPageNavigationLinks(account, currentUser, currentSchoolYearNavigationLink) :
    [];

  const evaluationTypePageLinks = currentSchoolYearNavigationLink ?
    getEvaluationTypePageNavigationLinks(pageNavigationLinks) :
    [];

  return {
    pageLinks: pageNavigationLinks,
    schoolYearLinks: schoolYearNavigationLinks,
    currentSchoolYearLink: currentSchoolYearNavigationLink,
    evaluationTypeLinks: evaluationTypeLinks,
    evaluationTypePageLinks: evaluationTypePageLinks
  };
}
