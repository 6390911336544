import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import useProjectTags from '../../hooks/useProjectTags';
import TagTypes from '../../constants/TagTypes';

function isMissing(name, missingFields) {
  return _.find(missingFields, function(missingField) {
    return missingField.key === name;
  });
}

export default function useMissingFields(project) {
  const missingFields = [];

  const projectParticipants = useProjectTags(project, TagTypes.Participant);
  const projectInteractionTypes = useProjectTags(project, TagTypes.InteractionType);

  if (
    projectParticipants.state === PayloadStates.FETCHING ||
    projectInteractionTypes.state === PayloadStates.FETCHING
  ) {
    return {
      missingFields: {
        state: PayloadStates.FETCHING,
        data: missingFields
      },
      isMissing: isMissing
    };
  }

  if (!project.data.activityId) {
    missingFields.push({
      field: 'activityId',
      name: 'activity',
      label: 'Activity'
    });
  }

  if (projectParticipants.data.length === 0) {
    missingFields.push({
      field: 'participants',
      name: 'participants',
      label: 'Participants'
    });
  }

  // if (
  //   _.find(projectParticipants.data, m => m.data.name === 'Students') &&
  //   !project.data.students
  // ) {
  //   missingFields.push({
  //     field: 'students',
  //     name: 'Student Count',
  //     label: 'Student Count'
  //   });
  // }

  if (!project.data.interactionDescription) {
    missingFields.push({
      field: 'interactionDescription',
      name: 'Summary',
      label: 'Summary'
    });
  }

  if (
    project.data.specificDate === 'Yes' &&
    !project.data.date
  ) {
    missingFields.push({
      field: 'date',
      name: 'Date',
      label: 'Date'
    });
  }

  if (
    project.data.specificDate === 'No' && (
      !project.data.startDate ||
      !project.data.endDate
    )
  ) {
    missingFields.push({
      field: 'date',
      name: 'Date Range',
      label: 'Date Range'
    });
  }

  if (projectInteractionTypes.data.length === 0) {
    missingFields.push({
      field: 'projectInteractionTypes',
      name: 'Location',
      label: 'Location'
    });
  }

  return {
    missingFields: {
      state: PayloadStates.RESOLVED,
      data: missingFields
    },
    isMissing: function(name) {
      return isMissing(name, missingFields)
    }
  };
};
