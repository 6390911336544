import React from 'react';
import NavigationHeader from '../_navigation/ThickNavigation/NavigationHeader';
import NavigationGroup from '../_navigation/ThickNavigation/NavigationGroup';
import NavigationLink from '../_navigation/ThickNavigation/NavigationLink';
import NavigationLink2 from '../_navigation/ThickNavigation/NavigationLink2';
import NavigationLabel from '../_navigation/ThickNavigation/NavigationLabel';
import isActiveChecker from '../_navigation/ThinNavigation/isActiveChecker';
import usePageRouter from '../../../hooks/@lore/dialogs-routable/_hooks/usePageRouter';

export default function SidebarNavigation(props) {
  const { navigation = [] } = props;

  const { location, history } = usePageRouter();

  const currentRoute = location.pathname;

  return (
    <NavigationGroup>
      <div className="space-y-1">
        {navigation.map((item) => (
          <NavigationLink2
            key={item.name}
            // item={item}
            // exactMatch={true}
            name={item.name}
            icon={item.icon}
            count={item.count}
            highlight={item.highlight}
            onClick={() => history.push(item.to || item.href)}
            isActive={isActiveChecker.exact(currentRoute, item.to || item.href)}
          />
        ))}
      </div>
    </NavigationGroup>
  )
}
