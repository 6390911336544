import React from 'react';
import { Helmet } from 'react-helmet-async';
import Events from './Events';
import useRouter from '../../hooks/useRouter';
import PageHeading from '../../@communityshare/ui/PageHeading';
import Results from '../_common_results/Results';
import Event from './Event';
import Filters from '../../forms/filters_events';
import { CalendarIcon } from '@heroicons/react/24/outline';
import MobileNavigation from './MobileNavigation';

export default function Layout(props) {
  const router = useRouter();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Explore Events</title>
      </Helmet>
      <MobileNavigation />
      <PageHeading
        breadcrumbs={['Explore', 'Events']}
        title="All Events"
        description={(
          <>
            This page shows all events in your network. Use the filters to narrow the
            list. Find a filter you want to see again in the future? Bookmark the page
            or save a link to the URL.
          </>
        )}
      />
      <Filters router={router} />
      <Events router={router}>
        {function(result) {
          return (
            <Results result={result} columns={1} resource="events" emptyIcon={CalendarIcon}>
              {function(event, index) {
                return (
                  <Event
                    key={index}
                    event={event}
                  />
                );
              }}
            </Results>
          );
        }}
      </Events>
    </div>
  );
}
