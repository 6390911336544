import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet-async';
import SectionTitle from '../_common/SectionTitle';
import Students from '../evaluation_event_educator/sections/Students';
import Outcome from '../evaluation_event_educator/sections/Outcome';
import Reflections from '../evaluation_event_educator/sections/Reflections';
import Photographs from './sections/Photographs';
import Testimonials from './sections/Testimonials';

Template.propTypes = {
  event: PropTypes.object.isRequired
};

export default function Template(props) {
  const { event, evaluationEventEducator } = props;

  if (event.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const page = `Event #${event.id}`;

  return (
    <div className="space-y-6 -mt-12">
      <Helmet>
        <title>
          Event Story: {event.data.title ? `${event.data.title}: ${page}` : page}
        </title>
      </Helmet>
      <Header
        event={event}
        evaluationEventEducator={evaluationEventEducator}
      />
      <div className="space-y-12">
        <SectionTitle
          title="Testimonials"
          subtitle="Provided by the people involved in this project as part of their evaluation."
        >
          <div className="grid grid-cols-1 gap-4">
            <Testimonials event={event} />
          </div>
        </SectionTitle>
        <SectionTitle
          title="Photos"
          subtitle="Uploaded by the educator during their evaluation."
        >
          <div className="grid grid-cols-1 gap-4">
            <Photographs evaluation={evaluationEventEducator} />
          </div>
        </SectionTitle>
        <SectionTitle
          title="Impact"
          subtitle="What the educator said about the experience during their evaluation."
        >
          <div className="grid grid-cols-1 gap-4">
            <Students evaluation={evaluationEventEducator} />
            <Outcome evaluation={evaluationEventEducator} />
            <Reflections evaluation={evaluationEventEducator} />
          </div>
        </SectionTitle>
      </div>
    </div>
  );
};
