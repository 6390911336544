import _ from 'lodash';

export default function checkProfile(user, props) {
  const {
    userAges,
    userActivityTypes,
    userExpertises,
    userSubjects,
    userGrades
  } = props;

  /*
   * 6 educator required fields:
   * first name
   * last name
   * bio
   * zip code
   * age groups
   * subject areas
   *
   * 7 partner required fields:
   * first name
   * last name
   * bio
   * zip code
   * age groups
   * activity types
   * expertise areas
   */

  const profile = {
    fields: []
  };

  const interests = {
    fields: []
  };

  if (!user.data.firstName) {
    profile.fields.push({
      field: 'firstName',
      name: 'first name',
      label: 'First name'
    });
  }

  if (!user.data.lastName) {
    profile.fields.push({
      field: 'lastName',
      name: 'last name',
      label: 'Last name'
    });
  }

  // if (
  //   !user.data.isEducator &&
  //   !user.data.biography
  // ) {
  //   profile.fields.push({
  //     field: 'biography',
  //     name: 'biography',
  //     label: 'Biography'
  //   });
  // }

  // optional
  // if (!user.data.gender) {
  //   profile.fields.push({
  //     field: 'gender',
  //     label: 'Gender'
  //   });
  // }
  //
  // if (!user.data.ethnicity) {
  //   profile.fields.push({
  //     field: 'ethnicity',
  //     label: 'Ethnicity'
  //   });
  // }
  //
  // if (!user.data.yearOfBirth) {
  //   profile.fields.push({
  //     field: 'yearOfBirth',
  //     label: 'Year of birth'
  //   });
  // }

  if (!user.data.zipCode) {
    profile.fields.push({
      field: 'zipCode',
      name: 'zip code',
      label: 'Zip code'
    });
  }

  // if (
  //   user.data.isEducator &&
  //   userGrades.data.length < 1
  // ) {
  //   interests.fields.push({
  //     field: 'grades',
  //     name: 'grade levels',
  //     // label: 'At least one grade level'
  //     label: 'Grade level'
  //   });
  // }

  // if (
  //   user.data.isEducator &&
  //   userSubjects.data.length < 1
  // ) {
  //   interests.fields.push({
  //     field: 'subjects',
  //     name: 'subject areas',
  //     // label: 'At least one subject area'
  //     label: 'Subject area'
  //   });
  // }

  // if (
  //   !user.data.isEducator &&
  //   userAges.data.length < 1
  // ) {
  //   interests.fields.push({
  //     field: 'ages',
  //     name: 'age groups',
  //     // label: 'At least one age group'
  //     label: 'Age group'
  //   });
  // }

  // if (
  //   !user.data.isEducator &&
  //   userActivityTypes.data.length < 1
  // ) {
  //   interests.fields.push({
  //     field: 'activityTypes',
  //     name: 'activity types',
  //     // label: 'At least one engagement type'
  //     label: 'Engagement type'
  //   });
  // }

  if (
    !user.data.isEducator &&
    userExpertises.data.length < 1
  ) {
    interests.fields.push({
      field: 'expertises',
      name: 'expertise areas',
      // label: 'At least one expertise area'
      label: 'Expertise area'
    });
  }

  return {
    profile: profile.fields,
    interests: interests.fields
  };
};
