import React from 'react';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import Dialog from '../steward_invitation_invite';

export default function Launcher(props) {
  const config = useDialogConfig();

  return (
    <Dialog
      onClose={props.onClose}
    />
  );
};
