import React  from 'react';
import moment from 'moment-timezone';
import TextField from '../../projects/_fields/TextField';

export default function SleepingStarting(props) {
  const { user } = props;

  return (
    <TextField label="Slept">
      {moment(user.data.sleepingStartingAt).fromNow()}
    </TextField>
  );

  return (
    <TextField label="Slept">
      {moment(user.data.sleepingStartingAt).format('MMM D, YYYY')}
    </TextField>
  );
}
