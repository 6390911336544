import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import { useUser } from '@lore/auth';
import { CalendarIcon } from '@heroicons/react/24/outline';
import ContentCard from '../../../components_profiles/ContentCard';
import useProjectTags from '../../../hooks/useProjectTags';
import TagTypes from '../../../constants/TagTypes';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';
import TextContentSection from '../../../components_profiles/TextContentSection';
import { formatDate, formatDateRange, formatTimeRange } from '../../../utils/dateTimeUtils';
import MissingField from '../ProjectMissingField';

export default function Commitment(props) {
  const { project } = props;

  const currentUser = useUser();

  const projectDays = useProjectTags(project, TagTypes.Day);
  const projectTimes = useProjectTags(project, TagTypes.Time);
  const projectCommitments = useProjectTags(project, TagTypes.Commitment);

  if (
    projectDays.state === PayloadStates.FETCHING ||
    projectTimes.state === PayloadStates.FETCHING ||
    projectCommitments.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  return (
    <ContentCard className="space-y-8">
      <SubSectionHeader
        icon={CalendarIcon}
        title="Date & Time"
      />
      {project.data.specificDate === 'Yes' ? (
        <>
          {project.data.date ? (
            <TextContentSection title="Date">
              {formatDate(project.data.date)}
            </TextContentSection>
          ) : (
            <MissingField name="Date" />
          )}
        </>
      ) : (
        <>
          {(
            project.data.startDate &&
            project.data.endDate
          ) ? (
            <>
              <TextContentSection title="Date Window">
                {formatDateRange(project.data.startDate, project.data.endDate)}
              </TextContentSection>
              {projectDays.data.length > 0 && (
                <TextContentSection title="Possible Days">
                  <ul className="list-disc ml-6">
                    {projectDays.data.map(function(projectDay) {
                      return (
                        <li key={projectDay.id || projectDay.cid}>
                          {projectDay.data.name}
                        </li>
                      );
                    })}
                  </ul>
                </TextContentSection>
              )}
            </>
          ) : (
            <MissingField name="Date Window" />
          )}
        </>
      )}
      {(
        project.data.specificTime === 'Yes' &&
        project.data.startTime &&
        project.data.endTime
      ) ? (
        <TextContentSection title="Time">
          {formatTimeRange(project.data.startTime, project.data.endTime, currentUser.data.timezone)}
        </TextContentSection>
      ) : (
        <>
          {projectTimes.data.length > 0 && (
            <TextContentSection title="Possible Times of Day">
              <ul className="list-disc ml-6">
                {projectTimes.data.map(function(projectTime) {
                  return (
                    <li key={projectTime.id || projectTime.cid}>
                      {projectTime.data.name}
                    </li>
                  );
                })}
              </ul>
            </TextContentSection>
          )}
        </>
      )}
      {projectCommitments.data.length > 0 && (
        <TextContentSection title="Frequency">
          <ul className="list-disc ml-6">
            {projectCommitments.data.map(function(projectCommitment) {
              return (
                <li key={projectCommitment.id || projectCommitment.cid}>
                  {projectCommitment.data.name}
                </li>
              );
            })}
          </ul>
        </TextContentSection>
      )}
    </ContentCard>
  );
}
