import React from 'react';
import PropTypes from 'prop-types';
import Project from '../../../pages/projects/Row';

export default function ProjectWrapper(props) {
  const { project } = props;

  return (
    <Project
      key={project.id}
      project={project}
    />
  );
};
