import { useNavigate } from 'react-router-dom';
import { getRouteForDialog, setRouteForDialog } from './launch';
// import useRouter from '../../hooks/useRouter';
import useDialogConfig from './useDialogConfig';

export default function useDialogRouter(_dialogId) {
  // const pageRouter = useRouter();
  const navigate = useNavigate();

  const config = useDialogConfig();
  const dialogId = _dialogId || config?.id;

  const route = getRouteForDialog(dialogId);
  const [pathname, search] = route.split('?');

  if (!dialogId) {
    return;
  }

  return {
    navigate: function(props, options) {
      let nextRoute = props;

      if (_.isObject(props)) {
        const { pathname, search } = props;
        nextRoute = search ? `${pathname}?${search}` : pathname;
      }

      setRouteForDialog(dialogId, nextRoute, function(to) {
        navigate(to, options);
      });
    },
    location: {
      pathname: pathname,
      search: search
      // pathname: route.pathname,
      // search: route.search
    },
    history: {
      push: function(props) {
        let nextRoute = props;

        if (_.isObject(props)) {
          const { pathname, search } = props;
          nextRoute = search ? `${pathname}?${search}` : pathname;
        }

        // route.navigate(nextRoute);
        setRouteForDialog(dialogId, nextRoute, function(to) {
          navigate(to);
        });
      }
    }
  }
};
