import React from 'react';
import PropTypes from 'prop-types';

TextContentSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

export default function TextContentSection(props) {
  const {
    title,
    children
  } = props;

  const content = (
    <div className="text-base text-cs-gray-500">
      {children}
    </div>
  );

  if (title) {
    return (
      <div className="space-y-1">
        <div className="text-lg text-cs-gray-900 font-semibold">
          {title}
        </div>
        {content}
      </div>
    );
  }

  return content;
};
