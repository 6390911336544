import React from 'react';
import TextField from '../../../../pages/_components/sidePanel/TextField';
import Section from '../../../../pages/_components/sidePanel/Section';

export default function Recommend(props) {
  const { evaluation } = props;

  return (
    <Section
      title="Recommend"
      // subtitle="As a community partner, please rate the following statements as they relate to your experience."
    >
      <TextField label="Would you recommend CommunityShare to other community members?">
        {evaluation.data.recommend || '-'}
      </TextField>
      {evaluation.data.recommend === 'Yes' && (
        <TextField label="Please give us a sentence or two about the interaction that we can use to inspire others to volunteer.">
          {evaluation.data.testimonial || '-'}
        </TextField>
      )}
      {evaluation.data.recommend === 'No' && (
        <TextField label="Tell us how we can improve.">
          {evaluation.data.improve || '-'}
        </TextField>
      )}
    </Section>
  );
}
