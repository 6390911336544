import React from 'react';
import PropTypes from 'prop-types';
import { PayloadStates } from '@lore/utils';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Loader from '../../components/Loader';
import Template from './Template';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import { useConnect } from '../../../hooks/@lore/query-connect';

EvaluationProjectEducatorDialog.propTypes = {
  evaluationProjectPartner: PropTypes.object.isRequired
};

export default function EvaluationProjectEducatorDialog(props) {
  const { evaluationProjectPartner } = props;

  const project = useConnect('project.byId', {
    id: evaluationProjectPartner.data.projectId
  });

  if (project.state === PayloadStates.FETCHING) {
    return (
      <DialogTemplate>
        <Loader />
      </DialogTemplate>
    );
  }

  return (
    <DialogTemplate className="w-full max-w-screen-lg">
      <CloseDialogButton onClick={props.onClose} />
      <div className="px-6 pt-16 pb-6">
        <Template
          project={project}
          evaluationProjectPartner={evaluationProjectPartner}
          onClose={props.onClose}
        />
      </div>
    </DialogTemplate>
  );
};
