import React  from 'react';
import PropTypes from 'prop-types';
import { GlobeAltIcon } from '@heroicons/react/24/outline';

export default function SleepingBadge(props) {
  const { isCentered = true } = props;

  return (
    <div className={`flex ${isCentered ? 'justify-center' : ''} items-center`}>
      {/*<div className="p-1 text-white bg-primary rounded-full z-40 shadow">*/}
      {/*  <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />*/}
      {/*</div>*/}
      <div className="text-xs text-blue-50 bg-blue-500 py-1 px-3 h-6 leading-tight rounded-md shadow-sm min-w-fit">
        Sleeping
      </div>
    </div>
  );
}
