import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import EventTabBar from './EventTabBar';
import EventManageIdeaLayout from './event-manage-idea/Layout';
import EventManageInvitationsLayout from './event-manage-invitations/Layout';
import EventManageEvaluationsLayout from './event-manage-evaluations/Layout';
import EventNotes from './EventNotes';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import useDialogRouter from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogRouter';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet-async';

EventDetailsDialog.propTypes = {
  event: PropTypes.object.isRequired
};

export default function EventDetailsDialog(props) {
  const { event } = props;

  const { id, route } = useDialogConfig();
  const router = useDialogRouter(id);

  useEffect(() => {
    if (route === '/') {
      router.navigate('/invitations');
    }
  }, []);

  if (event.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const pageMap = {
    '/idea': 'Idea',
    '/invitations': 'Connect',
    '/evaluations': 'Celebrate'
  };

  const pathname = route.split('?')[0];

  const page = pageMap[pathname] || pathname;

  return (
    <div className="space-y-6 -mt-12">
      <Helmet>
        <title>
          {event.data.title ? `${event.data.title}: ${page}` : `Event #${event.id}: ${page}`}
        </title>
      </Helmet>
      <Header event={event} />
      <div className="-mx-6">
        <EventNotes event={event} />
        <EventTabBar event={event} />
      </div>
      <div className="space-y-12">
        {pathname === `/idea` && (
          <EventManageIdeaLayout event={event} />
        )}
        {pathname === `/invitations` && (
          <EventManageInvitationsLayout event={event} />
        )}
        {pathname === `/evaluations` && (
          <EventManageEvaluationsLayout event={event} />
        )}
      </div>
    </div>
  );
};
