import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '../../../hooks/@lore/query-connect';
import ReminderDialogTemplate from '../_common_reminders/ReminderDialogTemplate';

Dialog.propTypes = {
  event: PropTypes.object.isRequired
};

export default function Dialog(props) {
  const { event } = props;

  const reminders = useConnect('reminder.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          type: {
            $in: [
              'Close'
            ]
          }
        }
      }
    }
  });

  const creator = useConnect('user.byId', {
    id: event.data.creatorId
  });

  let _data = {
    type: 'Close',
    eventId: event.id,
    recipientId: event.data.creatorId
  };

  return (
    <ReminderDialogTemplate
      title="Send reminder to close event"
      subtitle={(
        <span>
          This will send an email to {creator.data.firstName} nudging them to close their event.
        </span>
      )}
      reminders={reminders}
      _data={_data}
      blockerText={(
        event.data.completed ||
        event.data.cancelled
      ) && (
        <div className="text-base text-cs-gray-700">
          Event has already been closed. Sending a reminder is not allowed.
        </div>
      )}
      MAX_COUNT={3}
      onClose={props.onClose}
    />
  );
};
