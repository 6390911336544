import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import useProjectTags from '../../hooks/useProjectTags';
import TagTypes from '../../constants/TagTypes';

function isMissing(name, missingFields) {
  return _.find(missingFields, function(missingField) {
    return missingField.key === name;
  });
}

export default function useMissingFields(project) {
  const missingFields = [];

  const projectParticipants = useProjectTags(project, TagTypes.Participant);
  const projectInteractionTypes = useProjectTags(project, TagTypes.InteractionType);

  if (
    projectParticipants.state === PayloadStates.FETCHING ||
    projectInteractionTypes.state === PayloadStates.FETCHING
  ) {
    return {
      missingFields: {
        state: PayloadStates.FETCHING,
        data: missingFields
      },
      isMissing: isMissing
    };
  }

  if (!project.data.activityId) {
    missingFields.push({
      key: 'activityId',
      name: 'Activity',
      to: `/post-project/${project.id}/activity`
    });
  }

  if (projectParticipants.data.length === 0) {
    missingFields.push({
      key: 'projectParticipants',
      name: 'Participants',
      to: `/post-project/${project.id}/participants`
    });
  }

  if (
    _.find(projectParticipants.data, m => m.data.name === 'Students') &&
    !project.data.students
  ) {
    missingFields.push({
      key: 'students',
      name: 'Student Count',
      to: `/post-project/${project.id}/student-count`
    });
  }

  if (!project.data.interactionDescription) {
    missingFields.push({
      key: 'interactionDescription',
      name: 'Summary',
      to: `/post-project/${project.id}/summary`
    });
  }

  if (
    project.data.specificDate === 'Yes' &&
    !project.data.date
  ) {
    missingFields.push({
      key: 'date',
      name: 'Date',
      to: `/post-project/${project.id}/date`
    });
  }

  if (
    project.data.specificDate === 'No' && (
      !project.data.startDate ||
      !project.data.endDate
    )
  ) {
    missingFields.push({
      key: 'date',
      name: 'Date Range',
      to: `/post-project/${project.id}/date`
    });
  }

  if (projectInteractionTypes.data.length === 0) {
    missingFields.push({
      key: 'projectInteractionTypes',
      name: 'Location',
      to: `/post-project/${project.id}/location`
    });
  }

  return {
    missingFields: {
      state: PayloadStates.RESOLVED,
      data: missingFields
    },
    isMissing: function(name) {
      return isMissing(name, missingFields)
    }
  };
};
