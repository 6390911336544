import React from 'react';
import PropTypes from 'prop-types';
import { useUser } from '@lore/auth';
import { MapPinIcon } from '@heroicons/react/24/outline';
import IconSection from '../../../components/IconSection';
import { getDistanceFromLatLonInMiles } from '../../../components/User/utils';

Location.propTypes = {
  user: PropTypes.object.isRequired
};

export default function Location(props) {
  const { user } = props;

  const currentUser = useUser();

  let distance = getDistanceFromLatLonInMiles(user.data, currentUser.data);

  return (
    <IconSection
      icon={MapPinIcon}
      title={user.data.city ? `${user.data.city}, ${user.data.state}` : user.data.zipCode}
      subtitle={distance <= 1 ? '1 mile away' : `${distance} miles away`}
      xs={true}
    />
  );
}
