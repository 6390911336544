import React from 'react';
import PropTypes from 'prop-types';
import urlUtils from '../../../utils/url';

// Website Logo: https://icon-icons.com/icon/globe/184941
import WebsiteSocialIcon from '../../../../assets/globe_icon_184941.png';

// Twitter Logo: https://icon-icons.com/download/144784/PNG/256/
import TwitterSocialIcon from '../../../../assets/twitter_logo_icon_144784.png';

// LinkedIn Logo: https://icon-icons.com/download/143562/PNG/256/
import LinkedInSocialIcon from '../../../../assets/linkedin_logo_icon_143562.png';

function SocialLink(props) {
  const { href, src } = props;

  return (
    <a
      href={href}
      target="_blank"
      className="border border-cs-gray-200 rounded-lg p-1 h-8 w-8 flex items-center"
    >
      <img className="opacity-60" src={src}/>
    </a>
  );
}

SocialLinks.propTypes = {
  user: PropTypes.object.isRequired
};

export default function SocialLinks(props) {
  const { user } = props;

  if (!(
    user.data.website ||
    user.data.twitterHandle ||
    user.data.linkedinUrl
  )) {
    return null;
  }

  return (
    <div className="flex flex-row mb-8 space-x-2 justify-center">
      {user.data.website && (
        <SocialLink
          href={`${urlUtils.getFullyQualifiedUrl(user.data.website)}`}
          src={WebsiteSocialIcon}
        />
      )}
      {user.data.twitterHandle && (
        <SocialLink
          href={`https://twitter.com/${user.data.twitterHandle}`}
          src={TwitterSocialIcon}
        />
      )}
      {user.data.linkedinUrl && (
        <SocialLink
          href={`${urlUtils.getFullyQualifiedUrl(user.data.linkedinUrl)}`}
          src={LinkedInSocialIcon}
        />
      )}
    </div>
  );
}
