import React from 'react';
import _ from 'lodash';
import {
  ClipboardDocumentListIcon,
  EnvelopeIcon,
  EyeIcon,
  EyeSlashIcon,
  ShareIcon,
  UserIcon
} from '@heroicons/react/24/outline';
import { useUser } from '@lore/auth';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import useDialogRouter from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogRouter';
import TabBarButton from '../../@communityshare/ui/navigation/TabBarButton';
import TabBar from '../../@communityshare/ui/navigation/TabBar';
import TabBarDropdownMenu from '../../@communityshare/ui/navigation/TabBarDropdownMenu';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../DialogManager';

export default function UserTabBar(props) {
  const { user } = props;

  const currentUser = useUser();

  const { id } = useDialogConfig();
  const router = useDialogRouter(id);

  const launch = useDialogLauncher();

  const buttonLinks = _.remove([
    {
      icon: UserIcon,
      label: 'Profile',
      to: `/profile`
    },
    {
      icon: ShareIcon,
      label: 'Participation',
      to: `/participation`
    },
    {
      icon: ClipboardDocumentListIcon,
      label: 'Evaluations',
      to: `/evaluations`
    }
  ], entry => entry);

  const menuLinks = _.remove([
    {
      type: 'button',
      icon: user.data.inactive ? EyeIcon : EyeSlashIcon,
      label: user.data.inactive ? 'Make profile active' : 'Make profile inactive',
      onClick: () => launch(DialogManager.UserDisable.Config(user.id)),
      active: false
    },
    currentUser.data.isSuperuser ? {
      type: 'button',
      icon: EnvelopeIcon,
      label: 'Send reminder to complete profile',
      onClick: () => launch(DialogManager.ReminderProfileComplete.Config(user.id)),
      active: false,
      secret: true
    } : null
  ], entry => entry);

  return (
    <TabBar>
      {buttonLinks.map(function(link, index) {
        const active = (
          router.location.pathname.indexOf(link.to) === 0 ||
          link.to.indexOf(router.location.pathname) === 0
        );

        return (
          <TabBarButton
            key={index}
            active={active}
            onClick={() => router.navigate(link.to)}
            icon={link.icon}
            label={link.label}
            to={link.to}
          />
        );
      })}
      <TabBarDropdownMenu
        label="More actions"
        links={menuLinks}
      />
    </TabBar>
  );
};
