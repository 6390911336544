import React from 'react';
import SelectField from '../../../forms/_fields_v2/SelectField';
import useRouter from '../../../hooks/useRouter';
import useNavigationLinks from '../Sidebar/useNavigationLinks';

export default function TimeFrameSelectField(props) {
  const { location, history } = useRouter();

  const { timeFrameLinks, currentTimeFrameLink } = useNavigationLinks();

  return (
    <SelectField
      label="Time frame"
      value={currentTimeFrameLink.href}
      // placeholder="Select page..."
      options={{
        data: timeFrameLinks.map(function(link, index) {
          return {
            id: link.href,
            data: {
              name: link.name
            }
          };
        })
      }}
      optionLabel="name"
      onChange={function(id) {
        const tokens = location.pathname.split(currentTimeFrameLink.href);
        history.push(`${id}${tokens[1]}`);
      }}
    />
  );
}
