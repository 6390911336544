import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Project from '../../projects/Project';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Projects']}
      title="No Partners Connected"
      description={(
        <span>
          These are projects where no partners have advanced to the "connected" stage where they are
          discussing a project with an educator. All invitations are pending. CommunityShare recommends
          taking a look to see how long invitations have been pending. If it has been more than a week,
          consider calling the partner and following up. (If they can't assist, decline the invitation
          for them so the educator knows they can't help.) We also recommend considering inviting more
          partners to the project.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(project) {
        return (
          <Project key={project.id} project={project} />
        );
      }}
    />
  );
}
