import React from 'react';
import classNames from 'classnames';

export default function ContentCard(props) {
  const { children, className, padding = 'base' } = props;

  return (
    <div className={classNames(
      // 'bg-white rounded-xl shadow-lg py-6 px-6 space-y-8',
      'bg-white rounded-cs-10 shadow-cs-flat -m-0.5 border-2 border-cs-gray-100',
      padding === 'extra' ? 'p-6' : 'p-4',
      className
    )}>
      {children}
    </div>
  );
}
