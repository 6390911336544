import React from 'react';
import moment from 'moment-timezone';
import FeedItem from '../../feed/FeedItem';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import FeedHoverText from '../../feed/FeedHoverText';

export default function Partner(props) {
  const { user } = props;

  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.UserManage.Config(user.id));
  }

  return (
    <FeedItem timestamp={moment(user.data.createdAt).fromNow()} last={props.last}>
      <FeedHoverText onClick={onClick}>
        {user.data.firstName} {user.data.lastName}
      </FeedHoverText> created a partner account
    </FeedItem>
  );
}
