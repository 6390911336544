import React from 'react';
import LazyMetrics from '../../dashboard-year/LazyMetrics';
import EducatorProjectEvaluationsByMonth from '../../dashboard-year/Evaluations/EducatorProjectEvaluationsByMonth';
import CompositeEducatorProjectEvaluations from '../../dashboard-year/Evaluations/CompositeEducatorProjectEvaluations';
import PartnerProjectEvaluationsByMonth from '../../dashboard-year/Evaluations/PartnerProjectEvaluationsByMonth';
import CompositePartnerProjectEvaluations from '../../dashboard-year/Evaluations/CompositePartnerProjectEvaluations';
import EducatorEventEvaluationsByMonth from '../../dashboard-year/Evaluations/EducatorEventEvaluationsByMonth';
import CompositeEducatorEventEvaluations from '../../dashboard-year/Evaluations/CompositeEducatorEventEvaluations';
import PartnerEventEvaluationsByMonth from '../../dashboard-year/Evaluations/PartnerEventEvaluationsByMonth';
import CompositePartnerEventEvaluations from '../../dashboard-year/Evaluations/CompositePartnerEventEvaluations';

export default function Students(props) {
  const { params } = props;

  return (
    <>
      <LazyMetrics
        title="Project Evaluations - Educators"
        columns={1}
      >
        <EducatorProjectEvaluationsByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <CompositeEducatorProjectEvaluations {...params} titleOnly={true} />
        </div>
      </LazyMetrics>
      <LazyMetrics
        title="Project Evaluations - Partners"
        columns={1}
      >
        <PartnerProjectEvaluationsByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <CompositePartnerProjectEvaluations {...params} titleOnly={true} />
        </div>
      </LazyMetrics>
      <LazyMetrics
        title="Event Evaluations - Educators"
        columns={1}
      >
        <EducatorEventEvaluationsByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <CompositeEducatorEventEvaluations {...params} titleOnly={true} />
        </div>
      </LazyMetrics>
      <LazyMetrics
        title="Event Evaluations - Partners"
        columns={1}
      >
        <PartnerEventEvaluationsByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <CompositePartnerEventEvaluations {...params} titleOnly={true} />
        </div>
      </LazyMetrics>
    </>
  );
}
