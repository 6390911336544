import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Project from '../../projects/Project';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Projects']}
      title="New Projects"
      description={(
        <span>
          These are projects created in the last week. CommunityShare recommends reviewing what
          educators are planning so that you can share current needs when in the community, plan
          your outreach if no partners match the needed expertise, plan social media or other
          outreach, and provide advice or suggestions to the educator.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function (project) {
        return (
          <Project key={project.id} project={project} />
        );
      }}
    />
  );
}
