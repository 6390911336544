import React from 'react';
import { useConnect } from '@lore/query-connect';
import useStewardshipFilter from '../../partners/useStewardshipFilter';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import moment from 'moment-timezone';
import ComparisonMetric from '../ComparisonMetric';

export default function CreatedInviteToProject(props) {
  const { account, schoolStart, schoolEnd, titleOnly } = props;

  const stewardshipFilter = useStewardshipFilter();

  const previousSchoolStart = moment(schoolStart).subtract(1, 'year').toISOString();

  const previousUsers = useConnect('user.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfCreatedQuestions',
            relation: 'createdQuestions',
            $where: {
              projectId: {
                $not: null
              },
              'project.accountId': account.id,
              createdAt: {
                $lte: schoolStart,
                $gte: titleOnly ? undefined : previousSchoolStart
              }
            }
          }
        ],
        $where: {
          numberOfCreatedQuestions: {
            $gte: 1
          },
          isEducator: false,
          $or: stewardshipFilter
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  const newUsers = useConnect('user.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfCreatedQuestions',
            relation: 'createdQuestions',
            $where: {
              projectId: {
                $not: null
              },
              'project.accountId': account.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              }
            }
          }
        ],
        $where: {
          numberOfCreatedQuestions: {
            $gte: 1
          },
          isEducator: false,
          $or: stewardshipFilter
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  if (
    previousUsers.state === PayloadStates.FETCHING ||
    newUsers.state === PayloadStates.FETCHING
  ) {
    return (
      <MetricLoader
        name="Initiated contact with Educator about Project"
      />
    );
  }

  return (
    <ComparisonMetric
      name="Initiated contact with Educator about Project"
      value={newUsers.meta.totalCount}
      previousValue={previousUsers.meta.totalCount}
      titleOnly={titleOnly}
    />
  );
}
