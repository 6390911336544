import React from 'react';
import PropTypes from 'prop-types';

export default function Category(props) {
  const { children } = props;

  return (
    <div className="text-lg font-semibold text-cs-gray-900">
      {children}
    </div>
  );
}
