import _ from 'lodash';
import toJsonKey from '../utils/toJsonKey';
import { payloadCollection, PayloadStates } from '@lore/utils';

/**
 * First Connection Blueprint
 */

export default function(key, params, { Model, Collection, modelName }) {
  const jsonKey = toJsonKey(params);

  const collection = new Collection();

  const query = params.where;
  const pagination = params.pagination;

  const combinedQuery = {
    where: query,
    pagination: pagination
  };

  return {
    queryKey: [modelName, 'first', jsonKey],
    queryFn: async function() {
      try {
        const queryParameters = _.extend({}, query, pagination);

        // serialize the data to mimic behavior of axios v0 -- changed for v1 (they serialize differently)
        const serializedData = _.mapValues(queryParameters, function(value) {
          if(_.isObject(value)) {
            return JSON.stringify(value);
          }

          return value;
        });

        await collection.fetch({
          // data: queryParameters
          data: serializedData
        });

        const findPayload = payloadCollection(
          collection,
          PayloadStates.RESOLVED,
          null,
          combinedQuery
        );

        return findPayload.data[0] || {
          state: PayloadStates.NOT_FOUND,
          data: {}
        };
      } catch (err) {
        const response = err.response;
        const error = response.data;
        const findPayload = payloadCollection(
          collection,
          PayloadStates.ERROR_FETCHING,
          error,
          combinedQuery
        );
        return {
          state: PayloadStates.ERROR_FETCHING,
          data: {},
          error: error
        };
      }
    },
    placeholderData: {
      state: PayloadStates.FETCHING,
      data: {}
    }
  }
};
