import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Project from '../../projects/Project';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Projects']}
      title="Missing Educator Evaluation"
      description={(
        <span>
          These are projects where an educator indicated they completed a project, but they have
          not yet provided outcomes. CommunityShare recommends following up with educators to
          complete the evaluation and finding ways to incentivize and celebrate completing an
          evaluation. Evaluation data populates your dashboard.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function (project) {
        return (
          <Project key={project.id} project={project} />
        );
      }}
    />
  );
}
