import React from 'react';
import PropTypes from 'prop-types';
import { ClockIcon } from '@heroicons/react/24/outline';
import moment from 'moment-timezone';
import { useUser } from '@lore/auth';
import Tooltip from '../../../components/Tooltip';

export default function TimeRange(props) {
  const { event } = props;

  const currentUser = useUser();

  const startTime = moment(event.data.startTime).tz(currentUser.data.timezone).format('h:mm a');
  const endTime = moment(event.data.endTime).tz(currentUser.data.timezone).format('h:mm a');
  const timezone = moment().tz(currentUser.data.timezone).format('z');

  return (
    <div className="flex space-x-1 items-center text-gray-500 text-sm">
      <Tooltip tooltip="Time Range" icon={false}>
        <ClockIcon className="h-6 w-6" />
      </Tooltip>
      <span>
        {startTime} - {endTime} {timezone}
      </span>
    </div>
  );
};
