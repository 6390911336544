import React from 'react';
import classNames from 'classnames';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { PayloadStates } from '@lore/utils';

export default function ProjectImageData(props) {
  const { upload } = props;

  const launch = useDialogLauncher();

  const evaluationProjectEducator = useConnect('evaluationProjectEducator.byId', {
    id: upload.data.evaluationProjectEducatorId
  });

  const project = useConnect('project.byId', {
    id: evaluationProjectEducator.data.projectId
  }, {
    enabled: !!evaluationProjectEducator.data.projectId
  });

  const activity = useConnect('tag.byId', {
    id: project.data?.activityId
  }, {
    enabled: !!project.data?.activityId
  });

  function onClickProject() {
    launch(DialogManager.ProjectManage.Config(project.id));
  }

  function onClickUpload() {
    window.open(upload.data.url, '_blank').focus();
  }

  return (
    <div className="space-y-2 text-sm">
      <div>
        <div>
          Project
        </div>
        {project.state === PayloadStates.FETCHING ? (
          <div>
            ...
          </div>
        ) : (
          <div className="text-cs-orange-500 hover:text-cs-orange-300 cursor-pointer" onClick={onClickProject}>
            {project.data.title || activity.data.name || '...'}
          </div>
        )}
      </div>
      <div>
        <div>
          File
        </div>
        <div className={classNames(
          'flex space-x-1 items-center',
          'text-cs-orange-500 hover:text-cs-orange-300 cursor-pointer',
          'break-all'
        )} onClick={onClickUpload}>
          <span>
            {upload.data.name}
          </span>
          <ArrowTopRightOnSquareIcon className="h-4 2-4" />
        </div>
      </div>
    </div>
  );
}
