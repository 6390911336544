import React from 'react';
import { Bars3Icon, Bars3CenterLeftIcon, Bars3BottomRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';

export default function NavigationMenuDialogButton() {
  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.MobileNavigationMenu.Config())
  }

  return (
    <div className={classNames(
      'flex items-center h-14 w-14 overflow-hidden',
      // 'shadow-cs-flat -m-px border rounded-cs-10',
      '-m-px rounded-cs-10',
      'hover:border hover:border-cs-gray-200 hover:bg-gray-50',
      // 'border-cs-gray-200 bg-white',
      'bg-white',
      'items-center justify-center'
    )} onClick={onClick}>
      <span className="sr-only">Open sidebar</span>
      <Bars3Icon className="h-8 w-8 text-cs-gray-400" />
      {/*<Bars3Icon className="p-3 text-cs-gray-400" />*/}
    </div>
  );
}
