import React from 'react';
import PropTypes from 'prop-types';
import Claims from './Claims';
import EmptyState from '../../_common/EmptyState';
import SectionTitle from '../../_common/SectionTitle';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../DialogManager';

export default function Layout(props) {
  const { offer } = props;

  const launch = useDialogLauncher();

  function onInviteEducator() {
    launch(DialogManager.OfferInvite.Config(offer.id));
  }

  return (
    <div className="page space-y-12">
      <div className="flex space-x-8">
        <div className="flex-1 space-y-12">
          {/*<SectionTitle*/}
          {/*  title="Invitations"*/}
          {/*  subtitle="Educators who expressed positive interest in the offer."*/}
          {/*>*/}
            <div className="hover:shadow hover:opacity-80 cursor-pointer" onClick={onInviteEducator}>
              <EmptyState
                title="Find educators to invite"
                // subtitle="The more people that are invited, the better the chances of finding a match!"
                color="blue"
               />
            </div>
          {/*</SectionTitle>*/}
          <SectionTitle
            title="Connected"
            subtitle={(
              <>
                Educators who are discussing the offer or referring others who might be interested. Consider
                checking the conversation history to see if the partner may need additional educators.
              </>
            )}
          >
            <div className="grid grid-cols-1 gap-4">
              <Claims
                offer={offer}
                responses={[
                  'Connect'
                ]}
                emptyState={(
                  <EmptyState
                    title="No educators have expressed interest yet."
                    subtitle="The more people that are invited, the better the chances of finding a match!"
                  >
                    {/*<div className="mt-6 flex flex-col space-y-2">*/}
                    {/*  <div>*/}
                    {/*    <Link className="hover:text-orange-300 hover:underline" to={`/offer/${offer.id}/invite-populate`}>*/}
                    {/*      Find Educators*/}
                    {/*    </Link>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </EmptyState>
                )}
              />
            </div>
          </SectionTitle>
          <SectionTitle
            title="Invited"
            subtitle={(
              <>
                Educators who were invited to connect but have not yet responded. Consider
                following up with recent invitees, declining older invitations, and inviting
                new educators.
              </>
            )}
          >
            <div className="grid grid-cols-1 gap-4">
              <Claims
                offer={offer}
                responses={[
                  'Pending'
                ]}
                emptyState={(
                  <EmptyState
                    title="Invitations without a response will appear here."
                    // subtitle="Extra text..."
                  >
                    {/*<div className="mt-6 flex flex-col space-y-2">*/}
                    {/*  <div>*/}
                    {/*    <Link className="hover:text-orange-300 hover:underline" to={`/offer/${offer.id}/invite-populate`}>*/}
                    {/*      Find Educators*/}
                    {/*    </Link>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </EmptyState>
                )}
              />
            </div>
          </SectionTitle>
          <SectionTitle
            title="Declined"
            subtitle="Educators who are either uninterested or unable to participate."
          >
            <div className="grid grid-cols-1 gap-4">
              <Claims
                offer={offer}
                responses={[
                  'Decline'
                ]}
                emptyState={(
                  <EmptyState
                    title="Educators who turn down the invitation will appear here."
                    // subtitle="Educators who are unable to participate will show up here."
                  />
                )}
              />
            </div>
          </SectionTitle>
        </div>
      </div>
    </div>
  );
};
