import React from 'react';
import Recommend from '../_common/Recommend';
import Photographs from './Photographs';

export default function EducatorProjectEvaluationSummarySection(props) {
  const { evaluation } = props;

  return (
    <>
      <Recommend evaluation={evaluation} />
      <Photographs evaluation={evaluation} />
    </>
  );
};
