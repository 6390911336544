import React from 'react';
import PropTypes from 'prop-types';
import { UsersIcon } from '@heroicons/react/24/outline';
import Tooltip from '../../../components/Tooltip';

export default function Slots(props) {
  const { event } = props;

  return (
    <div className="flex space-x-1 items-center text-gray-500 text-sm">
      <Tooltip tooltip="Partner Slots" icon={false}>
        <UsersIcon className="h-6 w-6" />
      </Tooltip>
      <span>
        {event.data.slots}
      </span>
    </div>
  );
};
