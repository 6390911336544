import React from 'react';
import Loader from '../../../components/Loader';
import ChartHeader from './ChartHeader';
import Card from '../../../components/Card';

export default function ChartLoader(props) {
  const { title, subtitle } = props;

  return (
    <Card className="pt-2 pb-4 px-4">
      <ChartHeader
        title={title}
        subtitle={subtitle}
      />
      <Loader />
    </Card>
  );
}
