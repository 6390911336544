import React from 'react';
import { useConnect } from '@lore/query-connect';
import BaseCreator from '../../../components_profiles/BaseCreator';

export default function Creator(props) {
  const { event } = props;

  const creator = useConnect('user.byId', {
    id: event.data.creatorId
  });

  return (
    <BaseCreator
      title="Educator / Creator"
      creator={creator}
    />
  );
}
