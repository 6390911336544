import React from 'react';
import { LinkIcon } from '@heroicons/react/24/outline';
import Card from '../../../components/Card';

export default function EvaluationLinkSection(props) {
  const {
    description,
    href,
    profileType
  } = props;

  return (
    <div className="flex flex-col p-4 space-y-5 bg-amber-100">
      <div>
        <div className="text-lg font-semibold text-amber-800">
          Need to share a link to the evaluation?
        </div>
        {description ? (
          <div className="text-sm text-amber-800">
            {description}
          </div>
        ) : (
          <div className="text-sm text-amber-800">
            The link below will take the {profileType} directly to the beginning of the evaluation. This link will only work for them.
          </div>
        )}
      </div>
      <Card className="flex space-x-1 text-gray-500 items-center p-4">
        <LinkIcon className="h-5 w-5"/>
        <div className="text-sm">
          {href}
        </div>
      </Card>
    </div>
  );
};
