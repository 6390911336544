import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import ProjectTabBar from './ProjectTabBar';
import ProjectManageIdeaLayout from './project-manage-idea/Layout';
import ProjectManageInvitationsLayout from './project-manage-invitations/Layout';
import ProjectManageEvaluationsLayout from './project-manage-evaluations/Layout';
import ProjectNotes from './ProjectNotes';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import useDialogRouter from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogRouter';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet-async';

ProjectTemplate.propTypes = {
  project: PropTypes.object.isRequired
};

export default function ProjectTemplate(props) {
  const { project } = props;

  const { id, route } = useDialogConfig();
  const router = useDialogRouter(id);

  useEffect(() => {
    if (route === '/') {
      router.navigate('/invitations');
    }
  }, []);

  if (project.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const pageMap = {
    '/idea': 'Idea',
    '/invitations': 'Connect',
    '/evaluations': 'Celebrate'
  };

  const pathname = route.split('?')[0];
  const page = pageMap[pathname] || pathname;

  return (
    <div className="space-y-6 -mt-12">
      <Helmet>
        <title>
          {project.data.title ? `${project.data.title}: ${page}` : `Project #${project.id}: ${page}`}
        </title>
      </Helmet>
      <Header project={project} />
      <div className="-mx-6">
        <ProjectNotes project={project} />
        <ProjectTabBar project={project} />
      </div>
      <div className="space-y-12">
        {pathname === `/idea` && (
          <ProjectManageIdeaLayout
            project={project}
            onClose={props.onClose}
          />
        )}
        {pathname === `/invitations` && (
          <ProjectManageInvitationsLayout
            project={project}
            onClose={props.onClose}
          />
        )}
        {pathname === `/evaluations` && (
          <ProjectManageEvaluationsLayout
            project={project}
            onClose={props.onClose}
          />
        )}
      </div>
    </div>
  );
};
