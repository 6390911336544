import _ from 'lodash';
import {
  ArrowDownTrayIcon,
  DocumentTextIcon,
  PhotoIcon,
  PresentationChartBarIcon,
  QuestionMarkCircleIcon,
  GlobeAltIcon
} from '@heroicons/react/24/outline';

export default function getUploadInfo(title = '') {
  // const { title = '' } = props;

  const tokens = title.split('.');
  const name = tokens[0];
  // const extension = _.toLower(tokens[1] || 'filetype unknown');
  const extension = _.toLower(tokens[1] || '');

  const type = {
    docx: DocumentTextIcon,
    doc: DocumentTextIcon,
    pdf: DocumentTextIcon,
    ppt: PresentationChartBarIcon,
    pptx: PresentationChartBarIcon,
    png: PhotoIcon,
    jpeg: PhotoIcon,
    jpg: PhotoIcon,
    heic: PhotoIcon,
    gif: PhotoIcon,
  };

  const icon = {
    hack: extension?
      type[extension] || ArrowDownTrayIcon :
      GlobeAltIcon
  };

  function isImage(extension) {
    return [
      'png',
      'jpeg',
      'jpg',
      'heic',
      'gif'
    ].indexOf(extension) >= 0;
  }

  return {
    name,
    extension,
    icon,
    isImage: isImage(extension)
  }
};
