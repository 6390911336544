import React from 'react';
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import Template from './Template';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import useOfferTags from '../../../hooks/useOfferTags';
import TagTypes from '../../../constants/TagTypes';
import TextContentSection from '../../../components_profiles/TextContentSection';

export default function Supplies(props) {
  const { offer } = props;

  const offerSupplies = useOfferTags(offer, TagTypes.Supply);

  if (offerSupplies.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const noSupplies = !!_.find(offerSupplies.data, function(offerSupply) {
    return offerSupply._tag.data.name === 'No supplies needed.';
  });

  const basicSupplies = !!_.find(offerSupplies.data, function(offerSupply) {
    return offerSupply._tag.data.name === 'Basic art supplies (crayons, markers, scissors)';
  });

  const specialSupplies = !!_.find(offerSupplies.data, function(offerSupply) {
    return offerSupply._tag.data.name === 'Other special supplies';
  });

  if (noSupplies) {
    return (
      <Template
        icon={ArchiveBoxIcon}
        title="Supplies"
      >
        <TextContentSection>
          No supplies needed.
        </TextContentSection>
      </Template>
    );
  }

  return (
    <Template
      icon={ArchiveBoxIcon}
      title="Supplies"
      // subtitle="This offer requires the following supplies."
    >
      <TextContentSection>
        <div className="space-y-4">
          <div>
            This offer requires the following supplies:
          </div>
          <ul className="list-disc ml-6 mb-6">
            {basicSupplies && (
              <li>
                Basic art supplies
              </li>
            )}
            {specialSupplies && offer.data.supplies && (
              <li>
                {offer.data.supplies}
              </li>
            )}
          </ul>
          <div>
            *{offer.data.suppliesProvidedBy || 'Partner will provide supplies'}.
          </div>
        </div>
      </TextContentSection>
    </Template>
  );
};
