import React from 'react';
import classNames from 'classnames';

export default function TabBarButton(props) {
  const { active, onClick, icon, label } = props;

  return (
    <div
      onClick={onClick}
      className={classNames(
        'flex space-x-2 items-center',
        // 'px-4 py-3 rounded-lg cursor-pointer',
        'px-3 py-2.5 cursor-pointer',
        'rounded-lg',
        // 'rounded-lg',
        // 'shadow-lg',
        // 'shadow-cs-flat',
        active ? '-m-px border border-cs-orange-500': 'x-m-0.5 xborder-2 border-cs-gray-100',
        'text-sm',
        active ?
          'text-cs-orange-500 bg-cs-orange-50' :
          'text-cs-gray-500 bg-white hover:bg-cs-gray-50'
      )}
    >
      <props.icon className="h-5 w-5" />
      <span>
        {label}
      </span>
    </div>
  );
};
