import React from 'react';
import moment from 'moment-timezone';
import FeedItem from './FeedItem';
import { useConnect } from '@lore/query-connect';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../dialogs_new/DialogManager';
import FeedHoverText from './FeedHoverText';

export default function EvaluationTemplate(props) {
  const {
    evaluation,
    evaluationRole,
    onClickEvaluation,
    title,
    opportunityType,
    onClickOpportunity
  } = props;

  const user = useConnect('user.byId', {
    id: evaluation.data.userId
  });

  const launch = useDialogLauncher();

  function onClickUser() {
    launch(DialogManager.UserManage.Config(user.id));
  }

  const role = (
    evaluationRole === 'educator' ? 'an educator evaluation' :
    evaluationRole === 'partner' ? 'a partner evaluation' :
    '[unknown evaluation type]'
  );

  return (
    <FeedItem timestamp={moment(evaluation.data.createdAt).fromNow()} last={props.last}>
      <FeedHoverText onClick={onClickUser}>
        {user.data.firstName} {user.data.lastName}
      </FeedHoverText> was sent <FeedHoverText onClick={onClickEvaluation}>
        {role}
      </FeedHoverText> for the {opportunityType} <FeedHoverText onClick={onClickOpportunity}>
        {title}
      </FeedHoverText>
    </FeedItem>
  );
}
