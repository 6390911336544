import React from 'react';
import Event from './Event';
import useQuery from './useQuery';

export default function useMapping(props) {
  const models = useQuery(props);

  const type = 'event';

  const entries = models.data.map((value, index) => ({
    type: type,
    value: (
      <Event
        key={`${type}-${index}`}
        event={value}
      />
    ),
    data: value,
    timestamp: value.data.createdAt
  }));

  return {
    models,
    entries
  };
}
