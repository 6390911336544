import React from 'react';
import PropTypes from 'prop-types';
import { PayloadStates } from '@lore/utils';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Loader from '../../components/Loader';
import User from '../../pages/user/User'
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';

UserProfileDialog.propTypes = {
  user: PropTypes.object.isRequired
};

export default function UserProfileDialog(props) {
  const { user, project, event, offer } = props;

  if (user.state === PayloadStates.FETCHING) {
    return (
      <DialogTemplate className="w-full max-w-screen-lg">
        <Loader />
      </DialogTemplate>
    );
  }

  // w-screen max-w-screen-sm
  return (
    <DialogTemplate className="w-full max-w-screen-lg">
      <CloseDialogButton onClick={props.onClose} />
      <div className="px-6 pt-16 pb-6">
        <User
          user={user}
          project={project}
          event={event}
          offer={offer}
          onClose={props.onClose}
        />
      </div>
    </DialogTemplate>
  );
};
