import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Offer from '../../offers/Offer';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Offers']}
      title="Active Offers"
      description={(
        <span>
          These are offers available to educators now, based on the date range offered by the
          partner. CommunityShare recommends reviewing active offers to see if any match educator
          needs or interests and inviting educators to requests that may be a good fit.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(offer) {
        return (
          <Offer key={offer.id} offer={offer} />
        );
      }}
    />
  );
}
