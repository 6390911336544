import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

export default function Wrapper(props) {
  const { children } = props;

  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    openModal();
  }, []);

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  const showTransitions = false;

  const transitions = {
    background: showTransitions ? {
      enter: 'ease-out duration-300',
      enterFrom: 'opacity-0',
      enterTo: 'opacity-100',
      leave: 'ease-in duration-200',
      leaveFrom: 'opacity-100',
      leaveTo: 'opacity-0'
    } : {},
    dialog: showTransitions ? {
      enter: 'ease-out duration-300',
      enterFrom: 'opacity-0 scale-95',
      enterTo: 'opacity-100 scale-100',
      leave: 'ease-in duration-200',
      leaveFrom: 'opacity-100 scale-100',
      leaveTo: 'opacity-0 scale-95'
    } : {},
  };

  return (
    <Transition
      show={isOpen}
      as={Fragment}
      afterLeave={props.afterLeave}
      afterEnter={props.afterEnter}
    >
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          // enter="ease-out duration-300"
          // enterFrom="opacity-0"
          // enterTo="opacity-100"
          // leave="ease-in duration-200"
          // leaveFrom="opacity-100"
          // leaveTo="opacity-0"
          {...transitions.background}
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              // enter="ease-out duration-300"
              // enterFrom="opacity-0 scale-95"
              // enterTo="opacity-100 scale-100"
              // leave="ease-in duration-200"
              // leaveFrom="opacity-100 scale-100"
              // leaveTo="opacity-0 scale-95"
              {...transitions.dialog}
            >
              <span className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {React.cloneElement(children, {
                  closeModal: closeModal
                })}
              </span>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
