import React from 'react';
import { useConnect } from '@lore/query-connect';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import IconSection from '../../../@communityshare/ui/card/IconSection';

export default function School(props) {
  const { project } = props;

  const organization = useConnect('organization.byId', {
    id: project.data.organizationId
  }, {
    enabled: !!project.data.organizationId
  });

  if (!project.data.organizationId) {
    return null;
  }

  return (
    <IconSection
      icon={BuildingOfficeIcon}
      title={organization.data.name}
    />
  );
}
