import React from 'react';
import PropTypes from 'prop-types';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export default function AlertWarning(props) {
  const {
    icon = ExclamationTriangleIcon,
    title,
    subtitle,
    children
  } = props;

  const options = {
    icon
  }

  return (
    <div className="bg-orange-50 shadow-md rounded-lg py-4 px-6 space-y-4">
      <div className="flex space-x-4">
        <div>
          <options.icon className="h-12 w-12 text-accent" />
        </div>
        <div className="flex-1 flex flex-col">
          <div className="text-base font-semibold text-gray-700">
            {title}
          </div>
          <div className="text-base text-gray-500">
            {subtitle}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
}
