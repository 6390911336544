import React from 'react';
import PropTypes from 'prop-types';
import { HandRaisedIcon } from '@heroicons/react/24/outline';
import ContentCard from '../../../components_profiles/ContentCard';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';
import TextContentSection from '../../../components_profiles/TextContentSection';

export default function Support(props) {
  const { project } = props;

  return (
    <ContentCard className="space-y-8">
      <SubSectionHeader
        icon={HandRaisedIcon}
        title="Support Needed"
      />
      <TextContentSection title="Contact Requested">
        {project.data.contact}
      </TextContentSection>
      <TextContentSection title="Resources Requested">
        {project.data.resources || 'No'}
      </TextContentSection>
    </ContentCard>
  );
}
