import _ from 'lodash';
import {
  ClipboardDocumentCheckIcon,
  EnvelopeIcon,
  UsersIcon,
  FlagIcon,
  BanknotesIcon
} from '@heroicons/react/24/outline';

export default function getNavigationLinks(account, currentUser) {
  const root = `/c/${account.data.subdomain}`;

  return _.remove([
    { name: 'Staff', icon: UsersIcon, href: `${root}/team/staff`, matches: [`${root}/team/staff`]  },
    currentUser.data.isSuperuser ?
      { name: '[Invitations]', icon: EnvelopeIcon, href: `${root}/team/invitations`, matches: [`${root}/team/invitations`]  } :
      null,
    { name: 'Screening', icon: ClipboardDocumentCheckIcon, href: `${root}/settings/screening` },
    { name: 'Training', icon: ClipboardDocumentCheckIcon, href: `${root}/settings/training` },
    { name: 'Banner', icon: FlagIcon, href: `${root}/settings/banner` },
    { name: 'Grants', icon: BanknotesIcon, href: `${root}/settings/grants` }
  ]);
};
