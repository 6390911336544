import React  from 'react';
import { useConnect } from '@lore/query-connect';
import { ChatBubbleLeftIcon, ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import Tooltip from '../../../components/Tooltip';
import _ from 'lodash';

export default function Testimonials(props) {
  const { event } = props;

  const educatorEvaluations = useConnect('evaluationEventEducator.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          completed: true
        }
      }
    }
  });

  const partnerEvaluations = useConnect('evaluationEventPartner.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          completed: true
        }
      }
    }
  });

  const count = _.reduce([
    ...educatorEvaluations.data,
    ...partnerEvaluations.data
  ], function(result, evaluation, key) {
    return evaluation.data.testimonial ? result + 1 : result;
  }, 0)

  return (
    <div className="flex items-center space-x-1">
      <Tooltip tooltip="Testimonials" icon={false}>
        <ChatBubbleLeftIcon className="w-5 h-5 text-gray-500" />
      </Tooltip>
      <div className="text-sm text-gray-500">
        {count}
      </div>
    </div>
  );
}
