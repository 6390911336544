import React from 'react';
import moment from 'moment-timezone';
import FeedItem from '../../feed/FeedItem';
import { useConnect } from '@lore/query-connect';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import FeedHoverText from '../../feed/FeedHoverText';

export default function Invitation(props) {
  const { invitation } = props;

  const event = useConnect('event.byId', {
    id: invitation.data.eventId
  });

  const partner = useConnect('user.byId', {
    id: invitation.data.userId
  });

  const creator = useConnect('user.byId', {
    id: invitation.data.creatorId
  });

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  }, {
    enabled: !!event.data.activityId
  });

  const launch = useDialogLauncher();

  function onClickCreator() {
    launch(DialogManager.UserManage.Config(creator.id));
  }

  function onClickPartner() {
    launch(DialogManager.UserManage.Config(partner.id));
  }

  function onClickEvent() {
    launch(DialogManager.EventManage.Config(event.id));
  }

  const steward = (
    event.data.creatorId === creator.id ||
    creator.id === partner.id
  ) ? '' : '(steward)';

  return (
    <FeedItem timestamp={moment(invitation.data.createdAt).fromNow()} last={props.last}>
      <FeedHoverText onClick={onClickCreator}>
        {creator.data.firstName} {creator.data.lastName} {steward}
      </FeedHoverText> invited <FeedHoverText onClick={onClickPartner}>
        {partner.data.firstName} {partner.data.lastName}
      </FeedHoverText> to the event <FeedHoverText onClick={onClickEvent}>
        {event.data.title || activity.data.name}
      </FeedHoverText>
    </FeedItem>
  );
}
