import React from 'react';
import NavigationDropdown from '../dashboard/NavigationDropdown';
import NavigationLabel from '../_navigation/ThickNavigation/NavigationLabel';
import NavigationGroup from '../_navigation/ThickNavigation/NavigationGroup';
import LayoutSidebar from './LayoutSidebar';
import useNavigationLinks from '../dashboard/Sidebar/useNavigationLinks';

export default function LayoutSidebarDashboard(props) {
  const { children } = props;

  const { schoolYearLinks, pageLinks } = useNavigationLinks();

  return (
    <LayoutSidebar
      title="Dashboard"
      header={(
        <NavigationGroup>
          <NavigationLabel>
            School Year
          </NavigationLabel>
          <div className="-mx-3">
            <NavigationDropdown
              header="Switch school year to"
              navigationLinks={schoolYearLinks}
            />
          </div>
        </NavigationGroup>
      )}
      getNavigationLinks={() => pageLinks}
    >
      {children}
    </LayoutSidebar>
  );
}
