import React from 'react';
import PropTypes from 'prop-types';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import useEventTags from '../../../hooks/useEventTags';
import TagTypes from '../../../constants/TagTypes';
import { parse, stringify } from 'query-string';
import useRouter from '../../../hooks/useRouter';
import Callback from '../../_common_manage/Callback';

export default function Layout(props) {
  const { event } = props;

  const router = useRouter();

  const eventExpertiseTypes = useEventTags(event, TagTypes.ExpertiseType);

  if (eventExpertiseTypes.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Callback
      callback={() => router.navigate({
        pathname: `/invite`,
        search: stringify({
          expertiseTypes: eventExpertiseTypes.data.map(eventExpertiseType => eventExpertiseType._tag.id).join(','),
          // locationCondition: JSON.stringify({
          //   address: currentUser.data.zipCode,
          //   latitude: currentUser.data.latitude,
          //   longitude: currentUser.data.longitude,
          //   radius: 50
          // })
        })
      }, { replace: true })}
    />
  );
}
