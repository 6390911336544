import React, { forwardRef } from 'react';
import { Menu } from '@headlessui/react';
import classNames from 'classnames';

export default forwardRef(function TabBarDropdownMenuItem(props, ref) {
  const {
    icon,
    label,
    onClick,
    active,
    secret,
    ...other
  } = props;

  return (
    <div
      {...other}
      ref={ref}
      onClick={onClick}
      className={classNames(
        active ? 'text-white bg-cs-blue-500 rounded-md' :
          secret ? 'text-cs-orange-500 bg-cs-orange-50 rounded-md' : 'text-cs-gray-500',
        'cursor-pointer select-none relative py-2 pl-3 pr-9',
        'flex space-x-2 items-start px-4 py-3',
      )}
    >
      <props.icon className="h-5 w-5 mt-0.5" />
      <span className="flex-1">
        {label}
      </span>
    </div>
  );
});
