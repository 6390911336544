import React from 'react';
import classNames from 'classnames';

export default function Card(props) {
  const {
    children,
    background = true,
    shadow = true,
    rounded = true,
    border = true,
    className,
    onClick
  } = props;

  return (
    <div className={classNames(
        background && 'bg-white',
      shadow && 'shadow-cs-flat',
      // shadow && 'shadow-cs-xs',
      // shadow && 'shadow-cs-sm',
      // shadow && 'shadow-cs-md',
      // shadow && 'shadow-cs-lg',
      // shadow && 'shadow-cs-xl',
      border && '-m-px border border-cs-gray-200',
      rounded && 'rounded-cs-10 overflow-hiddenx',
      className
    )} onClick={onClick}>
      {children}
    </div>
  );
};
