import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

SubSectionHeader.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node
};

export default function SubSectionHeader(props) {
  const {
    title,
    subtitle,
    icon,
    iconSize = 'base',
    iconColor = 'base'
  } = props;

  const header = (
    <div className="flex-1 space-y-0.5">
      <div className="text-lg text-cs-gray-900 font-semibold">
        {title}
      </div>
      {subtitle && (
        <div className="text-sm text-cs-gray-500">
          {subtitle}
        </div>
      )}
    </div>
  );

  if (icon) {
    return (
      <div className="flex space-x-2">
        <props.icon className={classNames(
          iconSize === 'large' ? 'h-8 w-8' : 'h-6 w-6',
          iconColor === 'yellow' ? 'text-cs-yellow-500' : 'text-cs-gray-900',
        )} />
        {header}
      </div>
    );
  }

  return header;
};
