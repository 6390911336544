import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ScrollToTop from '../../components/ScrollToTop';
import Sidebar from '../_components/Sidebar';
import usePageRouter from '../../../hooks/@lore/dialogs-routable/_hooks/usePageRouter';
import ScrollAnchorContext from '../../context/ScrollAnchorContext';

LayoutSidebar.propTypes = {
  title: PropTypes.node,
  getNavigationLinks: PropTypes.func,
  header: PropTypes.node,
  children: PropTypes.node
};

export default function LayoutSidebar(props) {
  const { title, getNavigationLinks, header, grouped, children } = props;
  const { location } = usePageRouter();
  const scrollAnchorRef = useRef(null);

  const navigationLinks = getNavigationLinks();

  return (
    <ScrollAnchorContext.Provider value={scrollAnchorRef}>
      <div className="flex overflow-hidden h-screen">
        <Sidebar
          title={title}
          navigation={navigationLinks}
          header={header}
          grouped={grouped}
        />
        <div key={location.pathname} className="flex flex-col w-0 flex-1 overflow-hidden">
          <div ref={scrollAnchorRef} className="flex-1 overflow-y-auto">
            <ScrollToTop key={`scroll-to-top-${location.pathname}`}/>
            {children}
          </div>
        </div>
      </div>
    </ScrollAnchorContext.Provider>
  );
}
