import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ActionTypes } from '@lore/utils';
import { useConfig } from '@lore/config';
import Auth0 from 'auth0-js';
import { Helmet } from 'react-helmet-async';
import auth from '../utils/auth';
import ShowLoadingScreen from './ShowLoadingScreen';
import { useQueryClient } from '@tanstack/react-query';

export default function Logout(props) {
  const config = useConfig();
  const queryClient = useQueryClient();

  useEffect(() => {
    auth.deleteToken();

    queryClient.invalidateQueries();

    const auth0 = new Auth0.WebAuth(config.auth0);
    auth0.logout({
      returnTo: window.location.origin,
      client_id: config.auth0.clientID
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Logout</title>
      </Helmet>
      <ShowLoadingScreen />
    </>
  );
};
