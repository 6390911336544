import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import DialogTemplate from '../_common_old/DialogTemplate';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Loader from '../../components/Loader';
import { PayloadStates } from '@lore/utils';
import fileDownload from 'js-file-download';
import _ from 'lodash';
import { CloudArrowDownIcon, ExclamationCircleIcon, EyeIcon } from '@heroicons/react/24/outline';
import { SolidButton } from '../../@communityshare/ui/Button';
import { OutlineButton } from '../../@communityshare/ui/Button';
// import FormTemplate from '../_common_old/FormTemplate.vertical';
import FormTemplate from '../../dialogs_common/FormTemplate';
import useCollections from '../../hooks/useCollections';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Dialog.propTypes = {
  modelName: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired
};

export default function Dialog(props) {
  const { modelName, query } = props;

  const collections = useCollections();

  const [finished, setFinished] = useState(false);
  const [error, setError] = useState(false)

  useEffect(function() {
    const Collection = collections[modelName].extend({
      fetch: function(options) {
        options = _.extend({ parse: true }, options);
        return this.sync('read', this, options);
      }
    });
    const collection = new Collection();
    const queryParameters = _.extend({}, query.where, query.pagination);

    // serialize the data to mimic behavior of axios v0 -- changed for v1 (they serialize differently)
    const serializedData = _.mapValues(queryParameters, function(value) {
      if(_.isObject(value)) {
        return JSON.stringify(value);
      }

      return value;
    });

    collection.fetch({
      // data: JSON.stringify(queryParameters),
      // data: queryParameters,
      data: serializedData,
      responseType: 'text'
      // success: () => {}
    }).then(function(response) {
      setFinished(true);
      fileDownload(response.data, 'results.csv');
    }).catch(function(response) {
      setError(true);
      console.error(response);
    })
  }, []);

  if (finished) {
    return (
      <DialogTemplate>
        <FormTemplate
          // icon={CloudArrowDownIcon}
          icon={(
            <div className="flex justify-center mb-4">
              <CloudArrowDownIcon className="text-cs-orange-500 h-12 w-12" />
            </div>
          )}
          title="Results downloaded!"
          subtitle="You can close this dialog."
          onClose={props.onClose}
          footer={(
            <FormTemplateButtonFooter>
              <OutlineButton onClick={props.onClose}>
                Close
              </OutlineButton>
            </FormTemplateButtonFooter>
          )}
        />
      </DialogTemplate>
    );
  }

  if (error) {
    return (
      <DialogTemplate>
        <FormTemplate
          // icon={ExclamationCircleIcon}
          icon={(
            <div className="flex justify-center mb-4">
              <CloudArrowDownIcon className="text-cs-orange-500 h-12 w-12" />
            </div>
          )}
          title="There was an error generating the CSV."
          subtitle="Please contact support so they can resolve the issue."
          onClose={props.onClose}
          footer={(
            <FormTemplateButtonFooter>
              <OutlineButton onClick={props.onClose}>
                Close
              </OutlineButton>
            </FormTemplateButtonFooter>
          )}
        />
      </DialogTemplate>
    );
  }

  return (
    <DialogTemplate>
      <FormTemplate
        // icon={CloudArrowDownIcon}
        icon={(
          <div className="flex justify-center mb-4">
            <CloudArrowDownIcon className="text-cs-orange-500 h-12 w-12" />
          </div>
        )}
        title="Downloading results to CSV"
        subtitle="This may take a few seconds..."
        onClose={props.onClose}
        // alert={alert}
        body={(
          <div className="py-2">
            <Loader/>
          </div>
        )}
        footer={(
          <FormTemplateButtonFooter>
            <OutlineButton onClick={props.onClose}>
              Close
            </OutlineButton>
          </FormTemplateButtonFooter>
        )}
      />
    </DialogTemplate>
  );
};
