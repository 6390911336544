import React from 'react';
import useGetCount from '../../explore/useGetCount';

// Events
import starredEvents from '../../events-filtered/starred/useQuery';
import newEvents from '../../events-filtered/new/useQuery';
import draftEvents from '../../events-filtered/draft/useQuery.last4weeks';
import partnersEvents from '../../events-filtered/partners/useQuery';
import activeEvents from '../../events-filtered/active/useQuery';
import happeningSoonEvents from '../../events-filtered/soon/useQuery';
import expiredNotClosedEvents from '../../events-filtered/expired-not-closed/useQuery';
import missingEducatorEvaluationEvents from '../../events-filtered/missing-educator-evaluation/useQuery';
import completedEvents from '../../events-filtered/completed/useQuery';
import schoolYearEvents from '../../events-filtered/school-year/useQuery';

// Partners
import useAccount from '../../../hooks/useAccount';

export default function getNavigationLinks() {
  const account = useAccount();

  const root = `/c/${account.data.subdomain}`;

  return [
    { name: 'All', href: `${root}/explore/events` },
    // { name: 'All (Table)', href: `${root}/explore/events` },
    { name: 'Starred', href: `${root}/explore/events/starred`, count: useGetCount(starredEvents) },
    { name: 'New', href: `${root}/explore/events/new`, count: useGetCount(newEvents), highlight: true },
    { name: 'Incomplete', href: `${root}/explore/events/draft`, count: useGetCount(draftEvents) },
    { name: 'Needs partners', href: `${root}/explore/events/partners`, count: useGetCount(partnersEvents), highlight: true },
    { name: 'Active Events', href: `${root}/explore/events/active`, count: useGetCount(activeEvents) },
    { name: 'Happening Soon', href: `${root}/explore/events/soon`, count: useGetCount(happeningSoonEvents), highlight: true },
    { name: 'Expired but not Closed', href: `${root}/explore/events/expired-not-closed`, count: useGetCount(expiredNotClosedEvents), highlight: true },
    { name: 'Missing Educator Evaluation', href: `${root}/explore/events/missing-educator-evaluation`, count: useGetCount(missingEducatorEvaluationEvents) },
    { name: 'Completed', href: `${root}/explore/events/completed`, count: useGetCount(completedEvents) },
    { name: 'All Events this School Year', href: `${root}/explore/events/school-year`, count: useGetCount(schoolYearEvents) }
  ];
}
