/**
 * This component serves as the root of your application, and should typically be the only
 * component subscribed to the store.
 *
 * It is also a good place to fetch the current user. Once you have configured 'models/currentUser'
 * to fetch the current user (by pointing it to the correct API endpoint) uncomment the commented
 * out code below in order to fetch the user, display a loading experience while they're being
 * fetched, and store the user in the applications context so that components can retrieve it
 * without having to pass it down through props or extract it from the Redux store directly.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import PayloadStates from '../constants/PayloadStates';
import RemoveLoadingScreen from './RemoveLoadingScreen';
import { UserContext } from '@lore/auth';
import { useConfig } from '@lore/config';
import { DialogProvider } from '@lore/dialogs';
import { Navigate, Outlet } from 'react-router-dom';
import storage from '../utils/storage';
import isBlacklistedRedirectUrl from '../utils/isBlacklistedRedirectUrl';
import AccountContext from '../context/AccountContext';
import MemberContext from '../context/MemberContext';
import useTags from '../hooks/useTags';
import useTagTrees from '../hooks/useTagTrees';
import { setCommunity } from '../utils/community';
import useRouter from '../hooks/useRouter';
import DialogLauncherLayout from '../../hooks/@lore/dialogs-routable/_launcher/Layout';
import _ from 'lodash';

export default function Master(props) {
  const { history, location } = useRouter();
  const config = useConfig();

  const user = useConnect('currentUser.singleton');

  const tags = useTags(undefined, {
    enabled: !!user.id
  });

  const tagTrees = useTagTrees(undefined, {
    enabled: !!user.id
  });

  const accounts = useConnect('account.find', {
    pagination: {
      pageSize: 1000,
      order: 'name desc'
    }
  }, {
    enabled: !!user.id
  });

  const members = useConnect('member.find', {
    where: {
      eager: {
        $where: {
          userId: user.id,
          isStaff: true
        }
      }
    }
  }, {
    enabled: !!user.id
  });

  if (user.state === PayloadStates.FETCHING) {
    return null;
  }

  if (user.state === PayloadStates.ERROR_FETCHING) {
    const redirectUrl = `${window.location.pathname}${window.location.search}`;

    if (!isBlacklistedRedirectUrl(redirectUrl)) {
      storage.set('redirectUrl', redirectUrl);
    }

    if (user.error && user.error.statusCode === 401) {
      history.push('/logout');
      return null;
    }

    history.push('/unauthorized');
    return null;
  }

  if (
    tags.state === PayloadStates.FETCHING ||
    tagTrees.state === PayloadStates.FETCHING ||
    accounts.state === PayloadStates.FETCHING ||
    members.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  function getAccount() {
    const tokens = location.pathname.split('/');
    return _.find(accounts.data, function(account) {
      return account.data.subdomain === tokens[2];
    });
  }

  function isCommunity() {
    const tokens = location.pathname.split('/');
    return tokens[1] === 'c' && _.find(accounts.data, function(account) {
      return account.data.subdomain === tokens[2];
    });
  }

  const account = getAccount();

  if (account) {
    setCommunity(account.data.subdomain);
  }

  let member = account ?
    _.find(members.data, m => m.data.accountId === account.id) :
    undefined;

  if (isCommunity() && !member) {
    history.push('/unauthorized');
    return null;
  }

  return (
    <UserContext.Provider value={user}>
      <AccountContext.Provider value={account}>
        <MemberContext.Provider value={member}>
          <RemoveLoadingScreen />
          <DialogProvider
            templates={config.dialogs.templates}
            defaultTemplate={config.dialogs.defaultTemplate}
          >
            {(
              !isCommunity() &&
              location.pathname !== '/select-community'
            ) ? (
              <Navigate to="/select-community" />
            ) : (
              <Outlet />
            )}
          </DialogProvider>
          <DialogLauncherLayout/>
        </MemberContext.Provider>
      </AccountContext.Provider>
    </UserContext.Provider>
  );
};
