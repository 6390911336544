import React from 'react';
import PropTypes from 'prop-types';
import { OutlineButton } from '../../@communityshare/ui/Button';
import { SolidButton } from '../../@communityshare/ui/Button';
import TextAreaField from '../../forms/_fields_v2/TextAreaField';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormField from '../../forms/_common/FormField';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose
  } = props;

  return (
    <FormTemplate
      title="Edit note"
      subtitle={(
        <>
          <span className="block mb-4">
            Would you like to make changes to your note?
          </span>
        </>
      )}
      onClose={onClose}
      alert={alert}
      body={(
        <>
          <FormField name="text" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <TextAreaField
                label="Note"
                placeholder="Text..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
        </>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Save changes
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
