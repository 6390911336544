import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import validButtons from './validButtons';

LinkButton.propTypes = {
  button: validButtons,
  onClick: PropTypes.func
};

export default function LinkButton(props) {
  const {
    button,
    to,
    onClick,
    ...other
  } = props;

  return (
    <Link to={to}>
      <props.button { ...other} />
    </Link>
  );
}
