import React from 'react';
import PartnerEventEvaluationHeaderSection from '../../pages/evaluations/PartnerEventEvaluation/PartnerEventEvaluationHeaderSection';
import ShareLinkSection from '../../pages/evaluations-event-partners/EvaluationEventPartner/ShareLinkSection';
import Notes from '../../pages/evaluations-event-partners/EvaluationEventPartner/Notes';
import Card from '../../components/Card';

export default function Evaluation(props) {
  const { event, evaluationEventPartner } = props;

  return (
    <Card className="overflow-hidden divide-y divide-gray-100">
      <PartnerEventEvaluationHeaderSection
        evaluation={evaluationEventPartner}
        created={event.data.creatorId === evaluationEventPartner.data.userId}
        showViewLink={false}
      />
      {!evaluationEventPartner.data.completed && (
        <ShareLinkSection evaluation={evaluationEventPartner} />
      )}
      <div className="bg-gray-50 p-4">
        <Notes evaluationEventPartner={evaluationEventPartner} />
      </div>
    </Card>
  );
};
