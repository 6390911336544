import React  from 'react';
import { useConnect } from '@lore/query-connect';

export default function Title(props) {
  const { project } = props;

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  return (
    <div className="flex flex-col space-y-2">
      <div className="text-xl font-semibold text-gray-700">
        {project.data.title || activity.data.name}
      </div>
    </div>
  );
}
