import React  from 'react';
import useRouter from '../../hooks/useRouter';
import useQuery_cancelled from '../events-filtered/cancelled/useQuery';
import useQuery_incomplete from '../events-filtered/draft/useQuery';
import useQuery_expired from '../events-filtered/expired-not-closed/useQuery';
import useQuery_active from '../events-filtered/active/useQuery';
import useQuery_completed from '../events-filtered/completed/useQuery';

export default function Events(props) {
  const { user } = props;

  const { location } = useRouter();

  const PAGE_SIZE = 1;

  const cancelled = useQuery_cancelled({
    location,
    PAGE_SIZE,
    extra: {
      creatorId: user.id
    }
  });

  const incomplete = useQuery_incomplete({
    location,
    PAGE_SIZE,
    extra: {
      creatorId: user.id
    }
  });

  const expired = useQuery_expired({
    location,
    PAGE_SIZE,
    extra: {
      creatorId: user.id
    }
  });

  const active = useQuery_active({
    location,
    PAGE_SIZE,
    extra: {
      creatorId: user.id
    }
  });

  const completed = useQuery_completed({
    location,
    PAGE_SIZE,
    extra: {
      creatorId: user.id
    }
  });

  return (
    <div className="whitespace-nowrap space-x-2">
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
        {cancelled.meta ? cancelled.meta.totalCount : '...'}
      </span>
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
        {incomplete.meta ? incomplete.meta.totalCount : '...'}
      </span>
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
        {expired.meta ? expired.meta.totalCount : '...'}
      </span>
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
        {active.meta ? active.meta.totalCount : '...'}
      </span>
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800">
        {completed.meta ? completed.meta.totalCount : '...'}
      </span>
    </div>
  );
}
