import { useConnect } from '../../../hooks/@lore/query-connect';
import REQUIRED_FIELDS_PAST from '../projects/REQUIRED_FIELDS_PAST';

export default function usePastProjectsForUser(user) {
  return useConnect('project.find', {
    where: {
      eager: {
        $where: {
          creatorId: user.id,
          $and: REQUIRED_FIELDS_PAST.$and
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt desc'
    }
  });
}
