import React  from 'react';
import TextField from './TextField';

export default function Resources(props) {
  const { project } = props;

  return (
    <TextField label="Resources Needed">
      {project.data.resources}
    </TextField>
  );
}
