import { extractArrayFromQuery } from '../../../utils/query';

let currentAccount = null;

export function setCurrentAccount(account) {
  currentAccount = account;
}

export default {
  param: extractArrayFromQuery,
  query: function (values) {
    if (
      values.length > 0 &&
      values[0] === 0
    ) {
      return;
    }

    if (values.length > 0) {
      return {
        'accountId': {
          $in: values
        }
      }
    }

    if (currentAccount) {
      return {
        accountId: currentAccount.id
      }
    }
  }
}
