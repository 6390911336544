import React from 'react';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';
import useSchools from './_hooks/useSchools';
import moment from 'moment-timezone';
import ComparisonMetric from '../ComparisonMetric';

export default function CompositeSchoolGrowth(props) {
  const { account, schoolStart, schoolEnd, titleOnly } = props;

  const previousSchoolStart = titleOnly ? undefined : moment(schoolStart).subtract(1, 'year').toISOString();

  const totalSchools = useSchools(account, undefined, schoolEnd);
  const previousSchools = useSchools(account, previousSchoolStart, schoolStart);
  const newSchools = useSchools(account, schoolStart, schoolEnd);
  if (
    totalSchools.state === PayloadStates.FETCHING ||
    previousSchools.state === PayloadStates.FETCHING ||
    newSchools.state === PayloadStates.FETCHING
  ) {
    return (
      <>
        <MetricLoader
          name="Total school growth"
        />
        {!titleOnly && (
          <>
            <MetricLoader
              name="School growth"
            />
          </>
        )}
      </>
    );
  }

  return (
    <>
      <DeltaMetric
        name="Total school growth"
        value={totalSchools.meta.totalCount}
        delta={newSchools.meta.totalCount}
        titleOnly={titleOnly}
      />
      {!titleOnly && (
        <>
          <ComparisonMetric
            name="School growth"
            value={newSchools.meta.totalCount}
            previousValue={previousSchools.meta.totalCount}
            titleOnly={titleOnly}
          />
        </>
      )}
    </>
  );
}
