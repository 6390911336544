import React from 'react';
import moment from 'moment-timezone';
import FeedItem from '../../feed/FeedItem';
import { useConnect } from '@lore/query-connect';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import FeedHoverText from '../../feed/FeedHoverText';

export default function Event(props) {
  const { event } = props;

  const creator = useConnect('user.byId', {
    id: event.data.creatorId
  });

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  });

  const launch = useDialogLauncher();

  function onClickCreator() {
    launch(DialogManager.UserManage.Config(creator.id));
  }

  function onClickEvent() {
    launch(DialogManager.EventManage.Config(event.id));
  }

  return (
    <FeedItem timestamp={moment(event.data.createdAt).fromNow()} last={props.last}>
      <FeedHoverText onClick={onClickCreator}>
        {creator.data.firstName} {creator.data.lastName}
      </FeedHoverText> created an event titled <FeedHoverText onClick={onClickEvent}>
        {event.data.title || activity.data.name}
      </FeedHoverText>
    </FeedItem>
  );
}
