import React from 'react';
import PropTypes from 'prop-types';
import HeaderSection from './HeaderSection';
import ShareLinkSection from './ShareLinkSection';
import Notes from './Notes';
import Card from '../../../components/Card';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import EventHeader from '../../events/Row/Header';

EvaluationEventPartner.propTypes = {
  evaluationEventPartner: PropTypes.object.isRequired
};

export default function EvaluationEventPartner(props) {
  const { evaluationEventPartner } = props;

  const event = useConnect('event.byId', {
    id: evaluationEventPartner.data.eventId
  });

  if (event.state === PayloadStates.FETCHING) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  return (
    <Card>
      <div className="divide-y divide-gray-200">
        <EventHeader
          event={event}
          tags={false}
        />
        <HeaderSection
          evaluation={evaluationEventPartner}
          created={event.data.creatorId === evaluationEventPartner.data.userId}
        />
        {!evaluationEventPartner.data.completed && (
          <ShareLinkSection evaluation={evaluationEventPartner} />
        )}
      </div>
      <div className="bg-gray-50 p-4">
        <Notes evaluationEventPartner={evaluationEventPartner} />
      </div>
    </Card>
  );
};
