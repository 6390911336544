import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import EmptyState from '../../_common/EmptyState';
import SectionTitle from '../../_common/SectionTitle';
import Loader from '../../../components/Loader';
import ProjectHeader from '../../../pages/projects/Row/Header';
import EventHeader from '../../../pages/events/Row/Header';
import OfferHeader from '../../../pages/offers/Row/Header';
import ProjectEducatorEvaluation from '../../project_manage/project-manage-evaluations/Question/index.educator';
import ProjectPartnerEvaluation from '../../project_manage/project-manage-evaluations/Question';
import EventEducatorEvaluation from '../../event_manage/event-manage-evaluations/Invitation/index.educator';
import EventPartnerEvaluation from '../../event_manage/event-manage-evaluations/Invitation';
import QuestionSections from '../../project_manage/project-manage-evaluations/Question/QuestionSections';
import Invitation from '../../event_manage/event-manage-evaluations/Invitation';
import { UseQueryConnect } from '../../../../hooks/@lore/query-connect';
import DividerCard from '../../../components/DividerCard';

export default function Layout(props) {
  const { user } = props;

  const params = {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt desc'
    }
  };

  const evaluationProjectEducators = useConnect('evaluationProjectEducator.find', params);
  const evaluationProjectPartners = useConnect('evaluationProjectPartner.find', params);
  const evaluationEventEducators = useConnect('evaluationEventEducator.find', params);
  const evaluationEventPartners = useConnect('evaluationEventPartner.find', params);

  const questions = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          userId: user.id,
          response: 'Connect'
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  const invitations = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          userId: user.id,
          response: 'Yes'
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  if (
    evaluationProjectEducators.state === PayloadStates.FETCHING ||
    evaluationProjectPartners.state === PayloadStates.FETCHING ||
    evaluationEventEducators.state === PayloadStates.FETCHING ||
    evaluationEventPartners.state === PayloadStates.FETCHING ||
    questions.state === PayloadStates.FETCHING ||
    invitations.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  return (
    <div className="page space-y-12">
      <div className="flex space-x-8">
        <div className="flex-1 space-y-12">
          <SectionTitle
            title="Projects"
            // subtitle="Description..."
          >
            {evaluationProjectEducators.data.length > 0 && (
              <div className="grid grid-cols-1 gap-4">
                {evaluationProjectEducators.data.map(function(evaluation) {
                  return (
                    <UseQueryConnect key={evaluation.id || evaluation.cid} _key="project.byId" params={{
                      id: evaluation.data.projectId
                    }}>
                      {function(project) {
                        return (
                          <ProjectEducatorEvaluation
                            project={project}
                            alignChildren="top"
                          >
                            {project.state === PayloadStates.FETCHING ? (
                              <Loader/>
                            ) : (
                              <ProjectHeader project={project}/>
                            )}
                          </ProjectEducatorEvaluation>
                        );
                      }}
                    </UseQueryConnect>
                  );
                })}
              </div>
            )}
            {questions.data.length > 0 && (
              <div className="grid grid-cols-1 gap-4">
                {questions.data.map(function(question) {
                  return (
                    <UseQueryConnect key={question.id || question.cid} _key="project.byId" params={{
                      id: question.data.projectId
                    }}>
                      {function(project) {
                        return (
                          <DividerCard>
                            {project.state === PayloadStates.FETCHING ? (
                              <Loader/>
                            ) : (
                              <ProjectHeader project={project}/>
                            )}
                            <QuestionSections
                              question={question}
                            />
                          </DividerCard>
                        );
                      }}
                    </UseQueryConnect>
                  );
                })}
              </div>
            )}
            {(
              evaluationProjectEducators.data.length === 0 &&
              questions.data.length === 0
            ) && (
              <EmptyState
                title="No project evaluations"
              />
            )}
          </SectionTitle>
          <SectionTitle
            title="Events"
            // subtitle="Description..."
          >
            {evaluationEventEducators.data.length > 0 && (
              <div className="grid grid-cols-1 gap-4">
                {evaluationEventEducators.data.map(function(evaluation) {
                  return (
                    <UseQueryConnect key={evaluation.id || evaluation.cid} _key="event.byId" params={{
                      id: evaluation.data.eventId
                    }}>
                      {function(event) {
                        return (
                          <EventEducatorEvaluation
                            event={event}
                            alignChildren="top"
                          >
                            {event.state === PayloadStates.FETCHING ? (
                              <Loader/>
                            ) : (
                              <EventHeader event={event}/>
                            )}
                          </EventEducatorEvaluation>
                        );
                      }}
                    </UseQueryConnect>
                  );
                })}
              </div>
            )}
            {invitations.data.length > 0 && (
              <div className="grid grid-cols-1 gap-4">
                {invitations.data.map(function(invitation) {
                  return (
                    <UseQueryConnect key={invitation.id || invitation.cid} _key="event.byId" params={{
                      id: invitation.data.eventId
                    }}>
                      {function(event) {
                        return (
                          <Invitation
                            key={invitation.id || invitation.cid}
                            invitation={invitation}
                            alignChildren="top"
                          >
                            {event.state === PayloadStates.FETCHING ? (
                              <Loader/>
                            ) : (
                              <EventHeader event={event}/>
                            )}
                          </Invitation>
                        );
                      }}
                    </UseQueryConnect>
                  );
                })}
              </div>
            )}
            {(
              evaluationEventEducators.data.length === 0 &&
              invitations.data.length === 0
            ) && (
              <EmptyState
                title="No event evaluations"
              />
            )}
          </SectionTitle>
        </div>
      </div>
    </div>
  );
};
