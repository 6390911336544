import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogContext from './DialogContext';
import _ from 'lodash';

DialogProvider.propTypes = {
  children: PropTypes.node.isRequired
};

let keyCounter = 0;

export function DialogProvider(props) {
  const {
    templates,
    defaultTemplate = 'default',
    children
  } = props;

  const [dialogs, setDialogs] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);

  function show(dialog, options = {}) {
    const { template = defaultTemplate } = options;
    const Template = templates[template];

    keyCounter = keyCounter + 1;

    dialogs.push(
      <Template key={keyCounter} options={options}>
        {dialog}
      </Template>
    );

    setDialogs(dialogs);
    setUpdateFlag(!updateFlag);
  }

  function afterLeave(dialog, index) {
    // console.log('I left from provider!');
    dialogs.splice(index, 1);
    // setDialogs([...dialogs])
    setDialogs(dialogs);
    setUpdateFlag(!updateFlag);
  }

  function afterEnter(dialog, index) {
    // console.log('I entered from provider!');
  }

  // const nestedDialogs = dialogs.map(function(dialog, index) {
  //   return React.cloneElement(dialog, {
  //     // afterLeave: () => afterLeave(dialog, index),
  //     afterEnter: () => afterEnter(dialog, index)
  //   });
  // });

  const nestedDialogs = _.reduce(dialogs, function(previousDialog, dialog, index) {
    const clonedDialog = React.cloneElement(dialog, {
      afterLeave: () => afterLeave(dialog, index),
      afterEnter: () => afterEnter(dialog, index)
    });

    if (!previousDialog) {
      return clonedDialog;
    }

    return React.cloneElement(previousDialog, {
      childDialog: clonedDialog
    });
  }, null);

  return (
    <DialogContext.Provider value={show}>
      {nestedDialogs}
      {children}
    </DialogContext.Provider>
  );
}

export default DialogProvider;
