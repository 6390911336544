import React from 'react';
import Message from './Message';
import ProjectMessage from './ProjectMessage';
import EventMessage from './EventMessage';
import OfferMessage from './OfferMessage';
import useQuery from './useQuery';

export default function useMapping(props) {
  const models = useQuery(props);

  const type = 'message';

  const entries = models.data.map(function(message, index) {
    let Template = (
      <Message
        key={`${type}-${index}`}
        message={message}
      />
    );

    if (message.data.projectId) {
      Template = (
        <ProjectMessage
          key={`${type}-${index}`}
          message={message}
        />
      );
    }

    if (message.data.eventId) {
      Template = (
        <EventMessage
          key={`${type}-${index}`}
          message={message}
        />
      );
    }

    if (message.data.offerId) {
      Template = (
        <OfferMessage
          key={`${type}-${index}`}
          message={message}
        />
      );
    }

    return {
      type: type,
      value: Template,
      data: message,
      timestamp: message.data.createdAt
    };
  });

  return {
    models,
    entries
  };
}
