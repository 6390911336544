import React from 'react';
import ProjectNote from './ProjectNote';
import useQuery from './useQuery';

export default function useMapping(props) {
  const models = useQuery(props);

  const type = 'project-note';

  const entries = models.data.map((value, index) => ({
    type: type,
    value: (
      <ProjectNote
        key={`${type}-${index}`}
        note={value}
      />
    ),
    data: value,
    timestamp: value.data.createdAt
  }));

  return {
    models,
    entries
  };
}
