import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

CardChildrenWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default function CardChildrenWrapper(props) {
  const {
    children,
    flex = true,
    border = true,
    padding = true,
    className
  } = props;

  return (
    <div className={classNames(
      flex && 'flex',
      padding && 'p-4',
      border && 'border-t border-b border-cs-gray-100',
      className
    )}>
      {children}
    </div>
  );
}
