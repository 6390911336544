import React from 'react';

export default function NavigationGroup(props) {
  const { children } = props;

  return (
    <div className="px-5 space-y-3">
      {children}
    </div>
  );
}
