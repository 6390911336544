import React from 'react';
import Card from '../../../components/Card';

export default function Screening(props) {
  const { title, subtitle, body, footer, alert } = props;

  return (
    <Card className="overflow-hidden">
      <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
        {title || subtitle && (
          <div>
            {title && (
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {title}
              </h3>
            )}
            {subtitle && (
              <div className="mt-1 text-sm text-gray-500">
                {subtitle}
                {/*<p className="mb-2">*/}
                {/*  Some districts have specific background checks, paperwork, agreements, and*/}
                {/*  information that all volunteer community partners need to complete and return*/}
                {/*  to Human Resources.*/}
                {/*</p>*/}
                {/*<p className="mb-2">*/}
                {/*  In the box below, enter the URL where this screening information is located. CommunityShare*/}
                {/*  will automatically send an email with this link when a partner signs up inviting them to*/}
                {/*  start the screening process with your district and again when they accept an interaction*/}
                {/*  request as a reminder that they need to complete your required screening.*/}
                {/*</p>*/}
                {/*<p className="mb-2">*/}
                {/*  As we grow, we'll add the ability to track screening progress of individual community*/}
                {/*  partners in this Admin system.*/}
                {/*</p>*/}
              </div>
            )}

            {/*<p className="mt-1 text-sm text-gray-500">*/}
            {/*  Use a permanent address where you can recieve mail.*/}
            {/*</p>*/}
          </div>
        )}
        {alert}
        {body}
      </div>
      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
        {footer}
        {/*<button*/}
        {/*  type="submit"*/}
        {/*  className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"*/}
        {/*>*/}
        {/*  Save*/}
        {/*</button>*/}
      </div>
    </Card>
  );
};
