import React from 'react';

export default function Sidebar(props) {
  const { children } = props;

  return (
    <div className="w-72 hidden lg:block z-10">
      <nav className="flex flex-col -mr-0.5 border-r-2 border-cs-gray-100 py-8 bg-white overflow-y-auto h-screen space-y-8">
        {children}
      </nav>
    </div>
  );
}
