import React from 'react';
import Metric from './Metric';
import classNames from '../../utils/classNames';
import { ArrowSmallDownIcon, ArrowSmallUpIcon } from '@heroicons/react/24/solid';

export default function DeltaMetric(props) {
  const {
    name,
    value,
    delta,
    currency = false,
    titleOnly = false
  } = props;

  const changeType = (
    delta > 0 ? 'increase' :
    delta < 0 ? 'decrease' :
    'neutral'
  );
  const symbol = (
    delta > 0 ? '+' :
    delta < 0 ? '' :
    '+'
  );

  const currencySymbol = currency ? '$' : '';

  if (titleOnly) {
    return (
      <Metric
        name={name}
        title={`${currencySymbol}${value.toLocaleString()}`}
      />
    );
  }

  return (
    <Metric
      name={name}
      title={`${currencySymbol}${value.toLocaleString()}`}
      subtitle={`(${currencySymbol}${(value - delta).toLocaleString()} last year)`}
    >
      <div className="flex items-center text-center">
        {`${delta}` && (
          <div
            className={classNames(
              'flex items-center space-x-0.5 mt-6 mx-auto'
            )}
          >
            {changeType === 'increase' && (
              <ArrowSmallUpIcon className="h-5 w-5 text-green-600" />
            )}
            {changeType === 'decrease' && (
              <ArrowSmallDownIcon className="h-5 w-5 text-red-600" />
            )}
            <span className={classNames(
              'text-sm font-semibold',
              changeType === 'increase' && 'text-green-600',
              changeType === 'decrease' && 'text-red-600',
              changeType === 'neutral' && 'text-cs-gray-500',
            )}>
              {symbol}{currency ? '$' : ''}{delta.toLocaleString()}
            </span>
          </div>
        )}
      </div>
    </Metric>
  );
}
