import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton } from '../../@communityshare/ui/Button';
import { SolidButton } from '../../@communityshare/ui/Button';
import TextAreaField from '../../forms/_fields_v2/TextAreaField';
import FormTemplate from '../_common_old/FormTemplate.vertical';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const { onClose } = props;

  return (
    <FormTemplate
      title="Event assigned!"
      // subtitle={(
      //   <span>
      //     This will send an email to {educator.data.firstName} nudging them to complete their event.
      //   </span>
      // )}
      icon={CheckIcon}
      success={true}
      onClose={onClose}
      // alert={alert}
      body={(
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:grid-flow-row-dense">
          <OutlineButton onClick={onClose}>
            Close
          </OutlineButton>
        </div>
      )}
      // footer={(
      //   <>
      //     <OutlineButton onClick={onClose}>
      //       Close
      //     </OutlineButton>
      //   </>
      // )}
    />
  );
}
