import React, { useState } from 'react';
import moment from 'moment-timezone';
import PayloadStates from '../../constants/PayloadStates';
import useAccount from '../../hooks/useAccount';
import { useParams } from 'react-router-dom';

export default function useSchoolYearParams(props) {
  const routeParams = useParams();

  const [currentTimestamp] = useState(moment().toISOString());

  const account = useAccount();

  const year = Number(routeParams.year); // 2021

  const start = moment(`${year}-07-01T12:00:00.000-00:00`).toISOString();
  const end = moment(`${year + 1}-07-01T12:00:00.000-00:00`).toISOString();

  const timestamps = [0,1,2,3,4,5,6,7,8,9,10,11].map(function(value) {
    return {
      schoolStart: start,
      schoolEnd: end,
      start: moment(start).add(value, 'months').toISOString(),
      end: moment(start).add(value + 1, 'months').toISOString(),
      label: moment(start).add(value, 'months').format('MMM')
    }
  });

  function empty() {
    return {
      state: PayloadStates.RESOLVED,
      data: [],
      meta: {
        totalCount: 0
      }
    }
  }

  return {
    account,
    schoolStart: start || undefined,
    schoolEnd: end || undefined,
    timestamps,
    empty,
    currentTimestamp
  };
}
