import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import useTags from './useTags';

export default function useStoryTags(story, type) {
  const tags = useTags();

  const tagNodes = useConnect('tagNode.find', {
    where: {
      eager: {
        $where: {
          'tag.type': type,
          storyId: story.id
        }
      }
    },
    pagination: {
      pageSize: 1000
    },
    include: function(tagNode) {
      const tag = _.find(tags.data, tag => tag.id === tagNode.data.tagId);

      return (
        tagNode.data.storyId === story.id &&
        tag.data.type === type
      );
    },
    exclude: function(tagNode) {
      return tagNode.state === PayloadStates.DELETED;
    }
  });

  return {
    state: tagNodes.state,
    data: tagNodes.data.map(function(tagNode) {
      const tag = _.find(tags.data, tag => tag.id === tagNode.data.tagId);

      return {
        id: tagNode.id,
        cid: tagNode.cid,
        data: tag.data,
        _tag: tag,
        _tagNode: tagNode,
        _meta: 'generated in useStoryTags',
      }
    })
  }
}
