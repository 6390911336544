import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import useEventTags from '../../hooks/useEventTags';
import TagTypes from '../../constants/TagTypes';

function isMissing(name, missingFields) {
  return _.find(missingFields, function(missingField) {
    return missingField.key === name;
  });
}

export default function useMissingFields(event) {
  const missingFields = [];

  const eventParticipants = useEventTags(event, TagTypes.Participant);
  const eventInteractionTypes = useEventTags(event, TagTypes.InteractionType);

  if (
    eventParticipants.state === PayloadStates.FETCHING ||
    eventInteractionTypes.state === PayloadStates.FETCHING
  ) {
    return {
      missingFields: {
        state: PayloadStates.FETCHING,
        data: missingFields
      },
      isMissing: isMissing
    };
  }

  if (!event.data.activityId) {
    missingFields.push({
      key: 'activityId',
      name: 'Activity',
      to: `/post-event/${event.id}/activity`
    });
  }

  if (eventParticipants.data.length === 0) {
    missingFields.push({
      key: 'eventParticipants',
      name: 'Participants',
      to: `/post-event/${event.id}/participants`
    });
  }

  if (
    _.find(eventParticipants.data, m => m.data.name === 'Students') &&
    !event.data.students
  ) {
    missingFields.push({
      key: 'students',
      name: 'Student Count',
      to: `/post-event/${event.id}/student-count`
    });
  }

  if (!event.data.interactionDescription) {
    missingFields.push({
      key: 'interactionDescription',
      name: 'Summary',
      to: `/post-event/${event.id}/summary`
    });
  }

  if (
    event.data.specificDate === 'Yes' &&
    !event.data.date
  ) {
    missingFields.push({
      key: 'date',
      name: 'Date',
      to: `/post-event/${event.id}/date`
    });
  }

  if (
    event.data.specificDate === 'No' && (
      !event.data.startDate ||
      !event.data.endDate
    )
  ) {
    missingFields.push({
      key: 'date',
      name: 'Date Range',
      to: `/post-event/${event.id}/date`
    });
  }

  if (eventInteractionTypes.data.length === 0) {
    missingFields.push({
      key: 'eventInteractionTypes',
      name: 'Location',
      to: `/post-event/${event.id}/location`
    });
  }

  return {
    missingFields: {
      state: PayloadStates.RESOLVED,
      data: missingFields
    },
    isMissing: function(name) {
      return isMissing(name, missingFields)
    }
  };
};
