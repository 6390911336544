import React from 'react';
import classNames from 'classnames';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { useUser } from '@lore/auth';
import { formatDate, formatTime } from '../../utils/dateTimeUtils';

export default function QuestionChecker(props) {
  const { project, user, questions } = props;

  const currentUser = useUser();

  const question = _.find(questions.data, q => q.data.projectId === project.id);

  if (!question) {
    return null;
  }

  // return (
  //   <div className={classNames(
  //     // 'w-full grid grid-cols-1 gap-4',
  //     'bg-green-50 -m-4 p-4 w-full'
  //   )}>
  //     <div className="flex items-center space-x-2">
  //       <EnvelopeIcon className="text-cs-green-500 h-6 w-6" />
  //       <span className="text-sm">
  //         {user.data.firstName} was invited on {formatDate(question.data.createdAt)}, {formatTime(question.data.createdAt, currentUser.data.timezone, true)}
  //       </span>
  //     </div>
  //   </div>
  // );

  return (
    <div className={classNames(
      'bg-cs-green-500 p-4 w-full'
    )}>
      <div className="flex items-center space-x-2">
        <EnvelopeIcon className="text-white h-6 w-6" />
        <span className="text-sm text-white">
          {user.data.firstName} was invited on {formatDate(question.data.createdAt)}, {formatTime(question.data.createdAt, currentUser.data.timezone, true)}
        </span>
      </div>
    </div>
  );
}
