import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Offer from '../../offers/Offer';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Offers']}
      title="All Offers this School Year"
      description={(
        <span>
          These are all offers that were shared publicly on your community's platform. They
          include active events as well as those that expired during this academic year (7/1-6/30).
          CommunityShare recommends observing patterns in what has been offered to share with
          educators during presentations or in fellowships. You may also wish to contact partners
          whose offers have expired to see if they are willing to duplicate or extend older offers.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(offer) {
        return (
          <Offer key={offer.id} offer={offer} />
        );
      }}
    />
  );
}
