import React from 'react';
import PropTypes from 'prop-types';
import Invitations from './Invitations';
import EmptyState from '../../_common/EmptyState';
import EducatorEvaluation from './Invitation/index.educator';
import SectionTitle from '../../_common/SectionTitle';
import CloseEventAlert from './CloseEventAlert';

export default function Layout(props) {
  const { event } = props;

  return (
    <div className="page space-y-12">
      <div className="flex space-x-8">
        <div className="flex-1 space-y-12">
          <CloseEventAlert event={event} />
          <SectionTitle
            title="Educator"
            subtitle="Creator of the event. There is only one."
          >
            <div className="grid grid-cols-1 gap-4">
              <EducatorEvaluation
                event={event}
              />
            </div>
          </SectionTitle>
          <SectionTitle
            title="Partners"
            subtitle={(
              <>
                This is a list of partners who said they would attend this event. Once the event is marked
                complete, these partners will be asked to confirm whether they attended, and for those
                who did, asked to complete an evaluation.
              </>
            )}
          >
            <div className="grid grid-cols-1 gap-4">
              <Invitations
                event={event}
                responses={[
                  'Yes'
                ]}
                results={[
                  'Participated',
                  'Contributed',
                  {
                    $exists: false
                  }
                ]}
                emptyState={(
                  <EmptyState
                    title="No partners to display"
                    // subtitle="Extra text..."
                  />
                )}
              />
            </div>
          </SectionTitle>
          <SectionTitle
            title="Not involved"
            subtitle={(
              <>
                These partners said they would attend, but were unable to.
              </>
            )}
          >
            <div className="grid grid-cols-1 gap-4">
              <Invitations
                event={event}
                responses={[
                  'Yes'
                ]}
                results={[
                  'Nothing'
                ]}
                emptyState={(
                  <EmptyState
                    title="No partners to display"
                    // subtitle="Partners who are unable to participate will show up here."
                  />
                )}
              />
            </div>
          </SectionTitle>
        </div>
      </div>
    </div>
  );
};
