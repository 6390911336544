import React from 'react';
import { PayloadStates } from '@lore/utils';
import { UsersIcon } from '@heroicons/react/24/outline';
import Loader from '../../../components/Loader';
import ContentCard from '../../../components_profiles/ContentCard';
import ContentHeading from '../../../components_profiles/ContentHeading';
import ContentSection from '../../../components_profiles/ContentSection';
import useOfferTags from '../../../hooks/useOfferTags';
import TagTypes from '../../../constants/TagTypes';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';
import TextContentSection from '../../../components_profiles/TextContentSection';

export default function Students(props) {
  const { offer } = props;

  const offerParticipants = useOfferTags(offer, TagTypes.Participant);
  const offerSizes = useOfferTags(offer, TagTypes.Size);

  if (
    offerParticipants.state === PayloadStates.FETCHING ||
    offerSizes.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  const workingWithStudents = _.find(offerParticipants.data, model => model.data.name === 'Students');

  if (!workingWithStudents) {
    return null;
  }

  return (
    <ContentCard className="space-y-4">
      <SubSectionHeader
        icon={UsersIcon}
        title="Participants"
      />
      <TextContentSection title="This offer is primarily for">
        <ul className="list-disc ml-6">
          {offerParticipants.data.map(function(offerParticipant) {
            return (
              <li key={offerParticipant.id || offerParticipant.cid}>
                {offerParticipant.data.name}
              </li>
            );
          })}
        </ul>
      </TextContentSection>
      {offerSizes.data.length > 0 && (
        <TextContentSection title="Size of group">
          <ul className="list-disc ml-6">
            {offerSizes.data.map(function(offerSize) {
              return (
                <li key={offerSize.id || offerSize.cid}>
                  {offerSize.data.name}
                </li>
              );
            })}
          </ul>
        </TextContentSection>
      )}
    </ContentCard>
  );
}
