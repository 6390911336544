import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import ConnectButton from './ConnectButton';
import { PayloadStates } from '@lore/utils';
import Wrapper from './Wrapper';
import useDialogLauncher from '../../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../../dialogs_new/DialogManager';

OfferBanner.propTypes = {
  user: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired
};

export default function OfferBanner(props) {
  const {
    user,
    offer
  } = props;

  const launch = useDialogLauncher();

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  const claims = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          userId: user.id,
          offerId: offer.id
        }
      }
    },
    include: function(model) {
      return (
        model.data.userId === user.id &&
        model.data.offerId === offer.id
      );
    }
  })

  function showInviteDialog() {
    launch(DialogManager.ClaimInvite.Config(offer.id, user.id));
  }

  if (claims.state === PayloadStates.FETCHING) {
    return (
      <Wrapper user={user} creator={creator} type="offer">
        <ConnectButton disabled={true}>
          ...
        </ConnectButton>
      </Wrapper>
    );
  }

  // if (user.id === currentUser.id) {
  //   return (
  //     <Wrapper user={user} creator={creator} type="offer">
  //       <ConnectButton disabled={true}>
  //         This is you
  //       </ConnectButton>
  //     </Wrapper>
  //   );
  // }

  if (claims.data.length > 0) {
    return (
      <Wrapper user={user} creator={creator} type="offer">
        <ConnectButton disabled={true}>
          Connected
        </ConnectButton>
      </Wrapper>
    );
  }

  return (
    <Wrapper user={user} creator={creator} type="offer">
      <ConnectButton onClick={showInviteDialog}>
        Connect
      </ConnectButton>
    </Wrapper>
  );
};
