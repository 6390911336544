import React from 'react';
import PropTypes from 'prop-types';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import Tooltip from '../../../components/Tooltip';

export default function Organization(props) {
  const { project } = props;

  let organization = useConnect('organization.byId', {
    id: project.data.organizationId
  }, {
    enabled: !!project.data.organizationId
  });

  if (!project.data.organizationId) {
    organization =  {
      data: {
        name: '[missing]'
      }
    };
  }

  return (
    <div className="flex space-x-1 items-center text-gray-500 text-sm">
      <Tooltip tooltip="School" icon={false}>
        <BuildingOfficeIcon className="h-6 w-6" />
      </Tooltip>
      <span>
        {organization.data.name}
      </span>
    </div>
  );
};
