import React from 'react';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import { CalendarIcon, ClockIcon, FolderIcon, NewspaperIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import DropdownFilter from '../../components/DropdownFilter';

export default function SortBy(props) {
  const { location, history } = useRouter();

  const search = parse(location.search) || {};

  function onChange(order) {
    const search = parse(location.search) || {};

    search.order = order;
    delete search.page;

    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, search))
    });
  }

  const orderMap = {
    Newest: {
      name: 'Newest',
      param: 'createdAt desc'
    },
    Active: {
      name: 'Active',
      param: 'lastActiveAt desc'
    },
    QuestionsConnect: {
      name: 'Project invitations (connected)',
      param: 'numberOfQuestionsConnect desc'
    },
    QuestionsPending: {
      name: 'Project invitations (pending)',
      param: 'numberOfQuestionsPending desc'
    },
    QuestionsDeclined: {
      name: 'Project invitations (declined)',
      param: 'numberOfQuestionsDecline desc'
    },
    InvitationsYes: {
      name: 'Event invitations (yes)',
      param: 'numberOfInvitationsYes desc'
    },
    InvitationsMaybe: {
      name: 'Event invitations (maybe)',
      param: 'numberOfInvitationsMaybe desc'
    },
    InvitationsPending: {
      name: 'Event invitations (pending)',
      param: 'numberOfInvitationsPending desc'
    },
    InvitationsNo: {
      name: 'Event invitations (no)',
      param: 'numberOfInvitationsNo desc'
    },
    OffersCreated: {
      name: 'Offers created',
      param: 'numberOfOffersCreated desc'
    }
  };

  const reverseOrderMap = _.mapKeys(orderMap, function(value, key) {
    return value.param;
  });

  return (
    <DropdownFilter subtitle="Sort by:" title={reverseOrderMap[search.order]?.name || 'Newest'}>
      <DropdownFilter.MenuItem
        icon={ClockIcon}
        title={orderMap.Newest.name}
        subtitle="Display recently created partners first."
        selected={!search.order || search.order === orderMap.Newest.param}
        onClick={() => onChange(orderMap.Newest.param)}
      />
      <DropdownFilter.MenuItem
        icon={ClockIcon}
        title={orderMap.Active.name}
        subtitle="Display partners by date they last logged in."
        selected={search.order === orderMap.Active.param}
        onClick={() => onChange(orderMap.Active.param)}
      />
      <DropdownFilter.MenuItem
        icon={FolderIcon}
        title={orderMap.QuestionsConnect.name}
        subtitle="Display partners by the number of project invitations they have connected over."
        selected={search.order === orderMap.QuestionsConnect.param}
        onClick={() => onChange(orderMap.QuestionsConnect.param)}
      />
      <DropdownFilter.MenuItem
        icon={FolderIcon}
        title={orderMap.QuestionsPending.name}
        subtitle="Display partners by the number of project invitations waiting for a response."
        selected={search.order === orderMap.QuestionsPending.param}
        onClick={() => onChange(orderMap.QuestionsPending.param)}
      />
      <DropdownFilter.MenuItem
        icon={FolderIcon}
        title={orderMap.QuestionsDeclined.name}
        subtitle="Display partners by the number of project invitations they have declined."
        selected={search.order === orderMap.QuestionsDeclined.param}
        onClick={() => onChange(orderMap.QuestionsDeclined.param)}
      />
      <DropdownFilter.MenuItem
        icon={CalendarIcon}
        title={orderMap.InvitationsYes.name}
        subtitle="Display partners by the number of event invitations they replied 'Yes' to."
        selected={search.order === orderMap.InvitationsYes.param}
        onClick={() => onChange(orderMap.InvitationsYes.param)}
      />
      <DropdownFilter.MenuItem
        icon={CalendarIcon}
        title={orderMap.InvitationsMaybe.name}
        subtitle="Display partners by the number of event invitations they replied 'Maybe' to."
        selected={search.order === orderMap.InvitationsMaybe.param}
        onClick={() => onChange(orderMap.InvitationsMaybe.param)}
      />
      <DropdownFilter.MenuItem
        icon={CalendarIcon}
        title={orderMap.InvitationsPending.name}
        subtitle="Display partners by the number of event invitations waiting for a response."
        selected={search.order === orderMap.InvitationsPending.param}
        onClick={() => onChange(orderMap.InvitationsPending.param)}
      />
      <DropdownFilter.MenuItem
        icon={CalendarIcon}
        title={orderMap.InvitationsNo.name}
        subtitle="Display partners by the number of event invitations they have declined."
        selected={search.order === orderMap.InvitationsNo.param}
        onClick={() => onChange(orderMap.InvitationsNo.param)}
      />
      <DropdownFilter.MenuItem
        icon={NewspaperIcon}
        title={orderMap.OffersCreated.name}
        subtitle="Display partners by the number of offers they created."
        selected={search.order === orderMap.OffersCreated.param}
        onClick={() => onChange(orderMap.OffersCreated.param)}
      />
    </DropdownFilter>
  );
}
