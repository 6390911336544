import React from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Template from '../../../components_profiles/Template';
import useEventTags from '../../../hooks/useEventTags';
import TagTypes from '../../../constants/TagTypes';

export default function Skills(props) {
  const { event } = props;

  const eventExpertiseTypes = useEventTags(event, TagTypes.ExpertiseType);

  if (eventExpertiseTypes.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (eventExpertiseTypes.data.length === 0) {
    return null;
  }

  return (
    <Template
      icon={QuestionMarkCircleIcon}
      title="Expertise required"
      subtitle="Career or volunteer experience with..."
      tags={eventExpertiseTypes}
    />
  );
};
