import React from 'react';
import { Helmet } from 'react-helmet-async';
import Evaluations from './Evaluations';
import useRouter from '../../hooks/useRouter';
import PageHeading from '../../@communityshare/ui/PageHeading';
import Results from '../_common_results/Results';
import EvaluationEventPartnerComplete from '../evaluations-event-partners-filtered/complete/Evaluation';
import EvaluationEventPartnerPending from '../evaluations-event-partners-filtered/pending/Evaluation';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import MobileNavigation from '../evaluations/MobileNavigation';

export default function Layout(props) {
  const router = useRouter();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Explore Events</title>
      </Helmet>
      <MobileNavigation />
      <PageHeading
        breadcrumbs={['Evaluations', 'Events: Partners']}
        title="All Evaluations"
        description={(
          <>
            Event evaluations sent to a partner.
          </>
        )}
      />
      <Evaluations router={router}>
        {function(result) {
          return (
            <Results result={result} columns={1} resource="evaluations" emptyIcon={ClipboardDocumentListIcon}>
              {function(evaluationEventPartner) {
                if (evaluationEventPartner.data.completed) {
                  return (
                    <EvaluationEventPartnerComplete
                      key={evaluationEventPartner.id}
                      evaluation={evaluationEventPartner}
                    />
                  );
                }

                return (
                  <EvaluationEventPartnerPending
                    key={evaluationEventPartner.id}
                    evaluation={evaluationEventPartner}
                  />
                );
              }}
            </Results>
          );
        }}
      </Evaluations>
    </div>
  );
}
