import React from 'react';
import PropTypes from 'prop-types';
import { XMarkIcon } from '@heroicons/react/24/outline';

CloseDialogButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default function CloseDialogButton(props) {
  const { onClick } = props;

  return (
    <button
      type="button"
      className="z-30 absolute p-4 -m-4 text-3xl text-cs-orange-500 opacity-100 hover:text-cs-orange-500 hover:opacity-75 top-4 right-5"
      onClick={onClick}
    >
      <XMarkIcon aria-hidden="true" className="h-8 w-8 text-cs-orange-500"/>
      <span className="sr-only">Close</span>
    </button>
  );
};
