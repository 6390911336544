import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import classNames from 'classnames';
import ButtonLabel from './ButtonLabel';
import MenuItem from './MenuItem';
import FilterButtonCount from '../../pages/_common_filters/FilterButtonCount';

export default function DropdownFilter(props) {
  const { title, subtitle, count } = props;

  return (
    <div className="relative">
      <Menu as="div" className="relative h-10">
        <div>
          <Menu.Button className={classNames(
            'flex items-center rounded-2xl bg-cs-gray-100 px-3 py-2',
            'shadow-cs-flat -m-px border',
            count ? 'border-cs-gray-900' : 'border-cs-gray-200',
            'hover:bg-cs-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
          )}>
            <FilterButtonCount count={count} />
            <ButtonLabel>
              {subtitle}
            </ButtonLabel>
            <ButtonLabel bold={true}>
              {title}
            </ButtonLabel>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M16.291 10.7074C16.9214 10.0776 16.4754 9 15.5842 9H8.41268C7.52199 9 7.07572 10.0767 7.70525 10.7068L11.2878 14.2926C11.6782 14.6833 12.3113 14.6836 12.702 14.2932L16.291 10.7074Z" fill="#8F92A1" fillOpacity="0.4"/>
            </svg>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-20 absolute left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-2xl bg-white shadow-cs-flat ring-1 ring-black ring-opacity-5 focus:outline-none">
            {props.children}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

DropdownFilter.MenuItem = MenuItem;
