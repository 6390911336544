import React from 'react';
import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import Template from './Template';
import TextContentSection from '../../../components_profiles/TextContentSection';

export default function Cost(props) {
  const { offer } = props;

  return (
    <Template
      icon={CurrencyDollarIcon}
      title="Cost"
    >
      <TextContentSection>
        {
          offer.data.hasCost === 'Yes' ?
            (offer.data.cost || '(Yes, but not provided)') :
            'Free'
        }
      </TextContentSection>
    </Template>
  );
};
