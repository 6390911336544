import React  from 'react';
import { useConnect } from '@lore/query-connect';

export default function Questions(props) {
  const { user } = props;

  const pending = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          userId: user.id,
          response: 'Pending'
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const declined = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          userId: user.id,
          response: 'Decline'
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const attending = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          userId: user.id,
          response: 'Connect'
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  return (
    <div className="whitespace-nowrap space-x-2">
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
        {declined.meta ? declined.meta.totalCount : '...'}
      </span>
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
        {pending.meta ? pending.meta.totalCount : '...'}
      </span>
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
        {attending.meta ? attending.meta.totalCount : '...'}
      </span>
    </div>
  );
}
