import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '../../../hooks/@lore/query-connect';
import ReminderDialogTemplate from '../_common_reminders/ReminderDialogTemplate';

Dialog.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function Dialog(props) {
  const { offer } = props;

  const reminders = useConnect('reminder.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          type: {
            $in: [
              'InviteMoreEducators',
              'Invite'
            ]
          }
        }
      }
    }
  });

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  let _data = {
    type: 'Invite',
    offerId: offer.id,
    recipientId: offer.data.creatorId
  };

  return (
    <ReminderDialogTemplate
      title="Send reminder to invite more educators?"
      subtitle={(
        <span>
          This will send an email to {creator.data.firstName} nudging them to invite more educators.
        </span>
      )}
      reminders={reminders}
      _data={_data}
      MAX_COUNT={3}
      onClose={props.onClose}
    />
  );
};
