import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import useUserTags from '../../../hooks/useUserTags';
import TagTypes from '../../../constants/TagTypes';

function isMissing(name, missingFields) {
  return _.find(missingFields, function(missingField) {
    return missingField.key === name;
  });
}

export default function useMissingFields(user) {
  const missingFields = [];

  const userExpertises = useUserTags(user, TagTypes.Expertise);

  if (userExpertises.state === PayloadStates.FETCHING) {
    return {
      missingFields: {
        state: PayloadStates.FETCHING,
        data: missingFields
      },
      isMissing: isMissing
    };
  }

  if (
    !user.data.firstName ||
    !user.data.lastName
  ) {
    missingFields.push({
      key: 'name',
      name: 'Name',
      to: `/profile/edit/name`
    });
  }

  if (!user.data.zipCode) {
    missingFields.push({
      key: 'zipCode',
      name: 'ZIP Code',
      to: `/profile/edit/location`
    });
  }

  if (!user.data.timezone) {
    missingFields.push({
      key: 'timezone',
      name: 'Timezone',
      to: `/profile/edit/location`
    });
  }

  if (
    !user.data.isEducator &&
    userExpertises.data.length === 0
  ) {
    missingFields.push({
      key: 'userExpertises',
      name: 'Skills/Hobbies',
      to: `/profile/edit/skills`
    });
  }

  return {
    missingFields: {
      state: PayloadStates.RESOLVED,
      data: missingFields
    },
    isMissing: function(name) {
      return isMissing(name, missingFields)
    }
  };
};
