import React from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { useUser } from '@lore/auth';

export default function EvaluationIncompleteColumn(props) {
  const { evaluation, onClickView, onSendReminder } = props;

  const currentUser = useUser();

  return (
    <div className="flex flex-col text-right">
      <div
        className={classNames(
          'text-lg font-semibold text-yellow-700 space-x-1',
          currentUser.data.isSuperuser && 'hover:text-yellow-500 cursor-pointer'
        )}
        onClick={currentUser.data.isSuperuser ? onSendReminder : undefined}
      >
        <span>Started</span>
        <span>·</span>
        <span>Incomplete</span>
      </div>
      <div className="flex text-sm text-gray-500 space-x-1">
        <span>Abandoned {moment(evaluation.data.updatedAt).fromNow()}</span>
        {onClickView && (
          <>
            <span>·</span>
            <span className="text-setup-tag hover:text-blue-300 cursor-pointer" onClick={onClickView}>
              View
            </span>
          </>
        )}
      </div>
    </div>
  );
};
