import React from 'react';
import Card from '../../../components/Card';
import PartnerProjectEvaluationHeaderSection from '../../evaluations/PartnerProjectEvaluation/PartnerProjectEvaluationHeaderSection';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import Contribution from '../../evaluations/_common/Contribution';
import Contribution2 from '../../evaluations/Contribution';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import Testimonial from '../../evaluations/Testimonial';

export default function Evaluation(props) {
  const { evaluation } = props;

  // const project = useConnect('project.byId', {
  //   id: evaluation.data.projectId
  // });
  //
  // return (
  //   <Card className="overflow-hidden divide-y divide-gray-200">
  //     <PartnerProjectEvaluationHeaderSection
  //       evaluation={evaluation}
  //       created={project.data.creatorId === evaluation.data.userId}
  //     />
  //     <Contribution evaluation={evaluation} />
  //   </Card>
  // );

  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.EvaluationProjectPartner.Config(evaluation.id));
  }

  return (
    <Contribution2
      evaluation={evaluation}
      onClick={onClick}
    />
  );
}
