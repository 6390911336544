import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Project from '../../projects/Project';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Projects']}
      title="Active Projects"
      description={(
        <span>
          These are projects where today is within the date range an educator provided to
          complete their project. CommunityShare recommends monitoring these projects (looking
          to see that there are partners in the green below, promoting through social media
          and other outreach, connecting with the educator to get a sense of project
          progress). You can make notes visible only to your team.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(project) {
        return (
          <Project key={project.id} project={project} />
        );
      }}
    />
  );
}
