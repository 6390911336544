import React from 'react';
import PropTypes from 'prop-types';

export default function InactiveTag(props) {
  const { user } = props;

  if (!user.data.inactive) {
    return null;
  }

  return (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
      INACTIVE
    </span>
  );
}
