import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import SuccessMessage from '../../../forms/_common/SuccessMessage';
import Form from './Form';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
// import DialogTemplate from '../../../dialogs/_common/DialogTemplate';

Dialog.propTypes = {
  account: PropTypes.object.isRequired
};

export default function Dialog(props) {
  const { account } = props;

  const [data, setData] = useState({
    screeningUrl: account.data.screeningUrl || ''
  });
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('account.update', account);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      props.onSubmit && props.onSubmit(request);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
  }

  return (
    <div>
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          // text: [validators.isRequired]
        }}>
          {({errors, hasError}) => (
            <Form
              data={data}
              errors={errors}
              showErrors={showErrors}
              onChange={onChange}
              onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
              alert={error ? (
                <RequestError request={request}/>
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : (success) ? (
                <SuccessMessage>
                  Screening URL updated.
                </SuccessMessage>
              ) : null}
            />
          )}
        </FormErrors>
      </Overlay>
    </div>
  );
};
