import React from 'react';
import DividerCard from '../../../components/DividerCard';
import PartnerProjectEvaluationSections from './PartnerProjectEvaluationSections';

export default function PartnerProjectEvaluation(props) {
  const { evaluation, project } = props;

  return (
    <DividerCard>
      <PartnerProjectEvaluationSections
        evaluation={evaluation}
        project={project}
      />
    </DividerCard>
  );
};
