import React from 'react';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';
import usePartners from './_hooks/usePartners';
import moment from 'moment-timezone';
import ComparisonMetric from '../ComparisonMetric';
import useInactivePartners from './_hooks/useInactivePartners';
import useSleepingPartners from './_hooks/useSleepingPartners';
import useIncompletePartners from './_hooks/useIncompletePartners';
import useActivePartners from './_hooks/useActivePartners';

export default function CompositePartnerGrowth(props) {
  const { account, schoolStart, schoolEnd, titleOnly, currentTimestamp } = props;

  const previousSchoolStart = titleOnly ? undefined : moment(schoolStart).subtract(1, 'year').toISOString();

  const partners = {
    new: {
      total: usePartners(account, undefined, schoolEnd),
      previous: usePartners(account, previousSchoolStart, schoolStart),
      new: usePartners(account, schoolStart, schoolEnd)
    },
    inactive: {
      total: useInactivePartners(account, undefined, schoolEnd, currentTimestamp),
      previous: useInactivePartners(account, previousSchoolStart, schoolStart, currentTimestamp),
      new: useInactivePartners(account, schoolStart, schoolEnd, currentTimestamp)
    },
    sleeping: {
      total: useSleepingPartners(account, undefined, schoolEnd, currentTimestamp),
      previous: useSleepingPartners(account, previousSchoolStart, schoolStart, currentTimestamp),
      new: useSleepingPartners(account, schoolStart, schoolEnd, currentTimestamp)
    },
    incomplete: {
      total: useIncompletePartners(account, undefined, schoolEnd, currentTimestamp),
      previous: useIncompletePartners(account, previousSchoolStart, schoolStart, currentTimestamp),
      new: useIncompletePartners(account, schoolStart, schoolEnd, currentTimestamp)
    },
    active: {
      total: useActivePartners(account, undefined, schoolEnd, currentTimestamp),
      previous: useActivePartners(account, previousSchoolStart, schoolStart, currentTimestamp),
      new: useActivePartners(account, schoolStart, schoolEnd, currentTimestamp)
    }
  };

  if (
    partners.new.total.state === PayloadStates.FETCHING ||
    partners.new.previous.state === PayloadStates.FETCHING ||
    partners.new.new.state === PayloadStates.FETCHING ||
    partners.inactive.total.state === PayloadStates.FETCHING ||
    partners.inactive.previous.state === PayloadStates.FETCHING ||
    partners.inactive.new.state === PayloadStates.FETCHING ||
    partners.sleeping.total.state === PayloadStates.FETCHING ||
    partners.sleeping.previous.state === PayloadStates.FETCHING ||
    partners.sleeping.new.state === PayloadStates.FETCHING ||
    partners.incomplete.total.state === PayloadStates.FETCHING ||
    partners.incomplete.previous.state === PayloadStates.FETCHING ||
    partners.incomplete.new.state === PayloadStates.FETCHING ||
    partners.active.total.state === PayloadStates.FETCHING ||
    partners.active.previous.state === PayloadStates.FETCHING ||
    partners.active.new.state === PayloadStates.FETCHING
  ) {
    return (
      <>
        <MetricLoader
          name="Total partner signups"
        />
        <MetricLoader
          name="Total active partners"
        />
        <MetricLoader
          name="Total sleeping partners"
        />
        <MetricLoader
          name="Total inactive partners"
        />
        <MetricLoader
          name="Total incomplete partners"
        />
        {!titleOnly && (
          <>
            <MetricLoader
              name="Partner signups"
            />
            <MetricLoader
              name="Active partners"
            />
            <MetricLoader
              name="Sleeping partners"
            />
            <MetricLoader
              name="Inactive partners"
            />
            <MetricLoader
              name="Incomplete partners"
            />
          </>
        )}
      </>
    );
  }

  return (
    <>
      <DeltaMetric
        name="Total partner signups"
        value={partners.new.total.meta.totalCount}
        delta={partners.new.new.meta.totalCount}
        titleOnly={titleOnly}
      />
      <DeltaMetric
        name="Total active partners"
        value={partners.active.total.meta.totalCount}
        delta={partners.active.new.meta.totalCount}
        titleOnly={titleOnly}
      />
      <DeltaMetric
        name="Total sleeping partners"
        value={partners.sleeping.total.meta.totalCount}
        delta={partners.sleeping.new.meta.totalCount}
        titleOnly={titleOnly}
      />
      <DeltaMetric
        name="Total inactive partners"
        value={partners.inactive.total.meta.totalCount}
        delta={partners.inactive.new.meta.totalCount}
        titleOnly={titleOnly}
      />
      <DeltaMetric
        name="Total incomplete partners"
        value={partners.incomplete.total.meta.totalCount}
        delta={partners.incomplete.new.meta.totalCount}
        titleOnly={titleOnly}
      />
      {!titleOnly && (
        <>
          <ComparisonMetric
            name="Partner signups"
            value={partners.new.new.meta.totalCount}
            previousValue={partners.new.previous.meta.totalCount}
            titleOnly={titleOnly}
          />
          <ComparisonMetric
            name="Active partners"
            value={partners.active.new.meta.totalCount}
            previousValue={partners.active.previous.meta.totalCount}
            titleOnly={titleOnly}
          />
          <ComparisonMetric
            name="Sleeping partners"
            value={partners.sleeping.new.meta.totalCount}
            previousValue={partners.sleeping.previous.meta.totalCount}
            titleOnly={titleOnly}
          />
          <ComparisonMetric
            name="Inactive partners"
            value={partners.inactive.new.meta.totalCount}
            previousValue={partners.inactive.previous.meta.totalCount}
            titleOnly={titleOnly}
          />
          <ComparisonMetric
            name="Incomplete partners"
            value={partners.incomplete.new.meta.totalCount}
            previousValue={partners.incomplete.previous.meta.totalCount}
            titleOnly={titleOnly}
          />
        </>
      )}
    </>
  );
}
