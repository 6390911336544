import React from 'react';
import PropTypes from 'prop-types';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import Tooltip from '../../../components/Tooltip';

export default function Email(props) {
  const { user } = props;

  return (
    <div className="flex space-x-1 items-center text-gray-500 text-sm">
      <Tooltip tooltip="Email" icon={false}>
        <EnvelopeIcon className="h-6 w-6" />
      </Tooltip>
      <span>
        {user.data.email}
      </span>
    </div>
  );
};
