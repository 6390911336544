import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Event from '../../events/Event';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Events']}
      title="Completed"
      description={(
        <span>
          These are events where an educator indicated they completed an event. CommunityShare
          recommends finding ways to publicize successful events through media releases, social
          media, and presentations. Congratulating educators (and their supervisors) or partners
          (and their employers) after a successful event builds goodwill and momentum for
          community-engaged learning in your community.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(event) {
        return (
          <Event key={event.id} event={event} />
        );
      }}
    />
  );
}
