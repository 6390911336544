import React from 'react';
import PropTypes from 'prop-types';
import { PayloadStates } from '@lore/utils';
import Creator from './Creator';
import Project from '../Project';
import AlertWarning from '../../_common/AlertWarning';
import AlertSuccess from '../../_common/AlertSuccess';
import useMissingFields from '../useMissingFields';
import Loader from '../../../components/Loader';

export default function Layout(props) {
  const { project } = props;

  const { missingFields } = useMissingFields(project);

  if (missingFields.state === PayloadStates.FETCHING) {
    return (
      <div className="h-screenx">
        <Loader />
      </div>
    );
  }

  return (
    <div className="page space-y-12">
      <div className="flex space-x-8">
        <div className="flex-1 space-y-12">
          {missingFields.data.length > 0 ? (
            <AlertWarning
              title="Project description is not being shared publicly because key information is missing!"
              subtitle={(
                <>
                  Required fields are highlighted below. You can support the educator by having them
                  complete required fields with their current thinking - they can always update if the
                  project evolves. Partners need this information to decide if they'd like to work
                  with the educator.
                </>
              )}
            >
              <Creator project={project} />
            </AlertWarning>
          ) : (
            <AlertSuccess
              title="Project has been described"
              subtitle="No required fields are missing."
            />
          )}
          <Project project={project} />
        </div>
      </div>
    </div>
  );
};
