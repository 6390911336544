import React, { useState } from 'react';
import PageHeading from '../../@communityshare/ui/PageHeading';
import Table from '../team-staff/Table';
import ParentLayout from '../settings/Layout';
import initialColumns from './columns';
import useAccount from '../../hooks/useAccount';
import { PayloadStates } from '@lore/utils';
import SecondaryAction from '../_components/actions/SecondaryAction';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet-async';
import { DialogManager } from '../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import MobileNavigation from '../settings/MobileNavigation';


export default function Layout(props) {
  const launch = useDialogLauncher();
  const account = useAccount();
  const [timestamp] = useState(moment().toISOString());

  function onClick() {
    launch(DialogManager.StewardInvitationInvite.Config());
  }

  const PAGE_SIZE = 1000;

  const [columns, setColumns] = useState(initialColumns);

  const selectedColumns = columns.filter(column => column.selected);

  return (
    <ParentLayout>
      <div className="p-4 space-y-8">
        <Helmet>
          <title>Team: Invitations</title>
        </Helmet>
        <MobileNavigation />
        <PageHeading
          breadcrumbs={['Team']}
          title="Invitations (superuser only)"
          description="When someone signs up with the email listed, they will automatically be granted steward access."
        >
          <div>
            <SecondaryAction onClick={onClick} secret={true}>
              Invite Steward
            </SecondaryAction>
          </div>
        </PageHeading>
      </div>
      <Table
        pageSize={PAGE_SIZE}
        columns={selectedColumns}
        select={function (getState) {
          return getState('stewardInvitation.find', {
            where: {
              eager: {
                $where: {
                  accountId: account.id,
                  createdAt: {
                    $lte: timestamp
                  }
                }
              }
            },
            pagination: {
              pageSize: PAGE_SIZE,
              order: 'createdAt desc'
            },
            include: function(model) {
              return (
                model.data.accountId === account.id &&
                model.state === PayloadStates.RESOLVED
              );
            },
            exclude: function(model) {
              return model.state === PayloadStates.DELETED;
            }
          });
        }}
        row={function (stewardInvitation) {
          return (
            <tr key={stewardInvitation.id}>
              {selectedColumns.map(function (column) {
                if (!column.row) {
                  return null;
                }

                return (
                  <column.row
                    key={column.id}
                    stewardInvitation={stewardInvitation}
                  />
                );
              })}
            </tr>
          );
        }}
      />
    </ParentLayout>
  );
}
