import React from 'react';
import NavigationHeader from '../_navigation/ThickNavigation/NavigationHeader';
import NavigationGroup from '../_navigation/ThickNavigation/NavigationGroup';
import SidebarNavigationGrouped from './SidebarNavigationGrouped';
import SidebarNavigation from './SidebarNavigation';

export default function Sidebar(props) {
  const { navigation = [], title, grouped = false, header } = props;

  return (
    <div className="w-72 hidden lg:block z-10">
      <nav className="flex flex-col -mr-0.5 border-r-2 border-cs-gray-100 py-8 bg-white overflow-y-auto h-screen space-y-8">
        {/*<div className="hidden lg:flex lg:flex-shrink-0 bg-white">*/}
        {/*  <div className="flex flex-col w-64 border-r border-gray-200 space-y-8">*/}
        {title && (
          <NavigationGroup>
            <NavigationHeader>
              {title}
            </NavigationHeader>
          </NavigationGroup>
        )}
        {header}
        {grouped ? (
          <SidebarNavigationGrouped navigation={navigation} />
        ) : (
          <SidebarNavigation navigation={navigation} />
        )}
      </nav>
    </div>
  )
}
