import { useNavigate, useParams } from 'react-router-dom';

export default function usePageRouter() {
  const navigate = useNavigate();
  const location = window.location;
  const params = useParams();

  return {
    navigate: navigate,
    history: {
      push: navigate
    },
    location,
    match: {
      params
    }
  };
}
