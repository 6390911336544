import React from 'react';
import { FolderOpenIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import PropTypes from 'prop-types';

EmptyState.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  children: PropTypes.node
};

export default function EmptyState(props) {
  const { title, subtitle, children, icon } = props;

  const Icon = icon || FolderOpenIcon;

  return (
    <div className="w-full relative overflow-hidden bg-white shadow-cs-flat">
      <div className="absolute left-1/2 top-32">
        {/*<DocumentIcon className="absolute z-10 left-[calc(50%-24px)] top-[calc(50%-24px)] h-12 w-12 text-cs-orange-500" />*/}
        <Icon className="absolute z-10 left-[calc(50%-20px)] top-[calc(50%-20px)] h-10 w-10 text-cs-orange-500" />
        <div className="absolute left-[calc(50%-48px)] top-[calc(50%-48px)] h-24 w-24 bg-cs-orange-100 rounded-full" />
        <div className="absolute left-[calc(50%-80px)] top-[calc(50%-80px)] h-40 w-40 border border-cs-gray-300 border-opacity-30 rounded-full" />
        <div className="absolute left-[calc(50%-112px)] top-[calc(50%-112px)] h-56 w-56 border border-cs-gray-300 border-opacity-20 rounded-full" />
        <div className="absolute left-[calc(50%-144px)] top-[calc(50%-144px)] h-72 w-72 border border-cs-gray-300 border-opacity-10 rounded-full" />
        <div className="absolute left-[calc(50%-192px)] top-[calc(50%-192px)] h-96 w-96 border border-cs-gray-300 border-opacity-10 rounded-full" />
      </div>
      <div className="relative z-10 text-center border-2 border-cs-gray-200 border-dashed rounded-lg p-12 space-y-6 pt-48x pt-56 pt-64x">
        <div className="space-y-1 max-w-xl mx-auto">
          <h3 className={classNames(
            'text-lg',
            // 'text-2xl',
            'text-cs-gray-900 font-semibold'
          )}>
            {title}
          </h3>
          <div className={classNames(
            'text-sm',
            // 'text-base',
            'text-cs-gray-500 font-normal'
          )}>
            {subtitle}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
