import _ from 'lodash';

import extractArrayFromQuery from './extractArrayFromQuery';
import addOrRemoveNumberFromArray from './addOrRemoveNumberFromArray';

export default function insertOrRemoveNumberFromArray(query, param, value) {
  const extractedArray = extractArrayFromQuery(query, param);
  const updatedArray = addOrRemoveNumberFromArray(extractedArray, value);
  return _.extend({}, query, {
    [param]: updatedArray.join(',')
  });
}
