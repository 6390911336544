import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../../utils/classNames';

export default function Entry(props) {
  const { title, description } = props;

  return (
    <div className="space-y-0.5">
      <div className="text-base text-cs-gray-900">
        {title}
      </div>
      {description && (
        <div className="text-base text-cs-gray-500">
          {description}
        </div>
      )}
    </div>
  );
}
