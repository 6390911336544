import React, { useState } from 'react';
import { parse, stringify } from 'query-string';
import moment from 'moment-timezone';
import { PayloadStates } from '@lore/utils';
import useStewardshipFilter from '../../projects/useStewardshipFilter';
import REQUIRED_FIELDS from '../../projects/REQUIRED_FIELDS';
import getYear from '../../dashboard/getYear';
import { useConnect } from '../../../../hooks/@lore/query-connect';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10 } = props;

  const [timestamp] = useState(moment().toISOString());
  const stewardshipFilter = useStewardshipFilter();

  const year = getYear(moment());
  const start = moment(`${year}-07-01T12:00:00.000-00:00`).toISOString();
  const end = moment(`${year + 1}-07-01T12:00:00.000-00:00`).toISOString();

  return useConnect('project.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          createdAt: {
            $gte: start
          },
          $or: stewardshipFilter
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    },
    exclude: function(model) {
      return model.state === PayloadStates.DELETED;
    }
  }, {
    keepPreviousData: true
  });
}
