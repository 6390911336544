import React from 'react';
import classNames from 'classnames';

export default function FilterButtonCount(props) {
  const {
    count = 0,
    position = true,
    border = true,
    className
  } = props;

  if (count > 0) {
    return (
      <div className={classNames(
        position && 'absolute -top-1.5 -right-1.5',
        'flex items-center justify-center',
        // border && '-m-0.5 border-2 border-white',
        border && '-m-px border border-white',
        'bg-cs-gray-900 rounded-full w-6 h-6',
        'text-white text-xs',
        className
      )}>
        {count}
      </div>
    );
  }

  return null;
}
