import React  from 'react';
import PropTypes from 'prop-types';
import classNames from '../../../../utils/classNames';

Option.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default function Option(props) {
  const {
    title,
    subtitle,
    icon,
    selected,
    onClick,
    color
  } = props;

  const colorMap = {
    green: {
      icon: 'bg-green-500',
      selected: 'bg-green-50 border-green-500',
      unselected: 'border-gray-100 bg-white hover:bg-gray-50 hover:shadow-md'
    },
    red: {
      icon: 'bg-red-500',
      selected: 'bg-red-50 border-red-500',
      unselected: 'border-gray-100 bg-white hover:bg-gray-50 hover:shadow-md'
    }
  }

  const colors = colorMap[color] || {
    icon: 'bg-primary',
    selected: 'bg-orange-50 border-accent',
    unselected: 'border-gray-100 bg-white hover:bg-orange-50 hover:shadow-md'
  };

  return (
    <div
      className={classNames(
        'flex shadow rounded-xl border overflow-hidden cursor-pointer p-6 space-x-5',
        // selected ? 'border border-accent bg-orange-50' : 'bg-white',
        selected ? colors.selected : colors.unselected
      )}
      onClick={onClick}
    >
      <div>
        <div className={classNames(
          'p-3 rounded-full',
          colors.icon
        )}>
          <props.icon className="w-8 h-8 text-white" />
        </div>
      </div>
      <div className="flex-1">
        <h2 className="font-semibold text-gray-700">
          {title}
        </h2>
        <div className="text-sm font-lightx text-gray-500">
          {subtitle}
        </div>
      </div>
    </div>
  );
}
