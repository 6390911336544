import React from 'react';
import Heading from './Heading';
import classNames from '../../../utils/classNames';

export default function Section(props) {
  const {
    title,
    subtitle,
    children,
    subdivide = false,
    className = ''
  } = props;

  return (
    <div className={`px-4 pt-5 pb-5 sm:px-0 sm:pt-0 ${className}`}>
      <dl className="space-y-8 px-4">
        <div className={classNames(
          subdivide ? 'divide-y divide-gray-200' : '',
          'space-y-6'
        )}>
          <Heading
            title={title}
            subtitle={subtitle}
          />
          {children}
        </div>
      </dl>
    </div>
  )
}
