import React from 'react';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import colors from 'tailwindcss/colors';
import LineChart from '../../dashboard/_charts/LineChart';
import ChartLoader from '../../dashboard/_charts/ChartLoader';
import usePartners from './_hooks/usePartners';
import useInactivePartners from './_hooks/useInactivePartners';
import useSleepingPartners from './_hooks/useSleepingPartners';
import useIncompletePartners from './_hooks/useIncompletePartners';
import useActivePartners from './_hooks/useActivePartners';

export default function PartnerGrowthByMonth(props) {
  const { account, timestamps, empty, schoolStart, currentTimestamp } = props;

  const partners = timestamps.map(function(timestamp, index) {
    if (moment(currentTimestamp).diff(timestamp.start) < 0) {
      return {
        ...timestamp,
        results: {
          new: empty(),
          inactive: empty(),
          sleeping: empty(),
          incomplete: empty(),
          active: empty()
        }
      }
    }

    return {
      ...timestamp,
      results: {
        new: usePartners(account, timestamp.start, timestamp.end),
        inactive: useInactivePartners(account, timestamp.start, timestamp.end),
        sleeping: useSleepingPartners(account, timestamp.start, timestamp.end, currentTimestamp),
        incomplete: useIncompletePartners(account, timestamp.start, timestamp.end, currentTimestamp),
        active: useActivePartners(account, timestamp.start, timestamp.end, currentTimestamp)
      }
    }
  });

  const loading = _.reduce(partners, function(result, data) {
    return (
      result ||
      data.results.new.state === PayloadStates.FETCHING ||
      data.results.inactive.state === PayloadStates.FETCHING ||
      data.results.sleeping.state === PayloadStates.FETCHING ||
      data.results.incomplete.state === PayloadStates.FETCHING ||
      data.results.active.state === PayloadStates.FETCHING
    );
  }, false);

  if (loading) {
    return (
      <ChartLoader
        title="Partner signups"
        subtitle=""
      />
    );
  }

  const series = [
    {
      name: 'Signups',
      data: partners.map(e => e.results.new.meta.totalCount)
    },
    {
      name: 'Active',
      data: partners.map(e => e.results.active.meta.totalCount)
    },
    {
      name: 'Sleeping',
      data: partners.map(e => e.results.sleeping.meta.totalCount)
    },
    {
      name: 'Inactive',
      data: partners.map(e => e.results.inactive.meta.totalCount)
    },
    {
      name: 'Incomplete',
      data: partners.map(e => e.results.incomplete.meta.totalCount)
    }
  ];

  return (
    <LineChart
      title="Partner signups"
      subtitle="Over school year"
      labels={partners.map(e => e.label)}
      series={series}
      colors={[
        colors.gray['400'],
        colors.emerald['400'],
        colors.blue['400'],
        colors.red['400'],
        colors.yellow['400']
      ]}
    />
  );
}
