import React from 'react';
import PropTypes from 'prop-types';

export default function Section(props) {
  const { title, subtitle, children, hideIfNoChildren = false } = props;

  if (hideIfNoChildren) {
    const result = React.Children.map(children, function(child) {
      return !!child.type(child.props);
    });

    if (!_.find(result)) {
      return null;
    }
  }

  return (
    <div className="space-y-4">
      <div className="space-y-1">
        <h4 className="text-2xl text-gray-700 font-bold">
          {title}
        </h4>
        {subtitle && (
          <div className="text-lg text-gray-700">
            {subtitle}
          </div>
        )}
      </div>
      {children}
    </div>
  );
}
