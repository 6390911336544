import React from 'react';

export default function NavigationHeader(props) {
  const { children } = props;

  return (
    <h2 className="text-2xl font-semibold text-cs-gray-900">
      {children}
    </h2>
  );
}
