import React, { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import Note from '../../../../components/Note';
import { UseQueryConnect } from '../../../../../hooks/@lore/query-connect';

export default function Messages(props) {
  const { project, question } = props;

  const [expanded, setExpanded] = useState(false);

  const messages = useConnect('message.find', {
    where: {
      eager: {
        $where: {
          $and: [
            {
              $or: [
                { senderId: project.data.creatorId },
                { senderId: question.data.userId }
              ]
            },
            {
              $or: [
                { recipientId: project.data.creatorId },
                { recipientId: question.data.userId }
              ]
            }
          ]
        }
      }
    },
    pagination: {
      order: 'createdAt desc',
      pageSize: 100
    }
  });

  const MIN_COUNT = 2;

  const _data = expanded ? messages.data : messages.data.slice(0, MIN_COUNT);

  return (
    <div className="px-6x">
      <div className="flex justify-between text-base font-semibold">
        <div className="flex space-x-2">
          <div className="text-gray-700">
            Recent Messages ({messages.data.length})
          </div>
          {messages.data.length > MIN_COUNT && (
            <div className="text-blue-500 hover:text-blue-400 cursor-pointer" onClick={() => setExpanded(!expanded)}>
              {expanded ? 'Show less' : 'Show more'}
            </div>
          )}
        </div>
      </div>
      {messages.data.length > 0 && (
        <div className="flow-root mt-2">
          <ul className="-mb-4">
            {_data.map(function(message, index) {
              return (
                <UseQueryConnect key={message.id || message.cid} _key="user.byId" params={{
                  id: message.data.senderId
                }}>
                  {function(creator) {
                    return (
                      <li>
                        <div className="relative pb-4">
                          {index !== messages.data.length - 1 ? (
                            <span className="absolute top-5 left-3 -ml-px h-full max-h-6 w-px bg-gray-200"
                                  aria-hidden="true"/>
                          ) : null}
                          <Note
                            model={{
                              data: {
                                ...message.data,
                                text: message.data.content
                              }
                            }}
                            title={`${creator.data.firstName} ${creator.data.lastName}`}
                            imageSrc={creator.data.avatarUrl}
                          />
                        </div>
                      </li>
                    );
                  }}
                </UseQueryConnect>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
