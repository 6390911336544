import React from 'react';
import Network from '../../../components/Network';
import NavigationLogo from '../ThinNavigation/NavigationLogo';
import NavigationMenuDialogButton from '../ThinNavigation/NavigationMenuDialogButton';

export default function Navigation() {
  return (
    <div className="flex px-5 py-4 space-x-4 bg-white justify-between">
      <div className="flex space-x-4">
        <NavigationLogo />
        <Network showName={false} />
      </div>
      <NavigationMenuDialogButton />
    </div>
  );
}
