import React  from 'react';
import PropTypes from 'prop-types';
import Avatar from '../Avatar';
import { useUser } from '@lore/auth';
import { getDistanceFromLatLonInMiles } from './utils';
import VirtualOnlyBadge from './VirtualOnlyBadge';
import classNames from '../../utils/classNames';
import isSleeping from '../../utils/isSleeping';
import SleepingBadge from './SleepingBadge';
import InactiveBadge from './InactiveBadge';

VirtualAvatar.propTypes = {
  user: PropTypes.object.isRequired
};

export default function VirtualAvatar(props) {
  const { user, size = 32 } = props;

  const currentUser = useUser();

  let distance = getDistanceFromLatLonInMiles(user.data, currentUser.data);

  let isAsleep = isSleeping(user);

  // distance = 100;

  return (
    <div className="relative flex items-center">
      {user.data.inactive && (
        <div className="absolute z-20 left-0 right-0 mx-auto bottom-0.5">
          <InactiveBadge />
        </div>
      )}
      {isAsleep && !user.data.inactive && (
        <div className="absolute z-20 left-0 right-0 mx-auto bottom-0.5">
          <SleepingBadge user={user} />
        </div>
      )}
      {false && distance > 50 && (
        <>
          <div className="bg-primary absolute z-0 rounded-full left-0 right-0 mx-auto" style={{ width: `${size*4}px`, height: `${size*4}px` }}/>
          <div className="absolute z-20 left-0 right-0 mx-auto -bottom-1">
            <VirtualOnlyBadge />
          </div>
        </>
      )}
      <Avatar size={size} className={classNames(
        'mx-auto z-10 relative',
        distance > 50 && 'p-2'
      )} src={user.data.avatarUrl} />
    </div>
  );
}
