import React from 'react';
import PropTypes from 'prop-types';
import { EyeIcon } from '@heroicons/react/24/outline';
import StringField from '../../forms/_fields_v2/StringField';
import { OutlineButton } from '../../@communityshare/ui/Button';
import { SolidButton } from '../../@communityshare/ui/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';
import FormField from '../../forms/_common/FormField';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose,
    user
  } = props;

  if (data.inactive) {
    return (
      <FormTemplate
        icon={(
          <div className="flex justify-center mb-4">
            <EyeIcon className="text-cs-orange-500 h-12 w-12" />
          </div>
        )}
        title="Make profile active"
        subtitle={(
          <div className="space-y-4">
            <div>
              You are about to make the profile
              for <span className="font-semibold text-cs-gray-900">{user.data.firstName} {user.data.lastName}</span> active.
            </div>
            <div>
              Doing so will allow them to log in, will show their profile
              in search results, and will allows others to message them.
            </div>
            <div>
              If you are sure you want to do this,
              type <span className="font-semibold text-cs-gray-900">ACTIVE</span> into the field below.
            </div>
          </div>
        )}
        onClose={onClose}
        alert={alert}
        body={(
          <>
            <FormField name="confirmation" data={data} errors={showErrors && errors} onChange={onChange}>
              {(field) => (
                <StringField
                  label="Confirmation"
                  placeholder="Type ACTIVE..."
                  // description="Some text about a thing I like"
                  value={field.value}
                  error={field.error}
                  onChange={field.onChange}
                />
              )}
            </FormField>
          </>
        )}
        footer={(
          <FormTemplateButtonFooter>
            <OutlineButton onClick={onClose}>
              Cancel
            </OutlineButton>
            <SolidButton onClick={onSubmit}>
              Make profile active
            </SolidButton>
          </FormTemplateButtonFooter>
        )}
      />
    );
  }

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <EyeIcon className="text-cs-orange-500 h-12 w-12" />
        </div>
      )}
      title="Make profile inactive"
      subtitle={(
        <div className="space-y-4">
          <div>
            You are about to make the profile
            for <span className="font-semibold text-cs-gray-900">{user.data.firstName} {user.data.lastName}</span> inactive.
          </div>
          <div>
            Doing so will prevent them from being able to log in, will hide their profile
            in search results, and will prevent others from messaging them.
          </div>
          <div>
            If you are sure you want to do this,
            type <span className="font-semibold text-cs-gray-900">INACTIVE</span> into the field below.
          </div>
        </div>
      )}
      onClose={onClose}
      alert={alert}
      body={(
        <>
          <FormField name="confirmation" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="Confirmation"
                placeholder="Type INACTIVE..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
        </>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Make profile inactive
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
