import React from 'react';
import { useConnect } from '@lore/query-connect';
import BaseCreator from '../../../components_profiles/BaseCreator';

export default function Creator(props) {
  const { offer } = props;

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  return (
    <BaseCreator
      title="Partner / Creator"
      creator={creator}
    />
  );
}
