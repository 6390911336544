import _ from 'lodash';

export default {

  getFullyQualifiedUrl: function(url) {
    if (
      url.startsWith('http://') ||
      url.startsWith('https://')
    ) {
      return url;
    }

    return `http://${url}`;
  }

};
