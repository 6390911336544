import React  from 'react';

export default function Description(props) {
  const { event } = props;

  if (!_.trim(event.data.interactionDescription)) {
    return null;
  }

  return (
    <div className="text-base text-gray-500 line-clamp-2">
      {event.data.interactionDescription}
    </div>
  );
}
