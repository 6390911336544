import React from 'react';
import useRouter from '../../hooks/useRouter';
import changePage from '../../utils/changePage';
import useCurrentPage from '../../hooks/useCurrentPage';
import PaginationLinks from './PaginationLinks';

export default function Pagination(props) {
  let {
    pageSize,
    totalCount,
    onNavigate
  } = props;

  const { location, history } = useRouter();

  const currentPage = useCurrentPage();

  if (totalCount/pageSize > 1) {
    return (
      <div className="flex items-center justify-between">
        <PaginationLinks
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onNavigate={function(pageNumber) {
            changePage(history, location, pageNumber);
            onNavigate && onNavigate();
          }}
        />
      </div>
    );
  }

  return null;
}
