import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Project from '../../projects/Project';
import CreatorPhone from '../../projects/_fields/CreatorPhone';
import CreatorEmail from '../../projects/_fields/CreatorEmail';
import useQuery from './useQuery';
import CardChildrenWrapper from '../../_common_results/CardChildrenWrapper';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Projects']}
      title="Wants Contact"
      description={(
        <div className="space-y-2">
          <div>
            These are projects where an educator mapped out an idea and then selected that they'd
            like to talk to you. They might have a question, an idea of where to outreach, or need
            more help planning out a project. CommunityShare recommends contacting the educator as
            soon as possible.
          </div>
          <div>
            Educators will stay on this list until they mark their project completed
            or cancelled, or until the end date elapses. You can make notes visible only
            to your team.
          </div>
        </div>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(project) {
        return (
          <Project key={project.id} project={project}>
            <CardChildrenWrapper>
              <div className="grid grid-cols-2 gap-4 w-full">
                <CreatorPhone project={project} />
                <CreatorEmail project={project} />
              </div>
            </CardChildrenWrapper>
          </Project>
        );
      }}
    />
  );
}
