import React from 'react';
import Invitation from './Invitation';
import useQuery from './useQuery';

export default function useMapping(props) {
  const models = useQuery(props);

  const type = 'invitation';

  const entries = models.data.map((value, index) => ({
    type: type,
    value: (
      <Invitation
        key={`${type}-${index}`}
        invitation={value}
      />
    ),
    data: value,
    timestamp: value.data.createdAt
  }));

  return {
    models,
    entries
  };
}
