import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Avatar from '../../../../components/Avatar';
import Loader from '../../../../components/Loader';
import Card from '../../../../components/Card';
import EducatorProjectEvaluation from '../../../../pages/evaluations/EducatorProjectEvaluation';

export default function EducatorEvaluation(props) {
  const { project, children, alignChildren = "bottom" } = props;

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  const evaluation = useConnect('evaluationProjectEducator.first', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          userId: creator.id
        }
      }
    }
  });

  if (
    creator.state === PayloadStates.FETCHING ||
    evaluation.state === PayloadStates.FETCHING
  ) {
    return (
      <Card className="flex p-2.5 w-full">
        <Loader />
      </Card>
    );
  }

  /*
   * If evaluation exists, display it.
   */
  if (evaluation.state === PayloadStates.RESOLVED) {
    return (
      <EducatorProjectEvaluation
        evaluation={evaluation}
        project={project}
        children={children}
        alignChildren={alignChildren}
      />
    );
  }

  /*
   * If project was cancelled there will not be any evaluations sent.
   */
  if (project.data.cancelled) {
    return (
      <Card className="overflow-hidden divide-y divide-gray-200">
        {alignChildren === 'top' && children}
        <div className="flex justify-between p-4">
          <div className="flex flex-1 items-center space-x-4">
            <Avatar size={12} src={creator.data.avatarUrl}/>
            <div className="flex flex-col">
              <div className="text-lg font-semibold">
                {creator.data.firstName} {creator.data.lastName}
              </div>
              <div className="text-sm text-gray-500 space-x-1">
                <span>Creator</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col text-right">
            <div className="text-lg font-semibold text-red-500 space-x-1">
              Cancelled
            </div>
            <div className="flex text-sm text-gray-500 space-x-1">
              Project was cancelled. No evaluations will be sent.
            </div>
          </div>
        </div>
        {alignChildren === 'bottom' && children && (
          <div className="flex p-4">
            {children}
          </div>
        )}
      </Card>
    );
  }

  /*
   * If we reached this point, it means the project is complete, but no evaluation was
   * created. Steward should contact support to have them investigate and create the
   * missing evaluation.
   */
  if (
    project.data.completed &&
    evaluation.state === PayloadStates.NOT_FOUND
  ) {
    return (
      <Card className="overflow-hidden divide-y divide-gray-200">
        {alignChildren === 'top' && children}
        <div className="flex justify-between p-4">
          <div className="flex flex-1 items-center space-x-4">
            <Avatar size={12} src={creator.data.avatarUrl}/>
            <div className="flex flex-col">
              <div className="text-lg font-semibold">
                {creator.data.firstName} {creator.data.lastName}
              </div>
              <div className="text-sm text-gray-500 space-x-1">
                <span>Creator</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col text-right">
            <div className="text-lg font-semibold text-red-500 space-x-1">
              <span>Evaluation missing</span>
            </div>
            <div className="flex text-sm text-gray-500 space-x-1">
              This should not happen. Contact support and ask them to investigate.
            </div>
          </div>
        </div>
        {alignChildren === 'bottom' && children && (
          <div className="flex p-4">
            {children}
          </div>
        )}
      </Card>
    );
  }

  return (
    <Card className="overflow-hidden divide-y divide-gray-200">
      {alignChildren === 'top' && children}
      <div className="flex justify-between p-4">
        <div className="flex flex-1 items-center space-x-4">
          <Avatar size={12} src={creator.data.avatarUrl}/>
          <div className="flex flex-col">
            <div className="text-lg font-semibold">
              {creator.data.firstName} {creator.data.lastName}
            </div>
            <div className="text-sm text-gray-500 space-x-1">
              <span>Creator</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col text-right">
          <div className="text-lg font-semibold text-gray-700 space-x-1">
            <span>Project not complete</span>
          </div>
          <div className="flex text-sm text-gray-500 space-x-1">
            Evaluation will be created once the project is marked complete.
          </div>
        </div>
      </div>
      {alignChildren === 'bottom' && children && (
        <div className="flex p-4">
          {children}
        </div>
      )}
    </Card>
  );
};
