import React from 'react';
import classNames from 'classnames';
import UserPhone from '../../../dialogs_new/project_manage/project-manage-evaluations/Question/UserPhone';
import UserEmail from '../../../dialogs_new/project_manage/project-manage-evaluations/Question/UserEmail';
import { useUser } from '@lore/auth';
import { useConnect } from '../../../../hooks/@lore/query-connect';

export default function EvaluationNotStartedColumn(props) {
  const { evaluation, onSendReminder } = props;

  const currentUser = useUser();

  const user = useConnect('user.byId', {
    id: evaluation.data.userId
  });

  return (
    <div className="flex flex-col text-right">
      <div
        className={classNames(
          'text-lg font-semibold text-yellow-700 space-x-1',
          currentUser.data.isSuperuser && 'hover:text-yellow-500 cursor-pointer'
        )}
        onClick={currentUser.data.isSuperuser ? onSendReminder : undefined}
      >
        <span>Not Started</span>
      </div>
      <div className="flex text-sm text-gray-500 space-x-6">
        <UserPhone user={user} />
        <UserEmail user={user} />
      </div>
    </div>
  );
};
