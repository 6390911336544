import React from 'react';
import PropTypes from 'prop-types';
import useActiveProjectsForUser from '../useActiveProjectsForUser';
import usePastProjectsForUser from '../usePastProjectsForUser';
import useActiveEventsForUser from '../useActiveEventsForUser';
import usePastEventsForUser from '../usePastEventsForUser';
import useActiveOffersForUser from '../useActiveOffersForUser';
import usePastOffersForUser from '../usePastOffersForUser';
import SectionHeader from '../../../components_profiles/SectionHeader';
import Offer from '../../offers/Offer';

PastOffers.propTypes = {
  user: PropTypes.object.isRequired
};

export default function PastOffers(props) {
  const { user } = props;

  const activeProjects = useActiveProjectsForUser(user);
  const pastProjects = usePastProjectsForUser(user);
  const activeEvents = useActiveEventsForUser(user);
  const pastEvents = usePastEventsForUser(user);
  const activeOffers = useActiveOffersForUser(user);
  const pastOffers = usePastOffersForUser(user);

  if (
    // activeProjects.data.length > 0 ||
    // pastProjects.data.length > 0 ||
    // activeEvents.data.length > 0
    // pastEvents.data.length > 0
    // activeOffers.data.length > 0
    pastOffers.data.length > 0
  ) {
    return (
      <div className="space-y-4">
        <SectionHeader
          title="Past Offers"
          subtitle="Experiences I have offered to educators in the past."
        />
        <div className="grid grid-cols-1 gap-4">
          {pastOffers.data.map(function(offer) {
            return (
              <Offer
                key={offer.id || offer.cid}
                offer={offer}
              />
            );
          })}
        </div>
      </div>
    );
  }

  return null;
}
