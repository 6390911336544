import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import VirtualAvatar from '../../../components/User/VirtualAvatar';
import classNames from 'classnames';

ActiveEventBanner.propTypes = {
  event: PropTypes.object.isRequired
};

export default function ActiveEventBanner(props) {
  const { event } = props;

  const creator = useConnect('user.byId', {
    id: event.data.creatorId
  });

  return (
    <div className={classNames(
      'flex bg-cs-orange-500 bg-opacity-5 space-x-4 shadow-cs-flat',
      'px-6 py-5',
      '-m-px border border-cs-orange-500 rounded-cs-10',
      'w-full'
    )}>
      <div className="mb-4x">
        <VirtualAvatar size={14} user={creator} />
      </div>
      <div className="flex-1 space-y-0.5">
        <div className="text-lg font-semibold text-cs-orange-500">
          Do you know a good partner?
        </div>
        <div className="text-sm text-cs-orange-500 opacity-90">
          <span className="underline">View the event</span> and invite them to
          connect with {creator.data.firstName}.
        </div>
      </div>
    </div>
  );
}
