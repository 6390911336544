import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Event from '../../events/Event';
import Slots from '../../events/_fields/Slots';
import Filled from '../../events/_fields/Filled';
import useQuery from './useQuery';
import CardChildrenWrapper from '../../_common_results/CardChildrenWrapper';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 1000;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Events']}
      title="Needs partners"
      description={(
        <span>
          These are events where there are fewer partners planning to attend than the educator
          specified they needed. CommunityShare recommends inviting partners you think would
          be a good fit.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(event) {
        return (
          <Event key={event.id} event={event}>
            <CardChildrenWrapper>
              <div className="grid grid-cols-2 gap-4 w-full">
                <Slots event={event}/>
                <Filled event={event}/>
              </div>
            </CardChildrenWrapper>
          </Event>
        );
      }}
    />
  );
}
