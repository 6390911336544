import { useEffect } from 'react';
import PropTypes from 'prop-types';

Callback.propTypes = {
  callback: PropTypes.func.isRequired
}

export default function Callback(props) {
  const { callback } = props;

  useEffect(() => {
    callback();
  }, []);

  return null;
};
