import React from 'react';
import _ from 'lodash';

export default function DonutChartLegend(props) {
  const { labels = [], colors = [], series = [] } = props;

  const total = _.reduce(series, function(result, value) {
    return result + value;
  }, 0);

  // return (
  //   <div className="mt-8 grid grid-cols-1 sm:grid-cols-2x gap-y-1 gap-x-6">
  //     {labels.map(function(label, index) {
  //       const color = colors[index];
  //       const value = series[index];
  //       const percent = Math.floor(value / total * 100);
  //
  //       return (
  //         <div key={index} className="flex items-center justify-between">
  //           <div className="flex items-center space-x-2">
  //             <div className="h-3 w-3 rounded-full" style={{ backgroundColor: color }}/>
  //             <div className="text-sm text-cs-gray-500 text-nowrap">
  //               {label} ({value})
  //             </div>
  //           </div>
  //           <div className="text-sm text-cs-gray-900 font-semibold">
  //             {percent}%
  //           </div>
  //         </div>
  //       );
  //     })}
  //   </div>
  // );

  return (
    <div className="mt-8 grid grid-cols-1 sm:grid-cols-2x gap-y-1 gap-x-6">
      {labels.map(function(label, index) {
        const color = colors[index];
        const value = series[index];
        const percent = Math.floor(value / total * 100);

        return (
          <div key={index} className="flex items-centerx justify-between space-x-8">
            <div className="flex items-centerx space-x-2">
              <div className="h-3 w-3 rounded-full mt-1" style={{ backgroundColor: color }}/>
              <div className="text-sm text-cs-gray-500 text-nowrapx">
                {label} ({value})
              </div>
            </div>
            <div className="text-sm text-cs-gray-900 font-semibold">
              {percent}%
            </div>
          </div>
        );
      })}
    </div>
  );
}
