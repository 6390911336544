import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import SuccessMessage from '../../forms/_common/SuccessMessage';
import Form from './Form';
import DialogTemplate from '../_common_old/DialogTemplate';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

Dialog.propTypes = {
  stewardInvitation: PropTypes.object.isRequired
};

export default function Dialog(props) {
  const { stewardInvitation } = props;

  const [data, setData] = useState({
    // no fields
  });
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('stewardInvitation.destroy2');
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      props.onClose();
      props.onSubmit && props.onSubmit(request);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(stewardInvitation);
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
  }

  return (
    <DialogTemplate>
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          // text: [validators.isRequired]
        }}>
          {({errors, hasError}) => (
            <Form
              data={data}
              errors={errors}
              showErrors={showErrors}
              onChange={onChange}
              onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
              onClose={props.onClose}
              alert={error ? (
                <RequestError request={request}/>
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : (false && success) ? (
                <SuccessMessage>
                  Idea created.
                </SuccessMessage>
              ) : null}
              stewardInvitation={stewardInvitation}
            />
          )}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
