import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';

export default function useTags(type, options = {}) {
  const enabled = options.enabled || true;

  const tags = useConnect('tag.find', {
    pagination: {
      pageSize: 1000,
      order: 'name asc'
    }
  }, {
    enabled: enabled
  });

  return {
    state: tags.state,
    data: tags.data.filter(function(tag) {
      if (type) {
        return tag.data.type === type;
      }

      return true;
    })
  };
}
