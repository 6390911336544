import React from 'react';
import useEvaluationsProjectEducatorQuery from '../evaluations-project-educator/useQuery';
import useEvaluationsProjectPartnerQuery from '../evaluations-project-partner/useQuery';
import useEvaluationsEventEducatorQuery from '../evaluations-event-educator/useQuery';
import useEvaluationsEventPartnerQuery from '../evaluations-event-partner/useQuery';
import { PayloadStates } from '@lore/utils';

export default function useQuery(props) {
  const evaluationsProjectEducator = useEvaluationsProjectEducatorQuery(props);
  const evaluationsProjectPartner = useEvaluationsProjectPartnerQuery(props);
  const evaluationsEventEducator = useEvaluationsEventEducatorQuery(props);
  const evaluationsEventPartner = useEvaluationsEventPartnerQuery(props);

  if (
    evaluationsProjectEducator.state === PayloadStates.FETCHING ||
    evaluationsProjectPartner.state === PayloadStates.FETCHING ||
    evaluationsEventEducator.state === PayloadStates.FETCHING ||
    evaluationsEventPartner.state === PayloadStates.FETCHING
  ) {
    return {
      state: PayloadStates.FETCHING,
      data: [],
      meta: undefined
    };
  }

  return {
    state: PayloadStates.RESOLVED,
    data: [].concat(
      evaluationsProjectEducator.data,
      evaluationsProjectPartner.data,
      evaluationsEventEducator.data,
      evaluationsEventPartner.data
    ),
    meta: {
      totalCount: (
        evaluationsProjectEducator.meta.totalCount +
        evaluationsProjectPartner.meta.totalCount +
        evaluationsEventEducator.meta.totalCount +
        evaluationsEventPartner.meta.totalCount
      )
    }
  };
}
