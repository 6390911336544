import React  from 'react';
import { PayloadStates } from '@lore/utils';
import useUserProfileMissingFields from '../../../hooks/useUserProfileMissingFields';
import TextField from '../../projects/_fields/TextField';

export default function MissingFields(props) {
  const { user } = props;

  const missingFields = useUserProfileMissingFields(user);

  if (!missingFields) {
    return (
      <TextField label="Missing fields">
        ...
      </TextField>
    );
  }

  return (
    <TextField label="Missing fields">
      {missingFields.profile.map(function(field) {
        return field.label;
      }).concat(missingFields.interests.map(function(field) {
        return field.label;
      })).join(', ')}
    </TextField>
  );
}
