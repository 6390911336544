import React from 'react';
import PropTypes from 'prop-types';
import { OutlineButton, SolidButton } from '../../@communityshare/ui/Button';
import FormTemplate from '../_common_old/FormTemplate.v2';
import Avatar from '../../components/Avatar';
import FormSection from '../../forms/_common/FormSection';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  activityTypes: PropTypes.object,
  ages: PropTypes.object
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose,
    user,
    creator,
    emailPreview
  } = props;

  const promptMap = {
    // 0: `Great! What's your question?`,
    1: `Awesome! Many Partners are happy to offer their thoughts or feedback. Try to be as specific as possible to help them give you the feedback you need.`,
    // 2: `Exciting! Partners are much more likely to respond when they understand your need. Let's make it easy for them to say "yes"!`,
    2: `Partners are much more likely to respond when they understand your need. Let's make it easy for them to say "yes"!`,
    3: `Networking is a great way to build relationships! Why do you think this partner might have the right connections for you?`
  };

  return (
    <FormTemplate
      gray={true}
      icon={(
        <div className="flex justify-center mb-4">
          <Avatar size={32} src={user.data.avatarUrl} />
        </div>
      )}
      title={(
        <>
          Ready to connect {creator.data.firstName} with {user.data.firstName}?
        </>
      )}
      subtitle={(
        <>
          We'll email event details to the partner and ask them to reply to the
          educator directly. The educator can see and message the partner first if
          they log in to the platform.
        </>
      )}
      onClose={onClose}
      showClose={false}
      alert={alert}
      body={(
        <div className="space-y-8">
          <FormSection
            title="This is what we'll send to the partner."
          >
            <div className="bg-white border border-gray-300 shadow rounded-lg h-96 overflow-auto">
              {emailPreview}
            </div>
          </FormSection>
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
          <SolidButton disabled={!data.reason} onClick={onSubmit}>
            Send invite
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
