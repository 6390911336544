import React from 'react';
import PropTypes from 'prop-types';
import Creator from './Creator';
import AlertWarning from '../../_common/AlertWarning';
import AlertSuccess from '../../_common/AlertSuccess';
import AlertInfo from '../../_common/AlertInfo';
import moment from 'moment-timezone';
import useMissingFields from '../useMissingFields';

export default function CloseEventAlert(props) {
  const { event } = props;

  const diff = moment().diff(event.data.endDate, 'days');

  const { missingFields } = useMissingFields(event);

  const status = {
    Planning: (
      missingFields.data.length > 0 &&
      !(
        event.data.completed ||
        event.data.cancelled
      )
    ),
    Active: (
      missingFields.data.length === 0 &&
      diff < 0
    ),
    Expired: diff >= 0,
    Completed: event.data.completed,
    Cancelled: event.data.cancelled
  };

  // status = {
  //   Planning: true,
  //   Active: true,
  //   Expired: true,
  //   Completed: true,
  //   Cancelled: true
  // };

  if (status.Completed) {
    return (
      <AlertSuccess
        title="Event has been marked complete"
        subtitle="Evaluations may still be ongoing."
      />
    );
  }

  if (status.Cancelled) {
    return (
      <AlertSuccess
        title="Event has been cancelled"
        subtitle="Nothing to do here."
      />
    );
  }

  if (status.Active) {
    return (
      <AlertInfo
        title="Event is active"
        subtitle="It is visible to partners and they are able to be invited to participate."
      />
    );
  }

  if (status.Planning) {
    return (
      <AlertInfo
        title="Event is in the planning stage"
        subtitle="There are required fields that are missing."
      />
    );
  }

  if (status.Expired) {
    return (
      <AlertWarning
        title={`Date window elapsed ${diff} days ago.`}
        subtitle="Contact the educator to extend the date window or encourage them to close the event."
      >
        <Creator event={event} />
      </AlertWarning>
    );
  }

  return null;
};
