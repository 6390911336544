import getQueryUtils from '../../pages/offers/queryUtils';

export default function useDistanceFilterParam(search = {}) {
  const queryUtils = getQueryUtils(search, [
    'locationCondition'
  ]);

  const value = queryUtils.params.locationCondition;

  if (
    value.radius &&
    value.address &&
    value.latitude &&
    value.longitude
  ) {
    return {
      id: {
        $location: {
          radius: value.radius,
          latitude: value.latitude,
          longitude: value.longitude,
          // address: state.value.address
        }
      }
    };
  }

  return {};
}
