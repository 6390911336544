import React from 'react';
import SectionHeader from './SectionHeader';

export default function Section(props) {
  const { title, subtitle, children } = props;

  return (
    <div className="space-y-4">
      <SectionHeader
        title={title}
        subtitle={subtitle}
      />
      {children}
    </div>
  );
}
