import React from 'react';
import moment from 'moment-timezone';
import ShareLinkSection from '../../evaluations-project-partners/EvaluationProjectPartner/ShareLinkSection';
import PartnerProjectEvaluationSummarySection from './PartnerProjectEvaluationSummarySection';
import HeaderSection from '../../evaluations-project-partners/EvaluationProjectPartner/HeaderSection';

export default function PartnerProjectEvaluationSections(props) {
  const { evaluation, project } = props;

  const diff = moment(evaluation.data.updatedAt).unix() - moment(evaluation.data.createdAt).unix();

  return (
    <>
      <HeaderSection
        evaluation={evaluation}
        created={project.data.creatorId === evaluation.data.userId}
      />
      {!evaluation.data.completed && (
        <ShareLinkSection evaluation={evaluation} />
      )}
      {(evaluation.data.completed || diff > 100) && (
        <PartnerProjectEvaluationSummarySection evaluation={evaluation} />
      )}
    </>
  );
};
