import React from 'react';
import PropTypes from 'prop-types';
import ModelsContext from '../contexts/ModelsContext';
import CollectionsContext from '../contexts/CollectionsContext';

ConnectProvider.propTypes = {
  models: PropTypes.object.isRequired,
  collections: PropTypes.object.isRequired,
  context: PropTypes.object,
  children: PropTypes.any
};

export function ConnectProvider(props) {
  const {
    models,
    collections,
    children
  } = props;

  return (
    <ModelsContext.Provider value={models}>
      <CollectionsContext.Provider value={collections}>
        {children}
      </CollectionsContext.Provider>
    </ModelsContext.Provider>
  );
}

export default ConnectProvider;
