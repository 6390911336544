import React from 'react';
import PropTypes from 'prop-types';
import { ClockIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { OutlineButton } from '../../@communityshare/ui/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Blocker.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  blockerText: PropTypes.node,
  onClose: PropTypes.func
};

export default function Blocker(props) {
  const {
    title,
    subtitle,
    blockerText,
    onClose
  } = props;

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <ExclamationTriangleIcon className="text-red-500 h-12 w-12" />
        </div>
      )}
      title={title}
      // subtitle={subtitle}
      onClose={onClose}
      body={blockerText}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Close
          </OutlineButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
