import React from 'react';
import { Outlet, Route, createBrowserRouter, createRoutesFromElements, redirect } from 'react-router-dom';

/**
 * The AuthenticatedRoute provides an easy way to redirect the user
 * to a login experience if we don't know who they are.
 */

import UserIsAuthenticated from './src/decorators/UserIsAuthenticated';
import Master from './src/pages/Master';
import Layout from './src/pages/Layout';

/**
 * Routes are used to declare your view hierarchy
 * See: https://reacttraining.com/react-router/web/guides/quick-start
 */

import Login from './src/pages/Login';
import Signup from './src/pages/Signup';
import Logout from './src/pages/Logout';
import AuthCallback from './src/pages/AuthCallback';

import SelectCommunityLayout from './src/pages/select-community/Layout';

import NotAuthorized from './src/pages/unauthorized/Layout';
import NotFoundPage from './src/pages/NotFoundPage';

/*
 *  routes
 */

import DashboardRedirectLayout from './src/pages/dashboard-redirect/Layout';
import DashboardOverviewStudentsLayout from './src/pages/dashboard-overview/Layout.students';
import DashboardOverviewEducatorsLayout from './src/pages/dashboard-overview/Layout.educators';
import DashboardOverviewPartnersLayout from './src/pages/dashboard-overview/Layout.partners';
import DashboardOverviewProjectsLayout from './src/pages/dashboard-overview/Layout.projects';
import DashboardOverviewEventsLayout from './src/pages/dashboard-overview/Layout.events';
import DashboardOverviewOffersLayout from './src/pages/dashboard-overview/Layout.offers';
import DashboardOverviewEvaluationsLayout from './src/pages/dashboard-overview/Layout.evaluations';
import DashboardOverviewSchoolsLayout from './src/pages/dashboard-overview/Layout.schools';
import DashboardOverviewOrganizationsLayout from './src/pages/dashboard-overview/Layout.organizations';

import FeedLayout from './src/pages/feed/Layout';
import FeedMessagesLayout from './src/pages/feed-filtered/messages/Layout';
import FeedProjectsLayout from './src/pages/feed-filtered/projects/Layout';
import FeedNotesAllLayout from './src/pages/feed-filtered/notes-all/Layout';
import FeedEvaluationsAllLayout from './src/pages/feed-filtered/evaluations-all/Layout';
import FeedInvitationsAllLayout from './src/pages/feed-filtered/invitations-all/Layout';
import FeedEventsLayout from './src/pages/feed-filtered/events/Layout';
import FeedOffersLayout from './src/pages/feed-filtered/offers/Layout';
import FeedPartnersLayout from './src/pages/feed-filtered/partners/Layout';
import FeedEducatorsLayout from './src/pages/feed-filtered/educators/Layout';

import DashboardYearStudentsLayout from './src/pages/dashboard-year/Layout.students';
import DashboardYearEducatorsLayout from './src/pages/dashboard-year/Layout.educators';
import DashboardYearPartnersLayout from './src/pages/dashboard-year/Layout.partners';
import DashboardYearProjectsLayout from './src/pages/dashboard-year/Layout.projects';
import DashboardYearEventsLayout from './src/pages/dashboard-year/Layout.events';
import DashboardYearOffersLayout from './src/pages/dashboard-year/Layout.offers';
import DashboardYearEvaluationsLayout from './src/pages/dashboard-year/Layout.evaluations';
import DashboardYearSchoolsLayout from './src/pages/dashboard-year/Layout.schools';
import DashboardYearOrganizationsLayout from './src/pages/dashboard-year/Layout.organizations';

import ExploreProjectsLayout from './src/pages/projects/Layout';
import ExploreStarredProjectsLayout from './src/pages/projects-filtered/starred/Layout';
import ExploreExpiringProjectsLayout from './src/pages/projects-filtered/expiring/Layout';
import ExplorePartnersInvitedProjectsLayout from './src/pages/projects-filtered/partners-invited/Layout';
import ExplorePartnersConnectedProjectsLayout from './src/pages/projects-filtered/partners-connected/Layout';
import ExploreDraftProjectsLayout from './src/pages/projects-filtered/incomplete/Layout';
import ExploreNewProjectsLayout from './src/pages/projects-filtered/new/Layout';
import ExploreActiveProjectsLayout from './src/pages/projects-filtered/active/Layout';
import ExploreProjectsWantsContactLayout from './src/pages/projects-filtered/wants-contact/Layout';
import ExploreProjectsResourcesNeededLayout from './src/pages/projects-filtered/resources-needed/Layout';
import ExploreProjectsFundingRequestedLayout from './src/pages/projects-filtered/funding-requested/Layout';
import ExploreProjectsExpiredNotClosedLayout from './src/pages/projects-filtered/expired-not-closed/Layout';
import ExploreProjectsMissingEducatorEvaluationLayout from './src/pages/projects-filtered/missing-educator-evaluation/Layout';
import ExploreProjectsCompletedLayout from './src/pages/projects-filtered/completed/Layout';
import ExploreProjectsSchoolYearLayout from './src/pages/projects-filtered/school-year/Layout';

import ExploreEventsLayout from './src/pages/events/Layout';
import ExploreStarredEventsLayout from './src/pages/events-filtered/starred/Layout';
import ExplorePartnersEventsLayout from './src/pages/events-filtered/partners/Layout';
import ExploreDraftEventsLayout from './src/pages/events-filtered/draft/Layout';
import ExploreNewEventsLayout from './src/pages/events-filtered/new/Layout';
import ExploreActiveEventsLayout from './src/pages/events-filtered/active/Layout';
import ExploreSoonEventsLayout from './src/pages/events-filtered/soon/Layout';
import ExploreEventsExpiredNotClosedLayout from './src/pages/events-filtered/expired-not-closed/Layout';
import ExploreEventsMissingEducatorEvaluationLayout from './src/pages/events-filtered/missing-educator-evaluation/Layout';
import ExploreEventsCompletedLayout from './src/pages/events-filtered/completed/Layout';
import ExploreEventsSchoolYearLayout from './src/pages/events-filtered/school-year/Layout';

import ExploreOffersLayout from './src/pages/offers/Layout';
import ExploreStarredOffersLayout from './src/pages/offers-filtered/starred/Layout';
import ExploreNewOffersLayout from './src/pages/offers-filtered/new/Layout';
import ExploreActiveOffersLayout from './src/pages/offers-filtered/active/Layout';
import ExploreOffersSchoolYearLayout from './src/pages/offers-filtered/school-year/Layout';

import ExplorePartnersLayout from './src/pages/partners/Layout';
import ExplorePartnersEngagementLayout from './src/pages/partners-engagement/Layout';
import ExploreStarredPartnersLayout from './src/pages/partners-filtered/starred/Layout';
import ExploreIncompletePartnersLayout from './src/pages/partners-filtered/incomplete/Layout';
import ExplorePartnersNewLayout from './src/pages/partners-filtered/new/Layout';
import ExplorePartnersInactiveLayout from './src/pages/partners-filtered/inactive/Layout';
import ExplorePartnersSleepingLayout from './src/pages/partners-filtered/sleeping/Layout';
import ExplorePartnersNoInteractionsLayout from './src/pages/partners-filtered/no-interactions/Layout';
import ExplorePartnersMultipleEvaluationsLayout from './src/pages/partners-filtered/multiple-evaluations/Layout';
import ExplorePartnersMultipleHoursLayout from './src/pages/partners-filtered/multiple-hours/Layout';
import ExplorePartnersMultipleStudentsLayout from './src/pages/partners-filtered/multiple-students/Layout';
import ExplorePartnersMultipleDonationsLayout from './src/pages/partners-filtered/multiple-donations/Layout';

import ExploreEducatorsLayout from './src/pages/educators/Layout';
import ExploreEducatorsEngagementLayout from './src/pages/educators-engagement/Layout';
import ExploreStarredEducatorsLayout from './src/pages/educators-filtered/starred/Layout';
import ExploreIncompleteEducatorsLayout from './src/pages/educators-filtered/incomplete/Layout';
import ExploreEducatorsNewLayout from './src/pages/educators-filtered/new/Layout';
import ExploreEducatorsSleepingLayout from './src/pages/educators-filtered/sleeping/Layout';
import ExploreEducatorsInactiveLayout from './src/pages/educators-filtered/inactive/Layout';
import ExploreEducatorsNoneCreatedLayout from './src/pages/educators-filtered/none-created/Layout';
import ExploreEducatorsMultipleCompletedLayout from './src/pages/educators-filtered/multiple-completed/Layout';

import TeamStaffLayout from './src/pages/team-staff/Layout';
import TeamInvitationsLayout from './src/pages/team-invitations/Layout';

import EvaluationsRedirectLayout from './src/pages/evaluations-redirect/Layout';

import EvaluationsProjectEducatorLayout from './src/pages/evaluations-project-educators/Layout';
import EvaluationsProjectEducatorCompleteLayout from './src/pages/evaluations-project-educators-filtered/complete/Layout';
import EvaluationsProjectEducatorPendingLayout from './src/pages/evaluations-project-educators-filtered/pending/Layout';
import EvaluationsProjectEducatorPhotosLayout from './src/pages/evaluations-project-educators-filtered/photos/Layout';
import EvaluationsProjectEducatorTestimonialsLayout from './src/pages/evaluations-project-educators-filtered/testimonials/Layout';
import EvaluationsProjectEducatorStoriesLayout from './src/pages/evaluations-project-educators-filtered/stories/Layout';

import EvaluationsProjectPartnerLayout from './src/pages/evaluations-project-partners/Layout';
import EvaluationsProjectPartnerCompleteLayout from './src/pages/evaluations-project-partners-filtered/complete/Layout';
import EvaluationsProjectPartnerPendingLayout from './src/pages/evaluations-project-partners-filtered/pending/Layout';
import EvaluationsProjectPartnerContributionsLayout from './src/pages/evaluations-project-partners-filtered/contributions/Layout';
import EvaluationsProjectPartnerTestimonialsLayout from './src/pages/evaluations-project-partners-filtered/testimonials/Layout';

import EvaluationsEventEducatorLayout from './src/pages/evaluations-event-educators/Layout';
import EvaluationsEventEducatorCompleteLayout from './src/pages/evaluations-event-educators-filtered/complete/Layout';
import EvaluationsEventEducatorPendingLayout from './src/pages/evaluations-event-educators-filtered/pending/Layout';
import EvaluationsEventEducatorPhotosLayout from './src/pages/evaluations-event-educators-filtered/photos/Layout';
import EvaluationsEventEducatorTestimonialsLayout from './src/pages/evaluations-event-educators-filtered/testimonials/Layout';
import EvaluationsEventEducatorStoriesLayout from './src/pages/evaluations-event-educators-filtered/stories/Layout';

import EvaluationsEventPartnerLayout from './src/pages/evaluations-event-partners/Layout';
import EvaluationsEventPartnerCompleteLayout from './src/pages/evaluations-event-partners-filtered/complete/Layout';
import EvaluationsEventPartnerPendingLayout from './src/pages/evaluations-event-partners-filtered/pending/Layout';
import EvaluationsEventPartnerTestimonialsLayout from './src/pages/evaluations-event-partners-filtered/testimonials/Layout';


import SettingsScreeningLayout from './src/pages/settings-screening/Layout';
import SettingsTrainingLayout from './src/pages/settings-training/Layout';
import SettingsBannerLayout from './src/pages/settings-banner/Layout';
import SettingsGrantsLayout from './src/pages/settings-grants/Layout';

import LayoutSidebarNavigation from './src/pages/explore/LayoutSidebarNavigation';

export default createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={(
      <>
        {/*<RemoveLoadingScreen />*/}
        <Outlet />
      </>
    )}>
      {/* Redirects */}
      <Route index loader={() => redirect(`/select-community`)} />
      <Route path="/c/:subdomain" loader={({ params }) => redirect(`/c/${params.subdomain}/explore/partners`)} />
      <Route path="/c/:subdomain/dashboard" loader={({ params }) => redirect(`/c/${params.subdomain}/dashboard/redirect`)} />

      <Route path="/c/:subdomain/explore" loader={({ params }) => redirect(`/c/${params.subdomain}/explore/projects`)} />
      <Route path="/c/:subdomain/evaluations" loader={({ params }) => redirect(`/c/${params.subdomain}/evaluations/redirect`)} />

      <Route path="/c/:subdomain/explore/recommendations" loader={({ params }) => redirect(`/c/${params.subdomain}/explore/recommendations/projects`)} />
      <Route path="/c/:subdomain/team" loader={({ params }) => redirect(`/c/${params.subdomain}/team/staff`)} />
      <Route path="/c/:subdomain/settings" loader={({ params }) => redirect(`/c/${params.subdomain}/settings/screening`)} />

      <Route path="/c/:subdomain/feed" loader={({ params }) => redirect(`/c/${params.subdomain}/feed/1/all`)} />

      {/* Routes */}
      <Route path="/login" Component={Login} />
      <Route path="/signup" Component={Signup} />
      <Route path="/logout" Component={Logout} />
      <Route path="/auth/callback" Component={AuthCallback} />
      <Route path="/unauthorized" Component={NotAuthorized} />

      <Route Component={UserIsAuthenticated}>
        <Route Component={Master}>
          <Route path="/select-community" Component={SelectCommunityLayout} />
        </Route>
      </Route>

      {/* Authenticated Routes w/ Common Layout */}
      <Route Component={UserIsAuthenticated}>
        <Route Component={Master}>
          <Route Component={Layout}>
            {/* Dashboard */}
            <Route element={<LayoutSidebarNavigation type="dashboard"/>}>
              <Route path="/c/:subdomain/dashboard/redirect" Component={DashboardRedirectLayout} />
              <Route path="/c/:subdomain/dashboard/overview/students" Component={DashboardOverviewStudentsLayout} />
              <Route path="/c/:subdomain/dashboard/overview/educators" Component={DashboardOverviewEducatorsLayout} />
              <Route path="/c/:subdomain/dashboard/overview/partners" Component={DashboardOverviewPartnersLayout} />
              <Route path="/c/:subdomain/dashboard/overview/projects" Component={DashboardOverviewProjectsLayout} />
              <Route path="/c/:subdomain/dashboard/overview/events" Component={DashboardOverviewEventsLayout} />
              <Route path="/c/:subdomain/dashboard/overview/offers" Component={DashboardOverviewOffersLayout} />
              <Route path="/c/:subdomain/dashboard/overview/evaluations" Component={DashboardOverviewEvaluationsLayout} />
              <Route path="/c/:subdomain/dashboard/overview/schools" Component={DashboardOverviewSchoolsLayout} />
              <Route path="/c/:subdomain/dashboard/overview/organizations" Component={DashboardOverviewOrganizationsLayout} />

              <Route path="/c/:subdomain/dashboard/year/:year/students" Component={DashboardYearStudentsLayout} />
              <Route path="/c/:subdomain/dashboard/year/:year/educators" Component={DashboardYearEducatorsLayout} />
              <Route path="/c/:subdomain/dashboard/year/:year/partners" Component={DashboardYearPartnersLayout} />
              <Route path="/c/:subdomain/dashboard/year/:year/projects" Component={DashboardYearProjectsLayout} />
              <Route path="/c/:subdomain/dashboard/year/:year/events" Component={DashboardYearEventsLayout} />
              <Route path="/c/:subdomain/dashboard/year/:year/offers" Component={DashboardYearOffersLayout} />
              <Route path="/c/:subdomain/dashboard/year/:year/evaluations" Component={DashboardYearEvaluationsLayout} />
              <Route path="/c/:subdomain/dashboard/year/:year/schools" Component={DashboardYearSchoolsLayout} />
              <Route path="/c/:subdomain/dashboard/year/:year/organizations" Component={DashboardYearOrganizationsLayout} />
            </Route>

            {/* Feed */}
            <Route element={<LayoutSidebarNavigation type="feed"/>}>
              <Route path="/c/:subdomain/feed" Component={FeedLayout} />
              <Route path="/c/:subdomain/feed/:weeks/all" Component={FeedLayout} />
              <Route path="/c/:subdomain/feed/:weeks/messages" Component={FeedMessagesLayout} />
              <Route path="/c/:subdomain/feed/:weeks/projects" Component={FeedProjectsLayout} />
              <Route path="/c/:subdomain/feed/:weeks/events" Component={FeedEventsLayout} />
              <Route path="/c/:subdomain/feed/:weeks/offers" Component={FeedOffersLayout} />
              <Route path="/c/:subdomain/feed/:weeks/invitations" Component={FeedInvitationsAllLayout} />
              <Route path="/c/:subdomain/feed/:weeks/partners" Component={FeedPartnersLayout} />
              <Route path="/c/:subdomain/feed/:weeks/educators" Component={FeedEducatorsLayout} />
              <Route path="/c/:subdomain/feed/:weeks/notes" Component={FeedNotesAllLayout} />
              <Route path="/c/:subdomain/feed/:weeks/evaluations" Component={FeedEvaluationsAllLayout} />
            </Route>

            {/* Evaluations */}
            <Route path="/c/:subdomain/evaluations/redirect" Component={EvaluationsRedirectLayout} />
            <Route element={<LayoutSidebarNavigation type="evaluations"/>}>
              <Route path="/c/:subdomain/evaluations/:year/projects/educators" Component={EvaluationsProjectEducatorLayout} />
              <Route path="/c/:subdomain/evaluations/:year/projects/educators/complete" Component={EvaluationsProjectEducatorCompleteLayout} />
              <Route path="/c/:subdomain/evaluations/:year/projects/educators/pending" Component={EvaluationsProjectEducatorPendingLayout} />
              <Route path="/c/:subdomain/evaluations/:year/projects/educators/photos" Component={EvaluationsProjectEducatorPhotosLayout} />
              <Route path="/c/:subdomain/evaluations/:year/projects/educators/testimonials" Component={EvaluationsProjectEducatorTestimonialsLayout} />
              <Route path="/c/:subdomain/evaluations/:year/projects/educators/stories" Component={EvaluationsProjectEducatorStoriesLayout} />

              <Route path="/c/:subdomain/evaluations/:year/projects/partners" Component={EvaluationsProjectPartnerLayout} />
              <Route path="/c/:subdomain/evaluations/:year/projects/partners/complete" Component={EvaluationsProjectPartnerCompleteLayout} />
              <Route path="/c/:subdomain/evaluations/:year/projects/partners/pending" Component={EvaluationsProjectPartnerPendingLayout} />
              <Route path="/c/:subdomain/evaluations/:year/projects/partners/contributions" Component={EvaluationsProjectPartnerContributionsLayout} />
              <Route path="/c/:subdomain/evaluations/:year/projects/partners/testimonials" Component={EvaluationsProjectPartnerTestimonialsLayout} />

              <Route path="/c/:subdomain/evaluations/:year/events/educators" Component={EvaluationsEventEducatorLayout} />
              <Route path="/c/:subdomain/evaluations/:year/events/educators/complete" Component={EvaluationsEventEducatorCompleteLayout} />
              <Route path="/c/:subdomain/evaluations/:year/events/educators/pending" Component={EvaluationsEventEducatorPendingLayout} />
              <Route path="/c/:subdomain/evaluations/:year/events/educators/photos" Component={EvaluationsEventEducatorPhotosLayout} />
              <Route path="/c/:subdomain/evaluations/:year/events/educators/testimonials" Component={EvaluationsEventEducatorTestimonialsLayout} />
              <Route path="/c/:subdomain/evaluations/:year/events/educators/stories" Component={EvaluationsEventEducatorStoriesLayout} />

              <Route path="/c/:subdomain/evaluations/:year/events/partners" Component={EvaluationsEventPartnerLayout} />
              <Route path="/c/:subdomain/evaluations/:year/events/partners/complete" Component={EvaluationsEventPartnerCompleteLayout} />
              <Route path="/c/:subdomain/evaluations/:year/events/partners/pending" Component={EvaluationsEventPartnerPendingLayout} />
              <Route path="/c/:subdomain/evaluations/:year/events/partners/testimonials" Component={EvaluationsEventPartnerTestimonialsLayout} />
            </Route>

            {/* Settings */}
            <Route element={<LayoutSidebarNavigation type="settings"/>}>
              <Route path="/c/:subdomain/team/staff" Component={TeamStaffLayout} />
              <Route path="/c/:subdomain/team/invitations" Component={TeamInvitationsLayout} />

              <Route path="/c/:subdomain/settings/screening" Component={SettingsScreeningLayout} />
              <Route path="/c/:subdomain/settings/training" Component={SettingsTrainingLayout} />
              <Route path="/c/:subdomain/settings/banner" Component={SettingsBannerLayout} />
              <Route path="/c/:subdomain/settings/grants" Component={SettingsGrantsLayout} />
            </Route>

            {/* Projects */}
            <Route element={<LayoutSidebarNavigation type="projects"/>}>
              <Route path="/c/:subdomain/explore/projects" Component={ExploreProjectsLayout} />
              <Route path="/c/:subdomain/explore/projects/starred" Component={ExploreStarredProjectsLayout} />
              <Route path="/c/:subdomain/explore/projects/new" Component={ExploreNewProjectsLayout} />
              <Route path="/c/:subdomain/explore/projects/draft" Component={ExploreDraftProjectsLayout} />
              <Route path="/c/:subdomain/explore/projects/wants-contact" Component={ExploreProjectsWantsContactLayout} />
              <Route path="/c/:subdomain/explore/projects/partners-invited" Component={ExplorePartnersInvitedProjectsLayout} />
              <Route path="/c/:subdomain/explore/projects/partners-connected" Component={ExplorePartnersConnectedProjectsLayout} />
              <Route path="/c/:subdomain/explore/projects/active" Component={ExploreActiveProjectsLayout} />
              <Route path="/c/:subdomain/explore/projects/resources-needed" Component={ExploreProjectsResourcesNeededLayout} />
              <Route path="/c/:subdomain/explore/projects/funding-requested" Component={ExploreProjectsFundingRequestedLayout} />
              <Route path="/c/:subdomain/explore/projects/expiring" Component={ExploreExpiringProjectsLayout} />
              <Route path="/c/:subdomain/explore/projects/expired-not-closed" Component={ExploreProjectsExpiredNotClosedLayout} />
              <Route path="/c/:subdomain/explore/projects/missing-educator-evaluation" Component={ExploreProjectsMissingEducatorEvaluationLayout} />
              <Route path="/c/:subdomain/explore/projects/completed" Component={ExploreProjectsCompletedLayout} />
              <Route path="/c/:subdomain/explore/projects/school-year" Component={ExploreProjectsSchoolYearLayout} />
            </Route>

            {/* Events */}
            <Route element={<LayoutSidebarNavigation type="events"/>}>
              <Route path="/c/:subdomain/explore/events" Component={ExploreEventsLayout} />
              <Route path="/c/:subdomain/explore/events/starred" Component={ExploreStarredEventsLayout} />
              <Route path="/c/:subdomain/explore/events/new" Component={ExploreNewEventsLayout} />
              <Route path="/c/:subdomain/explore/events/active" Component={ExploreActiveEventsLayout} />
              <Route path="/c/:subdomain/explore/events/draft" Component={ExploreDraftEventsLayout} />
              <Route path="/c/:subdomain/explore/events/partners" Component={ExplorePartnersEventsLayout} />
              <Route path="/c/:subdomain/explore/events/soon" Component={ExploreSoonEventsLayout} />
              <Route path="/c/:subdomain/explore/events/expired-not-closed" Component={ExploreEventsExpiredNotClosedLayout} />
              <Route path="/c/:subdomain/explore/events/missing-educator-evaluation" Component={ExploreEventsMissingEducatorEvaluationLayout} />
              <Route path="/c/:subdomain/explore/events/completed" Component={ExploreEventsCompletedLayout} />
              <Route path="/c/:subdomain/explore/events/school-year" Component={ExploreEventsSchoolYearLayout} />
            </Route>

            {/* Offers */}
            <Route element={<LayoutSidebarNavigation type="offers"/>}>
              <Route path="/c/:subdomain/explore/offers" Component={ExploreOffersLayout} />
              <Route path="/c/:subdomain/explore/offers/starred" Component={ExploreStarredOffersLayout} />
              <Route path="/c/:subdomain/explore/offers/new" Component={ExploreNewOffersLayout} />
              <Route path="/c/:subdomain/explore/offers/active" Component={ExploreActiveOffersLayout} />
              <Route path="/c/:subdomain/explore/offers/school-year" Component={ExploreOffersSchoolYearLayout} />
            </Route>

            {/* Partners */}
            <Route element={<LayoutSidebarNavigation type="partners"/>}>
              <Route path="/c/:subdomain/explore/partners" Component={ExplorePartnersLayout} />
              <Route path="/c/:subdomain/explore/partners/engagement" Component={ExplorePartnersEngagementLayout} />
              <Route path="/c/:subdomain/explore/partners/starred" Component={ExploreStarredPartnersLayout} />
              <Route path="/c/:subdomain/explore/partners/new" Component={ExplorePartnersNewLayout} />
              <Route path="/c/:subdomain/explore/partners/inactive" Component={ExplorePartnersInactiveLayout} />
              <Route path="/c/:subdomain/explore/partners/sleeping" Component={ExplorePartnersSleepingLayout} />
              <Route path="/c/:subdomain/explore/partners/incomplete" Component={ExploreIncompletePartnersLayout} />
              <Route path="/c/:subdomain/explore/partners/no-interactions" Component={ExplorePartnersNoInteractionsLayout} />
              <Route path="/c/:subdomain/explore/partners/multiple-evaluations" Component={ExplorePartnersMultipleEvaluationsLayout} />
              <Route path="/c/:subdomain/explore/partners/multiple-hours" Component={ExplorePartnersMultipleHoursLayout} />
              <Route path="/c/:subdomain/explore/partners/multiple-students" Component={ExplorePartnersMultipleStudentsLayout} />
              <Route path="/c/:subdomain/explore/partners/multiple-donations" Component={ExplorePartnersMultipleDonationsLayout} />
            </Route>

            {/* Educators */}
            <Route element={<LayoutSidebarNavigation type="educators"/>}>
              <Route path="/c/:subdomain/explore/educators" Component={ExploreEducatorsLayout} />
              <Route path="/c/:subdomain/explore/educators/engagement" Component={ExploreEducatorsEngagementLayout} />
              <Route path="/c/:subdomain/explore/educators/starred" Component={ExploreStarredEducatorsLayout} />
              <Route path="/c/:subdomain/explore/educators/new" Component={ExploreEducatorsNewLayout} />
              <Route path="/c/:subdomain/explore/educators/sleeping" Component={ExploreEducatorsSleepingLayout} />
              <Route path="/c/:subdomain/explore/educators/inactive" Component={ExploreEducatorsInactiveLayout} />
              <Route path="/c/:subdomain/explore/educators/incomplete" Component={ExploreIncompleteEducatorsLayout} />
              <Route path="/c/:subdomain/explore/educators/none-created" Component={ExploreEducatorsNoneCreatedLayout} />
              <Route path="/c/:subdomain/explore/educators/multiple-completed" Component={ExploreEducatorsMultipleCompletedLayout} />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="*" Component={NotFoundPage} />
    </Route>
  )
);
