import getUploadInfo from './getUploadInfo';

export default function getUrlForUpload(upload) {
  const { extension } = getUploadInfo(upload.data.name);

  let url = upload.data.url;
  const origin = 'https://cdn.filestackcontent.com';

  if (
    extension === 'heic' &&
    _.startsWith(url, origin)
  ) {
    /*
     * Converts a URL like this:
     * https://cdn.filestackcontent.com/NsUsL5EJRnWWDKybGaID
     * to a URL like this:
     * https://cdn.filestackcontent.com/output=format:jpg/NsUsL5EJRnWWDKybGaID
     */
    url = url.split(origin).join(`${origin}/output=format:jpg`);
  }

  return url;
};
