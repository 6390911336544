import React from 'react';
import classNames from 'classnames';

export default function FeedHoverText(props) {
  const { children, onClick } = props;

  return (
    <span className={classNames(
      'font-semibold text-gray-900',
      onClick && 'hover:text-cs-gray-500 cursor-pointer'
    )} onClick={onClick}>
      {children}
    </span>
  );
}
