import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '../../../hooks/@lore/query-connect';
import ReminderDialogTemplate from '../_common_reminders/ReminderDialogTemplate';

Dialog.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Dialog(props) {
  const { project } = props;

  const reminders = useConnect('reminder.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          type: {
            $in: [
              'CompleteProject',
              'Complete'
            ]
          }
        }
      }
    }
  });

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  let _data = {
    type: 'Complete',
    projectId: project.id,
    recipientId: project.data.creatorId
  };

  return (
    <ReminderDialogTemplate
      title="Send reminder to finish describing project"
      subtitle={(
        <span>
          This will send an email to {creator.data.firstName} nudging them to complete their project.
        </span>
      )}
      reminders={reminders}
      _data={_data}
      MAX_COUNT={3}
      onClose={props.onClose}
    />
  );
};
