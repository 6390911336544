import React, { useState } from 'react';
import { OutlineButton } from '../../@communityshare/ui/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import ProjectsEvents from './ProjectsEvents';
import StringField from '../../forms/_fields_v2/StringField';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../DialogManager';

export default function Form(props) {
  const {
    user,
    onClose
  } = props;

  const [title, setTitle] = useState('');
  const [name, setName] = useState('');

  const launch = useDialogLauncher();

  function onChangeTitle(text) {
    setTitle(text);
  }

  function onChangeName(text) {
    setName(text);
  }

  function onClickProject(project) {
    launch(DialogManager.QuestionInvite.Config(project.id, user.id));
  }

  function onClickEvent(event) {
    launch(DialogManager.InvitationInvite.Config(event.id, user.id));
  }

  return (
    <FormTemplate
      title={`Invite ${user.data.firstName || 'Partner'} to a project/event?`}
      subtitle={(
        <span>
          Is there a project or event that you think would be a good fit for this partner? Search for it below!
        </span>
      )}
      body={(
        <div className="space-y-8">
          <div className="space-y-4">
            <StringField
              label="Title"
              placeholder="Enter title..."
              value={title}
              onChange={onChangeTitle}
            />
            <StringField
              label="Educator name"
              placeholder="Enter name..."
              value={name}
              onChange={onChangeName}
            />
          </div>
          <ProjectsEvents
            title={title}
            name={name}
            user={user}
            onClickProject={onClickProject}
            onClickEvent={onClickEvent}
          />
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
