import React  from 'react';
import HiddenTag from '../../_components/HiddenTag';
import CancelledTag from '../../_components/CancelledTag';
import CompletedTag from '../../_components/CompletedTag';
import ExpiredTag from '../../_components/ExpiredTag';
import moment from 'moment-timezone';

export default function StatusTags(props) {
  const { offer } = props;

  if (offer.data.cancelled) {
    return (
      <div className="flex space-x-4">
        <CancelledTag resource={offer} />
      </div>
    );
  }

  if (offer.data.completed) {
    return (
      <div className="flex space-x-4">
        <CompletedTag resource={offer} />
      </div>
    );
  }

  const diff = moment().diff(offer.data.endDate, 'days');

  if (diff >= 0) {
    return (
      <div className="flex space-x-4">
        <ExpiredTag resource={offer} />
      </div>
    );
  }

  return null;
}
