import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import { BuildingOfficeIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { getDistanceFromLatLonInMiles } from '../../../components/User/utils';
import IconSection from '../../../components/IconSection';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';

CollaborationOptions.propTypes = {
  user: PropTypes.object.isRequired
};

export default function CollaborationOptions(props) {
  const { user, relatedInteractionTypes } = props;

  const currentUser = useUser();

  const distance = getDistanceFromLatLonInMiles(user.data, currentUser.data);

  const isInPerson = relatedInteractionTypes ? _.find(relatedInteractionTypes.data, function(relatedInteractionType) {
    return relatedInteractionType.data.name === 'In-person';
  }) : user.data.inPerson;

  const isVirtual = relatedInteractionTypes ? _.find(relatedInteractionTypes.data, function(relatedInteractionType) {
    return relatedInteractionType.data.name === 'Virtual';
  }) : user.data.virtual;

  return (
    <div className="space-y-3">
      <SubSectionHeader title="Collaboration Options" />
      <div className="space-y-1">
        {isInPerson && distance <= 50 && (
          <IconSection
            icon={BuildingOfficeIcon}
            title="In-person"
            subtitle={distance <= 1 ? '1 mile away' : `${distance} miles away`}
            xs={true}
          />
        )}
        {isVirtual && (
          <IconSection
            icon={GlobeAltIcon}
            title="Virtual"
            subtitle={(!isInPerson || distance > 50) && (
              distance <= 1 ? '1 mile away' : `${distance} miles away`
            )}
            xs={true}
          />
        )}
      </div>
    </div>
  );
}
