import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import getYear from '../dashboard/getYear';
import Redirect from '../../components/Redirect';
import PageHeading from '../../@communityshare/ui/PageHeading';
import ParentLayout from '../dashboard/Layout';
import useAccount from '../../hooks/useAccount';
import { Helmet } from 'react-helmet-async';

export default function Layout(props) {
  const year = getYear(moment());
  const account = useAccount();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Dashboard: Redirect</title>
      </Helmet>
      <PageHeading
        breadcrumbs={['Dashboard']}
        // title="School Year"
      />
      <Redirect to={`/c/${account.data.subdomain}/dashboard/year/${year}/students`} />
    </div>
  );
}
