import { extractArrayFromQuery } from '../../../utils/query';

export default {
  param: extractArrayFromQuery,
  query: function (values) {
    if (values.length > 0) {
      return {
        numberOfPartnerInteractions: {
          // $in: values.map(function(value) {
          //   return Number(value) - 1;
          // })
          $or: values.map(function(value) {
            const count = Number(value) - 1;

            if (count === 3) {
              return {
                $gte: count
              }
            }

            return {
              $equals: count
            };
          })
        }
      }
    }
  }
}
