import React from 'react';
import NotFoundTemplate from './NotFoundTemplate';
import { SolidButton } from '../@communityshare/ui/Button';
import logo from '../../assets/images/logo.png';
import RemoveLoadingScreen from './RemoveLoadingScreen';
import { Helmet } from 'react-helmet-async';
import Card from '../components/Card';
import useRouter from '../hooks/useRouter';

export default function Layout(props) {
  const { history } = useRouter();

  return (
    <div className="p-8">
      <Helmet>
        <title>Not Found</title>
      </Helmet>
      <RemoveLoadingScreen/>
      <Card
        border={false}
        className="max-w-screen-sm mx-auto"
      >
        <NotFoundTemplate
          title="404 Page not found"
          subtitle="Uh oh! The page you requested does not exist."
          size="large"
          navigation={(
            <div className="mb-8">
              <img className="h-48 m-auto" src={logo} alt="logo"/>
            </div>
          )}
          // body={(
          //   <>
          //     <div className="text-center">
          //       <PrimaryButton className="px-20" onClick={() => history.push('/')}>
          //         Go to home page
          //       </PrimaryButton>
          //     </div>
          //   </>
          // )}
          footer={(
            <div className="text-center m-auto">
              <SolidButton className="px-20" onClick={() => history.push('/')}>
                Go to home page
              </SolidButton>
            </div>
          )}
        />
      </Card>
      {/*<div className="bg-cs-yellow-500 fixed top-0 bottom-0 left-0 right-0 -z-1" />*/}
      <div className="bg-gradient-to-b from-cs-yellow-100 to-cs-yellow-300 fixed top-0 bottom-0 left-0 right-0 -z-1" />
    </div>
  );
}
