import React from 'react';
import { parse, stringify } from 'query-string';
import useStewardshipFilter from '../../events/useStewardshipFilter';
import REQUIRED_FIELDS_PUBLIC from '../../events/REQUIRED_FIELDS_PUBLIC';
import { PayloadStates } from '@lore/utils';
import { useConnect } from '@lore/query-connect';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 1000 } = props;

  const stewardshipFilter = useStewardshipFilter();

  const raw = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS_PUBLIC.$and,
          $or: stewardshipFilter
        },
        invitations: {
          $where: {
            response: 'Yes'
          }
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    },
    exclude: function(model) {
      return model.state === PayloadStates.DELETED;
    }
  }, {
    keepPreviousData: true
  });

  const events = {
    ...raw
  };

  if (events.state === PayloadStates.RESOLVED) {
    events.data = events.data.filter(function(event) {
      return (event.data.invitations || []).length < event.data.slots;
    });

    events.meta.totalCount = events.data.length;
  }

  return events;
}
