import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../utils/classNames';

FormTemplate.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  alert: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func,
  onPrevious: PropTypes.func,
  showClose: PropTypes.bool
};

export default function FormTemplate(props) {
  const {
    header,
    navigation,
    title,
    subtitle,
    alert,
    body,
    footer,
    onClose,
    onPrevious,
    className
  } = props;

  // position: absolute;
  // left: 49px;
  // top: 41px;
  // padding: 16px 16px;
  // margin: -16px -16px -16px auto;
  // font-size: 48px;
  // line-height: 25px;
  // color: #f67f20;
  // opacity: 1;
  // overflow: hidden;
  // cursor: pointer;

  return (
    <div className={classNames(
      'my-16 lg:my-32x',
      className
    )}>
      {header}
      <div className="px-10 pb-0 md:px-24">
        {navigation && (
          <div className="flex items-center justify-between mb-12">
            {React.cloneElement(navigation, { v2: true })}
          </div>
        )}
        {title && (
          <h5 className="text-3xl sm:text-4xl font-semibold text-left m-auto">
            {title}
          </h5>
        )}
        {subtitle ? (
          <h6 className="font-normal mt-2 text-cs-grey-800 text-left text-lg -mb-4">
            {subtitle}
          </h6>
        ) : null}
        {false && onPrevious ? (
          <div className="absolute p-4 -m-4 text-accent cursor-pointer dialog-previous-button sm:dialog-previous-button-sm lg:dialog-previous-button-lg" onClick={onPrevious}>
            <i className="material-icons text-4xl font-bold">arrow_back</i>
          </div>
        ) : null}
      </div>
      <div className={classNames(
        (title || subtitle) && 'pt-16',
        'px-10 pb-0 md:px-24'
      )}>
        {body}
      </div>
      {alert}
      {footer ? (
        <div className="flex items-center justify-start pt-16 px-10 md:px-24">
          {onPrevious && (
            <div className="text-accent underline cursor-pointer mr-8 sm:mr-12" onClick={onPrevious}>
              Back
            </div>
          )}
          {footer}
        </div>
      ) : null}
    </div>
  );
}
