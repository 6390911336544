import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import { StarIcon  } from '@heroicons/react/24/outline';
import { StarIcon as SolidStarIcon } from '@heroicons/react/24/solid';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

Bookmark.propTypes = {
  type: PropTypes.oneOf([
    'user',
    'project',
    'event',
    'offer',
  ]).isRequired
};

export default function Bookmark(props) {
  const { type, resource } = props;

  const currentUser = useUser();

  const mutation_create = useQueryMutation('star.create');
  const mutation_destroy = useQueryMutation('star.destroy2');

  const userBookmarks = useConnect('star.find', {
    where: {
      eager: {
        $where: {
          creatorId: currentUser.id,
          [`${type}Id`]: resource.id
        }
      }
    }
  });

  const userBookmark = _.find(userBookmarks.data, function(userBookmark) {
    return (
      userBookmark.data.creatorId === currentUser.id &&
      userBookmark.data[`${type}Id`] === resource.id &&
      userBookmark.state !== PayloadStates.DELETED
    );
  }) || {
    state: PayloadStates.NOT_FOUND
  };

  function onCreateBookmark() {
    mutation_create.mutate({
      creatorId: currentUser.id,
      [`${type}Id`]: resource.id
    }, {
      onSuccess: payload => userBookmarks._add(payload)
    });
  }

  function onDestroyBookmark() {
    mutation_destroy.mutate(userBookmark, {
      onSuccess: payload => userBookmarks._remove(payload)
    });
  }

  if (userBookmark.state === PayloadStates.FETCHING) {
    return (
      <StarIcon
        className="h-6 w-6 text-gray-400"
      />
    );
  }

  if (
    userBookmark.state === PayloadStates.NOT_FOUND ||
    userBookmark.state === PayloadStates.DELETED
  ) {
    return (
      <StarIcon
        className="h-6 w-6 text-primary cursor-pointer"
        onClick={onCreateBookmark}
      />
    );
  }

  return (
    <SolidStarIcon
      className="h-6 w-6 text-primary cursor-pointer"
      onClick={onDestroyBookmark}
    />
  );
}
