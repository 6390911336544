import React  from 'react';
import moment from 'moment-timezone';
import SupportingTextColumn from '../../_components/table/columns/SupportingTextColumn';

export default function Accepted(props) {
  const { stewardInvitation } = props;

  return (
    <SupportingTextColumn
      title={stewardInvitation.data.accepted ? 'Yes' : 'No'}
      subtitle={stewardInvitation.data.accepted ? moment(stewardInvitation.data.acceptedAt).fromNow() : ''}
    />
  );
}
