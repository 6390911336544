import React  from 'react';

export default function TextColumn(props) {
  const { children, loading = false } = props;

  if (loading) {
    return (
      <td className="px-6 py-4 whitespace-nowrapx text-sm text-gray-500">
        <p className="line-clamp-2">
          ...
        </p>
      </td>
    );
  }

  return (
    <td className="px-6 py-4 whitespace-nowrapx text-sm text-gray-500">
      <p className="line-clamp-2">
        {children}
      </p>
    </td>
  );
}
