import React from 'react';
import Section from '../../../pages/_components/sidePanel/Section.new';
import TextContentSection from '../../../components_profiles/TextContentSection';
import Category from '../../../pages/user/User/Category';
import Entry from '../../../pages/user/User/Entry';
import ContentCard from '../../../components_profiles/ContentCard';

export default function Contribution(props) {
  const { evaluation } = props;

  return (
    <ContentCard className="space-y-4">
      <Category>
        Contribution
      </Category>
      <div className="space-y-3">
        <Entry
          title="How many times have you interacted?"
          description={evaluation.data.occurrences || '-'}
        />
        <Entry
          title="How many hours did you spend planning and interacting with the students and/or educator?"
          description={evaluation.data.hours || '-'}
        />
        <Entry
          title="Did you contribute any supplies, materials, or items to the educator or students to complete the project?"
          description={evaluation.data.contributed || '-'}
        />
        <Entry
          title="What supplies or materials did you contribute?"
          description={evaluation.data.contribution || '-'}
        />
        <Entry
          title="What is the approximate monetary value of your contribution (in dollars)?"
          description={evaluation.data.contributionValue || '-'}
        />
      </div>
    </ContentCard>
  );

  return (
    <Section title="Contribution">
      <TextContentSection title="How many times have you interacted?">
        {evaluation.data.occurrences || '-'}
      </TextContentSection>
      <TextContentSection title="How many hours did you spend planning and interacting with the students and/or educator?">
        {evaluation.data.hours || '-'}
      </TextContentSection>
      <TextContentSection title="Did you contribute any supplies, materials, or items to the educator or students to complete the project?">
        {evaluation.data.contributed || '-'}
      </TextContentSection>
      <TextContentSection title="What supplies or materials did you contribute?">
        {evaluation.data.contribution || '-'}
      </TextContentSection>
      <TextContentSection title="What is the approximate monetary value of your contribution (in dollars)?">
        {evaluation.data.contributionValue || '-'}
      </TextContentSection>
    </Section>
  );
}
