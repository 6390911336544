import React, { useState } from 'react';
import { parse, stringify } from 'query-string';
import moment from 'moment-timezone';
import useStewardshipFilter from '../../educators/useStewardshipFilter';
import { useConnect } from '@lore/query-connect';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 12, returnRawQuery = false } = props;

  const [timestamp] = useState(moment().toISOString());
  const stewardshipFilter = useStewardshipFilter();

  const query = {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfProjectsCompleted',
            relation: 'projects',
            $where: {
              completed: true
            }
          },
          {
            type: 'count',
            alias: 'numberOfEventsCompleted',
            relation: 'events',
            $where: {
              completed: true
            }
          }
        ],
        $where: {
          isEducator: true,
          $and: {
            $or: [
              {
                numberOfProjectsCompleted: {
                  $gte: 3
                }
              },
              {
                $and: {
                  numberOfProjectsCompleted: {
                    $gte: 2
                  },
                  numberOfEventsCompleted: {
                    $gte: 1
                  }
                }
              },
              {
                $and: {
                  numberOfProjectsCompleted: {
                    $gte: 1
                  },
                  numberOfEventsCompleted: {
                    $gte: 2
                  }
                }
              },
              {
                numberOfEventsCompleted: {
                  $gte: 3
                }
              }
            ]
          },
          // $and: {
          //   $or: [
          //     {
          //       projects: {
          //         $count_gte: 3
          //       }
          //     },
          //     {
          //       $and: {
          //         projects: {
          //           $count_gte: 2
          //         },
          //         events: {
          //           $count_gte: 1
          //         }
          //       }
          //     },
          //     {
          //       $and: {
          //         projects: {
          //           $count_gte: 1
          //         },
          //         events: {
          //           $count_gte: 2
          //         }
          //       }
          //     },
          //     {
          //       events: {
          //         $count_gte: 3
          //       }
          //     }
          //   ]
          // },
          $or: stewardshipFilter
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  };

  const result = useConnect('user.find', query, {
    enabled: !returnRawQuery,
    keepPreviousData: true
  });

  if (returnRawQuery) {
    return query;
  }

  return {
    ...result,
    data: _.sortBy(result.data, function(user) {
      return -(
        Number(user.data.numberOfProjectsCompleted) +
        Number(user.data.numberOfEventsCompleted)
      );
    })
  };
}
