import React from 'react';
import PropTypes from 'prop-types';
import Activity from './Activity';
import Description from './Description';
import Students from './Students';
import Commitment from './Commitment';
import Creator from './Creator';
import Section from '../../_common/Section';
import Participants from './Participants';
import Participation from './Participation';
import Uploads from './Uploads';
import Experience from './Experience';
import Skills from './Skills';
import Subjects from './Subjects';
import Slots from './Slots';

export default function Event(props) {
  const { event } = props;

  return (
    <div className="space-y-8">
      <div className="flex flex-col md:flex-row space-x-8">
        <div className="w-full md:w-2/3 space-y-8">
          <Section title="Event">
            <Activity event={event} />
            <div className="w-full space-y-4 block md:hidden">
              <Commitment event={event} />
              <Participation event={event} />
              <Slots event={event} />
            </div>
          </Section>
          <Section title="Details">
            <Description event={event} />
            <Subjects event={event} />
          </Section>
          <Section
            title="Audience"
            subtitle="Learners the activity is designed for."
          >
            <Participants event={event} />
          </Section>
          <Section
            title="Ideal Partner"
            subtitle="Do you have experience with some or all of these topics?"
            hideIfNoChildren={true}
          >
            <Skills event={event} />
            <Experience event={event} />
          </Section>
          <Uploads event={event} />
        </div>
        <div className="w-full md:w-1/3 space-y-8 mt-12 hidden md:block">
          <Commitment event={event} />
          <Participation event={event} />
          <Slots event={event} />
        </div>
      </div>
    </div>
  );
};
