import React from 'react';
import { useConnect } from '@lore/query-connect';
import NoteTemplate from '../../feed/NoteTemplate';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function PartnerNote(props) {
  const { note } = props;

  const user = useConnect('user.byId', {
    id: note.data.userId
  });

  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.UserManage.Config(user.id));
  }

  return (
    <NoteTemplate
      note={note}
      title={`${user.data.firstName} ${user.data.lastName}` || '[partner name missing]'}
      type="partner"
      last={props.last}
      onClick={onClick}
    />
  );
}
