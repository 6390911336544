import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export default function ScrollToTop(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};
