import React, { useRef, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import PageHeading from '../../@communityshare/ui/PageHeading';
import PaginationNew from '../../components/pagination-new/Pagination';
import PaginationInformation from '../../components/pagination-new/PaginationInformation';
import useScrollToTop from '../../hooks/useScrollToTop';
import Results from './Results';
import View from '../_components/View';
import { CloudArrowDownIcon } from '@heroicons/react/24/outline';
import MobileNavigation from '../educators/MobileNavigation';

export default function Layout(props) {
  const {
    breadcrumbs,
    title,
    description,
    result,
    pageSize,
    row,
    csvQuery
  } = props;

  const node = useRef();
  const scrollToTop = useScrollToTop();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Educators: {title}</title>
      </Helmet>
      <MobileNavigation />
      <PageHeading
        breadcrumbs={breadcrumbs}
        title={title}
        description={description}
      />
      <>
        <div ref={node} className="flex items-center justify-between">
          <PaginationInformation
            pageSize={pageSize}
            totalCount={result.meta?.totalCount}
          />
          {/*<PaginationNew*/}
          {/*  pageSize={pageSize}*/}
          {/*  totalCount={result.meta?.totalCount}*/}
          {/*  onNavigate={() => scrollToTop(node)}*/}
          {/*/>*/}
          {csvQuery && result.meta?.totalCount > 0 && (
            <View
              icon={CloudArrowDownIcon}
              onClick={csvQuery}
            >
              Download results as CSV
            </View>
          )}
        </div>
        {/*<div ref={node} className="flex justify-between">*/}
        {/*  <div className="flex space-x-4">*/}
        {/*    <NetworkFilter/>*/}
        {/*    <TypeFilter/>*/}
        {/*    <DistanceFilter/>*/}
        {/*  </div>*/}
        {/*  <div className="flex space-x-4">*/}
        {/*    <SortBy/>*/}
        {/*    <FilterButton*/}
        {/*      count={filterCount}*/}
        {/*      onClick={showFilters}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <Results
          result={result}
          row={row}
        />
        <div className="flex items-center justify-between">
          <PaginationInformation
            pageSize={pageSize}
            totalCount={result.meta?.totalCount}
          />
          <PaginationNew
            pageSize={pageSize}
            totalCount={result.meta?.totalCount}
            onNavigate={() => scrollToTop(node)}
          />
        </div>
      </>
    </div>
  );
}
