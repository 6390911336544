import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';
import classNames from 'classnames';

CheckboxField.propTypes = {
  label: PropTypes.node.isRequired,
  subtitle: PropTypes.node,
  selectable: PropTypes.bool,
  checked: PropTypes.bool.isRequired
};

export default function CheckboxField(props) {
  const {
    label,
    description,
    selectable = true,
    checked,
    onChange,
    reverse = false
  } = props;

  function onClick() {
    onChange({
      target: {
        checked: !checked
      }
    });
  }

  return (
    <div className={classNames(
      'flex space-x-4',
      reverse && ' flex-row-reverse space-x-reverse'
    )}>
      {selectable ? (
        <div className="">
          <Checkbox
            checked={checked}
            onChange={onChange}
          />
        </div>
      ) : null}
      <div className="flex-1 space-y-0.5">
        <div
          className={classNames(
            'text-cs-gray-900 text-base',
            selectable && 'cursor-pointer'
          )}
          onClick={selectable ? onClick : undefined}
        >
          {label}
        </div>
        {description && (
          <div className="text-cs-gray-500 text-sm font-normalx">
            {description}
          </div>
        )}
      </div>
    </div>
  );
};
