import { useConnect } from '../../../hooks/@lore/query-connect';
import REQUIRED_FIELDS_PUBLIC from '../projects/REQUIRED_FIELDS_PUBLIC';

export default function useActiveQuestionsForUser(user) {
  return useConnect('project.find', {
    where: {
      eager: {
        $where: {
          'questions.userId': user.id,
          'questions.response': 'Connect',
          $and: REQUIRED_FIELDS_PUBLIC.$and,
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt desc'
    }
  });
}
