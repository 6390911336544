import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton } from '../../@communityshare/ui/Button';
import { SolidButton } from '../../@communityshare/ui/Button';
import TextAreaField from '../../forms/_fields_v2/TextAreaField';
import FormTemplate from '../_common_old/FormTemplate.vertical';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose,
    stewardInvitation
  } = props;

  return (
    <FormTemplate
      showIcon={false}
      title={`Revoke invitation for ${stewardInvitation.data.email}?`}
      subtitle="If the invitation is associated with an existing steward account, this will also remove that person's steward access."
      onClose={onClose}
      alert={alert}
      // body={(
      //   <>
      //     <FormField name="text" data={data} errors={showErrors && errors} onChange={onChange}>
      //       {(field) => (
      //         <TextAreaField
      //           className="sm:col-span-6"
      //           label="Note"
      //           placeholder="Write something..."
      //           // description="Some text about a thing I like"
      //           rows={3}
      //           value={field.value}
      //           error={field.error}
      //           onChange={field.onChange}
      //         />
      //       )}
      //     </FormField>
      //   </>
      // )}
      footer={(
        <>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Revoke
          </SolidButton>
        </>
      )}
    />
  );
}
