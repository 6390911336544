import React from 'react';

export default function NavigationLabel(props) {
  const { children } = props;

  return (
    <h3 className="text-xs font-semibold text-cs-gray-900 tracking-wide">
      {children}
    </h3>
  );
}
