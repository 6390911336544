import React  from 'react';
import moment from 'moment-timezone';
import TextField from '../../projects/_fields/TextField';

export default function DateJoined(props) {
  const { user, align } = props;

  return (
    <TextField label="Date joined" align={align}>
      {moment(user.data.createdAt).format('MMM D, YYYY')}
    </TextField>
  );
}
