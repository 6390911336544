import React, { useState } from 'react';
import moment from 'moment-timezone';
import { PayloadStates } from '@lore/utils';
import PageHeading from '../../../@communityshare/ui/PageHeading';
import ParentLayout from '../../dashboard/Layout';
import Loader from '../../../components/Loader';
import { Helmet } from 'react-helmet-async';

import useProjectNotesMapping from '../project-notes/useMapping';
import useEventNotesMapping from '../event-notes/useMapping';
import useOfferNotesMapping from '../offer-notes/useMapping';
import usePartnerNotesMapping from '../partner-notes/useMapping';
import useEducatorNotesMapping from '../educator-notes/useMapping';

import useQueryMeta from '../../feed/useQueryMeta';
import { OutlineButton } from '../../../@communityshare/ui/Button';
import MobileNavigation from '../../feed/MobileNavigation';

const increment = 10;

export default function Layout(props) {
  const [maxCount, setMaxCount] = useState(increment);
  const { account, params, pagination } = useQueryMeta();

  const partnerNotes = usePartnerNotesMapping({ account, params, pagination });
  const educatorNotes = useEducatorNotesMapping({ account, params, pagination });
  const projectNotes = useProjectNotesMapping({ account, params, pagination });
  const eventNotes = useEventNotesMapping({ account, params, pagination });
  const offerNotes = useOfferNotesMapping({ account, params, pagination });

  if (
    projectNotes.models.state === PayloadStates.FETCHING ||
    eventNotes.models.state === PayloadStates.FETCHING ||
    offerNotes.models.state === PayloadStates.FETCHING ||
    partnerNotes.models.state === PayloadStates.FETCHING ||
    educatorNotes.models.state === PayloadStates.FETCHING
  ) {
    return (
      <ParentLayout>
        <div className="p-8 space-y-8">
          <Helmet>
            <title>Feed: Notes</title>
          </Helmet>
          <PageHeading
            breadcrumbs={['Feed']}
            title="Notes"
            description="List of notes created over the specified time frame."
          />
          <Loader />
        </div>
      </ParentLayout>
    );
  }

  let items = [].concat(
    partnerNotes.entries,
    educatorNotes.entries,
    projectNotes.entries,
    eventNotes.entries,
    offerNotes.entries
  );

  items = _.sortBy(items, function(item) {
    return -moment(item.timestamp).unix();
  });

  const visibleItems = items.slice(0, maxCount);

  return (
    <ParentLayout>
      <div className="p-8 space-y-8">
        <Helmet>
          <title>Feed: Notes</title>
        </Helmet>
        <MobileNavigation />
        <PageHeading
          breadcrumbs={['Feed']}
          title="Notes"
          description="List of notes created over the specified time frame."
        />
        <div className="max-w-3xl">
          <ul role="list" className="space-y-6">
            {visibleItems.map(function(item, index) {
              const last = items.length === (index + 1);
              return React.cloneElement(item.value, {
                last: last
              });
            })}
            {maxCount < items.length && (
              <OutlineButton onClick={() => setMaxCount(maxCount + increment)}>
                Show more ({items.length - visibleItems.length})
              </OutlineButton>
            )}
          </ul>
        </div>
      </div>
    </ParentLayout>
  );
}
