import React from 'react';
import PropTypes from 'prop-types';
import { ClipboardDocumentListIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { OutlineButton } from '../../@communityshare/ui/Button';
import { SolidButton } from '../../@communityshare/ui/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';
import Setting from '../_common_visibility/Setting';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose,
    event
  } = props;

  const educator = useConnect('user.byId', {
    id: event.data.creatorId
  });

  let activity = useConnect('tag.byId', {
    id: event.data.activityId
  }, {
    enabled: !!event.data.activityId
  });

  if (!event.data.activityId) {
    activity = {
      state: PayloadStates.RESOLVED,
      data: {
        name: 'No Title'
      }
    };
  }

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <EyeIcon className="text-cs-orange-500 h-12 w-12" />
        </div>
      )}
      title="Change event visibility"
      subtitle={(
        <>
          You are about to change the visibility of the
          event <span className="text-cs-gray-900 font-semibold">{event.data.title || activity.data.name || '...'}</span> created
          by <span className="text-cs-gray-900 font-semibold">{educator.data.firstName} {educator.data.lastName}</span>.
        </>
      )}
      onClose={onClose}
      alert={alert}
      body={(
        <>
          <div className="grid grid-cols-1 sm:grid-cols-1 gap-3">
            <Setting
              icon={ClipboardDocumentListIcon}
              title="Visible"
              description={(
                <>
                  The event will be shared with others on the platform and they'll be able
                  to find it and express interest.
                </>
              )}
              onClick={() => onChange('published', true)}
              selected={data.published === true}
            />
            <Setting
              icon={EyeSlashIcon}
              title="Hidden"
              description={(
                <>
                  The event will be hidden from others. You'll still be able to invite others
                  to attend, but no one will discover the event on their own. Only those who
                  are invited will learn about it.
                </>
              )}
              onClick={() => onChange('published', false)}
              selected={data.published === false}
            />
          </div>
        </>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Save
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
