import moment from 'moment-timezone';

export function getYear(timestamp) {
  return timestamp.month() > 6 ?
    timestamp.year() :
    timestamp.year() - 1;
}

export default function getTimestampsForAccount(account) {
  const startingYear = getYear(moment(account.data.createdAt));
  const endingYear = getYear(moment());

  const timestamps = [];

  // for (let year = endingYear; year >= startingYear; year--) {
  for (let year = startingYear; year <= endingYear; year++) {
    const start = moment(`${year}-07-01T12:00:00.000-00:00`).toISOString();
    const end = moment(`${year + 1}-07-01T12:00:00.000-00:00`).toISOString();

    timestamps.push({
      schoolStart: start,
      schoolEnd: end,
      label: `${year}-${year-1999} SY`,
      start,
      end
    });
  }

  return timestamps;
};
