import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import useAccount from '../../hooks/useAccount';
import useRouter from '../../hooks/useRouter';

export default function useStewardshipFilter(props) {
  const { location } = useRouter();
  const query = parse(location.search);

  const account = useAccount();

  if (query.accounts === '0') {
    return [
      { 'creator.accountId': account.id },
    ];
  }

  return [
    { 'creator.accountId': account.id }
  ];
};
