import React from 'react';

export default function Heading(props) {
  const { title, subtitle } = props;

  if (title || subtitle) {
    return (
      <div className="pt-6">
        {title && (
          <div className="flex items-center">
            <h3 className="font-medium leading-6 text-lg text-gray-900">
              {title}
            </h3>
          </div>
        )}
        {subtitle && (
          <div className="text-sm leading-5 text-gray-500">
            {subtitle}
          </div>
        )}
      </div>
    );
  }

  return null;
}
