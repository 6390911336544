import React from 'react';
import LazyMetrics from '../../dashboard-year/LazyMetrics';
import TotalYear from '../../dashboard-year/Schools/TotalYear';
import TotalAssociatedWithProject from '../../dashboard-year/Schools/TotalAssociatedWithProject';
import TotalAssociatedWithEvent from '../../dashboard-year/Schools/TotalAssociatedWithEvent';
import TotalAssociatedWithProjectOrEvent from '../../dashboard-year/Schools/TotalAssociatedWithProjectOrEvent';
import SchoolGrowthByMonth from '../../dashboard-year/Schools/SchoolGrowthByMonth';
import CompositeSchoolGrowth from '../../dashboard-year/Schools/CompositeSchoolGrowth';

export default function Schools(props) {
  const { params } = props;

  return (
    <>
      <LazyMetrics title="Schools">
        {/*<Total {...params} />*/}
        <TotalYear {...params} titleOnly={true} />
        <TotalAssociatedWithProject {...params} titleOnly={true} />
        <TotalAssociatedWithEvent {...params} titleOnly={true} />
        <TotalAssociatedWithProjectOrEvent {...params} titleOnly={true} />
      </LazyMetrics>
      <LazyMetrics
        title="Growth"
        columns={1}
      >
        <SchoolGrowthByMonth {...params} />
        {/*<div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">*/}
        {/*  <CompositeSchoolGrowth {...params} titleOnly={true} />*/}
        {/*</div>*/}
      </LazyMetrics>
    </>
  );
}
