import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

export default function LegendChartLegend(props) {
  const {
    labels = [],
    colors = [],
    series = [],
    columns = 2,
    verticalColumns = true
  } = props;

  const total = _.reduce(series, function(result, value) {
    return result + value;
  }, 0);

  const _labelsColumn1 = labels.slice(0, Math.floor(labels.length/2));
  const _labelsColumn2 = labels.slice(Math.floor(labels.length/2));
  const _seriesColumn1 = series.slice(0, Math.floor(series.length/2));
  const _seriesColumn2 = series.slice(Math.floor(series.length/2));

  function mapLegendItems(labels, series) {
    return labels.map(function(label, index) {
      const color = colors[index];
      const value = series[index];
      const percent = Math.floor(value / total * 100);

      return (
        <div key={index} className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <div className="h-3 w-3 rounded-full" style={{ backgroundColor: color }}/>
            <div className="text-sm text-cs-gray-500 text-nowrap">
              {label} ({value})
            </div>
          </div>
          <div className="text-sm text-cs-gray-900 font-semibold">
            {percent}%
          </div>
        </div>
      );
    });
  }

  if (!verticalColumns) {
    return (
      <div className={classNames(
        'mt-8 grid grid-cols-1 gap-y-1 gap-x-6',
        columns > 1 && 'sm:grid-cols-2',
        columns > 2 && 'md:grid-cols-3',
      )}>
        {labels.map(function(label, index) {
          const color = colors[index];
          const value = series[index];
          const percent = Math.floor(value / total * 100);

          return (
            <div key={index} className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <div className="h-3 w-3 rounded-full" style={{ backgroundColor: color }}/>
                <div className="text-sm text-cs-gray-500 text-nowrap">
                  {label} ({value})
                </div>
              </div>
              <div className="text-sm text-cs-gray-900 font-semibold">
                {percent}%
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={classNames(
      'mt-8 grid grid-cols-1 gap-y-1 gap-x-6',
      columns > 1 && 'sm:grid-cols-2',
      columns > 2 && 'md:grid-cols-3',
    )}>
      <div className="grid grid-cols-1 gap-y-1">
        {mapLegendItems(_labelsColumn1, _seriesColumn1)}
      </div>
      <div className="grid grid-cols-1 gap-y-1">
        {mapLegendItems(_labelsColumn2, _seriesColumn2)}
      </div>
    </div>
  );
}
