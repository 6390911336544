import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import useEventTags from '../../hooks/useEventTags';
import TagTypes from '../../constants/TagTypes';

function isMissing(name, missingFields) {
  return _.find(missingFields, function(missingField) {
    return missingField.key === name;
  });
}

export default function useMissingFields(event) {
  const missingFields = [];

  const eventParticipants = useEventTags(event, TagTypes.Participant);
  const eventInteractionTypes = useEventTags(event, TagTypes.InteractionType);

  if (
    eventParticipants.state === PayloadStates.FETCHING ||
    eventInteractionTypes.state === PayloadStates.FETCHING
  ) {
    return {
      missingFields: {
        state: PayloadStates.FETCHING,
        data: missingFields
      },
      isMissing: isMissing
    };
  }

  if (!event.data.activityId) {
    missingFields.push({
      field: 'activityId',
      name: 'activity',
      label: 'Activity'
    });
  }

  if (eventParticipants.data.length === 0) {
    missingFields.push({
      field: 'participants',
      name: 'participants',
      label: 'Participants'
    });
  }

  // if (
  //   _.find(eventParticipants.data, m => m.data.name === 'Students') &&
  //   !event.data.students
  // ) {
  //   missingFields.push({
  //     field: 'students',
  //     name: 'Student Count',
  //     label: 'Student Count'
  //   });
  // }

  if (!event.data.interactionDescription) {
    missingFields.push({
      field: 'interactionDescription',
      name: 'Summary',
      label: 'Summary'
    });
  }

  if (
    event.data.specificDate === 'Yes' &&
    !event.data.date
  ) {
    missingFields.push({
      field: 'date',
      name: 'Date',
      label: 'Date'
    });
  }

  if (
    event.data.specificDate === 'No' && (
      !event.data.startDate ||
      !event.data.endDate
    )
  ) {
    missingFields.push({
      field: 'date',
      name: 'Date Range',
      label: 'Date Range'
    });
  }

  if (eventInteractionTypes.data.length === 0) {
    missingFields.push({
      field: 'eventInteractionTypes',
      name: 'Location',
      label: 'Location'
    });
  }

  return {
    missingFields: {
      state: PayloadStates.RESOLVED,
      data: missingFields
    },
    isMissing: function(name) {
      return isMissing(name, missingFields)
    }
  };
};
