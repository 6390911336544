import React from 'react';
import PropTypes from 'prop-types';
import Students from './Students';
import Creator from './Creator';
import useEventTags from '../../../hooks/useEventTags';
import TagTypes from '../../../constants/TagTypes';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import MissingField from '../EventMissingField';

export default function Event(props) {
  const { event } = props;

  const eventParticipants = useEventTags(event, TagTypes.Participant);

  if (eventParticipants.state === PayloadStates.FETCHING) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (eventParticipants.data.length === 0) {
    return (
      <MissingField name="Participants" />
    );
  }

  return (
    <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
      <div className="w-full">
        <Creator event={event} />
      </div>
      <div className="w-full">
        <Students event={event} />
      </div>
    </div>
  );
};
