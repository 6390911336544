import React from 'react';
import DateWindow from './DateWindow';
import Partners from './Partners';
import Project from './Project';
import Bookmark from '../../_components/Bookmark';
import Educator from './Educator';
import Organization from './Organization';
import Notes from './Notes';
import Header from '../../../dialogs_new/project_manage/Header';
import DateRange from '../../../dialogs_new/project_manage/Header/DateRange';
import Creator from '../../../dialogs_new/project_manage/Header/Creator';
import Visibility from '../../../dialogs_new/project_manage/Header/Visibility';
import ShareLink from '../../../dialogs_new/project_manage/Header/ShareLink';
import Title from './Title';
import StatusTags from './StatusTags';
import Description from './Description';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function Row(props) {
  const { project, children } = props;

  const launch = useDialogLauncher();

  function onClick(project) {
    launch(DialogManager.ProjectManage.Config(project.id));
  }

  return (
    <div className="bg-white border border-gray-200 shadow rounded-lg overflow-hidden divide-y divide-gray-200 relative">
      <div className="absolute z-10 top-4 right-5 pt-0.5 pr-0.5">
        <Bookmark
          type="project"
          resource={project}
        />
      </div>
      <div className="flex flex-col p-4 space-y-2">
        <div className="space-y-2">
          <StatusTags project={project} />
          <div className="cursor-pointer hover:text-gray-500 space-y-1 pr-10" onClick={() => onClick(project)}>
            <Title project={project} />
            <Description project={project} />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            <DateRange project={project} />
            <Creator project={project} />
            <Organization project={project} />
          </div>
          <Partners project={project} />
        </div>
      </div>
      {children && (
        <div className="flex p-4">
          {children}
        </div>
      )}
      <div className="bg-gray-50 p-4">
        <Notes project={project} />
      </div>
    </div>
  );
}
