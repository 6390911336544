import React from 'react';
import EvaluationProjectEducator from './EvaluationProjectEducator';
import useQuery from './useQuery';

export default function useMapping(props) {
  const models = useQuery(props);

  const type = 'evaluation-project-educator';

  const entries = models.data.map((value, index) => ({
    type: type,
    value: (
      <EvaluationProjectEducator
        key={`${type}-${index}`}
        evaluation={value}
      />
    ),
    data: value,
    timestamp: value.data.createdAt
  }));

  return {
    models,
    entries
  };
}
