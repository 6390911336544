import React from 'react';

export default function FormTemplateButtonFooter(props) {
  const { children } = props;

  return (
    <div className="flex space-x-3">
      {children}
    </div>
  );
}
