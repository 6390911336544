import React from 'react';
import { Helmet } from 'react-helmet-async';
import Educators from './Educators';
import useRouter from '../../hooks/useRouter';
import PageHeading from '../../@communityshare/ui/PageHeading';
import Results from '../_common_results/Results';
import User from '../_common_user/ManageUser';
import LastActive from '../_common_user/User/LastActive';
import DateJoined from '../_common_user/User/DateJoined';
import Filters from '../../forms/filters_educators';
import { AcademicCapIcon } from '@heroicons/react/24/outline';
import MobileNavigation from './MobileNavigation';

export default function Layout(props) {
  const router = useRouter();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Explore Partners</title>
      </Helmet>
      <MobileNavigation />
      <PageHeading
        breadcrumbs={['Explore', 'Educators']}
        title="All Educators"
        description={(
          <>
            This page shows all educators in your network. Use the filters to narrow the
            list. Find a filter you want to see again in the future? Bookmark the page
            or save a link to the URL.
          </>
        )}
      />
      <Filters router={router} />
      <Educators router={router}>
        {function(result) {
          return (
            <Results result={result} columns={3} resource="educators" emptyIcon={AcademicCapIcon}>
              {function(user, index) {
                return (
                  <User
                    key={index}
                    user={user}
                  >
                    <div className="bg-cs-gray-50 p-4 border-t border-cs-gray-100">
                      <div className="grid grid-cols-2">
                        <LastActive user={user} />
                        <DateJoined user={user} align="right" />
                      </div>
                    </div>
                  </User>
                );
              }}
            </Results>
          );
        }}
      </Educators>
    </div>
  );
}
