import React from 'react';
import PropTypes from 'prop-types';
import { useConnect, UseQueryConnect } from '@lore/query-connect';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import IconSection from '../../../components/IconSection';
import { PayloadStates } from '@lore/utils';

Organizations.propTypes = {
  user: PropTypes.object.isRequired
};

export default function Organizations(props) {
  const { user } = props;

  const roles = useConnect('role.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 1000,
      order: 'createdAt asc'
    }
  });

  if (roles.data.length === 0) {
    return null;
  }

  return roles.data.map(function(role, index) {
    return (
      <UseQueryConnect key={index} _key="organization.byId" params={{ id: role.data.organizationId }}>
        {function(organization) {
          return (
            <IconSection
              icon={BuildingOfficeIcon}
              title={role.data.title}
              subtitle={organization.data.name}
              xs={true}
            />
          );
        }}
      </UseQueryConnect>
    );
  });
}
