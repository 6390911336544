import { extractArrayFromQuery } from '../../../utils/query';

export default {
  param: extractArrayFromQuery,
  query: function (values) {
    if (values.length > 0) {
      return {
        'evaluationEventEducatorEvent.activityId': {
          $in: values
        }
      }
    }
  }
}
