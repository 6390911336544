import React from 'react';
import { useConnect } from '@lore/query-connect';
import EvaluationTemplate from '../../feed/EvaluationTemplate';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function EvaluationProjectEducator(props) {
  const { evaluation } = props;

  const project = useConnect('project.byId', {
    id: evaluation.data.projectId
  });

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  }, {
    enabled: !!project.data.activityId
  });

  const launch = useDialogLauncher();

  function onClickProject() {
    launch(DialogManager.ProjectManage.Config(project.id));
  }

  function onClickEvaluation() {
    // launch(DialogManager.ProjectManage.Config(project.id));
  }

  return (
    <EvaluationTemplate
      evaluation={evaluation}
      evaluationRole="educator"
      onClickEvaluation={onClickEvaluation}
      title={project.data.title || activity.data.name}
      opportunityType="project"
      onClickOpportunity={onClickProject}
      last={props.last}
    />
  );
}
