import React from 'react';
import moment from 'moment-timezone';
import FeedItem from '../../feed/FeedItem';
import { useConnect } from '@lore/query-connect';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import FeedHoverText from '../../feed/FeedHoverText';

export default function Question(props) {
  const { question } = props;

  const project = useConnect('project.byId', {
    id: question.data.projectId
  });

  const partner = useConnect('user.byId', {
    id: question.data.userId
  });

  const creator = useConnect('user.byId', {
    id: question.data.creatorId
  });

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  }, {
    enabled: !!project.data.activityId
  });

  const launch = useDialogLauncher();

  function onClickCreator() {
    launch(DialogManager.UserManage.Config(creator.id));
  }

  function onClickPartner() {
    launch(DialogManager.UserManage.Config(partner.id));
  }

  function onClickProject() {
    launch(DialogManager.ProjectManage.Config(project.id));
  }

  const steward = (
    project.data.creatorId === creator.id ||
    creator.id === partner.id
  ) ? '' : '(steward)';

  return (
    <FeedItem timestamp={moment(question.data.createdAt).fromNow()} last={props.last}>
      <FeedHoverText onClick={onClickCreator}>
        {creator.data.firstName} {creator.data.lastName} {steward}
      </FeedHoverText> invited <FeedHoverText onClick={onClickPartner}>
        {partner.data.firstName} {partner.data.lastName}
      </FeedHoverText> to the project <FeedHoverText onClick={onClickProject}>
        {project.data.title || activity.data.name}
      </FeedHoverText>
    </FeedItem>
  );
}
