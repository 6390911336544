import React  from 'react';
import moment from 'moment-timezone';
import TextColumn from '../../_components/table/columns/TextColumn';

export default function Sent(props) {
  const { stewardInvitation } = props;

  return (
    <TextColumn>
      {/*{moment(user.data.lastActiveAt).format('MMM D, YYYY')}*/}
      {moment(stewardInvitation.data.createdAt).fromNow()}
    </TextColumn>
  );
}
