import { extractArrayFromQuery } from '../../../utils/query';
import moment from 'moment-timezone';

export default {
  param: extractArrayFromQuery,
  query: function (values, timestamp) {
    if (!timestamp) {
      throw new Error('Required param (timestamp) is missing');
    }

    if (values.length > 0) {
      return {
        createdAt: {
          $or: values.map(function(value) {
            return {
              $gte: moment(timestamp).subtract(Number(value), 'weeks').toISOString()
            }
          })
        }
      }
    }
  }
}
