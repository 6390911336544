import React from 'react';

export default function TextField(props) {
  const {
    label,
    children,
    invert = false,
    showEmpty = false
  } = props;

  if (!children && !showEmpty) {
    return null;
  }

  if (invert) {
    return (
      <div>
        <dt className="text-sm font-medium text-gray-900 sm:w-40x sm:flex-shrink-0">
          {label}
        </dt>
        <dd className="mt-1 text-sm text-gray-500 sm:col-span-2">
          {children}
        </dd>
      </div>
    );
  }

  return (
    <div>
      <dt className="text-sm font-medium text-gray-500 sm:w-40x sm:flex-shrink-0">
        {label}
      </dt>
      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
        {children}
      </dd>
    </div>
  );
}
