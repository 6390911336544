import React, { useState } from 'react';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import useStewardshipFilter from '../../events/useStewardshipFilter';
import REQUIRED_FIELDS from '../../events/REQUIRED_FIELDS';
import MISSING_FIELDS from '../../events/MISSING_FIELDS';
import ChartLoader from '../../dashboard/_charts/ChartLoader';
import LineChart from '../../dashboard/_charts/LineChart';
import colors from 'tailwindcss/colors';

export default function ActivityByMonth(props) {
  const { timestamps, empty, schoolStart } = props;

  const stewardshipFilter = useStewardshipFilter();

  const [currentTimestamp] = useState(moment().toISOString());

  const partners = timestamps.map(function(timestamp) {
    if (moment(currentTimestamp).diff(timestamp.start) < 0) {
      return {
        ...timestamp,
        results: {
          // all: empty(),
          // draft: empty(),
          // published: empty(),
          new: empty(),
          active: empty(),
          completed: empty(),
          cancelled: empty()
        }
      }
    }

    return {
      ...timestamp,
      results: {
        // all: useConnect('event.find', {
        //   where: {
        //     eager: {
        //       $where: {
        //         activityId: {
        //           $not: null
        //         },
        //         createdAt: {
        //           $lte: timestamp.end,
        //           $gte: timestamp.schoolStart
        //         },
        //         $or: stewardshipFilter
        //         // ...accountQuery
        //       }
        //     }
        //   },
        //   pagination: {
        //     pageSize: 1
        //   }
        // }),
        // draft: useConnect('event.find', {
        //   where: {
        //     eager: {
        //       $where: {
        //         published: false,
        //         activityId: {
        //           $not: null
        //         },
        //         $and: {
        //           $or: {
        //             ...MISSING_FIELDS
        //           }
        //         },
        //         createdAt: {
        //           $lte: timestamp.end,
        //           $gte: schoolStart
        //         },
        //         $or: stewardshipFilter
        //       }
        //     }
        //   },
        //   pagination: {
        //     pageSize: 1
        //   }
        // }),
        // published: useConnect('event.find', {
        //   where: {
        //     eager: {
        //       $where: {
        //         published: true,
        //         activityId: {
        //           $not: null
        //         },
        //         $and: REQUIRED_FIELDS.$and,
        //         createdAt: {
        //           $lte: timestamp.end,
        //           $gte: schoolStart
        //         },
        //         $or: stewardshipFilter
        //       }
        //     }
        //   },
        //   pagination: {
        //     pageSize: 1
        //   }
        // }),
        new: useConnect('event.find', {
          where: {
            eager: {
              $where: {
                activityId: {
                  $not: null
                },
                createdAt: {
                  $gte: timestamp.start,
                  $lte: timestamp.end
                },
                $or: stewardshipFilter
              }
            }
          },
          pagination: {
            pageSize: 1
          }
        }),
        active: useConnect('event.find', {
          where: {
            eager: {
              $where: {
                $and: [
                  ...REQUIRED_FIELDS.$and,
                  {
                    $or: [
                      // starts during month
                      {
                        startDate: {
                          $gte: timestamp.start,
                          $lte: timestamp.end
                        }
                      },
                      // starts before month, ends after month
                      {
                        startDate: {
                          $lte: timestamp.start
                        },
                        endDate: {
                          $gte: timestamp.end
                        },
                      },
                      // ends during month
                      {
                        endDate: {
                          $gte: timestamp.start,
                          $lte: timestamp.end
                        }
                      }
                    ]
                  },
                  {
                    $or: stewardshipFilter,
                  }
                ]
              }
            }
          },
          pagination: {
            pageSize: 1
          }
        }),
        completed: useConnect('event.find', {
          where: {
            eager: {
              $where: {
                $and: REQUIRED_FIELDS.$and,
                completed: true,
                endDate: {
                  $gte: timestamp.start,
                  $lte: timestamp.end
                },
                $or: stewardshipFilter
              }
            }
          },
          pagination: {
            pageSize: 1
          }
        }),
        cancelled: useConnect('event.find', {
          where: {
            eager: {
              $where: {
                $and: REQUIRED_FIELDS.$and,
                cancelled: true,
                endDate: {
                  $gte: timestamp.start,
                  $lte: timestamp.end
                },
                $or: stewardshipFilter
              }
            }
          },
          pagination: {
            pageSize: 1
          }
        })
      }
    }
  });

  const loading = _.reduce(partners, function(result, partner) {
    return (
      result ||
      // partner.results.all.state === PayloadStates.FETCHING ||
      // partner.results.draft.state === PayloadStates.FETCHING ||
      // partner.results.published.state === PayloadStates.FETCHING ||
      partner.results.new.state === PayloadStates.FETCHING ||
      partner.results.active.state === PayloadStates.FETCHING ||
      partner.results.completed.state === PayloadStates.FETCHING ||
      partner.results.cancelled.state === PayloadStates.FETCHING
    );
  }, false)

  if (loading) {
    return (
      <ChartLoader
        title="Event Activity"
        subtitle="Over school year"
      />
    );
  }

  const series =[
    // {
    //   name: 'Total',
    //   data: partners.map(e => e.results.all.meta.totalCount)
    // },
    // {
    //   name: 'Draft',
    //   data: partners.map(e => e.results.draft.meta.totalCount)
    // },
    // {
    //   name: 'Published',
    //   data: partners.map(e => e.results.published.meta.totalCount)
    // },
    {
      name: 'New',
      data: partners.map(e => e.results.new.meta.totalCount)
    },
    {
      name: 'Active',
      data: partners.map(e => e.results.active.meta.totalCount)
    },
    {
      name: 'Completed',
      data: partners.map(e => e.results.completed.meta.totalCount)
    },
    {
      name: 'Cancelled',
      data: partners.map(e => e.results.cancelled.meta.totalCount)
    }
  ];

  return (
    <LineChart
      title="Event Activity"
      subtitle="Over school year"
      labels={partners.map(e => e.label)}
      series={series}
      colors={[
        colors.yellow['400'],
        colors.blue['400'],
        colors.emerald['400'],
        colors.red['400']
      ]}
    />
  );
}
