import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import Dialog from '../claim_invite';
import { useQueryClient } from '@tanstack/react-query';

export default function Launcher(props) {
  const config = useDialogConfig();
  const queryClient = useQueryClient();

  const offer = useConnect('offer.byId', {
    id: config.params.offerId
  });

  const user = useConnect('user.byId', {
    id: config.params.userId
  });

  if (
    offer.state === PayloadStates.FETCHING ||
      user.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  return (
    <Dialog
      offer={offer}
      user={user}
      onClose={props.onClose}
      onSubmit={function(request) {
        queryClient.invalidateQueries({ queryKey: ['claim'] });
      }}
    />
  );
};
