import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import ThickNavigation from '../../pages/_navigation/ThickNavigation';

MobileNavigationMenuDialog.propTypes = {
  router: PropTypes.object.isRequired
};

export default function MobileNavigationMenuDialog(props) {
  const { router } = props;

  // w-screen max-w-screen-sm
  return (
    <DialogTemplate className="w-full max-w-screen-sm" overflow={true}>
      <CloseDialogButton onClick={props.onClose} />
      <div className="flex">
        <div className="w-72 z-20 py-5">
          <ThickNavigation />
        </div>
      </div>
    </DialogTemplate>
  );
};
