import React  from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import TextField from '../../projects/_fields/TextField';
import { parse } from 'query-string';

export default function StudentsServed(props) {
  const { user } = props;

  // const query = {
  //   where: {
  //     eager: {
  //       $aggregations: [
  //         {
  //           type: 'sum',
  //           alias: 'numberOfStudentsServed',
  //           relation: 'project.educatorEvaluation',
  //           field: 'students'
  //         }
  //       ],
  //       $where: {
  //         userId: user.id
  //       }
  //     }
  //   },
  //   pagination: {
  //     pageSize: 1000,
  //     order: 'createdAt desc'
  //   }
  // };

  const evaluations = useConnect('evaluationProjectPartner.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'sum',
            alias: 'numberOfStudentsServed',
            relation: 'project.educatorEvaluation',
            field: 'students'
          }
        ],
        $where: {
          userId: user.id
        }
      }
    }
  });

  if (evaluations.state === PayloadStates.FETCHING) {
    return (
      <TextField label="Students served">
        ...
      </TextField>
    );
  }

  const hours = _.reduce(evaluations.data, function(result, evaluation) {
    return result + Number(evaluation.data.numberOfStudentsServed || 0);
  }, 0)

  return (
    <TextField label="Students served">
      {hours}
    </TextField>
  );
}
