import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from '../../../components_profiles/SectionHeader';
import Project from '../../projects/Project';
import Event from '../../events/Event';
import useActiveQuestionsForUser from '../useActiveQuestionsForUser';
import useActiveInvitationsForUser from '../useActiveInvitationsForUser';

ActiveInvitations.propTypes = {
  user: PropTypes.object.isRequired
};

export default function ActiveInvitations(props) {
  const { user } = props;

  const activeProjects = useActiveQuestionsForUser(user);
  const activeEvents = useActiveInvitationsForUser(user);

  if (
    activeProjects.data.length > 0 ||
    activeEvents.data.length > 0
  ) {
    return (
      <div className="space-y-4">
        <SectionHeader
          title="Active Experiences"
          subtitle="Projects and events that I am currently partnering on."
        />
        <div className="grid grid-cols-1 gap-4">
          {activeProjects.data.map(function(project) {
            return (
              <Project
                key={project.id || project.cid}
                project={project}
              />
            );
          })}
          {activeEvents.data.map(function(event) {
            return (
              <Event
                key={event.id || event.cid}
                event={event}
              />
            );
          })}
        </div>
      </div>
    );
  }

  return null;
}
