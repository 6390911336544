import React from 'react';
import Evaluations from './Evaluations';
import ParentLayout from './ParentLayout';

export default function Layout(props) {
  return (
    <ParentLayout title="Evaluations">
      {function(params) {
        return (
          <>
            <Evaluations params={params} />
          </>
        );
      }}
    </ParentLayout>
  );
}
