import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Avatar from '../../../components/Avatar';
import Loader from '../../../components/Loader';
import ContentCard from '../../../components_profiles/ContentCard';
import UserOrganization from '../../../hooks/UserOrganization.v2';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../DialogManager';

export default function Creator(props) {
  const { project } = props;

  const launch = useDialogLauncher();

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  if (creator.state === PayloadStates.FETCHING) {
    return (
      <div className="container page">
        <Loader />
      </div>
    );
  }

  function onClick() {
    launch(DialogManager.UserManage.Config(creator.id));
  }

  return (
    <ContentCard className="overflow-hidden">
      <div
        className="relative -m-6 p-6 hover:bg-cs-orange-50 cursor-pointer"
        onClick={onClick}
      >
        <div className="flex space-x-5">
          <Avatar size={20} src={creator.data.avatarUrl} />
          <div className="flex flex-col">
            <div className="text-cs-gray-500">
              Educator / Creator
            </div>
            <div className="text-2xl font-semibold text-cs-gray-900">
              {`${creator.data.firstName} ${creator.data.lastName}`.trim()}
            </div>
            <div className="text-base text-cs-gray-500">
              <UserOrganization user={creator} />
            </div>
          </div>
        </div>
      </div>
    </ContentCard>
  );
}
