import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';

UserOrganization.propTypes = {
  user: PropTypes.object.isRequired
};

export default function UserOrganization(props) {
  const { user } = props;

  const roles = useConnect('role.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt asc'
    }
  });

  const role = roles.data[0];

  const organization = useConnect('organization.byId', {
    id: role?.data.organizationId
  }, {
    enabled: !!role
  });

  if (roles.state === PayloadStates.FETCHING) {
    return '...';
  }

  if (!role) {
    return null;
  }

  return organization.data.name || null;
}
