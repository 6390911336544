import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import EmptyState from '../../_common/EmptyState';
import SectionTitle from '../../_common/SectionTitle';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Invite from './Invite';
import Project from './Project';
import Event from './Event';
import Offer from './Offer';
import Question from './Question';
import Invitation from './Invitation';
import Claim from './Claim';
import moment from 'moment-timezone';

export default function Layout(props) {
  const { user } = props;

  const params1 = {
    where: {
      eager: {
        $where: {
          creatorId: user.id,
          activityId: {
            $not: null
          }
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt desc'
    }
  };

  const params2 = {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt desc'
    }
  };

  const projects = useConnect('project.find', params1);
  const events = useConnect('event.find', params1);
  const offers = useConnect('offer.find', params1);

  const questions = useConnect('question.find', params2);
  const invitations = useConnect('invitation.find', params2);
  const claims = useConnect('claim.find', params2);

  if (
    projects.state === PayloadStates.FETCHING ||
    events.state === PayloadStates.FETCHING ||
    offers.state === PayloadStates.FETCHING ||
    questions.state === PayloadStates.FETCHING ||
    invitations.state === PayloadStates.FETCHING ||
    claims.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  const typeMap = {
    'project': Project,
    'event': Event,
    'offer': Offer,
    'question': Question,
    'invitation': Invitation,
    'claim': Claim
  };

  const fieldMap = {
    'project': 'project',
    'event': 'event',
    'offer': 'offer',
    'question': 'question',
    'invitation': 'invitation',
    'claim': 'claim'
  };

  let items = [];

  items = items.concat(
    projects.data.map((value, index) => ({
      type: 'project',
      value: (
        <Project
          key={`project-${index}`}
          project={value}
        />
      ),
      data: value,
      timestamp: value.data.createdAt
    }))
  ).concat(
    events.data.map((value, index) => ({
      type: 'event',
      value: (
        <Event
          key={`event-${index}`}
          event={value}
        />
      ),
      data: value,
      timestamp: value.data.createdAt
    }))
  ).concat(
    offers.data.map((value, index) => ({
      type: 'offer',
      value: (
        <Offer
          key={`offer-${index}`}
          offer={value}
        />
      ),
      data: value,
      timestamp: value.data.createdAt
    }))
  ).concat(
    questions.data.map((value, index) => ({
      type: 'question',
      value: (
        <Question
          key={`question-${index}`}
          question={value}
        />
      ),
      data: value,
      timestamp: value.data.createdAt
    }))
  ).concat(
    invitations.data.map((value, index) => ({
      type: 'invitation',
      value: (
        <Invitation
          key={`invitation-${index}`}
          invitation={value}
        />
      ),
      data: value,
      timestamp: value.data.createdAt
    }))
  ).concat(
    claims.data.map((value, index) => ({
      type: 'claim',
      value: (
        <Claim
          key={`claim-${index}`}
          claim={value}
        />
      ),
      data: value,
      timestamp: value.data.createdAt
    }))
  );

  items = _.sortBy(items, function(item) {
    return -moment(item.timestamp).unix();
  });

  return (
    <div className="page space-y-12">
      <div className="flex space-x-8">
        <div className="flex-1 space-y-12">
          <Invite user={user} />
          <SectionTitle
            title="Participation"
            subtitle="A list of projects, events, offers that this user has either created or been invited to."
          >
            {items.length > 0 ? (
              <div className="grid grid-cols-1 gap-4">
                {items.map(function(item, index) {
                  const Component = typeMap[item.type];

                  return (
                    <Component
                      key={index}
                      last={index === items.length - 1}
                      {...{
                        [fieldMap[item.type]]: item.data
                      }}
                    />
                  );
                })}
                {/*{projects.data.map(function(project) {*/}
                {/*  return (*/}
                {/*    <Project*/}
                {/*      key={project.id}*/}
                {/*      project={project}*/}
                {/*    />*/}
                {/*  );*/}
                {/*})}*/}
                {/*{events.data.map(function(event) {*/}
                {/*  return (*/}
                {/*    <Event*/}
                {/*      key={event.id}*/}
                {/*      event={event}*/}
                {/*    />*/}
                {/*  );*/}
                {/*})}*/}
                {/*{offers.data.map(function(offer) {*/}
                {/*  return (*/}
                {/*    <Offer*/}
                {/*      key={offer.id}*/}
                {/*      offer={offer}*/}
                {/*    />*/}
                {/*  );*/}
                {/*})}*/}
                {/*{questions.data.map(function(question) {*/}
                {/*  return (*/}
                {/*    <Question*/}
                {/*      key={question.id}*/}
                {/*      question={question}*/}
                {/*    />*/}
                {/*  );*/}
                {/*})}*/}
                {/*{invitations.data.map(function(invitation) {*/}
                {/*  return (*/}
                {/*    <Invitation*/}
                {/*      key={invitation.id}*/}
                {/*      invitation={invitation}*/}
                {/*    />*/}
                {/*  );*/}
                {/*})}*/}
                {/*{claims.data.map(function(claim) {*/}
                {/*  return (*/}
                {/*    <Claim*/}
                {/*      key={claim.id}*/}
                {/*      claim={claim}*/}
                {/*    />*/}
                {/*  );*/}
                {/*})}*/}
              </div>
            ) : (
              <EmptyState
                title="No participation in any projects, events, or offers."
              />
            )}
          </SectionTitle>
        </div>
      </div>
    </div>
  );
};
