import React from 'react';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import Dialog from '../csv_download';

export default function Launcher(props) {
  const config = useDialogConfig();

  const modelName = config.params.modelName;
  const query = JSON.parse(config.params.query);

  return (
    <Dialog
      modelName={modelName}
      query={query}
      onClose={props.onClose}
    />
  );
};
