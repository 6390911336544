import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Event from '../../events/Event';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Events']}
      title="Missing Educator Evaluation"
      description={(
        <span>
          These are events where an educator indicated they completed an event, but they have
          not yet provided outcomes. CommunityShare recommends following up with educators to
          complete the evaluation and finding ways to incentivize and celebrate completing an
          evaluation. Evaluation data populates your dashboard.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(event) {
        return (
          <Event key={event.id} event={event} />
        );
      }}
    />
  );
}
