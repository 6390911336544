import _ from 'lodash';
import { payload, PayloadStates } from '@lore/utils';
import payloadToModel from '../utils/payloadToModel';

/**
 * Update Blueprint
 */

export default function(key, { Model, modelName, queryClient, currentPayload }) {
  return {
    mutationFn: async function(params) {
      const nextModel = payloadToModel(currentPayload, Model, params);

      try {
        await nextModel.save();

        return payload(
          nextModel,
          PayloadStates.RESOLVED
        );
      } catch (err) {
        const response = err.response;
        const error = response.data;
        throw payload(nextModel, PayloadStates.ERROR_UPDATING, error);
      }
    },
    onMutate: async function(variables) {
      // const previousData = queryClient.getQueryData([modelName, 'byId', variables.id]);
      const optimisticModel = payloadToModel(currentPayload, Model, variables);

      // Create optimistic data
      const optimisticPayload = payload(optimisticModel, PayloadStates.UPDATING);

      // Add optimistic model to cache
      queryClient.setQueryData([modelName, 'byId', currentPayload.id], optimisticPayload);

      // Return context with the optimistic data
      return {
        previousPayload: currentPayload,
        optimisticPayload
      };
    },
    onSuccess: function(result, variables, context) {
      queryClient.setQueryData([modelName, 'byId', currentPayload.id], result);
    },
    onError: function(error, variables, context) {
      queryClient.setQueryData([modelName, 'byId', currentPayload.id], context.previousPayload);
    },
  }
};
