import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import useProjectTags from '../../../hooks/useProjectTags';
import TagTypes from '../../../constants/TagTypes';
import BaseParticipation from '../../../components_profiles/BaseParticipation';
import MissingField from '../ProjectMissingField';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';
import { ShareIcon } from '@heroicons/react/24/outline';
import Loader from '../../../components/Loader';
import ContentCard from '../../../components_profiles/ContentCard';

Participation.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Participation(props) {
  const { project } = props;

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  const projectInteractionTypes = useProjectTags(project, TagTypes.InteractionType);

  if (projectInteractionTypes.data.length === 0) {
    return (
      <ContentCard className="space-y-8">
        <SubSectionHeader
          icon={ShareIcon}
          title="Participation Options"
        />
        <MissingField name="Participation Options" />
      </ContentCard>
    );
  }

  return (
    <BaseParticipation
      creator={creator}
      interactionTypes={projectInteractionTypes}
    />
  );
}
