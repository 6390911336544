import React from 'react';
import PropTypes from 'prop-types';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { PayloadStates } from '@lore/utils';
import Template from './Template';
import Loader from '../../../components/Loader';
import useEventTags from '../../../hooks/useEventTags';
import TagTypes from '../../../constants/TagTypes';


export default function Experience(props) {
  const { event } = props;

  const eventGuidances = useEventTags(event, TagTypes.Guidance);

  if (eventGuidances.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (eventGuidances.data.length === 0) {
    return null;
  }

  return (
    <Template
      icon={QuestionMarkCircleIcon}
      title="Professional competencies"
      subtitle="Learners would benefit from partner addressing these topics."
      tags={eventGuidances}
    />
  );
};
