import React from 'react';
import classNames from 'classnames';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { useUser } from '@lore/auth';
import { formatDate, formatTime } from '../../utils/dateTimeUtils';

export default function InvitationChecker(props) {
  const { event, user, invitations } = props;

  const currentUser = useUser();

  const invitation = _.find(invitations.data, q => q.data.eventId === event.id);

  if (!invitation) {
    return null;
  }

  // return (
  //   <div className={classNames(
  //     // 'w-full grid grid-cols-1 gap-4',
  //     'bg-green-50 -m-4 p-4 w-full'
  //   )}>
  //     <div className="flex items-center space-x-2">
  //       <EnvelopeIcon className="text-cs-green-500 h-6 w-6" />
  //       <span className="text-sm">
  //         {user.data.firstName} was invited on {formatDate(invitation.data.createdAt)}, {formatTime(invitation.data.createdAt, currentUser.data.timezone, true)}
  //       </span>
  //     </div>
  //   </div>
  // );

  return (
    <div className={classNames(
      'bg-cs-green-500 p-4 w-full'
    )}>
      <div className="flex items-center space-x-2">
        <EnvelopeIcon className="text-white h-6 w-6" />
        <span className="text-sm text-white">
          {user.data.firstName} was invited on {formatDate(invitation.data.createdAt)}, {formatTime(invitation.data.createdAt, currentUser.data.timezone, true)}
        </span>
      </div>
    </div>
  );
}
