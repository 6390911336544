import React from 'react';
import PropTypes from 'prop-types';
import Questions from './Questions';
import EmptyState from '../../_common/EmptyState';
import SectionTitle from '../../_common/SectionTitle';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../DialogManager';

export default function Layout(props) {
  const { project } = props;

  const launch = useDialogLauncher();

  function onInvitePartner() {
    launch(DialogManager.ProjectInvite.Config(project.id));
  }

  return (
    <div className="page space-y-12">
      <div className="flex space-x-8">
        <div className="flex-1 space-y-12">
          {/*<SectionTitle*/}
          {/*  title="Invitations"*/}
          {/*  subtitle="Partners who expressed positive interest in the project."*/}
          {/*>*/}
            <div className="hover:shadow hover:opacity-80 cursor-pointer" onClick={onInvitePartner}>
              <EmptyState
                title="Find partners to invite"
                // subtitle="The more people that are invited, the better the chances of finding a match!"
                color="blue"
               />
            </div>
          {/*</SectionTitle>*/}
          <SectionTitle
            title="Connected"
            subtitle={(
              <>
                Partners who are discussing the idea, referring others who can help, or engaging
                around this project. Consider checking the conversation history to see if the
                educator may need additional partners.
              </>
            )}
          >
            <div className="grid grid-cols-1 gap-4">
              <Questions
                project={project}
                responses={[
                  'Connect'
                ]}
                emptyState={(
                  <EmptyState
                    title="No partners have expressed interest yet."
                    subtitle="The more people that are invited, the better the chances of finding a match!"
                  >
                    {/*<div className="mt-6 flex flex-col space-y-2">*/}
                    {/*  <div>*/}
                    {/*    <Link className="hover:text-orange-300 hover:underline" to={`/project/${project.id}/invite-populate`}>*/}
                    {/*      Find Partners*/}
                    {/*    </Link>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </EmptyState>
                )}
              />
            </div>
          </SectionTitle>
          <SectionTitle
            title="Invited"
            subtitle={(
              <>
                Partners who were invited to connect but have not yet responded. Consider
                following up with recent invitees, declining older invitations, and inviting
                new partners.
              </>
            )}
          >
            <div className="grid grid-cols-1 gap-4">
              <Questions
                project={project}
                responses={[
                  'Pending'
                ]}
                emptyState={(
                  <EmptyState
                    title="Invitations without a response will appear here."
                    // subtitle="Extra text..."
                  >
                    {/*<div className="mt-6 flex flex-col space-y-2">*/}
                    {/*  <div>*/}
                    {/*    <Link className="hover:text-orange-300 hover:underline" to={`/project/${project.id}/invite-populate`}>*/}
                    {/*      Find Partners*/}
                    {/*    </Link>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </EmptyState>
                )}
              />
            </div>
          </SectionTitle>
          <SectionTitle
            title="Declined"
            subtitle="Partners who are either uninterested or unable to participate."
          >
            <div className="grid grid-cols-1 gap-4">
              <Questions
                project={project}
                responses={[
                  'Decline'
                ]}
                emptyState={(
                  <EmptyState
                    title="Partners who turn down the invitation will appear here."
                    // subtitle="Partners who are unable to participate will show up here."
                  />
                )}
              />
            </div>
          </SectionTitle>
        </div>
      </div>
    </div>
  );
};
