import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from '../offer_manage/Header';
import OfferTabBar from '../offer_manage/OfferTabBar';
import OfferManageIdeaLayout from '../offer_manage/offer-manage-idea/Layout';
import OfferManageInvitationsLayout from '../offer_manage/offer-manage-invitations/Layout';
import OfferNotes from '../offer_manage/OfferNotes';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import useDialogRouter from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogRouter';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet-async';

OfferTemplate.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function OfferTemplate(props) {
  const { offer } = props;

  const { id, route } = useDialogConfig();
  const router = useDialogRouter(id);

  useEffect(() => {
    if (route === '/') {
      router.navigate('/invitations');
    }
  }, []);

  if (offer.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const pageMap = {
    '/idea': 'Idea',
    '/invitations': 'Connect'
  };

  const pathname = route.split('?')[0];

  const page = pageMap[pathname] || pathname;

  return (
    <div className="space-y-6 -mt-12">
      <Helmet>
        <title>
          {offer.data.title ? `${offer.data.title}: ${page}` : `Offer #${offer.id}: ${page}`}
        </title>
      </Helmet>
      <Header offer={offer} />
      <div className="-mx-6">
        <OfferNotes offer={offer} />
        <OfferTabBar offer={offer} />
      </div>
      <div className="space-y-12">
        {pathname === `/idea` && (
          <OfferManageIdeaLayout offer={offer} />
        )}
        {pathname === `/invitations` && (
          <OfferManageInvitationsLayout offer={offer} />
        )}
      </div>
    </div>
  );
};
