import React from 'react';

export default function IconSection(props) {
  const { title, subtitle, icon } = props;

  return (
    <div className="flex items-center space-x-1">
      <props.icon className="text-cs-gray-500 h-6 w-6"/>
      {/*<div className="flex">*/}
        <div className="flex-1 text-sm text-cs-gray-500">
          {title}
        </div>
        {/*{subtitle && (*/}
        {/*  <div className={xs ? 'text-xs text-cs-gray-400' : 'text-sm text-cs-gray-500'}>*/}
        {/*    {subtitle}*/}
        {/*  </div>*/}
        {/*)}*/}
      {/*</div>*/}
    </div>
  );
}
