import React from 'react';
import PropTypes from 'prop-types';
import ProjectBanner from './ProjectBanner';
import EventBanner from './EventBanner';
import OfferBanner from './OfferBanner';
// import RedirectBanner from './RedirectBanner';

Banner.propTypes = {
  user: PropTypes.object.isRequired
};

export default function Banner(props) {
  const {
    user,
    project,
    event,
    offer,
    onClose
  } = props;

  if (project) {
    return (
      <ProjectBanner
        user={user}
        project={project}
      />
    );
  }

  if (event) {
    return (
      <EventBanner
        user={user}
        event={event}
      />
    );
  }

  if (offer) {
    return (
      <OfferBanner
        user={user}
        offer={offer}
      />
    );
  }

  // return (
  //   <RedirectBanner
  //     user={user}
  //     onClose={onClose}
  //   />
  // );

  return null;
}
