import React from 'react';
import { useConnect } from '@lore/query-connect';
import REQUIRED_FIELDS from '../../projects/REQUIRED_FIELDS';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';

export default function TotalAssociatedWithProject(props) {
  const { account, schoolStart, schoolEnd, titleOnly } = props;

  const allSchools = useConnect('organization.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfProjects',
            relation: 'users.projects',
            $where: {
              $and: REQUIRED_FIELDS.$and,
              'creator.isEducator': true,
              accountId: account.id
            }
          }
        ],
        $where: {
          numberOfProjects: {
            $gte: 1
          },
          createdAt: {
            $lte: schoolEnd
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const newSchools = useConnect('organization.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfProjects',
            relation: 'users.projects',
            $where: {
              $and: REQUIRED_FIELDS.$and,
              'creator.isEducator': true,
              accountId: account.id
            }
          }
        ],
        $where: {
          numberOfProjects: {
            $gte: 1
          },
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  if (
    allSchools.state === PayloadStates.FETCHING ||
    newSchools.state === PayloadStates.FETCHING
  ) {
    return (
      <MetricLoader
        name="Total associated with Project"
      />
    );
  }

  return (
    <DeltaMetric
      name="Total associated with Project"
      value={allSchools.meta.totalCount}
      delta={newSchools.meta.totalCount}
      titleOnly={titleOnly}
    />
  );
}
