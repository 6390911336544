import React  from 'react';
import moment from 'moment-timezone';
import TextColumn from '../TextColumn';

export default function Joined(props) {
  const { user } = props;

  return (
    <TextColumn>
      {moment(user.data.createdAt).format('MMM D, YYYY')}
    </TextColumn>
  );
}
