import React from 'react';

export default function TabBar(props) {
  const { children } = props;

  return (
    <div className="flex items-center justify-center px-6 py-4 bg-cs-gray-100x border-t border-b border-cs-gray-100">
      <div className="flex flex-wrap gap-4 justify-center">
        {children}
      </div>
    </div>
  );
};
