import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import StringField from './StringField';

export default function SearchFilter(props) {
  const {
    label,
    description,
    placeholder,
    tooltip,
    value,
    onChange
  } = props;

  return (
    <StringField
      label={label}
      description={description}
      icon={MagnifyingGlassIcon}
      placeholder={placeholder}
      value={value}
      tooltip={tooltip}
      onChange={(value) => onChange(value)}
    />
  );
};
