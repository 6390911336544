import React from 'react';
import { UseQueryConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { Disclosure } from '@headlessui/react';
import useTags from '../../hooks/useTags';
import DisclosureTagFilterButton from './DisclosureTagFilterButton';
import CheckboxField from './CheckboxField';
import PropTypes from 'prop-types';
import DisclosureBaseFilter from './DisclosureBaseFilter';

DisclosureTagFilter.propTypes = {
  getParams: PropTypes.func.isRequired
};

export default function DisclosureTagFilter(props) {
  const {
    type,
    label,
    tooltip,
    getParams,
    value = [],
    onChange,
    modelName = 'user'
  } = props;

  // const numberSelected = value.length;

  // function isTagSelected(tag) {
  //   return !!_.find(value, function(id) {
  //     return id === tag.id;
  //   });
  // }

  const tags = useTags(type);

  return (
    <DisclosureBaseFilter
      label={label}
      tooltip={tooltip}
      getParams={getParams}
      value={value}
      onChange={onChange}
      modelName={modelName}
      tags={tags}
    />
  );
};
