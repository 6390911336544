import { extractTextFromQuery } from '../../../utils/query';

export default {
  param: extractTextFromQuery,
  query: function (text) {
    if (text) {
      return {
        $or: [
          {
            biography: {
              $ilike: `%${text}%`
            }
          },
          {
            id: {
              $includes_any_ilike: {
                'tagNodes.description': `%${text}%`
              }
            }
          }
        ]
      }
    }
  }
}
