import React from 'react';
import { useConnect } from '@lore/query-connect';

export default function useInvitationsReal(account, schoolStart, schoolEnd, extra = {}, eager = {}) {
  return useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          'event.accountId': account.id,
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          },
          ...extra
        },
        ...eager
      }
    },
    pagination: {
      pageSize: 1000
    }
  });
}
