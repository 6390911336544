import React from 'react';
import Bookmark from '../../_components/Bookmark';
import Date from '../../../dialogs_new/event_manage/Header/Date';
import Slots from '../../../dialogs_new/event_manage/Header/Slots';
import Filled from '../../../dialogs_new/event_manage/Header/Filled';
import Creator from '../../../dialogs_new/event_manage/Header/Creator';
import Partners from './Partners';
import Organization from './Organization';
import Notes from './Notes';
import Title from './Title';
import StatusTags from './StatusTags';
import Description from './Description';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function Row(props) {
  const { event, children } = props;

  const launch = useDialogLauncher();

  function onClick(event) {
    launch(DialogManager.EventManage.Config(event.id));
  }

  return (
    <div className="bg-white border border-gray-200 shadow rounded-lg overflow-hidden divide-y divide-gray-200 relative">
      <div className="absolute z-10 top-4 right-5 pt-0.5 pr-0.5">
        <Bookmark
          type="event"
          resource={event}
        />
      </div>
      <div className="flex flex-col p-4 space-y-2">
        <div className="space-y-2">
          <StatusTags event={event} />
          <div className="cursor-pointer hover:text-gray-500 space-y-1 pr-10" onClick={() => onClick(event)}>
            <Title event={event} />
            <Description event={event} />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            <Date event={event} />
            <Slots event={event} />
            <Filled event={event} />
            <Creator event={event} />
            <Organization event={event} />
          </div>
          <Partners event={event} />
        </div>
      </div>
      {children && (
        <div className="flex p-4">
          {children}
        </div>
      )}
      <div className="bg-gray-50 p-4">
        <Notes event={event} />
      </div>
    </div>
  );
}
