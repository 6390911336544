import React from 'react';
import PartnerProjectEvaluationHeaderSection from '../../pages/evaluations/PartnerProjectEvaluation/PartnerProjectEvaluationHeaderSection';
import ShareLinkSection from '../../pages/evaluations-project-partners/EvaluationProjectPartner/ShareLinkSection';
import Notes from '../../pages/evaluations-project-partners/EvaluationProjectPartner/Notes';
import Card from '../../components/Card';

export default function Evaluation(props) {
  const { project, evaluationProjectPartner } = props;

  return (
    <Card className="overflow-hidden divide-y divide-gray-100">
      <PartnerProjectEvaluationHeaderSection
        evaluation={evaluationProjectPartner}
        created={project.data.creatorId === evaluationProjectPartner.data.userId}
        showViewLink={false}
      />
      {!evaluationProjectPartner.data.completed && (
        <ShareLinkSection evaluation={evaluationProjectPartner} />
      )}
      <div className="bg-gray-50 p-4">
        <Notes evaluationProjectPartner={evaluationProjectPartner} />
      </div>
    </Card>
  );
};
