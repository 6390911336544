import React, { useState } from 'react';
import _ from 'lodash';
import ReactApexChart  from 'react-apexcharts';
import { useConnect } from '@lore/query-connect';
import Chart from '../../dashboard-year/Chart';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import moment from 'moment-timezone';
import { REQUIRED_EDUCATOR_FIELDS } from '../../../utils/user/REQUIRED_FIELDS';
import useStewardshipFilter from '../../educators/useStewardshipFilter';
import ChartHeader from './ChartHeader';
import tailwindColors from 'tailwindcss/colors';
import Card from '../../../components/Card';
import LineChartLegend from './LineChartLegend';
import defaultColors from './defaultColors';

export default function LineChart(props) {
  const {
    title,
    subtitle,
    labels = [],
    series = [],
    colors = defaultColors,
    legend = true
  } = props;

  const options = {
    // title: {
    //   text: 'Educator Engagement',
    //   align: 'center'
    // },
    // subtitle: {
    //   text: 'Over school year',
    //   align: 'center'
    // },
    chart: {
      type: 'area',
      height: 350,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: true
    },
    stroke: {
      curve: 'smooth',
      // show: true,
      width: 2,
      // colors: ['transparent']
    },
    xaxis: {
      show: false,
      categories: labels,
      // axisTicks: {
      //   show: true,
      //   color: '#ff0000'
      // },
    },
    grid: {
      show: true,
      // borderColor: '#90A4AE',
      borderColor: tailwindColors.neutral['100'],
      // position: 'front',
      // row: {
      //   colors: ['#e5e5e5', 'transparent'],
      //   opacity: 0.5
      // },
      // column: {
      //   colors: ['#f8f8f8', 'transparent'],
      // },
      xaxis: {
        lines: {
          show: true,
          color: '#ff0000'
        }
      },
      yaxis: {
        lines: {
          show: true,
          color: '#ff0000'
        }
      }
    },
    // axisTicks: {
    //   show: false
    // },
    // axisBorder: {
    //   show: true,
    //   color: '#ff0000'
    // },
    yaxis: {
      show: false,
      title: {
        text: 'Count'
      },
      // axisBorder: {
      //   show: true
      // },
      // axisTicks: {
      //   show: true,
      //   color: '#ff0000'
      // }
    },
    fill: {
      // type: "gradient",
      opacity: 1
    },
    // tooltip: {
    //   y: {
    //     formatter: function (val) {
    //       return "$ " + val + " thousands"
    //     }
    //   }
    // },
    colors: colors,
    legend: {
      show: false,
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      offsetY: 0
    }
  };

  return (
    <Card className="pt-2 pb-4 px-4 relative space-y-6">
      <ChartHeader
        title={title}
        subtitle={subtitle}
      />
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={350}
      />
      {legend && (
        <LineChartLegend
          series={series}
          colors={colors}
        />
      )}
    </Card>
  );
}
