import React from 'react';
import PropTypes from 'prop-types';
import Option from './Option';
import { XMarkIcon } from '@heroicons/react/24/outline';

export default function Disabled(props) {
  const { selected, onClick } = props;

  return (
    <Option
      color="red"
      title="Disabled"
      icon={XMarkIcon}
      subtitle={(
        <span>
          Educators who create a project will NOT have the option to indicate they'd
          like to be considered for project funding and provide a budget outline. They will
          still get asked about resources they need generally so that partners in the platform
          will be able to see and potentially help.
        </span>
      )}
      selectable={true}
      selected={selected}
      onClick={onClick}
    />
  );
}
