import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '../../components/Card';

Setting.propTypes = {
  // icon: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

export default function Setting(props) {
  const {
    icon,
    title,
    description,
    onClick,
    selected
  } = props;

  return (
    <Card
      background={false}
      border={false}
      className={classNames(
        'flex hover:bg-cs-orange-50 cursor-pointer p-4 space-x-4',
        selected ? 'bg-cs-orange-50 -m-0.5 border-2 border-cs-orange-500' : '-m-px border border-cs-gray-100 bg-white'
      )}
      onClick={onClick}
    >
      <div>
        <props.icon className="w-8 h-8 text-cs-orange-500" />
      </div>
      <div className="flex-1 space-y-0.5">
        <h2 className="text-base font-semibold text-cs-gray-900">
          {title}
        </h2>
        <div className="text-sm text-cs-gray-500">
          {description}
        </div>
      </div>
    </Card>
  );
}
