import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Project from '../../projects/Project';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Projects']}
      title="Starred Projects"
      description={(
        <span>
          These are projects that you starred. CommunityShare recommends using this page to help
          track projects you want to pay special attention to.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function (project) {
        return (
          <Project key={project.id} project={project} />
        );
      }}
    />
  );
}
