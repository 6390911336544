import React from 'react';
import EducatorEventEvaluationHeaderSection from '../../pages/evaluations/EducatorEventEvaluation/EducatorEventEvaluationHeaderSection';
import ShareLinkSection from '../../pages/evaluations-event-educators/EvaluationEventEducator/ShareLinkSection';
import Notes from '../../pages/evaluations-event-educators/EvaluationEventEducator/Notes';
import Card from '../../components/Card';

export default function Evaluation(props) {
  const { event, evaluationEventEducator } = props;

  return (
    <Card className="overflow-hidden divide-y divide-gray-100">
      <EducatorEventEvaluationHeaderSection
        evaluation={evaluationEventEducator}
        created={event.data.creatorId === evaluationEventEducator.data.userId}
        showViewLink={false}
      />
      {!evaluationEventEducator.data.completed && (
        <ShareLinkSection evaluation={evaluationEventEducator} />
      )}
      <div className="bg-gray-50 p-4">
        <Notes evaluationEventEducator={evaluationEventEducator} />
      </div>
    </Card>
  );
};
