/**
 * Configuration file for the Filestack file picker
 *
 * This file is where you define overrides for the default file picker parameters
 */

export default {

  /**
   * API key associated with account files should be uploaded to
   */

  apiKey: 'Ay4RfxsIeTCa6ESrtJeeTz',

  /**
   * Specify maximum image dimensions.Images bigger than the specified dimensions will be resized to
   * the maximum size while maintaining the original aspect ratio. The output will not be exact
   * unless the imageMax matches the aspect ratio of the original image.
   *
   * https://filestack.github.io/filestack-js/interfaces/pickeroptions.html#imagemax
   */

  imageMax: [960, 960]
};
