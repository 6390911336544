import React from 'react';
import { useConnect } from '@lore/query-connect';
import Entry from '../../../pages/user/User/Entry';
import classNames from 'classnames';
import getUrlForUpload from '../../../utils/getUrlForUpload';
import ImageChildBuilder from '../../../pages/evaluations/_common/ImageChildBuilder';
import Images from '../../../pages/story/Story/Images';
import { EmptyState } from '../../../@communityshare/ui/EmptyState';
import { PhotoIcon } from '@heroicons/react/24/outline';

export default function Photographs(props) {
  const { evaluation } = props;

  const evaluationUploads = useConnect('upload.find', {
    where: {
      eager: {
        $where: {
          evaluationEventEducatorId: evaluation.id
        }
      }
    }
  });

  return (
    <div className="space-y-4">
      {evaluationUploads.data.length > 0 ? (
        <>
          <Entry
            title="Do you have permission to share these images?"
            description={evaluation.data.releasePermission ? 'Yes' : 'No'}
          />
          <Images
            className={classNames(
              'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4',
              evaluationUploads._isPreviousData && 'opacity-50'
            )}
            imageHeight="h-64 sm:h-64 lg:h-64"
            images={{
              data: evaluationUploads.data.map(function(upload) {
                const url = getUrlForUpload(upload);

                return {
                  id: upload.id,
                  data: {
                    name: upload.data.name,
                    imageUrl: url,
                    // width: 0,
                    // height: 0
                  },
                  _upload: upload
                };
              })
            }}
            imageChild={function(image) {
              return (
                <ImageChildBuilder upload={image._upload} />
              );
            }}
          />
        </>
      ) : (
        <EmptyState
          icon={PhotoIcon}
          title="No images were uploaded."
        />
      )}
    </div>
  );
}
