import React from 'react';
import classNames from 'classnames';

export default function FeedItem(props) {
  const { last = false, children, timestamp } = props;

  return (
    <li className="relative flex gap-x-4">
      <div
        className={classNames(
          last ? 'h-6' : '-bottom-6',
          'absolute left-0 top-0 flex w-6 justify-center'
        )}
      >
        <div className="w-px bg-gray-200" />
      </div>
      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
        <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
      </div>
      <div className="flex-auto py-0.5 text-sm leading-5 text-gray-500">
        {children}
        <div className="pt-1 block sm:hidden">
          {timestamp}
        </div>
      </div>
      <div className="flex-none py-0.5 text-sm leading-5 text-gray-500 hidden sm:block">
        {timestamp}
      </div>
    </li>
  );
}
