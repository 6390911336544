/*
 * Root component
 */

import React, { useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ConfigContext } from '@lore/config';
import { ConnectProvider as QueryConnectProvider } from '@lore/query-connect';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import routes from './routes';
import { HelmetProvider } from 'react-helmet-async';

function App(props) {
  const {
    config,
    models,
    collections
  } = props;

  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        // staleTime: Infinity,
        staleTime: 3000,
        networkMode: 'always'
      }
    }
  }));

  window.queryClient = queryClient;

  return (
    <HelmetProvider>
      <ConfigContext.Provider value={config}>
        <QueryClientProvider client={queryClient}>
          <QueryConnectProvider
            models={models}
            collections={collections}
          >
            <RouterProvider router={routes} />
          </QueryConnectProvider>
        </QueryClientProvider>
      </ConfigContext.Provider>
    </HelmetProvider>
  );
}

// export default hot(module)(App);
export default App;
