import React from 'react';
import useQuestionsQuery from '../questions/useQuery';
import useInvitationsQuery from '../invitations/useQuery';
import useClaimsQuery from '../claims/useQuery';
import { PayloadStates } from '@lore/utils';

export default function useQuery(props) {
  const questions = useQuestionsQuery(props);
  const invitations = useInvitationsQuery(props);
  const claims = useClaimsQuery(props);

  if (
    questions.state === PayloadStates.FETCHING ||
    invitations.state === PayloadStates.FETCHING ||
    claims.state === PayloadStates.FETCHING
  ) {
    return {
      state: PayloadStates.FETCHING,
      data: [],
      meta: undefined
    };
  }

  return {
    state: PayloadStates.RESOLVED,
    data: [].concat(
      questions.data,
      invitations.data,
      claims.data
    ),
    meta: {
      totalCount: (
        questions.meta.totalCount +
        invitations.meta.totalCount +
        claims.meta.totalCount
      )
    }
  };
}
