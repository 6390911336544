import React from 'react';
import usePartners from './usePartners';
import { REQUIRED_PARTNER_FIELDS } from '../../../../utils/user/REQUIRED_FIELDS';

export default function useActivePartners(account, schoolStart, schoolEnd, currentTimestamp) {
  return usePartners(account, schoolStart, schoolEnd, {
    ...REQUIRED_PARTNER_FIELDS,
    inactive: false,
    $or: [
      {
        sleepingStartingAt: {
          $gte: currentTimestamp
        }
      },
      {
        sleepingEndingAt: {
          $lte: currentTimestamp
        }
      }
    ]
  });
}
