import React from 'react';
import { useConfig } from '@lore/config';
import EvaluationLinkSection from '../../evaluations/_common/EvaluationLinkSection';

export default function ShareLinkSection(props) {
  const { evaluation, description } = props;

  const config = useConfig();
  const platformUrl = config.custom.platformUrl();

  return (
    <EvaluationLinkSection
      profileType="partner"
      href={`${platformUrl}/evaluation-event-partner/${evaluation.id}`}
      description={description}
    />
  );
};
