import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Invitation from '../../event_manage/event-manage-invitations/Invitation';
import EventHeader from '../../../pages/events/Row/Header';

export default function InvitationWrapper(props) {
  const { invitation } = props;

  const event = useConnect('event.byId', {
    id: invitation.data.eventId
  });

  return (
    <Invitation
      invitation={invitation}
      alignChildren="top"
    >
      {event.state === PayloadStates.FETCHING ? (
        <Loader />
      ) : (
        <EventHeader event={event} />
      )}
    </Invitation>
  );
};
