import React from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { PayloadStates } from '@lore/utils';
import Template from './Template';
import Loader from '../../../components/Loader';
import useOfferTags from '../../../hooks/useOfferTags';
import TagTypes from '../../../constants/TagTypes';

export default function Experience(props) {
  const { offer } = props;

  const offerGuidances = useOfferTags(offer, TagTypes.Guidance);

  if (offerGuidances.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (offerGuidances.data.length === 0) {
    return null;
  }

  return (
    <Template
      icon={QuestionMarkCircleIcon}
      title="Professional competencies"
      subtitle="Offer addresses these topics."
      tags={offerGuidances}
    />
  );
};
