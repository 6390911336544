import React from 'react';
import classNames from '../../utils/classNames';
import Card from '../../components/Card';
import Loader from '../../components/Loader';

export default function MetricLoader(props) {
  const { name } = props;

  return (
    <Card className="py-8 px-10 text-center">
      <div
        className={classNames(
          'text-sm font-semiboldx text-cs-gray-700 uppercasex',
          'mb-3'
        )}
      >
        {name}
      </div>
      <Loader />
    </Card>
  );
}
