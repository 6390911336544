import React from 'react';
import NavigationHeader from '../_navigation/ThickNavigation/NavigationHeader';
import NavigationGroup from '../_navigation/ThickNavigation/NavigationGroup';

export default function SidebarTitle(props) {
  const { title } = props;

  return (
    <NavigationGroup>
      <NavigationHeader>
        {title}
      </NavigationHeader>
    </NavigationGroup>
  );
}
