import React from 'react';
import StatusTag from '../_common_evaluation/StatusTag';
import EventHeader from '../../pages/events/Row/Header';
import Card from '../../components/Card';

export default function Header(props) {
  const { event, evaluationEventEducator } = props;

  return (
    <div className="flex flex-col space-y-6">
      <div className="space-y-2">
        <div className="flex space-x-4">
          <div className="text-sm text-gray-500">
            Event Story
          </div>
          <div className="flex space-x-2">
            <StatusTag evaluation={evaluationEventEducator} />
          </div>
        </div>
        <div className="text-3xl font-bold text-cs-gray-700">
          Event Story
        </div>
      </div>
      <Card>
        <EventHeader
          event={event}
          tags={false}
        />
      </Card>
    </div>
  );
};
