import React from 'react';
import ChartHeader from './ChartHeader';
import Card from '../../../components/Card';
import LegendChartLegend from './LegendChartLegend';
import colors from 'tailwindcss/colors';

export default function LegendChart(props) {
  const {
    title,
    subtitle,
    series = [],
    labels = [],
    columns = 2
  } = props;

  const defaultColors = [
    // colors.slate['400'],
    // colors.gray['400'],
    // colors.zinc['400'],
    // colors.neutral['400'],
    // colors.stone['400'],
    colors.red['400'],
    colors.orange['400'],
    // colors.amber['400'],
    colors.yellow['400'],
    colors.lime['400'],
    // colors.green['400'],
    colors.emerald['400'],
    colors.teal['400'],
    colors.cyan['400'],
    colors.sky['400'],
    colors.blue['400'],
    colors.indigo['400'],
    colors.violet['400'],
    // colors.purple['400'],
    colors.fuchsia['400'],
    colors.pink['400'],
    colors.rose['400']
  ];

  return (
    <Card className="pt-2 pb-4 px-4 relative space-y-6">
      <ChartHeader
        title={title}
        subtitle={subtitle}
      />
      <LegendChartLegend
        series={series}
        labels={labels}
        colors={defaultColors}
        columns={columns}
      />
    </Card>
  );
}
