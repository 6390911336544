/**
 * Configuration file for FullStory
 *
 * This file is where you define overrides for the default auth0 parameters.
 */

export default {
  orgId: 'S4YED',
  enabled: false
};
