/**
 * Configuration file for Auth0
 *
 * This file is where you define overrides for the default auth0 parameters.
 */

export default {
  clientID: 'B6r4Hyjjx9moMLYdeyn48MVfo1hhgYvQ',
  domain: 'auth.communityshare.org',
  redirectUri: `http://steward.communityshare.test/auth/callback`,
  audience: 'https://communityshare.auth0.com/userinfo',
  responseType: 'token id_token',
  scope: 'openid profile email'
};
