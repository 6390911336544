import React from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { PayloadStates } from '@lore/utils';
import Template from '../../../components_profiles/Template';
import Loader from '../../../components/Loader';
import useProjectTags from '../../../hooks/useProjectTags';
import TagTypes from '../../../constants/TagTypes';


export default function Experience(props) {
  const { project } = props;

  const projectGuidances = useProjectTags(project, TagTypes.Guidance);

  if (projectGuidances.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (projectGuidances.data.length === 0) {
    return null;
  }

  return (
    <Template
      icon={QuestionMarkCircleIcon}
      title="Professional competencies"
      subtitle="Learners would benefit from partner addressing these topics."
      tags={projectGuidances}
    />
  );
};
