import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import Tooltip from '../../pages/_post-opportunity/forms/Tooltip';
import classNames from 'classnames';

export default function DisclosureTagFilterButton(props) {
  const { label, tooltip, open } = props;

  return (
    <Disclosure.Button className={classNames(
      'flex w-full justify-between rounded-lg px-4 py-3',
      // 'bg-purple-100',
      'bg-cs-gray-100',
      // 'hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'
      'hover:bg-cs-gray-200'
    )}>
      <span className="flex items-center space-x-1">
        <span className="text-cs-gray-900 text-base font-semibold leading-6">
          {label}
        </span>
        {tooltip && (
          <div>
            <Tooltip tooltip={tooltip} label={true} />
          </div>
        )}
      </span>
      <ChevronUpIcon
        className={classNames(
          open ? 'rotate-180 transform' : '',
          // 'h-5 w-5',
          'h-6 w-6',
          // 'text-purple-500',
          'text-cs-gray-500',
        )}
      />
    </Disclosure.Button>
  );
};
