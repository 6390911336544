import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import Avatar from '../../../../components/Avatar';
import Loader from '../../../../components/Loader';
import Card from '../../../../components/Card';
import PartnerProjectEvaluation from '../../../../pages/evaluations/PartnerProjectEvaluation';

export default function Question(props) {
  const {
    question,
    children,
    alignChildren = 'bottom'
  } = props;

  const recipient = useConnect('user.byId', {
    id: question.data.userId
  });

  const creator = useConnect('user.byId', {
    id: question.data.creatorId
  });

  const project = useConnect('project.byId', {
    id: question.data.projectId
  });

  const evaluation = useConnect('evaluationProjectPartner.first', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          userId: recipient.id
        }
      }
    }
  });

  if (
    recipient.state === PayloadStates.FETCHING ||
    project.state === PayloadStates.FETCHING ||
    creator.state === PayloadStates.FETCHING ||
    evaluation.state === PayloadStates.FETCHING
  ) {
    return (
      <div className="flex bg-white shadow-lg rounded-xl p-2.5 w-full">
        <Loader />
      </div>
    );
  }

  /*
   * If evaluation exists, display it.
   */
  if (evaluation.state === PayloadStates.RESOLVED) {
    return (
      <PartnerProjectEvaluation
        evaluation={evaluation}
        project={project}
        children={children}
        alignChildren={alignChildren}
      />
    );
  }

  /*
   * If result is 'Nothing' it means the partner connected, but was later classified as not
   * having contributed or participated, so it is expected for an evaluation to not exist.
   */
  if (
    question.data.result === 'Nothing'
  ) {
    return (
      <Card className="overflow-hidden divide-y divide-gray-200">
        {alignChildren === 'top' && children}
        <div className="flex justify-between p-4">
          <div className="flex flex-1 items-center space-x-4">
            <Avatar size={12} src={recipient.data.avatarUrl}/>
            <div className="flex flex-col">
              <div className="text-lg font-semibold">
                {recipient.data.firstName} {recipient.data.lastName}
              </div>
              <div className="text-sm text-gray-500 space-x-1">
                {creator.id === recipient.id ? (
                  <span>Reached out</span>
                ) : (
                  <span>Invited by {creator.data.firstName} {creator.data.lastName} {project.data.creatorId !== creator.id ? '(Steward)' : ''}</span>
                )}
                <span>·</span>
                <span>{moment(question.data.createdAt).fromNow()}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col text-right">
            <div className="text-lg font-semibold text-red-500 space-x-1">
              <span>No involvement</span>
            </div>
            <div className="flex text-sm text-gray-500 space-x-1">
              Partner did not contribute or participate.
            </div>
          </div>
        </div>
        {alignChildren === 'bottom' && children && (
          <div className="flex p-4">
            {children}
          </div>
        )}
      </Card>
    );
  }

  /*
   * If an invitation has a result at this point then there should be an evaluation. If
   * there isn't this is an issue and support should investigate and create the missing evaluation.
   */
  if (question.data.result) {
    return (
      <Card className="overflow-hidden divide-y divide-gray-200">
        {alignChildren === 'top' && children}
        <div className="flex justify-between p-4">
          <div className="flex flex-1 items-center space-x-4">
            <Avatar size={12} src={recipient.data.avatarUrl}/>
            <div className="flex flex-col">
              <div className="text-lg font-semibold">
                {recipient.data.firstName} {recipient.data.lastName}
              </div>
              <div className="text-sm text-gray-500 space-x-1">
                {creator.id === recipient.id ? (
                  <span>Reached out</span>
                ) : (
                  <span>Invited by {creator.data.firstName} {creator.data.lastName} {project.data.creatorId !== creator.id ? '(Steward)' : ''}</span>
                )}
                <span>·</span>
                <span>{moment(question.data.createdAt).fromNow()}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col text-right">
            <div className="text-lg font-semibold text-red-500 space-x-1">
              <span>Evaluation missing</span>
            </div>
            <div className="flex text-sm text-gray-500 space-x-1">
              This should not happen. Contact support and ask them to investigate.
            </div>
          </div>
        </div>
        {alignChildren === 'bottom' && children && (
          <div className="flex p-4">
            {children}
          </div>
        )}
      </Card>
    );
  }

  if (question.data.response === 'Connect') {
    return (
      <Card className="overflow-hidden divide-y divide-gray-200">
        {alignChildren === 'top' && children}
        <div className="flex justify-between p-4">
          <div className="flex flex-1 items-center space-x-4">
            <Avatar size={12} src={recipient.data.avatarUrl}/>
            <div className="flex flex-col">
              <div className="text-lg font-semibold">
                {recipient.data.firstName} {recipient.data.lastName}
              </div>
              <div className="text-sm text-gray-500 space-x-1">
                {creator.id === recipient.id ? (
                  <span>Reached out</span>
                ) : (
                  <span>Invited by {creator.data.firstName} {creator.data.lastName} {project.data.creatorId !== creator.id ? '(Steward)' : ''}</span>
                )}
                <span>·</span>
                <span>{moment(question.data.createdAt).fromNow()}</span>
              </div>
            </div>
          </div>
          {question.data.response === 'Connect' && (
            <div className="flex flex-col text-right">
              <div className="text-lg font-semibold text-gray-700 space-x-1">
                <span>Participation unknown</span>
              </div>
              <div className="flex text-sm text-gray-500">
                Ask the educator to classify the partner.
              </div>
            </div>
          )}
        </div>
        {alignChildren === 'bottom' && children && (
          <div className="flex p-4">
            {children}
          </div>
        )}
      </Card>
    );
  }

  return null;
};
