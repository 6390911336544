import React from 'react';
import { DialogManager } from '../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import User from './User';
import { useConnect } from '@lore/query-connect';
import _ from 'lodash';

export default function ProjectUser(props) {
  const {
    project,
    user,
    children
  } = props;

  const launch = useDialogLauncher();

  const questions = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  });

  const question = _.find(questions.data, function(question) {
    return question.data.userId === user.id;
  });

  const selected = !!question;

  function onClick() {
    launch(DialogManager.UserView.Config(user.id, {
      modelName: 'project',
      modelId: project.id
    }));
  }

  return (
    <User
      user={user}
      selected={selected}
      footer={question ? question.data.response : undefined}
      children={children}
      onClick={onClick}
    />
  );
}
