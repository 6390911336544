import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import useQuery from './useQuery';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import Evaluation from './Evaluation';
import useSchoolYearParams from '../../evaluations/useSchoolYearParams';
import { parse } from 'query-string';

export default function Layout(props) {
  const { location } = useRouter();
  const launch = useDialogLauncher();

  const params = useSchoolYearParams();

  const PAGE_SIZE = 12;

  const result = useQuery({
    location,
    search: parse(location.search),
    PAGE_SIZE,
    account: params.account,
    schoolStart: params.schoolStart,
    schoolEnd: params.schoolEnd
  });

  const csvQuery = useQuery({
    location,
    PAGE_SIZE,
    returnRawQuery: true
  });

  function onDownload(query) {
    launch(DialogManager.CSVDownload.Config(query));
  }

  return (
    <ParentLayout
      location={location}
      extra={{
        schoolStart: params.schoolStart,
        schoolEnd: params.schoolEnd,
        variant: 'pending'
      }}
      breadcrumbs={['Evaluations', 'Projects: Partners']}
      title="Pending Evaluations"
      description={(
        <span>
          Project evaluations sent to a partner, but not completed.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      csvQuery={function () {
        onDownload(csvQuery);
      }}
      row={function(evaluationProjectPartner) {
        return (
          <Evaluation
            key={evaluationProjectPartner.id}
            evaluation={evaluationProjectPartner}
          />
        );
      }}
    />
  );
}
