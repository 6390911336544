import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import TabBarDropdownMenuItem from './TabBarDropdownMenuItem';

export default function TabBarDropdownMenu(props) {
  const { label, links, secret = false } = props;

  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button
          className={classNames(
              'flex space-x-2 items-center shadow-cs-flat px-4 py-3 rounded-cs-10 cursor-pointer',
            // '-m-px border border-cs-gray-200',
            secret ? '-m-px border border-dashed border-cs-orange-500' : '-m-px border border-cs-gray-200',
            'text-cs-gray-500 bg-white hover:bg-cs-gray-50',
            'relative w-full pr-10'
          )}
        >
          {/*<DotsVerticalIcon className="h-5 w-5" />*/}
          <span className="whitespace-nowrap">
            {label}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon className="h-5 w-5 text-cs-gray-400" aria-hidden="true" />
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-20 origin-top absolute right-0 mt-1 w-60 left-0x mt-1x rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
          <div className="py-1 px-1">
            {links.map(function(link, index) {
              return (
                <Menu.Item key={index}>
                  {({ active }) => {
                    return (
                      <TabBarDropdownMenuItem
                        onClick={link.onClick}
                        active={active}
                        icon={link.icon}
                        label={link.label}
                        secret={link.secret}
                      />
                    );
                  }}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
};
