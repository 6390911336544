import { DateTime } from 'luxon';

export function formatDate(date, timezone) {
  if (date) {
    // return DateTime.fromISO(date).setZone(timezone).toLocaleString(DateTime.DATE_MED);
    return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
  }

  return '(not provided)';
}

export function formatDateRange(startDate, endDate, timezone) {
  if (startDate && endDate) {
    return `${formatDate(startDate, timezone)} - ${formatDate(endDate, timezone)}`;
  }

  return '(not provided)';
}

export function formatTime(time, timezone, includeTimezone = false) {
  if (time) {
    return includeTimezone ?
        DateTime.fromISO(time).setZone(timezone).toLocaleString(DateTime.TIME_SIMPLE_WITH_SHORT_OFFSET) :
        DateTime.fromISO(time).setZone(timezone).toLocaleString(DateTime.TIME_SIMPLE);
  }

  return '(not provided)';
}

export function formatTimeRange(startTime, endTime, timezone) {
  if (startTime && endTime) {
    return `${formatTime(startTime, timezone)} - ${formatTime(endTime, timezone, true)}`;
  }

  return '(not provided)';
}
