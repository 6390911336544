import React from 'react';
import PropTypes from 'prop-types';
import { UsersIcon } from '@heroicons/react/24/outline';
import MissingField from '../EventMissingField';
import ContentCard from '../../../components_profiles/ContentCard';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';
import TextContentSection from '../../../components_profiles/TextContentSection';

export default function Slots(props) {
  const { event } = props;

  return (
    <ContentCard className="space-y-4">
      <SubSectionHeader
        icon={UsersIcon}
        title="Slots"
      />
      {event.data.slots ? (
        <TextContentSection title="Partners needed">
          {event.data.slots}
        </TextContentSection>
      ) : (
        <MissingField name="Partners Needed" />
      )}
    </ContentCard>
  );
}
