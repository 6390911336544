import React from 'react';
import PropTypes from 'prop-types';

FormField.propTypes = {
  name: PropTypes.string,
  data: PropTypes.object,
  errors: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  onChange: PropTypes.func,
  children: PropTypes.func,
};

FormField.defaultProps = {
  data: {},
  errors: {},
};

export default function FormField(props) {
  const {
    name,
    data,
    errors,
    onChange,
    children
  } = props;

  const value = data[name];
  const error = errors[name];

  return children({
    value,
    error,
    onChange: function(value) {
      onChange(name, value);
    }
  });
}
