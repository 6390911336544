import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import REQUIRED_FIELDS from '../../projects/REQUIRED_FIELDS';
import ChartLoader from '../../dashboard/_charts/ChartLoader';
import DonutChart from '../../dashboard/_charts/DonutChart';
import colors from 'tailwindcss/colors';

export default function ProjectResponseYear(props) {
  const { account, schoolStart, schoolEnd, subtitle } = props;

  const responses = [
    'Connected',
    'Pending',
    'Declined',
    'No invites'
  ];

  const responseMap = {
    'Connected': {
      // numberOfQuestionsPending: 0,
      numberOfQuestionsConnect: {
        $gte: 1
      },
      // numberOfQuestionsDecline: 0
    },
    'Pending': {
      numberOfQuestionsPending: {
        $gte: 1
      },
      numberOfQuestionsConnect: 0,
      // numberOfQuestionsDecline: 0
    },
    'Declined': {
      numberOfQuestionsPending: 0,
      numberOfQuestionsConnect: 0,
      numberOfQuestionsDecline: {
        $gte: 1
      }
    },
    'No invites': {
      numberOfQuestionsPending: 0,
      numberOfQuestionsConnect: 0,
      numberOfQuestionsDecline: 0
    }
  };

  const requests = responses.map(function(response) {
    return {
      label: response,
      projects: useConnect('project.find', {
        where: {
          eager: {
            $aggregations: [
              'Pending',
              'Connect',
              'Decline'
            ].map(function(response) {
              return {
                type: 'count',
                alias: `numberOfQuestions${response}`,
                relation: 'questions',
                $where: {
                  response: response
                }
              };
            }),
            $where: {
              $and: REQUIRED_FIELDS.$and,
              activityId: {
                $not: null
              },
              ...responseMap[response],
              accountId: account.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              }
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    };
  });

  const loading = _.reduce(requests, function(result, request) {
    return result || request.projects.state === PayloadStates.FETCHING;
  }, false)

  if (loading) {
    return (
      <ChartLoader
        title="Projects by Partner Status"
        subtitle={subtitle || 'For school year'}
      />
    );
  }

  const series = requests.map(r => r.projects.meta.totalCount);
  const labels = requests.map(r => r.label);

  return (
    <DonutChart
      title="Projects by Partner Status"
      subtitle={subtitle || 'For school year'}
      labels={labels}
      series={series}
      colors={[
        colors.emerald['400'],
        colors.yellow['400'],
        colors.red['400'],
        colors.blue['400']
      ]}
    />
  );
}
