import React from 'react';
import useProjectNotesQuery from '../project-notes/useQuery';
import useEventNotesQuery from '../event-notes/useQuery';
import useOfferNotesQuery from '../offer-notes/useQuery';
import usePartnerNotesQuery from '../partner-notes/useQuery';
import useEducatorNotesQuery from '../educator-notes/useQuery';
import { PayloadStates } from '@lore/utils';

export default function useQuery(props) {
  const projectNotes = useProjectNotesQuery(props);
  const eventNotes = useEventNotesQuery(props);
  const offerNotes = useOfferNotesQuery(props);
  const partnerNotes = usePartnerNotesQuery(props);
  const educatorNotes = useEducatorNotesQuery(props);

  if (
    projectNotes.state === PayloadStates.FETCHING ||
    eventNotes.state === PayloadStates.FETCHING ||
    offerNotes.state === PayloadStates.FETCHING ||
    partnerNotes.state === PayloadStates.FETCHING ||
    educatorNotes.state === PayloadStates.FETCHING
  ) {
    return {
      state: PayloadStates.FETCHING,
      data: [],
      meta: undefined
    };
  }

  return {
    state: PayloadStates.RESOLVED,
    data: [].concat(
      projectNotes.data,
      eventNotes.data,
      offerNotes.data,
      partnerNotes.data,
      educatorNotes.data
    ),
    meta: {
      totalCount: (
        projectNotes.meta.totalCount +
        eventNotes.meta.totalCount +
        offerNotes.meta.totalCount +
        partnerNotes.meta.totalCount +
        educatorNotes.meta.totalCount
      )
    }
  };
}
