import _ from 'lodash';

export default function addOrRemoveNumberFromArray(array, value) {

  const isSelected = !!_.find(array, function(id) {
    return id === value;
  });

  if (isSelected) {
    array = array.filter(function(id) {
      return id !== value;
    });
  } else {
    array.push(value);
  }

  return array;
}
