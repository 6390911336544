import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton } from '../../@communityshare/ui/Button';
import { SolidButton } from '../../@communityshare/ui/Button';
import TextAreaField from '../../forms/_fields_v2/TextAreaField';
import FormTemplate from '../_common_old/FormTemplate.vertical';
import { ClockIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import moment from 'moment-timezone';
import MessageDeliveryStatus from '../../components/MessageDeliveryStatus';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import avatar from '../../../assets/images/avatar.svg';
import { ChevronRightIcon, EnvelopeIcon } from '@heroicons/react/24/solid';
import Loader from '../../components/Loader';
import UserAvatar from '../../components/UserAvatar';
import useAccount from '../../hooks/useAccount';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose,
    onBack
  } = props;

  const currentUser = useUser();
  const account = useAccount();
  const [timestamp] = useState(moment().toISOString());

  const stewardInvitation = useConnect('stewardInvitation.first', {
    where: {
      eager: {
        $where: {
          accountId: account.id,
          email: data.email,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    }
  });

  const user = useConnect('user.first', {
    where: {
      eager: {
        $where: {
          // 'members.accountId': account.id,
          email: data.email,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    }
  });

  const member = useConnect('member.first', {
    where: {
      eager: {
        $where: {
          accountId: account.id,
          'user.email': data.email,
          // isStaff: true,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    }
  });

  if (
    stewardInvitation.state === PayloadStates.FETCHING ||
    user.state === PayloadStates.FETCHING ||
    member.state === PayloadStates.FETCHING
  ) {
    return (
      <FormTemplate
        title="Invite Steward"
        subtitle="Fetching data on email provided..."
        showIcon={false}
        icon={ClockIcon}
        onClose={onClose}
        alert={alert}
        body={(
          <div className="mt-4">
            <Loader />
          </div>
        )}
        footer={(
          <>
            <OutlineButton onClick={onBack}>
              Back
            </OutlineButton>
            <SolidButton onClick={onSubmit} disabled={true}>
              Send invitation
            </SolidButton>
          </>
        )}
      />
    );
  }

  // if (stewardInvitations.data.length > 0) {
  if (stewardInvitation.state === PayloadStates.RESOLVED) {
    return (
      <FormTemplate
        title="An invitation was already sent to this email."
        subtitle="Once they sign up, they will automatically become a steward."
        showIcon={false}
        icon={ClockIcon}
        onClose={onClose}
        alert={alert}
        body={(
          <div className="mt-4">
            <div className="text-sm text-gray-700 bg-orange-50 border border-accent shadow p-4 rounded my-6">
              {/*{stewardInvitations.data.map(function (stewardInvitation) {*/}
              {/*  return (*/}
                  <div key={stewardInvitation.id || stewardInvitation.cid}>
                    Invitation was sent on {moment(stewardInvitation.data.createdAt).tz(currentUser.data.timezone).format('MMM Do YYYY, hh:mm a')}
                    {stewardInvitation.data.sendgridMessageId && (
                      <div className="text-sm uppercase font-bold text-accent ml-2x">
                        <MessageDeliveryStatus message={stewardInvitation} />
                      </div>
                    )}
                  </div>
              {/*  );*/}
              {/*})}*/}
            </div>
            {/*<p className="text-sm text-gray-700 mb-6">*/}
            {/*  Would you like to invite a different person?*/}
            {/*</p>*/}
          </div>
        )}
        footer={(
          <>
            <OutlineButton onClick={onClose}>
              Close
            </OutlineButton>
            <SolidButton onClick={onBack}>
              Select different person
            </SolidButton>
          </>
        )}
      />
    );
  }

  if (user.state === PayloadStates.RESOLVED) {
    return (
      <FormTemplate
        title="We found a matching account."
        subtitle="Do you want to invite this person to be a steward?"
        showIcon={false}
        icon={ClockIcon}
        onClose={onClose}
        alert={alert}
        body={(
          <div className="mt-4">
            <div className="text-sm text-gray-700 bg-orange-50 border border-accent shadow p-4x rounded my-6">
              <div key={user.id}>
                <div className="block hover:bg-gray-50">
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="min-w-0 flex-1 flex items-center">
                      <div className="flex-shrink-0">
                        <UserAvatar
                          user={user}
                          large={true}
                        />
                      </div>
                      <div className="min-w-0 flex-1 px-4 ">
                        <div>
                          <p className="text-sm font-medium text-indigo-600x truncate">
                            {user.data.firstName} {user.data.lastName}
                          </p>
                          <p className="mt-1 flex items-center text-sm text-gray-500">
                            {/*<EnvelopeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />*/}
                            <span className="truncate">
                              {user.data.email}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*<div>*/}
                    {/*  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
            {/*<p className="text-sm text-gray-700 mb-6">*/}
            {/*  Invite this person to be a steward for your community?*/}
            {/*</p>*/}
          </div>
        )}
        footer={(
          <>
            <OutlineButton onClick={onBack}>
              Back
            </OutlineButton>
            <SolidButton onClick={() => onSubmit(user)}>
              Send invitation
            </SolidButton>
          </>
        )}
      />
    );
  }

  return (
    <FormTemplate
      title="There is no user account linked to that email."
      subtitle="Would you like to send them an invitation to that email address? If yes, they will automatically become a steward once they sign up."
      showIcon={false}
      icon={ClockIcon}
      onClose={onClose}
      alert={alert}
      body={(
        <div className="mt-4 space-y-6">
          <div className="block bg-white shadow rounded-lg">
            <div className="flex items-center px-4 py-4 sm:px-6">
              <div className="min-w-0 flex-1 flex items-center">
                {/*<div className="flex-shrink-0">*/}
                {/*  <UserAvatar large={true} user={{ data: { accountId: account.id }}} />*/}
                {/*</div>*/}
                <div className="min-w-0 flex-1 px-4 ">
                  <div>
                    {/*<p className="text-sm font-medium text-indigo-600x truncate">*/}
                    {/*  {data.email}*/}
                    {/*</p>*/}
                    <p className="mt-1x flex items-center text-sm text-gray-500">
                      <EnvelopeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="truncate">
                        {data.email}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<p className="text-sm text-gray-700 mb-6">*/}
          {/*  Would you like to include a custom message about why you're sending this invitation?*/}
          {/*</p>*/}
          {/*<FormField name="message" data={data} errors={showErrors && errors} onChange={onChange}>*/}
          {/*  {(field) => (*/}
          {/*    <TextAreaField*/}
          {/*      label="Custom Message"*/}
          {/*      placeholder="Enter message..."*/}
          {/*      rows={11}*/}
          {/*      value={field.value}*/}
          {/*      error={field.error}*/}
          {/*      onChange={field.onChange}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*</FormField>*/}
        </div>
      )}
      footer={(
        <>
          <OutlineButton onClick={onBack}>
            Back
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Send invitation
          </SolidButton>
        </>
      )}
    />
  );
}
