import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

RadioButtonField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  options: PropTypes.object.isRequired,
  label: PropTypes.node,
  optionLabel: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.node,
  error: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

RadioButtonField.defaultProps = {
  options: {
    data: []
  },
  placeholder: '',
  disabled: false
};

export default function RadioButtonField(props) {
  const {
    name,
    value,
    options,
    label,
    optionLabel,
    placeholder,
    description,
    error,
    disabled,
    onChange,
    className
  } = props;

  return (
    <div className={className}>
      {label && (
        <div className="space-y-2">
          <div className="text-base text-cs-gray-900 font-normal">
            {label}
          </div>
          {description ? (
            <div className="text-sm text-cs-gray-500 font-normal">
              {description}
            </div>
          ) : null}
        </div>
      )}
      <div className="mt-4 space-y-3">
        {options.data.map((datum, index) => {
          return (
            <div key={datum.id || datum.cid} className="flex items-center space-x-3">
              <input
                type="radio"
                id={`radio-${datum.id}`}
                className="focus:ring-cs-orange-500 h-5 w-5 text-cs-orange-500 border-cs-gray-300"
                name={`radio-${name}-${index}`}
                value={datum.id}
                checked={value === datum.id}
                onChange={(event) => {
                  const selectedOption = _.find(options.data, function(option) {
                    return String(option.id) === event.target.value;
                  });
                  onChange(selectedOption ? selectedOption.id : null);
                }}
              />
              <label htmlFor={`radio-${datum.id}`} className="text-base text-cs-gray-700">
                {_.isFunction(optionLabel) ? optionLabel(datum) : datum.data[optionLabel]}
              </label>
            </div>
          );
        })}
      </div>
      {error && (
        <div className="mt-2 text-sm text-red-500">
          {error}
        </div>
      )}
    </div>
  );
}
