import React, { useContext } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import ModelsContext from '../contexts/ModelsContext';
import CollectionsContext from '../contexts/CollectionsContext';
import find from '../_blueprints/find';
import byId from '../_blueprints/byId';
import singleton from '../_blueprints/singleton';
import first from '../_blueprints/first';
import { useRemoveQueries } from './useRemoveQueries';
import { useInvalidateQueries } from './useInvalidateQueries';
import toJsonKey from '../utils/toJsonKey';

export function useQueryConnect(key, params = {}, extra = {}) {
  const models = useContext(ModelsContext);
  const collections = useContext(CollectionsContext);
  const removeQueries = useRemoveQueries();
  const invalidateQueries = useInvalidateQueries();
  const queryClient = useQueryClient();

  // todo:
  // implement queryClient.fetchQuery uses a function that gets returned
  // https://tanstack.com/query/v4/docs/react/reference/QueryClient#queryclientfetchquery

  const [model, method] = key.split('.');

  const Model = models[model];
  const Collection = collections[model];

  const blueprint = {
    find: find,
    byId: byId,
    first: first,
    singleton: singleton
  }[method];

  const queryParams = blueprint(key, params, {
    Model,
    Collection,
    queryClient,
    modelName: model
  });

  const result = useQuery({
    ...queryParams,
    ...extra
  });

  return {
    ...result.data,
    _isPreviousData: result.isPreviousData,
    _params: params,
    _removeQuery: () => removeQueries(key, params),
    _invalidateQuery: () => invalidateQueries(key, params),
    _remove: function(payload) {
      const jsonKey = toJsonKey(params);
      const queryKey = params ? [model, method, jsonKey] : [model, method];

      const previousData = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey,{
        ...previousData,
        data: _.filter(previousData.data, datum => datum.id !== payload.id)
      });
    },
    _add: function(payload) {
      const jsonKey = toJsonKey(params);
      const queryKey = params ? [model, method, jsonKey] : [model, method];

      const previousData = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey,{
        ...previousData,
        data: previousData.data.concat([payload])
      });
    },
    _update: function(payload) {
      const jsonKey = toJsonKey(params);
      const queryKey = params ? [model, method, jsonKey] : [model, method];

      const previousData = queryClient.getQueryData(queryKey);
      const nextData = previousData.data.map(function(datum) {
        return datum.id === payload.id ? payload : datum;
      });

      queryClient.setQueryData(queryKey,{
        ...previousData,
        data: nextData
      });
    }
  };
}
