import React  from 'react';
import moment from 'moment-timezone';
import TextField from '../../projects/_fields/TextField';

export default function LastActive(props) {
  const { user, align } = props;

  return (
    <TextField label="Wakes" align={align}>
      {moment(user.data.sleepingEndingAt).fromNow()}
    </TextField>
  );

  return (
    <TextField label="Wakes" align={align}>
      {moment(user.data.sleepingEndingAt).format('MMM D, YYYY')}
    </TextField>
  );
}
