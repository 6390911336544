import _ from 'lodash';
import moment from 'moment';
import TagTypes from '../../constants/TagTypes';

const timestamp = moment().toISOString(true);

const REQUIRED_FIELDS = {
  firstName: {
    $and: [
      {
        $not: ''
      },
      {
        $not: null
      }
    ]
  },
  lastName: {
    $and: [
      {
        $not: ''
      },
      {
        $not: null
      }
    ]
  },
  zipCode: {
    $and: [
      {
        $not: ''
      },
      {
        $not: null
      }
    ]
  },
  // userExpertises
  tags: {
    $tag_type_count_gte: {
      type: TagTypes.Expertise,
      value: 1
    }
  },
  $or: [
    {
      // both dates are in the future (not yet sleeping)
      $and: [
        { inactiveStartingAt: { $gt: timestamp } },
        { inactiveEndingAt: { $gt: timestamp } },
      ]
    },
    {
      // both dates are in the past (no longer sleeping)
      $and: [
        { inactiveStartingAt: { $lt: timestamp } },
        { inactiveEndingAt: { $lt: timestamp } },
      ]
    }
  ]
}

export default REQUIRED_FIELDS;

export const REQUIRED_EDUCATOR_FIELDS = _.omit(REQUIRED_FIELDS, [
  'tags'
]);

export const REQUIRED_PARTNER_FIELDS = _.omit(REQUIRED_FIELDS, [
  // none
]);
