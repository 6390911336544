import React, { useState } from 'react';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import colors from 'tailwindcss/colors';
import LineChart from '../../dashboard/_charts/LineChart';
import ChartLoader from '../../dashboard/_charts/ChartLoader';
import useEducatorEventEvaluations from '../Students/_hooks/useEducatorEventEvaluations';
import useCompletedEducatorEventEvaluations from '../Students/_hooks/useCompletedEducatorEventEvaluations';

export default function EducatorEventEvaluationsByMonth(props) {
  const { account, timestamps, empty, schoolStart } = props;

  const [currentTimestamp] = useState(moment().toISOString());

  const educators = timestamps.map(function(timestamp, index) {
    if (moment(currentTimestamp).diff(timestamp.start) < 0) {
      return {
        ...timestamp,
        results: {
          created: empty(),
          completed: empty()
        }
      }
    }

    return {
      ...timestamp,
      results: {
        created: useEducatorEventEvaluations(account, timestamp.start, timestamp.end),
        completed: useCompletedEducatorEventEvaluations(account, timestamp.start, timestamp.end)
      }
    }
  });

  const loading = _.reduce(educators, function(result, educator) {
    return (
      result ||
      educator.results.created.state === PayloadStates.FETCHING ||
      educator.results.completed.state === PayloadStates.FETCHING
    );
  }, false)

  if (loading) {
    return (
      <ChartLoader
        title="Event Evaluations: Educators"
        subtitle="Over school year"
      />
    );
  }

  const series = [
    {
      name: 'Evaluations (Sent)',
      data: educators.map(e => e.results.created.meta.totalCount)
    },
    {
      name: 'Evaluations (Completed)',
      data: educators.map(e => e.results.completed.meta.totalCount)
    }
  ];

  return (
    <LineChart
      title="Completion rates for event evaluations sent to educators"
      subtitle="Over school year"
      labels={educators.map(e => e.label)}
      series={series}
      colors={[
        colors.blue['400'],
        colors.emerald['400'],
        colors.yellow['400']
      ]}
    />
  );
}
