import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import toJsonKey from '../utils/toJsonKey';

export function useInvalidateQueries() {
  const queryClient = useQueryClient();

  return function invalidateQueries(key, params) {
    const [model, method] = key.split('.');

    const jsonKey = toJsonKey(params);

    if (
      method === 'first' ||
      method === 'find'
    ) {
      return queryClient.invalidateQueries({
        queryKey: params ?
          [model, method, jsonKey] :
          [model, method],
        exact: true
      });
    }

    if (method === 'byId') {
      if (!params.id) {
        throw new Error('missing params.id');
      }

      return queryClient.invalidateQueries({
        queryKey: [model, method, params.id],
        exact: true
      });
    }

    throw new Error(`Invalid method: ${key}`);
  }
}
