import React from 'react';
import PropTypes from 'prop-types';
import { PayloadStates } from '@lore/utils';
import Creator from './Creator';
import User from '../../../pages/user/User';
import AlertWarning from '../../_common/AlertWarning';
import AlertSuccess from '../../_common/AlertSuccess';
import useMissingFields from '../Header/useMissingFields';
import Loader from '../../../components/Loader';

export default function Layout(props) {
  const { user } = props;

  const { missingFields } = useMissingFields(user);

  if (missingFields.state === PayloadStates.FETCHING) {
    return (
      <div className="h-screenx">
        <Loader />
      </div>
    );
  }

  return (
    <div className="page space-y-12">
      <div className="flex space-x-8">
        <div className="flex-1 space-y-12">
          {missingFields.data.length > 0 ? (
            <AlertWarning
              title="User profile is not being shared publicly because key information is missing!"
              subtitle={(
                <>
                  Required fields are highlighted below.
                </>
              )}
            >
              <Creator user={user} />
            </AlertWarning>
          ) : (
            <AlertSuccess
              title="Profile has been described"
              subtitle="No required fields are missing."
            />
          )}
          <User
            user={user}
            onClose={props.onClose}
          />
        </div>
      </div>
    </div>
  );
};
