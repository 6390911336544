import React, { useState } from 'react';
import { parse, stringify } from 'query-string';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import TagTypes from '../../constants/TagTypes';
import SearchFilter from '../../forms/_fields_v2/SearchFilter';
import DisclosureTagFilter from '../../forms/_fields_v2/DisclosureTagFilter';
// import SearchTags from '../../components/SearchTags';
import ClearAllFilters from '../../components/ClearAllFilters';
import { getSearchUtils } from '../../utils/query-utils';
import useQuery from '../../pages/educators/useQuery';
import useFilterCount from '../../hooks/useFilterCount';
import useStewardshipFilter from './useStewardshipFilter';
import useInteractionTypesFilterParam from './useInteractionTypesFilterParam';
import useDistanceFilterParam from './useDistanceFilterParam';
// import REQUIRED_FIELDS_PUBLIC from '../../pages/educators/REQUIRED_FIELDS_PUBLIC';
import { REQUIRED_PARTNER_FIELDS } from '../../utils/user/REQUIRED_FIELDS';
import { SolidButton } from '../../@communityshare/ui/Button';
import DisclosureFilter from '../../forms/_fields_v2/DisclosureFilter';
import { attributeKeys } from '../../pages/educators/queryUtils';
import _ from 'lodash';
import DisclosureBaseFilter from '../../forms/_fields_v2/DisclosureBaseFilter';
import PayloadStates from '../../constants/PayloadStates';
import moment from 'moment-timezone';
import useAccountFilter from './useAccountFilter';

export default function PartnerFiltersDialog(props) {
  const { router } = props;

  const { location, history } = router;

  const [search, setSearch] = useState(parse(location.search));

  const [timestamp] = useState(new Date().toISOString());

  const accountFilter = useAccountFilter(search);
  // const stewardshipFilter = useStewardshipFilter(search);
  // const interactionTypesFilter = useInteractionTypesFilterParam(search);
  // const distanceFilter = useDistanceFilterParam(search);

  const filterFields = {
    name: 'name',
    organization: 'organization',
    searchEducator: 'searchEducator',

    grades: 'grades',
    subjects: 'subjects',
    practices: 'practices',

    projects: 'projects',
    events: 'events',

    createdDates: 'createdDates',
  };

  const filterCount = useFilterCount(search, _.keys(filterFields));

  function onSubmit() {
    history.push({
      pathname: location.pathname,
      search: stringify({
        ...search,
        page: 1
      })
    });
    props.onClose();
  }

  function onChange(nextSearch) {
    setSearch(nextSearch);
  }

  function getTagParams(tag) {
    return {
      where: {
        eager: {
          $where: {
            ...accountFilter,
            // ...stewardshipFilter,
            // ...interactionTypesFilter,
            // ...distanceFilter,
            'tagNodes.tagId': tag.id,
            isEducator: true,
            // $and: REQUIRED_FIELDS_PUBLIC.$and
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    };
  }

  function getParentTagParams(tag) {
    return {
      where: {
        eager: {
          $where: {
            ...accountFilter,
            // ...stewardshipFilter,
            // ...interactionTypesFilter,
            // ...distanceFilter,
            'tagTrees.parentId': tag.id,
            isEducator: true,
            // $and: REQUIRED_FIELDS_PUBLIC.$and
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    };
  }

  const searchUtils = getSearchUtils(search, onChange);

  const result = useQuery({
    search
  });

  return (
    <DialogTemplate className="w-screen max-w-screen-sm h-[calc(100dvh-32px)] max-h-[calc(720px)]">
      <CloseDialogButton onClick={props.onClose} />
      <div className="flex flex-col space-y-8x h-full">
        <div className="space-y-1 p-6 border-b border-cs-gray-200">
          <h2 className="text-cs-gray-900 text-2xl font-semibold">
            Filters
          </h2>
          <div className="text-cs-gray-500 text-sm">
            Narrow your search results.
          </div>
          {/*<div className="pt-4">*/}
          {/*  <SearchTags*/}
          {/*    search={search}*/}
          {/*    onChange={setSearch}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div className="px-6 py-8 space-y-8 overflow-y-auto h-full">
          <div className="space-y-4">
            <DisclosureFilter
              label="Search"
              value={[
                searchUtils(filterFields.name).value,
                searchUtils(filterFields.organization).value,
                searchUtils(filterFields.searchEducator).value
              ]}
            >
              <SearchFilter
                label="Name"
                description="Find educators whose first or last name matches any of the words you enter."
                value={searchUtils(filterFields.name).value}
                onChange={searchUtils(filterFields.name).onChangeValue}
              />
              <SearchFilter
                label="School"
                description="Find educators affiliated with a school whose name contains the phrase you type."
                value={searchUtils(filterFields.organization).value}
                onChange={searchUtils(filterFields.organization).onChangeValue}
              />
              <SearchFilter
                label="Profile"
                description="Find educators that have a specific phrase in their biography, or in the description of the subjects they teach."
                value={searchUtils(filterFields.searchEducator).value}
                onChange={searchUtils(filterFields.searchEducator).onChangeValue}
              />
            </DisclosureFilter>
          </div>
          <DisclosureTagFilter
            modelName="user"
            type={TagTypes.Grade}
            label="Grade levels"
            getParams={getTagParams}
            value={searchUtils(filterFields.grades).value}
            onChange={searchUtils(filterFields.grades).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="user"
            type={TagTypes.Subject}
            label="Subjects"
            getParams={getTagParams}
            value={searchUtils(filterFields.subjects).value}
            onChange={searchUtils(filterFields.subjects).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="user"
            type={TagTypes.Practice}
            label="Teaching practices"
            getParams={getTagParams}
            value={searchUtils(filterFields.practices).value}
            onChange={searchUtils(filterFields.practices).onChangeArray}
          />
          <DisclosureBaseFilter
            modelName="user"
            label="Projects created"
            getParams={function getProjectParams(model) {
              return {
                where: {
                  eager: {
                    $where: {
                      projects: {
                        $count_eq: model.id - 1,
                      },
                      isEducator: true,
                      // $or: stewardshipFilter
                      ...accountFilter,
                    }
                  }
                },
                pagination: {
                  pageSize: 1
                }
              };
            }}
            value={searchUtils(filterFields.projects).value}
            onChange={searchUtils(filterFields.projects).onChangeArray}
            tags={{
              state: PayloadStates.RESOLVED,
              data: [
                0,
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10
              ].map(function(value) {
                return {
                  id: value + 1,
                  data: {
                    name: `${value}`
                  }
                }
              })
            }}
          />
          <DisclosureBaseFilter
            modelName="user"
            label="Events created"
            getParams={function getEventParams(model) {
              return {
                where: {
                  eager: {
                    $where: {
                      events: {
                        $count_eq: model.id - 1,
                      },
                      isEducator: true,
                      // $or: stewardshipFilter
                      ...accountFilter,
                    }
                  }
                },
                pagination: {
                  pageSize: 1
                }
              };
            }}
            value={searchUtils(filterFields.events).value}
            onChange={searchUtils(filterFields.events).onChangeArray}
            tags={{
              state: PayloadStates.RESOLVED,
              data: [
                0,
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10
              ].map(function(value) {
                return {
                  id: value + 1,
                  data: {
                    name: `${value}`
                  }
                }
              })
            }}
          />
          <DisclosureBaseFilter
            modelName="user"
            label="Joined"
            getParams={function getJoinedParams(model) {
              // const weeks = Number(model.id.split('_')[1]);
              const weeks = model.id;

              return {
                where: {
                  eager: {
                    $where: {
                      createdAt: {
                        $gte: moment(timestamp).subtract(weeks, 'weeks').toISOString(),
                      },
                      isEducator: true,
                      // $or: stewardshipFilter
                      ...accountFilter,
                    }
                  }
                },
                pagination: {
                  pageSize: 1
                }
              };
            }}
            value={searchUtils(filterFields.createdDates).value}
            onChange={searchUtils(filterFields.createdDates).onChangeArray}
            tags={{
              state: PayloadStates.RESOLVED,
              data: [
                // ['lte_1_weeks', 'Within 1 week'],
                // ['lte_2_weeks', 'Within 2 weeks'],
                // ['lte_4_weeks', 'Within 1 month'],
                // ['lte_8_weeks', 'Within 2 months'],
                // ['lte_13_week', 'Within 3 months'],
                // ['lte_26_week', 'Within 6 months'],
                // ['lte_52_week', 'Within the year']
                [1, 'Within 1 week'],
                [2, 'Within 2 weeks'],
                [4, 'Within 1 month'],
                [8, 'Within 2 months'],
                [13, 'Within 3 months'],
                [26, 'Within 6 months'],
                [52, 'Within the year']
              ].map(function(value) {
                return {
                  id: value[0],
                  data: {
                    name: value[1]
                  }
                }
              })
            }}
          />
        </div>
        <div className="p-6 flex items-center justify-between border-t border-cs-gray-200">
          <ClearAllFilters
            show={true}
            className="hover:opacity-70"
            onClick={() => setSearch(_.omit(search, _.keys(filterFields)))}
            count={filterCount}
          />
          <SolidButton onClick={onSubmit}>
            Show {result.meta?.totalCount} results
          </SolidButton>
        </div>
      </div>
    </DialogTemplate>
  );
};
