import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function EmptyState(props) {
  const { title, subtitle, color = 'gray', children } = props;

  const colorMap = {
    gray: {
      border: 'border-gray-300',
      text: 'text-gray-500'
    },
    blue: {
      border: 'border-setup-tag hover:bg-blue-50',
      text: 'text-setup-tag'
    }
  }[color];

  return (
    <div className={classNames(
      'text-center border-2 border-dashed rounded-lg p-12',
      colorMap.border
    )}>
      <div className="mt-2 space-y-1">
        <h3 className={`text-xl font-semibold ${colorMap.text}`}>
          {title}
        </h3>
        <div className={`text-base ${colorMap.text}`}>
          {subtitle}
        </div>
      </div>
      {children}
    </div>
  );
}
