import React from 'react';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Template from '../../../components_profiles/Template';
import useProjectTags from '../../../hooks/useProjectTags';
import TagTypes from '../../../constants/TagTypes';

export default function Subjects(props) {
  const { project } = props;

  const projectSubjects = useProjectTags(project, TagTypes.Subject);

  if (projectSubjects.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (projectSubjects.data.length === 0) {
    return null;
  }

  return (
    <Template
      icon={BookOpenIcon}
      title="Subjects"
      subtitle="Project is related to these subject areas."
      tags={projectSubjects}
    />
  );
};
