import { useNavigate } from 'react-router-dom';
import removeDialogFromSearchParams from './removeDialogFromSearchParams';

export default function useRemoveDialogFromSearchParams() {
  const navigate = useNavigate();

  return function(dialogId) {
    removeDialogFromSearchParams(dialogId, navigate);
  }
};
