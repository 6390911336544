import React from 'react';
import { PayloadStates } from '@lore/utils';
import ChartLoader from '../../dashboard/_charts/ChartLoader';
import DonutChart from '../../dashboard/_charts/DonutChart';
import colors from 'tailwindcss/colors';
import { partnerResponses, stewardResponses } from './InvitationDeclineReasonYear';
import useInvitations from './_hooks/useInvitations';
export default function InvitationDeclinerYear(props) {
  const { account, schoolStart, schoolEnd, subtitle } = props;

  const partnerInvitations = useInvitations(account, schoolStart, schoolEnd, {
    responseMessage: {
      $or: partnerResponses.map(function(response) {
        return {
          $ilike: `%${response}%`
        }
      })
    }
  });

  const stewardInvitations = useInvitations(account, schoolStart, schoolEnd, {
    responseMessage: {
      $or: stewardResponses.map(function(response) {
        return {
          $ilike: `%${response}%`
        }
      })
    }
  });

  const requests = [
    {
      label: 'Declined by Partner',
      invitations: partnerInvitations
    },
    {
      label: 'Declined by Steward',
      invitations: stewardInvitations
    }
  ]

  const loading = _.reduce(requests, function(result, request) {
    return (
      result ||
      request.invitations.state === PayloadStates.FETCHING
    );
  }, false)

  if (loading) {
    return (
      <ChartLoader
        title="Breakdown of who declined the invitation"
        subtitle={subtitle || 'For school year'}
      />
    );
  }

  const series = requests.map(r => r.invitations.meta.totalCount);
  const labels = requests.map(r => r.label);

  return (
    <DonutChart
      title="Breakdown of who declined the invitation"
      subtitle={subtitle || 'For school year'}
      labels={labels}
      series={series}
      colors={[
        colors.emerald['400'],
        colors.yellow['400']
      ]}
    />
  );
}
