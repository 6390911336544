import React, { useState } from 'react';
import moment from 'moment-timezone';
import { PayloadStates } from '@lore/utils';
import PageHeading from '../../../@communityshare/ui/PageHeading';
import ParentLayout from '../../dashboard/Layout';
import Loader from '../../../components/Loader';
import { Helmet } from 'react-helmet-async';

import useEvaluationsProjectEducatorMapping from '../evaluations-project-educator/useMapping';
import useEvaluationsProjectPartnerMapping from '../evaluations-project-partner/useMapping';
import useEvaluationsEventEducatorMapping from '../evaluations-event-educator/useMapping';
import useEvaluationsEventPartnerMapping from '../evaluations-event-partner/useMapping';

import useQueryMeta from '../../feed/useQueryMeta';
import { OutlineButton } from '../../../@communityshare/ui/Button';
import MobileNavigation from '../../feed/MobileNavigation';

const increment = 10;

export default function Layout(props) {
  const [maxCount, setMaxCount] = useState(increment);
  const { account, params, pagination } = useQueryMeta();

  const evaluationProjectEducators = useEvaluationsProjectEducatorMapping({ account, params, pagination });
  const evaluationProjectPartners = useEvaluationsProjectPartnerMapping({ account, params, pagination });
  const evaluationEventEducators = useEvaluationsEventEducatorMapping({ account, params, pagination });
  const evaluationEventPartners = useEvaluationsEventPartnerMapping({ account, params, pagination });

  if (
    evaluationProjectEducators.models.state === PayloadStates.FETCHING ||
    evaluationProjectPartners.models.state === PayloadStates.FETCHING ||
    evaluationEventEducators.models.state === PayloadStates.FETCHING ||
    evaluationEventPartners.models.state === PayloadStates.FETCHING
  ) {
    return (
      <ParentLayout>
        <div className="p-8 space-y-8">
          <Helmet>
            <title>Feed: Evaluations</title>
          </Helmet>
          <PageHeading
            breadcrumbs={['Feed']}
            title="Evaluations"
            description="List of evaluations created over the specified time frame."
          />
          <Loader />
        </div>
      </ParentLayout>
    );
  }

  let items = [].concat(
    evaluationProjectEducators.entries,
    evaluationProjectPartners.entries,
    evaluationEventEducators.entries,
    evaluationEventPartners.entries
  );

  items = _.sortBy(items, function(item) {
    return -moment(item.timestamp).unix();
  });

  const visibleItems = items.slice(0, maxCount);

  return (
    <ParentLayout>
      <div className="p-8 space-y-8">
        <Helmet>
          <title>Feed: Evaluations</title>
        </Helmet>
        <MobileNavigation />
        <PageHeading
          breadcrumbs={['Feed']}
          title="Evaluations"
          description="List of evaluations created over the specified time frame."
        />
        <div className="max-w-3xl">
          <ul role="list" className="space-y-6">
            {visibleItems.map(function(item, index) {
              const last = items.length === (index + 1);
              return React.cloneElement(item.value, {
                last: last
              });
            })}
            {maxCount < items.length && (
              <OutlineButton onClick={() => setMaxCount(maxCount + increment)}>
                Show more ({items.length - visibleItems.length})
              </OutlineButton>
            )}
          </ul>
        </div>
      </div>
    </ParentLayout>
  );
}
