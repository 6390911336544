import React from 'react';
import { useUser } from '@lore/auth';
import useAccount from '../../../hooks/useAccount';
import useGetCount from '../../explore/useGetCount';

// Partners
import starredPartners from '../../partners-filtered/starred/useQuery';
import incompletePartners from '../../partners-filtered/incomplete/useQuery';
import newPartners from '../../partners-filtered/new/useQuery';
import sleepingPartners from '../../partners-filtered/sleeping/useQuery';
import inactivePartners from '../../partners-filtered/inactive/useQuery';
import noInteractionsPartners from '../../partners-filtered/no-interactions/useQuery';
import multipleEvaluationsPartners from '../../partners-filtered/multiple-evaluations/useQuery';
import multipleHoursPartners from '../../partners-filtered/multiple-hours/useQuery';
import multipleStudentsPartners from '../../partners-filtered/multiple-students/useQuery';
import multipleDonationsPartners from '../../partners-filtered/multiple-donations/useQuery';

export default function getNavigationLinks() {
  const account = useAccount();
  const currentUser = useUser();

  const root = `/c/${account.data.subdomain}`;

  return [
    { name: 'All', href: `${root}/explore/partners` },
    currentUser.data.isSuperuser ?
      { name: '[Engagement]', href: `${root}/explore/partners/engagement` } :
      null,
    { name: 'Starred', href: `${root}/explore/partners/starred`, count: useGetCount(starredPartners) },
    { name: 'New', href: `${root}/explore/partners/new`, count: useGetCount(newPartners), highlight: true },
    { name: 'Sleeping', href: `${root}/explore/partners/sleeping`, count: useGetCount(sleepingPartners) },
    { name: 'Inactive', href: `${root}/explore/partners/inactive`, count: useGetCount(inactivePartners) },
    { name: 'Incomplete Profile', href: `${root}/explore/partners/incomplete`, count: useGetCount(incompletePartners) },
    { name: 'No Interactions', href: `${root}/explore/partners/no-interactions`, count: useGetCount(noInteractionsPartners) },
    { name: '3+ Evaluations', href: `${root}/explore/partners/multiple-evaluations`, count: useGetCount(multipleEvaluationsPartners) },
    { name: '25+ Hours Served', href: `${root}/explore/partners/multiple-hours`, count: useGetCount(multipleHoursPartners) },
    { name: '50+ Students Served', href: `${root}/explore/partners/multiple-students`, count: useGetCount(multipleStudentsPartners) },
    { name: '$50+ Donations', href: `${root}/explore/partners/multiple-donations`, count: useGetCount(multipleDonationsPartners) }
  ];
}
