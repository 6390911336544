import React from 'react';
import Breadcrumb from './Breadcrumb';

export default function Breadcrumbs(props) {
  const { breadcrumbs = [] } = props;

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {breadcrumbs.map(function(breadcrumb, index) {
          return (
            <Breadcrumb
              key={index}
              index={index}
              title={breadcrumb}
              to={undefined}
            />
          );
        })}
      </ol>
    </nav>
  );
}
