import React  from 'react';
import avatar from '../../assets/images/avatar.svg';
import { useUser } from '@lore/auth';
import { getDistanceFromLatLonInMiles } from '../utils/distance';
import useAccount from '../hooks/useAccount';

export default function UserAvatar(props) {
  const { user, large = false } = props;

  const currentUser = useUser();
  const account = useAccount();

  const distance = getDistanceFromLatLonInMiles(user.data, currentUser.data);
  const stewarded = user.data.accountId === account.id;

  return (
    <div className="relative">
      {user.data.accountId && !stewarded && (
        <div className="absolute bg-accent h-3 w-3 top-o right-0 rounded-full"/>
      )}
      <img
        className={`${large ? 'h-12 w-12' : 'h-10 w-10'} rounded-full object-cover`}
        src={user.data.avatarUrl || avatar}
        alt="Avatar"
      />
    </div>
  );

  return (
    <img
      className={`${large ? 'h-12 w-12' : 'h-10 w-10'} rounded-full object-cover ${!stewarded ? 'border-4 border-accent rounded-full' : ''}`}
      src={user.data.avatarUrl || avatar}
      alt="Avatar"
    />
  );
}
