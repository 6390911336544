import { parse } from 'query-string';
import useRouter from './useRouter';

export default function useCurrentPage(props) {
  const { location } = useRouter();

  const query = parse(location.search);
  const currentPage = Number(query.page || '1');
  return currentPage;
}
