import React  from 'react';
import { BanknotesIcon } from '@heroicons/react/24/outline';
import Tooltip from '../../../components/Tooltip';

export default function Contribution(props) {
  const { evaluation } = props;

  return (
    <div className="flex items-center space-x-1">
      <Tooltip tooltip="Testimonial" icon={false}>
        <BanknotesIcon className="w-5 h-5 text-gray-500" />
      </Tooltip>
      <div className="text-sm text-gray-500">
        {evaluation.data.contributed === 'Yes' ?
          `$${(evaluation.data.contributionValue || 0).toLocaleString()}` :
          '$0'
        }
      </div>
    </div>
  );
}
