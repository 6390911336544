import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import { BuildingOfficeIcon, GlobeAltIcon, ShareIcon } from '@heroicons/react/24/outline';
import { getDistanceFromLatLonInMiles } from '../components/User/utils';
import { PayloadStates } from '@lore/utils';
import Loader from '../components/Loader';
import ContentCard from './ContentCard';
import SubSectionHeader from './SubSectionHeader';
import TextIconGroup from './TextIconGroup';

BaseParticipation.propTypes = {
  creator: PropTypes.object.isRequired,
  interactionTypes: PropTypes.object.isRequired
};

export default function BaseParticipation(props) {
  const { creator, interactionTypes } = props;

  const currentUser = useUser();

  if (
    creator.state === PayloadStates.FETCHING ||
    interactionTypes.state === PayloadStates.FETCHING
  ) {
    return (
      <ContentCard className="space-y-8">
        <SubSectionHeader
          icon={ShareIcon}
          title="Participation Options"
        />
        <Loader/>
      </ContentCard>
    );
  }

  const distance = getDistanceFromLatLonInMiles(creator.data, currentUser.data);

  const isInPerson = _.find(interactionTypes.data, function(interactionType) {
    return interactionType.data.name === 'In-person';
  });

  const isVirtual = _.find(interactionTypes.data, function(interactionType) {
    return interactionType.data.name === 'Virtual';
  });

  return (
    <ContentCard className="space-y-8">
      <SubSectionHeader
        icon={ShareIcon}
        title="Participation Options"
      />
      <div className="flex flex-col space-y-2">
        {isInPerson && (
          <TextIconGroup
            icon={BuildingOfficeIcon}
            title="In-person"
            subtitle={distance <= 1 ? '1 mile away' : `${distance} miles away`}
          />
        )}
        {isVirtual && (
          <TextIconGroup
            icon={GlobeAltIcon}
            title="Virtual"
            subtitle={!isInPerson ? undefined : distance <= 1 ? '1 mile away' : `${distance} miles away`}
          />
        )}
      </div>
    </ContentCard>
  );
}
