import React from 'react';
import Card from '../../../components/Card';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import EvaluationCompleteColumn from '../../evaluations/_common/EvaluationCompleteColumn';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import Avatar from '../../../components/Avatar';
import DateRange from '../../../dialogs_new/project_manage/Header/DateRange';
import Images from '../stories/Images';
import Testimonial from './Testimonial';
import Students from './Students';

export default function Evaluation(props) {
  const { evaluation } = props;

  const project = useConnect('project.byId', {
    id: evaluation.data.projectId
  });

  const user = useConnect('user.byId', {
    id: evaluation.data.userId
  });

  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.EvaluationProjectEducator.Config(evaluation.id));
  }

  return (
    <Card className="hover:bg-orange-50 cursor-pointer" onClick={onClick}>
      <div className="flex justify-between p-4">
        <div className="flex flex-1 items-center space-x-4">
          <Avatar size={12} src={user.data.avatarUrl}/>
          <div className="flex flex-col">
            <div className="text-lg font-semibold">
              {user.data.firstName} {user.data.lastName}
            </div>
            <div className="flex flex-wrap gap-x-4 gap-y-2">
              <Students evaluation={evaluation} />
              <Images evaluation={evaluation} />
              <Testimonial evaluation={evaluation} />
              <DateRange project={project} />
            </div>
          </div>
        </div>
        <EvaluationCompleteColumn
          evaluation={evaluation}
        />
      </div>
    </Card>
  );
}
