import React from 'react';
import PropTypes from 'prop-types';
import SleepingTag from '../../../pages/_components/SleepingTag';
import InactiveTag from '../../../pages/_components/InactiveTag';
import Visibility from './Visibility';
import Organization from './Organization';
import ShareLink from './ShareLink';
import Phone from './Phone';
import Email from './Email';

export default function Header(props) {
  const { user } = props;

  return (
    <div className="flex flex-col space-y-6">
      <div className="space-y-2">
        <div className="flex space-x-4">
          <div className="text-sm text-gray-500">
            {user.data.isEducator ? 'Educator' : 'Partner'}
          </div>
          <div className="flex space-x-2">
            <SleepingTag user={user} />
            <InactiveTag user={user} />
          </div>
        </div>
        <div className="text-3xl font-bold text-cs-gray-700">
          {user.data.firstName} {user.data.lastName}
        </div>
        <div className="text-cs-gray-500">
          {user.data.biography}
        </div>
        <div className="flex justify-between">
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            <Phone user={user} />
            <Email user={user} />
            <Organization user={user} />
            <Visibility user={user} />
            <ShareLink user={user} />
          </div>
        </div>
      </div>
    </div>
  );
};
