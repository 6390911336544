import React from 'react';
import SelectField from '../../../forms/_fields_v2/SelectField';
import useRouter from '../../../hooks/useRouter';
import useNavigationLinks from '../Sidebar/useNavigationLinks';
import SelectFieldLinkOption from '../../../forms/SelectFieldLinkOption';

export default function PageSelectField(props) {
  const { location, history } = useRouter();

  const { pageLinks } = useNavigationLinks();

  return (
    <SelectField
      label="Page"
      value={location.pathname}
      // placeholder="Select page..."
      options={{
        data: pageLinks.map(function(link, index) {
          return {
            id: link.href,
            data: {
              name: link.name,
              count: link.count,
              highlight: link.highlight
            }
          };
        })
      }}
      // optionLabel="name"
      optionLabel={function(datum) {
        return (
          <SelectFieldLinkOption
            name={datum.data.name}
            count={datum.data.count}
            highlight={datum.data.highlight}
          />
        );
      }}
      onChange={function(id) {
        history.push(id);
      }}
    />
  );
}
