import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from '../offer_manage/Header';
import OfferInvitePopulateLayout from './offer-invite-populate/Layout';
import OfferInviteLayout from './offer-invite/Layout';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import useDialogRouter from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogRouter';
import { Helmet } from 'react-helmet-async';

Offer.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function Offer(props) {
  const { offer } = props;

  const { id, route } = useDialogConfig();
  const router = useDialogRouter(id);

  useEffect(() => {
    if (route === '/') {
      router.navigate('/invite-populate');
    }
  }, []);

  const pageMap = {
    '/invite': 'Invite',
    '/invite-populate': 'Invite'
  };

  const pathname = route.split('?')[0];
  const page = pageMap[pathname] || pathname;

  return (
    <>
      <Helmet>
        <title>
          {offer.data.title ? `${offer.data.title}: ${page}` : `Offer #${offer.id}: ${page}`}
        </title>
      </Helmet>
      <div className="px-6 pt-4 border-b border-cs-gray-100 pb-6">
        <Header offer={offer} />
      </div>
      <div className="flex-1 relative z-0 overflow-y-auto bg-cs-gray-50 px-6 py-6">
        {pathname === `/invite-populate` && (
          <OfferInvitePopulateLayout
            offer={offer}
            onClose={props.onClose}
          />
        )}
        {pathname === `/invite` && (
          <OfferInviteLayout
            offer={offer}
            router={router}
            onClose={props.onClose}
          />
        )}
      </div>
    </>
  );
};
