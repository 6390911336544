import React from 'react';
import Card from '../../components/Card';
import { useConnect } from '../../../hooks/@lore/query-connect';
import Avatar from '../../components/Avatar';
import moment from 'moment-timezone';

export default function Contribution(props) {
  const { evaluation, onClick } = props;

  const user = useConnect('user.byId', {
    id: evaluation.data.userId
  });

  return (
    <Card>
      <div className="flex justify-between p-4 hover:bg-cs-orange-50 cursor-pointer" onClick={onClick}>
        <div className="flex space-x-4">
          <Avatar size={12} src={user.data.avatarUrl} />
          <div className="flex flex-1 flex-col">
            <div className="text-lg font-semibold">
              {user.data.firstName} {user.data.lastName}
            </div>
            <div className="text-sm text-gray-500 space-x-1">
              <span>
                {evaluation.data.contribution || '[Description of contribution not provided]'}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col text-right">
          <div className="text-lg font-semibold text-green-600 space-x-1">
            <span>${evaluation.data.contributionValue}</span>
          </div>
          <div className="flex text-sm text-gray-500 space-x-1">
            <span>{moment(evaluation.data.updatedAt).fromNow()}</span>
          </div>
        </div>
      </div>
    </Card>
  );
}
