import React from 'react';
import * as Sentry from '@sentry/browser';
import Card from '../components/Card';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true
    };
  }

  componentDidCatch(error, errorInfo) {
    const config = this.props.config.raven;

    if (config.enabled) {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  render() {
    const { pathname } = this.props;
    const config = this.props.config.raven;

    if (this.state.hasError) {
      return (
        <div key={pathname} className="p-8">
          <Card className="mx-auto text-center p-8">
            <h1 className="text-3xl mb-8 font-light">
              Something went wrong : (
            </h1>
            {config.enabled ? (
              <>
                <p className="mb-4">
                  It looks like there was an error on this page. The team has been notified. If you'd like to
                  help, you can provide feedback about what happened.
                </p>
                <h3 className="text-cs-orange-500 underline cursor-pointer font-normal" onClick={() => Sentry.showReportDialog()}>
                  Report feedback
                </h3>
              </>
            ) : (
              <p>
                It looks like there was an error on this page.
              </p>
            )}
          </Card>
        </div>
      );
    }

    return this.props.children;
  }
}
