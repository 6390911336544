import React from 'react';
import StatusTag from '../_common_evaluation/StatusTag';
import Evaluation from './Evaluation';

export default function Header(props) {
  const { event, evaluationEventPartner } = props;

  return (
    <div className="flex flex-col space-y-6">
      <div className="space-y-2">
        <div className="flex space-x-4">
          <div className="text-sm text-gray-500">
            Evaluation
          </div>
          <div className="flex space-x-2">
            <StatusTag evaluation={evaluationEventPartner} />
          </div>
        </div>
        <div className="text-3xl font-bold text-cs-gray-700">
          Event Evaluation (Partner)
        </div>
      </div>
      <Evaluation
        evaluationEventPartner={evaluationEventPartner}
        event={event}
      />
    </div>
  );
};
