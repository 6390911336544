import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import Dialog from '../user_invite_project_event';

export default function Launcher(props) {
  const config = useDialogConfig();

  const user = useConnect('user.byId', {
    id: config.params.userId
  });

  if (user.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Dialog
      user={user}
      onClose={props.onClose}
    />
  );
};
