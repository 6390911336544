import React from 'react';
import PropTypes from 'prop-types';
import Offer from '../../../pages/offers/Row';

export default function OfferWrapper(props) {
  const { offer } = props;

  return (
    <Offer
      key={offer.id}
      offer={offer}
    />
  );
};
