import React from 'react';
import Card from '../../../components/Card';
import PartnerEventEvaluationHeaderSection from '../../evaluations/PartnerEventEvaluation/PartnerEventEvaluationHeaderSection';
import { useConnect } from '../../../../hooks/@lore/query-connect';
// import Photographs from '../../evaluations/PartnerEventEvaluation/Photographs';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import EventHeader from '../../events/Row/Header';
import HeaderSection from '../../evaluations-event-partners/EvaluationEventPartner/HeaderSection';
import ShareLinkSection from '../../evaluations-event-partners/EvaluationEventPartner/ShareLinkSection';
import Notes from '../../evaluations-event-partners/EvaluationEventPartner/Notes';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function Evaluation(props) {
  const { evaluation } = props;

  const launch = useDialogLauncher();

  const event = useConnect('event.byId', {
    id: evaluation.data.eventId
  });

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  }, {
    enabled: !!event.data.activityId
  });

  if (event.state === PayloadStates.FETCHING) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  function onClickEvent() {
    launch(DialogManager.EventManage.Config(event.id));
  }

  const link = (
    <span
      className="text-amber-800 hover:text-amber-500 cursor-pointer underline"
      onClick={onClickEvent}>
      {event.data.title || activity.data.name}
    </span>
  );

  return (
    <Card className="overflow-hidden">
      <div className="divide-y divide-gray-200">
        {/*<EventHeader*/}
        {/*  event={event}*/}
        {/*  tags={false}*/}
        {/*/>*/}
        <HeaderSection
          evaluation={evaluation}
          created={event.data.creatorId === evaluation.data.userId}
        />
        <ShareLinkSection
          evaluation={evaluation}
          description={(
            <>
              The link below will take the partner directly to the beginning of the evaluation
              for the event {link}. This link will only work for them.
            </>
          )}
        />
      </div>
      <div className="bg-gray-50 p-4">
        <Notes evaluationEventPartner={evaluation} />
      </div>
    </Card>
  );
}
