import React from 'react';
import PropTypes from 'prop-types';
import TextContentSection from '../../../components_profiles/TextContentSection';

TagsToList.propTypes = {
  tags: PropTypes.object.isRequired
};

export default function TagsToList(props) {
  const { title, tags } = props;

  if (tags.data.length === 0) {
    return null;
  }

  return (
    <TextContentSection title={title}>
      <ul className="list-disc ml-6">
        {tags.data.map(function(tag) {
          return (
            <li key={tag.id || tag.cid}>
              {tag.data.name}
            </li>
          );
        })}
      </ul>
    </TextContentSection>
  );
};
