import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';
import 'photoswipe/style.css';
import Image from './Image';

export default function Images(props) {
  const { images, className, imageHeight, imageChild } = props;

  const [galleryId] = useState(`g-${uuidv4()}`);

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: `#${galleryId}`,
      children: 'a',
      // pswpModule: () => import('photoswipe')
      pswpModule: PhotoSwipe
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);

  return (
    <div id={galleryId} className={className}>
      {images.data.map(function(image, index) {
        return (
          <Image
            key={image.id}
            image={image}
            imageHeight={imageHeight}
            imageChild={imageChild}
          />
        );
      })}
    </div>
  );
};
