import React  from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import TextField from './TextField';

export default function CreatorPhone(props) {
  const { project } = props;

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  return (
    <TextField label="Phone">
      {creator.state === PayloadStates.FETCHING ? '...' : creator.data.phoneNumber}
    </TextField>
  );
}
