import React  from 'react';

export default function TextField(props) {
  const { label, children, loading = false } = props;

  if (loading) {
    return (
      <div className="flex flex-col text-sm space-y-1">
        <div className="text-gray-700 font-semibold">
          {label}
        </div>
        <div className="text-gray-500">
          ...
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col text-sm space-y-1">
      <div className="text-gray-700 font-semibold">
        {label}
      </div>
      <div className="text-gray-500">
        {children}
      </div>
    </div>
  );
}
