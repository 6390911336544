import React from 'react';
import Metrics from './Metrics';
import RenderOnlyWhenInViewport from './RenderOnlyWhenInViewport';

export default function LazyMetrics(props) {
  const { title, children, columns = 3 } = props;

  return (
    <RenderOnlyWhenInViewport title={title}>
      <Metrics {...props} />
    </RenderOnlyWhenInViewport>
  );
}
