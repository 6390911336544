import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Section from '../../../../pages/_components/sidePanel/Section';
import TextField from '../../../../pages/_components/sidePanel/TextField';

export default function Photographs(props) {
  const { evaluation } = props;

  const evaluationUploads = useConnect('upload.find', {
    where: {
      eager: {
        $where: {
          evaluationEventEducatorId: evaluation.id
        }
      }
    }
  });

  return (
    <Section
      title="Event Photographs"
      // subtitle="Documents uploaded by the educator with more information about the evaluation."
    >
      <TextField label="Do you have permission to share these images?">
        {evaluation.data.releasePermission ? 'Yes' : 'No'}
      </TextField>
      <div>
        <div className="grid grid-cols-4 gap-4">
          {evaluationUploads.data.map(function(evaluationUpload) {
            return (
              <div key={evaluationUpload.id}>
                <div className="space-y-4">
                  <div className="aspect-w-3 aspect-h-2">
                    <img className="object-cover shadow-lg rounded-lg" src={evaluationUpload.data.url} alt="" />
                  </div>
                  <div className="space-y-2">
                    <div className="text-md leading-6 font-medium space-y-1">
                      <a
                        className="text-accent hover:text-primary underline"
                        href={evaluationUpload.data.url}
                        target="_blank"
                      >
                        {evaluationUpload.data.name}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {evaluationUploads.data.length === 0 && '-'}
    </Section>
  )
}
