import React from 'react';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import { ClockIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import DropdownFilter from '../../components/DropdownFilter';

export default function SortBy(props) {
  const { location, history } = useRouter();

  const search = parse(location.search) || {};

  function onChange(order) {
    const search = parse(location.search) || {};

    search.order = order;
    delete search.page;

    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, search))
    });
  }

  const orderMap = {
    Newest: {
      name: 'Newest',
      param: 'createdAt desc'
    },
    Oldest: {
      name: 'Oldest',
      param: 'createdAt asc'
    }
  };

  const reverseOrderMap = _.mapKeys(orderMap, function(value, key) {
    return value.param;
  });

  return (
    <DropdownFilter subtitle="Sort by:" title={reverseOrderMap[search.order]?.name || 'Newest'}>
      <DropdownFilter.MenuItem
        icon={ClockIcon}
        title={orderMap.Newest.name}
        subtitle="Display recently created evaluations first."
        selected={!search.order || search.order === orderMap.Newest.param}
        onClick={() => onChange(orderMap.Newest.param)}
      />
      <DropdownFilter.MenuItem
        icon={ClockIcon}
        title={orderMap.Oldest.name}
        subtitle="Display oldest evaluations first."
        selected={search.order === orderMap.Oldest.param}
        onClick={() => onChange(orderMap.Oldest.param)}
      />
    </DropdownFilter>
  );
}
