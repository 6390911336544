import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Wrapper from './Wrapper';

NoteForm.propTypes = {
  extraParams: PropTypes.object.isRequired
};

export default function NoteForm(props) {
  const [key, setKey] = useState(0);

  function onSubmit(note) {
    setKey(key + 1);
    props.onSubmit && props.onSubmit(note);
  }

  return (
    <Wrapper
      key={key}
      {...props}
      onSubmit={onSubmit}
    />
  );
};
