import React from 'react';
import PageSelectField from './PageSelectField';

export default function MobileNavigation(props) {
  return (
    <div className="grid grid-cols-1 gap-4 lg:hidden mb-8" hidden>
      <PageSelectField />
    </div>
  );
}
