import React from 'react';
import PropTypes from 'prop-types';
import PlaceholderImage from '../../../../assets/images/placeholder.png';

Image.propTypes = {
  story: PropTypes.object.isRequired,
  onChange: PropTypes.func
};

export default function Image(props) {
  const { story } = props;

  if (story.data.imageUrl) {
    return (
      <img className="rounded-t-xlx h-full w-full object-center object-cover" src={story.data.imageUrl} />
    );
  }

  return (
    <div className="rounded-t-xlx"  style={{ backgroundColor: 'rgba(0,0,0,.54)' }}>
      <img className="h-full m-auto" src={PlaceholderImage} />
    </div>
  );
};
