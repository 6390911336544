import React from 'react';
import Breadcrumbs from './Breadcrumbs';

export default function PageHeading(props) {
  const { breadcrumbs = [], title, description, children } = props;

  return (
    <div className="space-y-2">
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <div className="text-3xl font-semibold text-cs-gray-900">
            {title}
          </div>
          {description && (
            <div className="mt-2 max-w-4xl text-sm text-gray-500">
              {description}
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
}
