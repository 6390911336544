import React from 'react';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import useDialogRouter from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogRouter';
import usePageRouter from '../../../hooks/@lore/dialogs-routable/_hooks/usePageRouter';
import Dialog from '../../dialogs_new/mobile_navigation_menu';

export default function Launcher(props) {
  const config = useDialogConfig();

  // const router = config.params.parentDialogId ?
  //   useDialogRouter(config.params.parentDialogId) :
  //   usePageRouter();

  const router = usePageRouter();

  return (
    <Dialog
      router={router}
      onClose={props.onClose}
    />
  );
};
