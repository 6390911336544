import { useState } from 'react';
import moment from 'moment-timezone';
import useQueryBase from './useQuery';

export default function useQuery(props) {
  const [timestamp] = useState(moment().toISOString());

  return useQueryBase({
    ...props,
    extra: {
      createdAt: {
        $gte: moment(timestamp).subtract(4, 'weeks').toISOString()
      }
    }
  });
}
