import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import Dialog from '../steward_invitation_revoke';

export default function Launcher(props) {
  const config = useDialogConfig();

  const stewardInvitation = useConnect('stewardInvitation.byId', {
    id: config.params.stewardInvitationId
  });

  if (stewardInvitation.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Dialog
      stewardInvitation={stewardInvitation}
      onClose={props.onClose}
    />
  );
};
