import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Event from '../../events/Event';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Events']}
      title="Expired but not Closed"
      description={(
        <div className="space-y-2">
          <div>
            These are projects where the event date an educator specified has passed. CommunityShare
            recommends focusing your efforts on these events, as getting these resolved will help you
            with your community's impact reporting. Reach out with the goal of getting educators to
            determine the status of their project.
          </div>
          <div>
            1. Has the educator rescheduled the event? Have them adjust the date of their event.
          </div>
          <div>
            2. Did the educator complete the event? Have them select the “Close Event” button and
            select that the event happened, which will unlock evaluations for participants.
          </div>
          <div>
            3. Will the educator not be able to complete the event this year? Have them select
            the “Close Event” button and the event will be marked as cancelled. They can always
            duplicate the event to try again in the future.
          </div>
        </div>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(event) {
        return (
          <Event key={event.id} event={event} />
        );
      }}
    />
  );
}
