import React from 'react';
import PropTypes from 'prop-types';
import ScrollToTop from '../../../components/ScrollToTop';
import Sidebar from '../../_components/Sidebar.new';
import useRouter from '../../../hooks/useRouter';
import NavigationDropdown from '../../dashboard/NavigationDropdown';
import NavigationLabel from '../../_navigation/ThickNavigation/NavigationLabel';
import NavigationGroup from '../../_navigation/ThickNavigation/NavigationGroup';
import SidebarTitle from '../../_components/SidebarTitle';
import SidebarNavigation from '../../_components/SidebarNavigation';
import useNavigationLinks from './useNavigationLinks';

LayoutFeed.propTypes = {
  children: PropTypes.node
};

export default function LayoutFeed(props) {
  const { children } = props;
  const { location } = useRouter();

  const { pageLinks, timeFrameLinks } = useNavigationLinks();

  return (
    <div className="flex overflow-hidden h-screen">
      <Sidebar>
        <SidebarTitle title={(
          <>
            Feed
            <div className="text-base text-cs-gray-500">
              (superuser only)
            </div>
          </>
        )} />
        <NavigationGroup>
          <NavigationLabel>
            Time frame
          </NavigationLabel>
          <div className="-mx-3">
            <NavigationDropdown
              header="Switch time frame to"
              navigationLinks={timeFrameLinks}
            />
          </div>
        </NavigationGroup>
        <SidebarNavigation navigation={pageLinks} />
      </Sidebar>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="flex-1 overflow-y-auto">
          <ScrollToTop key={`scroll-to-top-${location.pathname}`}/>
          {children}
        </div>
      </div>
    </div>
  );
}
