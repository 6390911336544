import React from 'react';
import ClampableText from '../../../components/ClampableText';
import ContentCard from '../../../components_profiles/ContentCard';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import TextContentSection from '../../../components_profiles/TextContentSection';
import MissingField from '../EventMissingField';

export default function Description(props) {
  const { event } = props;

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  });

  return (
    <ContentCard className="space-y-4">
      {(event.data.title || activity.data.name) ? (
        <div className="text-2xl text-cs-gray-900 font-semibold">
          {event.data.title || activity.data.name}
        </div>
      ) : (
        <MissingField name="Title" />
      )}
      <TextContentSection title="Description">
        {event.data.interactionDescription ? (
          <ClampableText markup>
            {event.data.interactionDescription || activity.data.description}
          </ClampableText>
        ) : (
          <MissingField name="Description" />
        )}
      </TextContentSection>
    </ContentCard>
  );
};
