import React from 'react';
import LazyMetrics from '../LazyMetrics';
import ActivityByMonth from './ActivityByMonth';
import ProjectActivityYear from './ProjectActivityYear';
import ProjectResponseYear from './ProjectResponseYear';
import ProjectStatusYear from './ProjectStatusYear';
import TotalYear from './TotalYear';
import StudentsYear from './StudentsYear';
import QuestionsByMonth from './QuestionsByMonth';
import QuestionDeclineReasonYear from './QuestionDeclineReasonYear';
import QuestionCreatorYear from './QuestionCreatorYear';
import QuestionDeclinerYear from './QuestionDeclinerYear';

export default function Projects(props) {
  const { params } = props;

  return (
    <>
      <LazyMetrics
        // title="Projects"
        columns={1}
      >
        <ActivityByMonth {...params} />
        <ProjectActivityYear {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          <ProjectResponseYear {...params} />
          <ProjectStatusYear {...params} />
        </div>
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <TotalYear {...params} />
          <StudentsYear {...params} />
        </div>
      </LazyMetrics>
      <LazyMetrics
        title="Invitations - Projects"
        columns={1}
      >
        <QuestionsByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          <QuestionCreatorYear {...params} />
          <QuestionDeclinerYear {...params} />
        </div>
        <QuestionDeclineReasonYear {...params} />
      </LazyMetrics>
    </>
  );
}
