import React from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Template from '../../../components_profiles/Template';
import useProjectTags from '../../../hooks/useProjectTags';
import TagTypes from '../../../constants/TagTypes';

export default function Skills(props) {
  const { project } = props;

  const projectExpertiseTypes = useProjectTags(project, TagTypes.ExpertiseType);

  if (projectExpertiseTypes.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (projectExpertiseTypes.data.length === 0) {
    return null;
  }

  return (
    <Template
      icon={QuestionMarkCircleIcon}
      title="Expertise required"
      subtitle="Career or volunteer experience with..."
      tags={projectExpertiseTypes}
    />
  );
};
