import React from 'react';
import PropTypes from 'prop-types';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { PayloadStates } from '@lore/utils';
import { useDialog } from '@lore/dialogs';
// import EventVisibilityDialog from '../../dialogs/event.visibility';
import useMissingFields from '../useMissingFields';
import Tooltip from '../../../components/Tooltip';

export default function Visibility(props) {
  const { event } = props;

  const show = useDialog();
  const { missingFields } = useMissingFields(event);

  if (missingFields.state === PayloadStates.FETCHING) {
    return (
      <div className="flex space-x-1 items-center text-gray-500 text-sm">
        <Tooltip tooltip="Visibility" icon={false}>
          <EyeIcon className="h-6 w-6" />
        </Tooltip>
        <span>
          ...
        </span>
      </div>
    );
  }

  function onChangeVisibility() {
    // show(
    //   <EventVisibilityDialog
    //     event={event}
    //   />
    // );
  }

  if (missingFields.data.length > 0) {
    return (
      <div className="flex space-x-1 items-center text-gray-500 text-sm">
        <Tooltip tooltip="Visibility" icon={false}>
          <EyeSlashIcon className="h-6 w-6" />
        </Tooltip>
        <span>
          Incomplete
        </span>
      </div>
    );
  }

  if (event.data.published) {
    return (
      <div className="flex space-x-1 items-center text-gray-500 text-sm">
        <Tooltip tooltip="Visibility" icon={false}>
          <EyeIcon className="h-6 w-6" />
        </Tooltip>
        {/*<span className="text-accent cursor-pointer" onClick={onChangeVisibility}>*/}
        <span>
          Visible
        </span>
      </div>
    );
  }

  return (
    <div className="flex space-x-1 items-center text-gray-500 text-sm">
      <Tooltip tooltip="Visibility" icon={false}>
        <EyeSlashIcon className="h-6 w-6" />
      </Tooltip>
      {/*<span className="text-accent cursor-pointer" onClick={onChangeVisibility}>*/}
      <span>
        Hidden
      </span>
    </div>
  );
};
