import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import UserTabBar from './UserTabBar';
import UserManageProfileLayout from './user-manage-profile/Layout';
import UserManageParticipationLayout from './user-manage-participation/Layout';
import UserManageEvaluationsLayout from './user-manage-evaluations/Layout';
import UserNotes from './UserNotes';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import useDialogRouter from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogRouter';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet-async';

User.propTypes = {
  user: PropTypes.object.isRequired
};

export default function User(props) {
  const { user } = props;

  const { id, route } = useDialogConfig();
  const router = useDialogRouter(id);

  useEffect(() => {
    if (route === '/') {
      router.navigate('/profile');
    }
  }, []);

  if (user.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const pageMap = {
    '/profile': 'Profile',
    '/participation': 'Participation',
    '/evaluations': 'Evaluations'
  };

  const pathname = route.split('?')[0];
  const page = pageMap[pathname] || pathname;

  return (
    <div className="space-y-6 -mt-12">
      <Helmet>
        <title>
          {user.data.title ? `${user.data.title}: ${page}` : `User #${user.id}: ${page}`}
        </title>
      </Helmet>
      <Header user={user} />
      <div className="-mx-6">
        <UserNotes user={user} />
        <UserTabBar user={user} />
      </div>
      <div className="space-y-12">
        {pathname === `/profile` && (
          <UserManageProfileLayout
            user={user}
            onClose={props.onClose}
          />
        )}
        {pathname === `/participation` && (
          <UserManageParticipationLayout
            user={user}
            onClose={props.onClose}
          />
        )}
        {pathname === `/evaluations` && (
          <UserManageEvaluationsLayout
            user={user}
            onClose={props.onClose}
          />
        )}
      </div>
    </div>
  );
};
