import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import useOfferTags from '../../../hooks/useOfferTags';
import TagTypes from '../../../constants/TagTypes';
import BaseParticipation from '../../../components_profiles/BaseParticipation';
import ContentCard from '../../../components_profiles/ContentCard';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';
import { ShareIcon } from '@heroicons/react/24/outline';
import MissingField from '../../offer_manage/OfferMissingField';

Participation.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function Participation(props) {
  const { offer } = props;

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  const offerInteractionTypes = useOfferTags(offer, TagTypes.InteractionType);

  if (offerInteractionTypes.data.length === 0) {
    return (
      <ContentCard className="space-y-8">
        <SubSectionHeader
          icon={ShareIcon}
          title="Participation Options"
        />
        <MissingField name="Participation Options" />
      </ContentCard>
    );
  }

  return (
    <BaseParticipation
      creator={creator}
      interactionTypes={offerInteractionTypes}
    />
  );
}
