import React from 'react';
import moment from 'moment-timezone';
import FeedItem from '../../feed/FeedItem';
import { useConnect } from '@lore/query-connect';
import FeedHoverText from '../../feed/FeedHoverText';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function Project(props) {
  const { project } = props;

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  const launch = useDialogLauncher();

  function onClickCreator() {
    launch(DialogManager.UserManage.Config(creator.id));
  }

  function onClickProject() {
    launch(DialogManager.ProjectManage.Config(project.id));
  }

  return (
    <FeedItem timestamp={moment(project.data.createdAt).fromNow()} last={props.last}>
      <FeedHoverText onClick={onClickCreator}>
        {creator.data.firstName} {creator.data.lastName}
      </FeedHoverText> created the project <FeedHoverText onClick={onClickProject}>
        {project.data.title || activity.data.name}
      </FeedHoverText>
    </FeedItem>
  );
}
