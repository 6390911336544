import React from 'react';
import ContentCard from './ContentCard';
import SubSectionHeader from './SubSectionHeader';

export default function Template(props) {
  const {
    title,
    subtitle,
    icon,
    tags
  } = props;

  return (
    <ContentCard className="space-y-4">
      <SubSectionHeader
        icon={icon}
        title={title}
        subtitle={subtitle}
      />
      {tags && (
        <ul className="list-disc ml-6 text-cs-gray-500 text-base">
          {tags.data.map(function(tag) {
            return (
              <li key={tag.id || tag.cid}>
                {tag.data.name}
              </li>
            );
          })}
        </ul>
      )}
    </ContentCard>
  );
}
