import { extractObjectFromQuery } from '../../../utils/query';

let currentUser = null;

export function setCurrentUser(user) {
  currentUser = user;
}

export default {
  param: extractObjectFromQuery({
    radius: 5
  }),
  query: function (value) {
    if (currentUser) {
      return {
        id: {
          $location: {
            radius: value.address ? value.radius : 50,
            latitude: currentUser.data.latitude,
            longitude: currentUser.data.longitude,
            // address: state.value.address
          }
        }
      };
    }

    if (
      value.radius &&
      value.address &&
      value.latitude &&
      value.longitude
    ) {
      return {
        id: {
          $location: {
            radius: value.radius,
            latitude: value.latitude,
            longitude: value.longitude,
            // address: state.value.address
          }
        }
      };
    }
  }
}
